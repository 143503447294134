import React, { Component } from 'react'
import '../assets/vendor/icofont/icofont.min.css'
import '../assets/vendor/boxicons/css/boxicons.min.css'
import '../assets/vendor/venobox/venobox.css'
import '../assets/vendor/owl.carousel/assets/owl.carousel.min.css'
import '../assets/vendor/bootstrap/css/bootstrap.css'
import '../assets/css/style.css'
import NavbarL from './NavBar/NavbarLanding'
import Alert from 'react-bootstrap/Alert'
import {Link,Redirect} from 'react-router-dom'
const axios = require('axios');



class Recover extends Component{
    constructor(props){
        super(props)
        this.state={
            redirect:"",
            form:{
                email:"",
            },
        }

    }

    onChange =(event)=>{
        this.setState({
            form: {
            ...this.state.form,
            [event.target.name]:event.target.value,
        }
    })
}
    RecoverPass=async ()=>{
        var baseurl = "https://api.restaured.net"
        var data = JSON.stringify([{"email":this.state.form.email}]);
        var baseurl = "https://api.restaured.net"
        const url = baseurl+'/auth/recoverPassword'
        var config = {
            method: 'post',
            url: url,
            headers: { 
              'Content-Type': 'application/json'
            },
            withCredentials:true,
            data: data
          };
        await axios(config)
        .then(response => {
            if(response.status === 200){
                this.setState({alertEmail:'El email ha sido enviado, por favor revise su correo'})
            }else{
                this.setState({alertEmail:'Lo sentimos, no tenemos ese email registrado en el sistema'})
            }
        })
    }

    render(){
        return(
            <div> 
              <div>
                <NavbarL/>
                {/* ======= Header ======= */}
                <main id="main">
                <div className="container">
                    <div className="row">
                    <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                        <div className="card card-signin my-5">
                        { this.state.redirect ? <Redirect push to= {{pathname:"login"}}></Redirect> : null}
                        {this.state.alertEmail? <Alert dismissible onClose={(e) => {this.setState({alertEmail:null, redirect:true})}} variant={'primary'}> {this.state.alertEmail}</Alert> : null}
                        <div className="card-body">
                            <h5 className="card-title text-center">Recuperar Contraseña</h5>
                            <form className="form-signin mt-3 mb-3">
                            <div className="form-label-group">
                                <input type="email" name="email" id="inputEmail" onChange={this.onChange} className="form-control" placeholder="Correo Electrónico" required autofocus />
                                <label htmlFor="inputEmail">Correo Electrónico</label>
                            </div>
                                <button onClick={this.RecoverPass} onEnter={this.RecoverPass} className="btn btn-lg btn-primary btn-block text-uppercase" >Enviar</button>
                            </form>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </main>
                {/* End #main */}
                <style dangerouslySetInnerHTML={{__html: "\n      :root {\n  --input-padding-x: 1.5rem;\n  --input-padding-y: .75rem;\n}\n\nbody {\n  background: #1cc88a;\n  background: linear-gradient(to right, #1cc88a, #67b0d1);" }} />
            </div>
            </div>
        );
    }
}

export default  Recover