import React from 'react'
import { Pie } from '@reactchartjs/react-chart.js'

// const data = {
//   labels: ['Machos', 'Hembras'],
//   datasets: [
//     {
//       label: 'MvH',
//       data: [42,58],
//       backgroundColor: [
//         'rgba(255, 99, 132, 0.2)',
//         'rgba(54, 162, 235, 0.2)',
//       ],
//       borderColor: [
//         'rgba(255, 99, 132, 1)',
//         'rgba(54, 162, 235, 1)',
//       ],
//       borderWidth: 1,
//     },
//   ],
// }

const PieChart = (data, title) => (
  <>
    <div className='header'>
      <h3 className='title'>{title}</h3>
      <div className='links'>
        {/* <a
          className='btn btn-gh'
          href='https://github.com/reactchartjs/react-chartjs-2/blob/react16/example/src/charts/Pie.js'
        >
          Github Source
        </a> */}
      </div>
    </div>
    <Pie data={data} />
  </>
)

export default PieChart