import React , {Component} from 'react'
import '../../assets/css/sb-admin-2.css'
import '../../assets/vendor/bootstrap/css/bootstrap.css'
import Collapse from 'react-bootstrap/Collapse'
import { Link } from "react-router-dom";



class NavbarA extends Component{
  constructor(props){
    super(props);
    this.state={
      Rol: this.props.Rol,
      Nombre: this.props.Nombre,
      Iduser: this.props.Iduser,
      open: false,
    }
  }
  setOpen=(e)=>{
    e = !e;
    this.setState({
          open: e
    })
  } 
  render(){
    return(
        <div id="wrapper" >
              {/* Sidebar */}
              <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
                {/* Sidebar - Brand */}
                <Link to="/restaured.admin"> 
                  <p className="sidebar-brand d-flex align-items-center justify-content-center">
                    <div className="sidebar-brand-text mx-3">RestauRed  <sup>Admin</sup></div>
                  </p>
                 </Link>
                {/* Divider */}
                <hr className="sidebar-divider my-0" />
                {/* Nav Item - Dashboard */}
                {/* Divider */}
                <hr className="sidebar-divider" />
                {/* Heading */}
                <div className="sidebar-heading">
                  Sistema
                </div>
                {/* Nav Item - Pages Collapse Menu */}
                <li className="nav-item">
                    <p
                      onClick={() => this.setOpen(this.state.open)}
                      aria-controls="users-control"
                      aria-expanded={this.state.open}
                      type="button"
                      className ="nav-link"
                    >
                      Usuarios
                    </p>
                    <Collapse in={this.state.open}>
                      <div id="users-control">
                          <div className="bg-white py-2 collapse-inner rounded">
                            <Link to="/restaured.admin/cusers">
                              <p className="collapse-item" >Crear Nuevo Usuario</p>
                            </Link>
                            <Link to="/restaured.admin/vusers">
                              <p className="collapse-item" href="/vusers">Usuarios Registrados</p>
                            </Link>
                        </div>
                      </div>
                    </Collapse>
                </li>
                {/* Nav Item - Charts */}
                <li className="nav-item">
                  <Link to="/restaured.admin/location"> 
                    <p className="nav-link">
                    <span>Localizaciones</span></p>
                  </Link>
                </li>
                {/* Nav Item - Tables */}
                <li className="nav-item">
                  <Link to="/restaured.admin/charts"> 
                    <p className="nav-link">
                      <i className="fas fa-fw fa-table" />
                      <span>Especies</span></p>
                  </Link>
                </li>
                {/* Nav Item - Charts */}
                <li className="nav-item">
                  <Link to="/restaured.admin/monitoreo"> 
                  <p className="nav-link" >
                    <i className="fas fa-fw fa-chart-area" />
                    <span>Monitoreos</span></p>
                  </Link>
                </li>
                {/* Divider */}
                <hr className="sidebar-divider d-none d-md-block" />
                {/* Sidebar Toggler (Sidebar) */}
                <div className="text-center d-none d-md-inline">
                  <button className="rounded-circle border-0" id="sidebarToggle" />
                </div>
              </ul>
              {/* End of Sidebar */}
        </div>
     )};
};   
   
export default NavbarA