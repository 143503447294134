import React,{ Component} from 'react'
import { Redirect } from 'react-router'
import '../../assets/vendor/bootstrap/css/bootstrap.css'
import '../../assets/css/sb-admin-2.css'
const axios = require('axios');

export default class CambiarPassword extends Component{
	constructor(props){
		super(props);
		this.state={
				Rol: this.props.Rol,
				Nombre: this.props.Nombre,
				Iduser: this.props.Iduser,
				External: this.props.External,
				open:false,
				volver:false,
				form:{
					password:"",
					newPassword:"",
				},
		}
	}
	//HANDLEE
	onChange = (event ) => {
		this.setState({
			 form: {
					...this.state.form,
					[event.target.name]:event.target.value,
			 }
		});
	};
	//ACCIONES DE BOTONES
	Cancelar=async()=>{
		this.setState({volver : true})
	}
	Guardar=async()=>{
		var body = {
				_id: this.props.Iduser,
				password: this.state.form.password,
				newPassword: this.state.form.newPassword,
			}
		var baseurl = "https://api.restaured.net"
		var url = baseurl+'/auth/user/changepassword'
		var data = JSON.stringify([body])
		var config = {
			method: 'put',
			url: url,
			headers: { 
				'Content-Type': 'application/json'
			},
			withCredentials:true,
			data: data
		};
		await axios(config)
		this.setState({volver : true})
	}
	// VISUALIZACION
	renderCambiarPasswordEdit=()=>{
		console.log(this.props)
		if(!this.state.External){
			var form=[]
			form.push(<>
				<div className="row pd-5 mb-5 mt-5">
					<div className="col-lg-6 pd-5">
					<p><strong> Contraseña Actual: </strong></p>	
					</div>
					<div className="col-lg-4">
						<input className="form-control" type="password" value={this.state.form.password} name="password" onChange={this.onChange} />
					</div>
				</div>		
				<div className="row pd-5 mb-5">
					<div className="col-lg-6">
					<p><strong> Nueva Contraseña: </strong></p>	
					</div>
					<div className="col-lg-4">
						<input className="form-control" type="password" value={this.state.form.newPassword} name="newPassword" onChange={this.onChange} />
					</div>
				</div>		
				
			</>)		
			return (<>{form}</>)
		}else{
			return (<></>)
		}
	}
    render(){
        return(
            <div>
			<div id="wrapper">
				<div id="content-wrapper" className="d-flex flex-column">
					<div id="content">
						<div className="container-fluid">							
						{ this.state.volver ? <Redirect push to={{ pathname:this.props.Rol}}> </Redirect>: null }
						<div className="card shadow mb-5 mt-5 pd-5"> 
							<h1 className="h1 mb-2 mt-5 pd-5 text-gray-800">Cambio de Contraseña</h1>
							{this.renderCambiarPasswordEdit()}
							<div className="row mb-3 pd-5">
								<div className="col-lg-6 mb-5 pt-5">
									<button className="btn btn-primary" onClick={(e) =>this.Cancelar()}> 
										<strong> Cancelar </strong>
									</button>
								</div>
								<div className="col-lg-6 mb-5 pt-5">
								<button  onClick={(e)=> this.Guardar()} className="btn btn-primary pd-5"> 
									<strong> Guardar </strong>
								</button>
								</div>
								<br/>
							</div>
						</div>
						</div>
					</div>
					 <footer id="footer" className="sticky-footer">
						<div className="container my-auto">
							<div className="copyright text-center my-auto">
								© Copyright <strong><span>Restaured 2024</span></strong>. All Rights Reserved
							</div>
						</div>
					</footer>
				</div>
			</div>
			<a className="scroll-to-top rounded" href="#page-top">
			<i className="fas fa-angle-up" />
			</a>
		</div>
        )
    }
}