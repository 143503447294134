import React,{ Component} from 'react'
import { Redirect} from 'react-router'
import NavbarC from '../../NavBar/NavbarUsersCC'
import HeaderUsers from './HeaderUsers'
import '../../../assets/vendor/bootstrap/css/bootstrap.css'
import '../../../assets/css/sb-admin-2.css'
import {Link} from 'react-router-dom'
import { BsPlusCircle, BsTrash, BsArchive,BsGeoAlt } from "react-icons/bs"
import { useTable, usePagination,useRowSelect } from 'react-table'
import {Collapse} from 'react-bootstrap'
import Alert from 'react-bootstrap/Alert'
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {v4 as uuidv4} from 'uuid';
import { MapContainer,GeoJSON, TileLayer} from "react-leaflet";
import Logout from './Logout'
import L from 'leaflet';
import Icono from '../../../assets/img/iconMap.png'
const axios = require('axios');
// Create an editable cell renderer
const EditableCell = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateMyData, // This is a custom function that we supplied to our table instance
  }) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue)
  
    const onChange = e => {
      setValue(e.target.value)
    }
  
    // We'll only update the external data when the input is blurred
    const onBlur = () => {
      updateMyData(index, id, value)
    }
  
    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
      setValue(initialValue)
    }, [initialValue])
  
    return <input className="form-control" value={value} onChange={onChange} onBlur={onBlur} />
}
const NoNEditableCell = ({
	value: initialValue,
	row: { index },
	column: { id },
	updateMyData, // This is a custom function that we supplied to our table instance
}) => {
	// We need to keep and update the state of the cell normally
	const [value, setValue] = React.useState(initialValue)

	const onChange = e => {
		setValue(e.target.value)
	}

	// We'll only update the external data when the input is blurred
	const onBlur = () => {
		updateMyData(index, id, value)
	}

	// If the initialValue is changed external, sync it up with our state
	React.useEffect(() => {
		setValue(initialValue)
	}, [initialValue])

	return <input disabled className="form-control" value={value} onChange={onChange} onBlur={onBlur} />
}  

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  }
)
const IndeterminateCheckboxBody = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  }
)
function DeleteEntrada(event,selectedRowIds,monitoreos,Iduser,location){
	var selectedMonitoreo = location.state
	var idEntradas=[]
	var idVariables=[]
	for(const i in selectedRowIds){
		var idEntrada ={
			_id: monitoreos[selectedMonitoreo.monitoreo].registros.entradas[selectedMonitoreo.registro].id[i]
		}
		for(const j in monitoreos[selectedMonitoreo.monitoreo].registros.entradas[selectedMonitoreo.registro].idVariablesRegistro[i]){
			idVariables.push({_id: monitoreos[selectedMonitoreo.monitoreo].registros.entradas[selectedMonitoreo.registro].idVariablesRegistro[i][j]})
		}
		idEntradas.push(idEntrada)
	}
	var baseurl = "https://api.restaured.net"
	var url = baseurl+'/restaured/variableregistro'
	var data = JSON.stringify(idVariables)
	var config = {
		method: 'delete',
		url: url,
		headers: { 
		'Content-Type': 'application/json'
		},
		withCredentials:true,
		data: data
	};
	axios(config)
	.then(response => {console.log(response);	})
	.catch(error => console.log(error));
	url = baseurl+'/restaured/entradamonitoreo'
	data = JSON.stringify(idEntradas)
	var config2 = {
		method: 'delete',
		url: url,
		headers: { 
		'Content-Type': 'application/json'
		},
		withCredentials:true,
		data: data
	};
	axios(config2)
	.then(response => {console.log(response);	})
	.catch(error => {
		var monitoreosLocal = localStorage.getItem('monitoreos')
		monitoreosLocal = JSON.parse(monitoreosLocal)
		console.log(selectedRowIds,monitoreosLocal[location.state.monitoreo].registros.entradas[location.state.registro] )
		for(const i in idEntradas){
			for(const j in monitoreosLocal[location.state.monitoreo].registros.entradas[location.state.registro].id){
				if(idEntradas[i]._id === monitoreosLocal[location.state.monitoreo].registros.entradas[location.state.registro].id[j]){
					monitoreosLocal[location.state.monitoreo].registros.entradas[location.state.registro].id.splice(j, 1)
					monitoreosLocal[location.state.monitoreo].registros.entradas[location.state.registro].curado.splice(j, 1)
					monitoreosLocal[location.state.monitoreo].registros.entradas[location.state.registro].img.splice(j, 1)
					monitoreosLocal[location.state.monitoreo].registros.entradas[location.state.registro].especies.splice(j, 1)
					monitoreosLocal[location.state.monitoreo].registros.entradas[location.state.registro].idLocalizacion.splice(j, 1)
					monitoreosLocal[location.state.monitoreo].registros.entradas[location.state.registro].valores.splice(j, 1)
					monitoreosLocal[location.state.monitoreo].registros.entradas[location.state.registro].variables.splice(j, 1)
					monitoreosLocal[location.state.monitoreo].registros.entradas[location.state.registro].idVariablesRegistro.splice(j, 1)
				}
			}
		}
		localStorage.setItem('monitoreos', JSON.stringify(monitoreosLocal))
		var local = localStorage.getItem('entradaQueries')	
		if(local !== null){
			local = JSON.parse(local)
		}else{
			local=[]
		}
		var idDelete =[]
		for(const i in idEntradas){
			for(const j in local){
				var data = JSON.parse(local[j].entrada.data)
				if(idEntradas[i]._id === data[0].id && local[j].entrada.method === 'post'){
					local.splice(j,1)
					idDelete.push(i)
				}	
			}
		}
		console.log(local, idEntradas)
		localStorage.setItem('entradaQueries', JSON.stringify(local))		
		for(var i = idDelete.length -1;i>=0;i--){
			idEntradas.splice(idDelete[i], 1)
		}
		var dataVariables=[]
		for(const h in idEntradas){
			for(const i in selectedRowIds){
				for(const j in monitoreos[selectedMonitoreo.monitoreo].registros.entradas[selectedMonitoreo.registro].idVariablesRegistro[i]){
					if(idEntradas[h]._id ===monitoreos[selectedMonitoreo.monitoreo].registros.entradas[selectedMonitoreo.registro].id[i])
					dataVariables.push({_id: monitoreos[selectedMonitoreo.monitoreo].registros.entradas[selectedMonitoreo.registro].idVariablesRegistro[i][j]})
				}
			}
		}
		var configVariables = {
			method: 'delete',
			url: baseurl+'/restaured/variableregistro',
			headers: { 
			'Content-Type': 'application/json'
			},
			withCredentials:true,
			data: JSON.stringify(dataVariables)
		}
		console.log(local, idEntradas, configVariables)
		var configEntrada = {
			method: 'delete',
			url: url,
			headers: { 
			'Content-Type': 'application/json'
			},
			withCredentials:true,
			data: JSON.stringify(idEntradas)
		}
		var localRegistro = localStorage.getItem('registroQueries')
		if(localRegistro !== null){
			localRegistro = JSON.parse(localRegistro)
		}else{
			localRegistro=[]
		}
		localRegistro.push(configVariables)
		localRegistro.push(configEntrada)
		localStorage.setItem('registroQueries', JSON.stringify(localRegistro))
		
	})
}
function EditarEntrada(event,selectedRowIds,monitoreos,Iduser,location,data){
	var selectedMonitoreo = location.state
	var variablesActualizar=[]
	var variablesAgregar=[]
	for( const i in selectedRowIds){
		for(const k in monitoreos[selectedMonitoreo.monitoreo].variables){
			if(data[i][monitoreos[selectedMonitoreo.monitoreo].variables[k].nombre] !== undefined){
				for(const l in monitoreos[selectedMonitoreo.monitoreo].registros.entradas[selectedMonitoreo.registro].variables[i]){
					var variable;
					if(monitoreos[selectedMonitoreo.monitoreo].variables[k].id === 
					monitoreos[selectedMonitoreo.monitoreo].registros.entradas[selectedMonitoreo.registro].variables[i][l]){
						variable = {
							_id: monitoreos[selectedMonitoreo.monitoreo].registros.entradas[selectedMonitoreo.registro].idVariablesRegistro[i][l],
							valor: data[i][monitoreos[selectedMonitoreo.monitoreo].variables[k].nombre]
						}
						variablesActualizar.push(variable)
					}else{
						variable ={
							idEntrada : monitoreos[selectedMonitoreo.monitoreo].registros.entradas[selectedMonitoreo.registro].id[i],
							idVariable:monitoreos[selectedMonitoreo.monitoreo].variables[k].id,
							valor: data[i][monitoreos[selectedMonitoreo.monitoreo].variables[k].nombre]
						}
						variablesAgregar.push(variable)
					}
				}
			}				  
		}
	
	}
	console.log(variablesAgregar)
	console.log(variablesActualizar)
	var baseurl = "https://api.restaured.net"
	var url = baseurl+'/restaured/variableregistro'
	// ACTUALIZAR DATOS
	var datos = JSON.stringify(variablesActualizar)
	var config = {
		method: 'put',
		url: url,
		headers: { 
		'Content-Type': 'application/json'
		},
		withCredentials:true,
		data: datos
	};
	axios(config)
	.then(response => console.log(response))
	.catch(error => console.log(error))
	// AGREGAR NUEVOS DATOS
	var data2 = JSON.stringify(variablesAgregar)
	var config2={
	method: 'post',
	url: url,
	headers: { 
	'Content-Type': 'application/json'
	},
	withCredentials:true,
	data: data2
	}
	axios(config2)
	.then(response => console.log(response))
	.catch(error => {
		var local = localStorage.getItem('registroQueries')	
		if(local !== null){
			local = JSON.parse(local)
			console.log(error, config)
			local.push(config)
			localStorage.setItem('registroQueries', JSON.stringify(local))

		}else{
			local =[]
			local.push(config)
			localStorage.setItem('registroQueries', JSON.stringify(local))
		}
	})
}
function RenderDeleteEntrada({selectedRowIds,monitoreos,Iduser,location}){
	var selectedMonitoreo = location.state
	if(Iduser === monitoreos[selectedMonitoreo.monitoreo].registros.iduser[selectedMonitoreo.registro]){
		return(
		<Link to={{pathname:"/restaured.CzScie/reloaderentrada",state:location.state}}>
			<button type="button" className="btn border" onClick={(event) => DeleteEntrada(event,selectedRowIds,monitoreos,Iduser,location)}> <BsTrash/><span>Eliminar Entradas </span></button>
		</Link>
		)
	}else{
		return(<></>)
	}
}
function RenderGuardarCambios({data,selectedRowIds,monitoreos,Iduser,location}){
	var selectedMonitoreo = location.state 
	if(Iduser === monitoreos[selectedMonitoreo.monitoreo].registros.iduser[selectedMonitoreo.registro]){
		return(
			<Link to={{pathname:"/restaured.CzScie/reloaderentrada",state:location.state}}>
				<button type="button" className="btn border" onClick={(event) => EditarEntrada(event,selectedRowIds,monitoreos,Iduser,location,data)}> <BsArchive/><span>Guardar Cambios </span></button>
			</Link>
		)
	}else{
		return(<></>)
	}
}

function RenderImagenes({selectedRowIds,monitoreos,Iduser,location}){
	const [imagen, setImagen] = React.useState('')
	const [redirect, setRedirect] = React.useState(null)
	var EntradaImagen
	const seleccionarImagen=(File)=>{
		setImagen(File[0])
	}
	const subirImagen=async()=>{
		var id =monitoreos[location.state.monitoreo].registros.entradas[location.state.registro].id[selectedRowIds.id]
		const form = new FormData()
		form.append('img', imagen)
		form.append('name', 'imagen')
		console.log(form)
		var baseurl = "https://api.restaured.net"
		var url = baseurl+'/restaured/entradamonitoreo/'+id+'/upload'
		var config = {
			method: 'post',
			url: url,
			headers: { 
				'Content-Type': 'application/json'
			},
			withCredentials:true,
			data: form
		};
		await axios(config)
		.then(response => {
			console.log(response)
		})
		.catch( error => console.log(error))  		
		setRedirect(true)
	}
	if(Iduser === monitoreos[location.state.monitoreo].registros.iduser[location.state.registro]){
		if(monitoreos[location.state.monitoreo].registros.entradas[location.state.registro].img[selectedRowIds.id]){
			const EntradaImagen = (
				<Popover id="popover-basic">
				<Popover.Title as="h3">Imagen</Popover.Title>
				<Popover.Content>
					<img style={{ width:'150%'}} src= {monitoreos[location.state.monitoreo].registros.entradas[location.state.registro].img[selectedRowIds.id]}/>
				</Popover.Content>
				</Popover>
			);
			return (
				<OverlayTrigger trigger="click" placement="right" overlay={EntradaImagen}>
				<button className="btn border">Ver Imagen</button>
			</OverlayTrigger>
			)
		}else{
			EntradaImagen = (
				<Popover id="popover-basic">
				  <Popover.Title as="h3">Agregar Imagen</Popover.Title>
				  <Popover.Content>
					<input type="file" accept='.png,.jpg,.jpeg,.webp' className="btn border" onChange={(e)=> seleccionarImagen(e.target.files)} placeholder="Subir Imagen"/>
					{ redirect ? <Redirect push to={{ pathname:"/restaured.CzScie/reloaderentrada",state:location.state}}> </Redirect>: null}
					<button className="btn border" onClick={(e) => subirImagen()}>Confirmar</button>
				  </Popover.Content>
				</Popover>
			  );
			  return (
				<OverlayTrigger trigger="click" placement="right" overlay={EntradaImagen}>
				<button className="btn border">Subir Imagen</button>
			  </OverlayTrigger>
			)		
		}
	}else{
		if(monitoreos[location.state.monitoreo].registros.entradas[location.state.registro].img[selectedRowIds.id]){
			const EntradaImagen = (
				<Popover id="popover-basic">
				<Popover.Title as="h3">Imagen</Popover.Title>
				<Popover.Content>
					<img style={{ width:'150%'}} src= {monitoreos[location.state.monitoreo].registros.entradas[location.state.registro].img[selectedRowIds.id]}/>
				</Popover.Content>
				</Popover>
			);
			return (
				<OverlayTrigger trigger="click" placement="right" overlay={EntradaImagen}>
				<button className="btn border">Ver Imagen</button>
			</OverlayTrigger>
			)
		}
		else{
			return<></>
		}
	}
	  
}

function Table({ columns, data, updateMyData, skipPageReset,monitoreos, Iduser,location }) {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize,selectedRowIds },
    } = useTable(
      {
        columns,
        data,
        initialState:{pageSize:5},
        autoResetPage: !skipPageReset,
        updateMyData,
      },
      usePagination,
      useRowSelect,
      hooks => {
        hooks.visibleColumns.push(columns => [
          // Let's make a column for selection
          {
			id: 'imagen',
			// The header can use the table's getToggleAllRowsSelectedProps method
			// to render a checkbox
			Header: () => (
				<div>
				</div>
			),
			// The cell can use the individual row's getToggleRowSelectedProps method
			// to the render a checkbox
			Cell: ({ row }) => (
				<div>
					<RenderImagenes selectedRowIds={row} monitoreos={monitoreos} Iduser={Iduser} location={location}  />
				</div>
			),
		},
		{
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckboxBody {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ])
      }
    )  
    // Render the UI for your table
    return (
      <>
        <table className="table table-h-scroll"{...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
        <div className="pagination">
          <button className="page-link"onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {'<<'}
          </button>{' '}
          <button className="page-link"onClick={() => previousPage()} disabled={!canPreviousPage}>
            {'<'}
          </button>{' '}
          <button className="page-link"onClick={() => nextPage()} disabled={!canNextPage}>
            {'>'}
          </button>{' '}
          <button className="page-link"onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {'>>'}
          </button>{' '}
          <span className="page-link">
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
          <span className="page-link">
            | Go to page:{' '}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                gotoPage(page)
              }}
              style={{ width: '100px' }}
            />
          </span>{' '}
          <select
            className="page-link"
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value))
            }}
          >
            {[5, 10, 15, 20, 25].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
		<br/>
		<br/>
		<div className="row">
			<div className="col-lg-6">
				<RenderDeleteEntrada selectedRowIds={selectedRowIds} monitoreos={monitoreos} Iduser={Iduser} location={location}/>
			</div>
			<div className="col-lg-6"> 
				<RenderGuardarCambios  data ={data} selectedRowIds={selectedRowIds} monitoreos={monitoreos} Iduser={Iduser} location={location}/>
			</div>
		</div>
		<br/>
		<br/>
      </>
    )
}
function TablaEntradas(dataset,columnas, monitoreos,Iduser,location){
    const columns = React.useMemo(() => columnas,[])
    const [data, setData] = React.useState(dataset)
    const [originalData] = React.useState(data)
    const [skipPageReset, setSkipPageReset] = React.useState(false)
    const updateMyData = (rowIndex, columnId, value) => {
      // We also turn on the flag to not reset the page
      setSkipPageReset(true)
      setData(old =>
        old.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...old[rowIndex],
              [columnId]: value,
            }
          }
          return row
        })
      )
    }
    React.useEffect(() => {
      setSkipPageReset(false)
    }, [data])
    const resetData = () => setData(originalData)
  
    return (
      <div className="table-responsive">
        <button className="btn border" onClick={resetData}><span><strong>Reestablecer Datos </strong></span></button>
        <Table
          columns={columns}
          data={data}
          updateMyData={updateMyData}
          skipPageReset={skipPageReset}
          monitoreos={monitoreos}
          Iduser={Iduser}
          location={location}
        />
      </div>
    )
}

export default class  GenerarReporte extends Component{
  constructor(props){
    super(props);
    this.state={
        Rol: this.props.Rol,
        Nombre: this.props.Nombre,
        Iduser: this.props.Iduser,
        open:false,
        reload:false,
		alertAgrego:false,
		alertError:false,
		filepreview:null,
		temporalEspecie:null,
		inputLocalizacion:'',
        viewTablaEntradas: [],
        suggestions: [],
				gbifespecies:{
					canonicalName:[],
					order:[],
					family:[],
					key:[],
					genus:[],
				},
				datasetLocalizaciones: [],
        especie:{
          id:[],
          nombreCientifico:[],
          nombreComun:[],
          activo:[],
          idLocalizacion:[],
        },
        selectedEspecie:"",
		selectedOrder:"",
		selectedFamily:"",
		selectedVernacular:"",
		selectedGenus:"",
		selectedVariable:"",
		imgEntradaAgregar:[],
		imgEntrada:[],
        form:{
          fecha:"",
          nombre:"",
          valores:[],
          variable:"",
          especie:"",
          idMonitoreo:"",
          idUser:"",
          idRegistro:"",
          idLocalizacion:"",
          idEspecie:"",
          idEntrada:"",
          idVariables:[],
          variables:[],

        },
        variable:{
          id:[],
          idMonitoreo:[],
          nombre:[],
		  tipo:[],
		  unidades:[],
          pubObj:[]
        },
        variablexindicador:{
          id:[],
          idIndicador:[],
          idVariable:[]
        },
        indicador:{
          id:[],
          idMonitoreo:[],
          formula:[],
          nombre:[],
          pubObj:[],
          tipo:[],
          tipoGrafico:[],
          unidades:[],
        },
        monitoreo:{
          id:[],
          nombre:[],
          descripcion:[],
        },
        registroMonitoreo:{
          id:[],
          nombre:[],
          idUser:[],
          idMonitoreo:[],
        },
        entradaMonitoreo:{
          id:[],
          idLocalizacion:[],
          curado:[],
		  img:[],
          idRegistro:[],
          idEspecies:[],
        },
        variableRegistro:{
          id:[],
          idEntrada:[],
          idVariable:[],
          valor:[],
        },
        localizacion:{
					id:[],
					idSitio:[],
					isParcela:[],
					idTransecto:[],
					idPO:[],
				},
				sitio:{
					id:[],
					nombre:[],
					geos:[],
					descripcion:[],
				},
				parcela:{
					id:[],
					nombre:[],
					geos:[],
					descripcion:[],
				},
				transecto:{
					id:[],
					nombre:[],
					geos:[],
					descripcion:[],
				},
				PO:{
					id:[],
					nombre:[],
					geos:[],
					descripcion:[],
				},
		ingresarEntradaMonitoreo:{
			body:[],
			status:"",
		},
        monitoreos:[],

    }
    this.initial_state();
    this.renderTablaEntradas();
	}
	//PETICIONES
	Getespecies=async()=>{
		var baseurl = "https://api.restaured.net"
		const url = baseurl+'/restaured/especie'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
		  var id =[];var nombreCientifico =[]; var nombreComun =[]; var idLocalizacion =[];
		  var activo =[]; var familia =[]; var genero =[]; var orden =[];
		  for(const i in data.body){
			id.push(data.body[i]._id)
			idLocalizacion.push(data.body[i].idLocalizacion)
			nombreCientifico.push(data.body[i].nombreCientifico)
			nombreComun.push(data.body[i].nombreComun)
			familia.push(data.body[i].familia)
			orden.push(data.body[i].orden)
			genero.push(data.body[i].genero)
			activo.push(data.body[i].activo)
		  };
		  localStorage.setItem('especies', JSON.stringify({
			especies:{
			  id:id,
			  idLocalizacion:idLocalizacion,
			  nombreCientifico:nombreCientifico,
			  nombreComun:nombreComun,
			  orden:orden,
			  familia:familia,
			  genero:genero,
			  activo:activo, }}))
		  this.setState({
			especie:{
			  id:id,
			  idLocalizacion:idLocalizacion,
			  nombreCientifico:nombreCientifico,
			  nombreComun:nombreComun,
			  orden:orden,
			  familia:familia,
			  genero:genero,
			  activo:activo,
			}});
		})
		.catch( error => {
		  console.log(error)
		  var local = localStorage.getItem('especies');
		  local = JSON.parse(local)
		  this.setState({
			especie:{
			  id:local.especies.id,
			  idLocalizacion:local.especies.idLocalizacion,
			  nombreCientifico:local.especies.nombreCientifico,
			  nombreComun:local.especies.nombreComun,
			  orden:local.especies.orden,
			  familia:local.especies.familia,
			  genero:local.especies.genero,
			  activo:local.especies.activo,
			}});
		})
		// console.log(this.state.especies);
	} 
	getData=async()=>{
		var baseurl = "https://api.restaured.net"
		var url = baseurl+'/restaured/variable'
		try {
			await axios.get(url,{withCredentials: true})
			.then(response => response.data)
			.then(data => {
			// console.log(data)
			var id =[];
			var idMonitoreo =[];
			var nombre =[];
			var pubObj =[];
			var tipo =[];
			var unidades =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				idMonitoreo.push(data.body[i].idMonitoreo);
				nombre.push(data.body[i].nombre);
				pubObj.push(data.body[i].pubObj);
				unidades.push(data.body[i].unidades);
				tipo.push(data.body[i].tipo);
			};
			this.setState({
				variable:{
					id:id,
					idMonitoreo:idMonitoreo,
					nombre:nombre,
					pubObj:pubObj,
					unidades:unidades,
					tipo:tipo,
			}})
			})
			url = baseurl+'/restaured/variablexindicador'
			await axios.get(url,{withCredentials:true})
			.then(response => response.data)
			.then(data => {
			// console.log(data)
			var id =[];
			var idIndicador =[];
			var idVariable =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				idIndicador.push(data.body[i].idIndicador);
				idVariable.push(data.body[i].idVariable);
			};
			this.setState({
				variablexindicador:{
					id:id,
					idIndicador:idIndicador,
					idVariable:idVariable,
			}})
			})
			url = baseurl+'/restaured/indicador'
			await axios.get(url, {withCredentials:true})
			.then(response => response.data)
			.then(data => {
			// console.log(data)
			var id =[];
			var idMonitoreo =[];
			var formula =[];
			var nombre =[];
			var pubObj =[];
			var tipo =[];
			var tipoGrafico =[];
			var unidades =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				idMonitoreo.push(data.body[i].idMonitoreo);
				formula.push(data.body[i].formula);
				nombre.push(data.body[i].nombre);
				pubObj.push(data.body[i].pubObj);
				tipo.push(data.body[i].tipo);
				tipoGrafico.push(data.body[i].tipoGrafico);
				unidades.push(data.body[i].unidades);
			};
			this.setState({
				indicador:{
					id:id,
					idMonitoreo:idMonitoreo,
					formula:formula,
					nombre:nombre,
					pubObj:pubObj,
					tipo:tipo,
					tipoGrafico:tipoGrafico,
					unidades:unidades,
			}})
			})
			url = baseurl+'/restaured/localizacion'
			await axios.get(url, {withCredentials:true})
			.then(response => response.data)
			.then(data => {
			console.log(data)
			var id =[];
			var idSitio =[];
			var idParcela =[];
			var idTransecto =[];
			var idPO =[];
			var nombre =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				idSitio.push(data.body[i].idSitio);
				idParcela.push(data.body[i].idParcela);
				idTransecto.push(data.body[i].idTransecto);
				idPO.push(data.body[i].idPO);
				nombre.push(data.body[i].nombre);
			};
			this.setState({
				localizacion:{
					id:id,
					idSitio:idSitio,
					idParcela:idParcela,
					idTransecto:idTransecto,
					idPO:idPO,
					nombre:nombre,
			}})
			})
			url = baseurl+'/restaured/sitio'
			await axios.get(url, {withCredentials:true})
			.then(response => response.data)
			.then(data => {
			var id =[];
			var nombre =[];
			var geos =[];
			var descripcion =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				geos.push(data.body[i].geos);
				descripcion.push(data.body[i].descripcion);
				nombre.push(data.body[i].nombre);
			};
			this.setState({
				sitio:{
					id:id,
					nombre:nombre,
					geos:geos,
					descripcion:descripcion,
			}})
			})
			url = baseurl+'/restaured/puntoobservacion'
			await axios.get(url, {withCredentials:true})
			.then(response => response.data)
			.then(data => {
			var id =[];
			var nombre =[];
			var geos =[];
			var descripcion =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				geos.push(data.body[i].geos);
				descripcion.push(data.body[i].descripcion);
				nombre.push(data.body[i].nombre);
			};
			this.setState({
				PO:{
					id:id,
					nombre:nombre,
					geos:geos,
					descripcion:descripcion,
			}})
			})
			url = baseurl+'/restaured/transecto'
			await axios.get(url, {withCredentials:true})
			.then(response => response.data)
			.then(data => {
			var id =[];
			var nombre =[];
			var geos =[];
			var descripcion =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				geos.push(data.body[i].geos);
				descripcion.push(data.body[i].descripcion);
				nombre.push(data.body[i].nombre);
			};
			this.setState({
				transecto:{
					id:id,
					nombre:nombre,
					geos:geos,
					descripcion:descripcion,
			}})
			})
			url = baseurl+'/restaured/parcela'
			await axios.get(url, {withCredentials:true})
			.then(response => response.data)
			.then(data => {
			var id =[];
			var nombre =[];
			var geos =[];
			var descripcion =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				geos.push(data.body[i].geos);
				descripcion.push(data.body[i].descripcion);
				nombre.push(data.body[i].nombre);
			};
			this.setState({
				parcela:{
					id:id,
					nombre:nombre,
					geos:geos,
					descripcion:descripcion,
			}})
			})
			url = baseurl+'/restaured/monitoreo'
			await axios.get(url, {withCredentials:true})
			.then(response => response.data)
			.then(data => {
			// console.log(data)
			var id =[];
			var nombre =[];
			var descripcion =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				nombre.push(data.body[i].nombre);
				descripcion.push(data.body[i].descripcion);
			};
			this.setState({
				monitoreo:{
				id:id,
				nombre:nombre,
				descripcion:descripcion,
			}})
			})
			url = baseurl+'/restaured/registromonitoreo'
			await axios.get(url, {withCredentials:true})
			.then(response => response.data)
			.then(data => {
			var id =[];
			var nombre =[];
			var idMonitoreo =[];
			var idUser =[];
			var date=[]
			for(const i in data.body){
				id.push(data.body[i]._id);
				nombre.push(data.body[i].nombre);
				idUser.push(data.body[i].idUser);
				idMonitoreo.push(data.body[i].idMonitoreo);
				date.push(data.body[i].date);
			};
			this.setState({
				registroMonitoreo:{
				id:id,
				nombre:nombre,
				idUser:idUser,
				idMonitoreo:idMonitoreo,
				date:date,
			}})
			})
			url = baseurl+'/restaured/entradamonitoreo'
			await axios.get(url, {withCredentials:true})
			.then(response => response.data)
			.then(data => {
			var id =[];
			var idLocalizacion =[];
			var curado =[];
			var idRegistro =[];
			var idEspecies =[];
			var img =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				idLocalizacion.push(data.body[i].idLocalizacion);
				curado.push(data.body[i].curado);
				idRegistro.push(data.body[i].idRegistro);
				img.push(data.body[i].img);
				idEspecies.push(data.body[i].idEspecie);
			};
			this.setState({
				entradaMonitoreo:{
					id:id,
					idLocalizacion:idLocalizacion,
					curado:curado,
					img:img,
					idRegistro:idRegistro,
					idEspecies:idEspecies,
			}})
			})
			url = baseurl+'/restaured/variableregistro'
			await axios.get(url, {withCredentials:true})
			.then(response => response.data)
			.then(data => {
			var id =[];
			var idEntrada =[];
			var idVariable =[];
			var valor =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				idEntrada.push(data.body[i].idEntrada);
				idVariable.push(data.body[i].idVariable);
				valor.push(data.body[i].valor);
			};
			this.setState({
				variableRegistro:{
					id:id,
					idEntrada:idEntrada,
					idVariable:idVariable,
					valor:valor,
			}})
			})
			url = baseurl+'/auth/user'
			await axios.get(url, {withCredentials:true})
			.then(response => response.data)
			.then(data => {
				// console.log(data)
				var id =[];
				var nombre =[];
				for(const i in data.body){
					id.push(data.body[i]._id);
					nombre.push(data.body[i].nombre);
				};
				this.setState({
					users:{
						id:id,
						nombre:nombre,
				}})
			})
			localStorage.setItem('users', JSON.stringify({users:{id:this.state.users.id, nombre:this.state.users.nombre}}))
			await this.loadLocalizaciones()
			await this.loadMonitoreos()
		}
		catch (error){
		var localizaciones = localStorage.getItem('localizaciones')
		var monitoreos = localStorage.getItem('monitoreos')
		var users = localStorage.getItem('users')
		localizaciones = JSON.parse(localizaciones)
		users = JSON.parse(users)
		monitoreos = JSON.parse(monitoreos)
		this.setState({monitoreos:monitoreos, datasetLocalizaciones:localizaciones, users:{ id: users.users.id, nombre: users.users.nombre}})

		}
	}
	loadMonitoreos=async()=>{
	var monitoreo={
		id:0,
		nombre:"",
		descripcion:"",
		variables:[],
		registros:[],
		indicadores:[],
	};
	var indicadores = {
		id:[],
		idMonitoreo:[],
		formula:[],
		nombre:[],
		pubObj:[],
		tipo:[],
		tipoGrafico:[],
		unidades:[],
	};
	var registros={
		id:[],
		iduser:[],
		nombre:[],
		entradas:[],
		date:[],
	};
	var entradas={
		id:[],  
		idLocalizacion:[],  
		idVariablesRegistro:[],  
		variables:[],
		especies:[],
		valores:[],
		curado:[],
		img:[],
	};
	var monitoreos=[]
	var valores=[];
	var variables=[];
	var idvariableRegistro=[]
	var idvariablesRegistro=[]
	var variableEntrada=[];
	var valorEntrada=[];
	for(const i in this.state.monitoreo.id){
		monitoreo.id=this.state.monitoreo.id[i]
		monitoreo.nombre=this.state.monitoreo.nombre[i]
		monitoreo.descripcion=this.state.monitoreo.descripcion[i]
		for(const j in this.state.variable.nombre){
		if(this.state.variable.idMonitoreo[j]===this.state.monitoreo.id[i]){
			var variable={
			id:this.state.variable.id[j],
			nombre:this.state.variable.nombre[j],
			pubObj:this.state.variable.pubObj[j],
			tipo:this.state.variable.tipo[j],
			unidades:this.state.variable.unidades[j],
			}
			monitoreo.variables.push(variable);
		}
		}
		for(const j in this.state.indicador.id){
		if(this.state.indicador.idMonitoreo[j]===this.state.monitoreo.id[i]){
			indicadores.id.push(this.state.indicador.id[j])
			indicadores.idMonitoreo.push(this.state.indicador.idMonitoreo[j])
			indicadores.formula.push(this.state.indicador.formula[j])
			indicadores.nombre.push(this.state.indicador.nombre[j])
			indicadores.pubObj.push(this.state.indicador.pubObj[j])
			indicadores.tipo.push(this.state.indicador.tipo[j])
			indicadores.tipoGrafico.push(this.state.indicador.tipoGrafico[j])
			indicadores.unidades.push(this.state.indicador.unidades[j])
		}
		}
		// console.log(this.state.entradaMonitoreo)
		for(const j in this.state.registroMonitoreo.id){
		if(this.state.monitoreo.id[i] === this.state.registroMonitoreo.idMonitoreo[j]){
			registros.id.push(this.state.registroMonitoreo.id[j])
			registros.iduser.push(this.state.registroMonitoreo.idUser[j])
			registros.nombre.push(this.state.registroMonitoreo.nombre[j])
			registros.date.push(this.state.registroMonitoreo.date[j])
			for(const k in this.state.entradaMonitoreo.id){
			if(this.state.registroMonitoreo.id[j] === this.state.entradaMonitoreo.idRegistro[k]){
				entradas.id.push(this.state.entradaMonitoreo.id[k]);
				entradas.idLocalizacion.push(this.state.entradaMonitoreo.idLocalizacion[k]);
				entradas.curado.push(this.state.entradaMonitoreo.curado[k]);
				entradas.img.push(this.state.entradaMonitoreo.img[k]);
				entradas.especies.push(this.state.entradaMonitoreo.idEspecies[k]);
				for(const l in this.state.variableRegistro.id){
					if(this.state.entradaMonitoreo.id[k] === this.state.variableRegistro.idEntrada[l]){
					idvariableRegistro.push(this.state.variableRegistro.id[l]);
					valorEntrada.push(this.state.variableRegistro.valor[l]);
					variableEntrada.push(this.state.variableRegistro.idVariable[l]);
					}
				}
				idvariablesRegistro.push(idvariableRegistro)  
				variables.push(variableEntrada)  
				valores.push(valorEntrada)  
		idvariableRegistro=[]
				valorEntrada=[]
				variableEntrada=[]
				}
			}
			entradas.variables=variables;
			entradas.valores=valores;
			entradas.idVariablesRegistro=idvariablesRegistro;
			variables=[];
			idvariablesRegistro=[];
			valores=[];
			registros.entradas.push(entradas);
			entradas={id:[], idLocalizacion:[], idVariablesRegistro:[], variables:[], valores:[], curado:[],especies:[], img:[]}
		}
		}
		monitoreo.indicadores=indicadores;
		monitoreo.registros=registros;
		registros={ id:[], iduser:[], nombre:[], entradas:[], date:[] };
		indicadores = { id:[], idMonitoreo:[], formula:[], nombre:[], pubObj:[], tipo:[], tipoGrafico:[], unidades:[],};
		monitoreos.push(monitoreo);
		monitoreo={ id:0, nombre:"", descripcion:"", variables:[],indicadores:[],};
	}
	console.log(monitoreos)
	localStorage.setItem('monitoreos', JSON.stringify(monitoreos))
	this.setState({
		monitoreos:monitoreos
	})
	}
	loadLocalizaciones =async()=>{
	var localizacion={
		id:[],
		sitio:[],
		parcela:[],
		transecto:[],
		PO:[],    
	}
	for(const i in this.state.localizacion.id){
		for(const j in this.state.sitio.id){
		if(this.state.localizacion.idSitio[i] === this.state.sitio.id[j]){
			var sitio= {
			id:this.state.sitio.id[j],
			nombre:this.state.sitio.nombre[j],
			geos:this.state.sitio.geos[j],
			descripcion:this.state.sitio.descripcion[j],
			}
			localizacion.id.push(this.state.localizacion.id[i])
			localizacion.sitio.push(sitio)
			var verify = localizacion.PO.length
			for(const k in this.state.PO.id){
			if(this.state.PO.id[k]=== this.state.localizacion.idPO[i]){
				var ubicacionpo = {
				id:this.state.PO.id[k],
				geos:this.state.PO.geos[k],
				nombre:this.state.PO.nombre[k],
				descripcion:this.state.PO.descripcion[k],
				}
				localizacion.PO.push(ubicacionpo)
			}
			}
			if(verify === localizacion.PO.length)
			localizacion.PO.push(null)
			verify=localizacion.transecto.length
			for(const k in this.state.transecto.id){
			if(this.state.transecto.id[k]=== this.state.localizacion.idTransecto[i]){
				var ubicaciontrans = {
				id:this.state.transecto.id[k],
				geos:this.state.transecto.geos[k],
				nombre:this.state.transecto.nombre[k],
				descripcion:this.state.transecto.descripcion[k],
				}
				localizacion.transecto.push(ubicaciontrans)
			}
			}
			if(verify === localizacion.transecto.length)
			localizacion.transecto.push(null)
			verify=localizacion.parcela.length
			for(const k in this.state.parcela.id){
			if(this.state.parcela.id[k]=== this.state.localizacion.idParcela[i]){
				var ubicacionparce = {
				id:this.state.parcela.id[k],
				geos:this.state.parcela.geos[k],
				nombre:this.state.parcela.nombre[k],
				descripcion:this.state.parcela.descripcion[k],
				}
				localizacion.parcela.push(ubicacionparce)
			}
			}
			if(verify === localizacion.parcela.length)
			localizacion.parcela.push(null)
		}
		}
	}
	localStorage.setItem('localizaciones', JSON.stringify(localizacion))
	this.setState({
		datasetLocalizaciones:localizacion
	})
	}
	initial_state=async()=>{
	await this.Getespecies();
	await this.getData()
	}
  //HANDLEE
	setOpen=()=>{
	this.setState({open:!this.state.open})
	}
	onChangeFiles=async(File)=>{
		const form = new FormData()
		await form.append('file', File[0])
		console.log(File)
		console.log(form)
		this.setState({
			filepreview: URL.createObjectURL(File[0])
		  })
		this.setState({imgEntrada:File[0]})
	}
	onChange = (event, j,variables,selectedMonitoreo ) => {
		var idMonitoreo= this.state.monitoreos[selectedMonitoreo.monitoreo].id;
		var idRegistro =this.state.monitoreos[selectedMonitoreo.monitoreo].registros.id[selectedMonitoreo.registro];
		var fecha =this.state.monitoreos[selectedMonitoreo.monitoreo].registros.date[selectedMonitoreo.registro];
		var nombre =this.state.monitoreos[selectedMonitoreo.monitoreo].registros.nombre[selectedMonitoreo.registro];
		var idVariables=[]
		for(const i in variables){
			idVariables.push( this.state.monitoreos[selectedMonitoreo.monitoreo].variables[i].id)
		}
		// console.log(selectedMonitoreo)
		var valores =this.state.form.valores
		valores[j]=event.target.value
		this.setState({
			form: {
				...this.state.form,
				[event.target.name]:valores,
				variables:variables,
				idRegistro:idRegistro,
				idMonitoreo:idMonitoreo,
				idEspecie:'',
				idVariables:idVariables,
				fecha:fecha,
				nombre:nombre,
			}
		});
	};
	handleSelect = (event , view) => {
	var select
	for(const i in view.id){
		if(view.id[i]=== event.target.value )
		select = view.nombre[i]
	}
		this.setState({
		selectedEspecie:select,
		form:{
		...this.state.form,
		especie: event.target.value
		}
	});
	};
	handleSelectLocalizacion=(e)=>{
		this.setState({ form:{
			...this.state.form,
			idLocalizacion: e.target.value
		}})
	}
	handleSelectV = event => {
	console.log(event.target)
	this.setState({
		selectedVariable:event.target.value,
		form: {
		...this.state.form,
		[event.target.name]: event.target.value
		}
	});
	};
	onChangedText=async(e)=>{
		const value = e.target.value
		let suggestions =[]
		var config = {
		method: 'get',
		url: 'https://api.gbif.org/v1/species/suggest',
		params:{ q: value, limit:5},
		headers: { }
		};
		axios(config)
		.then(async response => {
			var canonicalName=[]; var family=[]; var order=[]; var genus=[]; var key=[]
			for(const i in response.data){
				canonicalName.push(response.data[i].canonicalName)
				family.push(response.data[i].family)
				key.push(response.data[i].key)
				genus.push(response.data[i].genus)
				order.push(response.data[i].order)
			}
			this.setState({gbifespecies: {...this.state.gbifespecies, key:key, family: family, order: order, genus: genus ,canonicalName:canonicalName}});
		})
		.catch(error =>{
			this.setState({ gbifespecies: {...this.state.gbifespecies, canonicalName:this.state.especie.nombreCientifico}});
		});
		// HACER AQUI LA CONSULTA DE GBIF
		let items =this.state.gbifespecies.canonicalName
		if (value.length > 0){
			const regex = new RegExp(`${value}`,'i')
			suggestions =items.filter( v => regex.test(v))
		}
		this.setState({ suggestions : suggestions, selectedEspecie:value})
	}
	suggestionsSelected=async(value,i)=>{
		console.log(value)
		var config = {
			method: 'get',
			url: 'https://api.gbif.org/v1/species/'+this.state.gbifespecies.key[i],
			// params:{ name: this.state.gbifespecies.key[i], limit:1},
			headers: { }
			};
		await axios(config)
		.then(async response => {
			console.log(response)
			if( response.data.vernacularName !== undefined ){
				var vernacularName = response.data.vernacularName;
				await this.setState({ selectedVernacular:vernacularName});
			}
			await this.setState({ suggestions:[], selectedEspecie:value,
				selectedFamily: this.state.gbifespecies.family[i],
				selectedOrder: this.state.gbifespecies.order[i], selectedGenus: this.state.gbifespecies.genus[i] })
		})
		.catch( async error => {
			await this.setState({ suggestions:[], selectedEspecie:value })	
		 })
	}
	ingresarEntradaMonitoreo=async(body)=>{
	var baseurl = "https://api.restaured.net"
	var url = baseurl+'/restaured/entradamonitoreo'
	var data = JSON.stringify([body])
	var config = {
	method: 'post',
	url: url,
	headers: { 
		'Content-Type': 'application/json'
	},
	withCredentials:true,
	data: data
	};
	await axios(config)
	.then(response => {
	console.log(response)
	this.setState({
			ingresarEntradaMonitoreo:{
				body:response.data.body,
				status:response.data.status
			}
		})
	})
	.catch( error => {
		const form = new FormData()
		form.append('img', this.state.imgEntrada)
		form.append('name', this.props.Nombre)
		var url = baseurl+'/restaured/entradamonitoreo/'
		var configImagen = {
			method: 'post',
			url: url,
			headers: { 
				'Content-Type': 'application/json'
			},
			withCredentials:true,
			data: form
		};
		var idEntrada = uuidv4()
		var bodyRegistro =[]
		for(const i in this.state.form.variables){
			if ( this.state.form.valores[i] !== undefined ){
				var variableRegistro={
				idEntrada:idEntrada,
				idVariable: this.state.form.idVariables[i],
				valor: this.state.form.valores[i],
				}
				bodyRegistro.push(variableRegistro)
			}
		}
		var baseurl = "https://api.restaured.net"
		url = baseurl+'/restaured/variableregistro'
		data = JSON.stringify(bodyRegistro)
		var configVariableRegistro = {
			method: 'post',
			url: url,
			headers: { 
			'Content-Type': 'application/json'
			},
			withCredentials:true,
			data: data
		};
		var local = localStorage.getItem('entradaQueries')	
		if(local !== null){
			local = JSON.parse(local)
		}else{
			local = []
		}
		url = baseurl+'/restaured/entradamonitoreo'
		if(this.state.temporalEspecie){
			var temporalEspecie = this.state.temporalEspecie
			var datosEntrada = JSON.stringify([{
				id:idEntrada,
				idRegistro:this.state.form.idRegistro,
				idLocalizacion: this.state.form.idLocalizacion  ,
				idEspecie:this.state.form.idEspecie,
				tipo:"Especie", //Si es Individuo, Muestra o Especie
				curado:false,
			}])
			var configEntrada = {
				method: 'post',
				url: url,
				headers: { 
					'Content-Type': 'application/json'
				},
				withCredentials:true,
				data: datosEntrada
			};
			local.push({
				especie: temporalEspecie,
				entrada: configEntrada,
				variableRegistro: configVariableRegistro,
				imagen: configImagen
			})
		}else{
			var datosEntrada = JSON.stringify([{
				id:idEntrada,
				idRegistro:this.state.form.idRegistro,
				idLocalizacion: this.state.form.idLocalizacion  ,
				idEspecie:this.state.form.idEspecie,
				tipo:"Especie", //Si es Individuo, Muestra o Especie
				curado:true,
			}])
			var configEntrada = {
				method: 'post',
				url: url,
				headers: { 
					'Content-Type': 'application/json'
				},
				withCredentials:true,
				data: datosEntrada
			};
			local.push({
				entrada: configEntrada,
				variableRegistro: configVariableRegistro,
				imagen: configImagen
			})
		}		
		localStorage.setItem('entradaQueries', JSON.stringify(local))
		var monitoreos = localStorage.getItem('monitoreos')
		monitoreos = JSON.parse(monitoreos)
		var idEspecie
		if(this.state.form.idEspecie==="" || this.state.form.idEspecie===undefined){
			idEspecie = uuidv4()
			var especies = localStorage.getItem('especies')
			especies = JSON.parse(especies)
			console.log(especies)
			especies.especies.activo.push(true)
			especies.especies.familia.push(null)
			especies.especies.genero.push(null)
			especies.especies.id.push(idEspecie)
			especies.especies.idLocalizacion.push(this.state.form.idLocalizacion)
			especies.especies.nombreCientifico.push(this.state.selectedEspecie)
			especies.especies.nombreComun.push(null)
			especies.especies.orden.push(null)
			console.log(especies)
			localStorage.setItem('especies', JSON.stringify(especies))
		}else{
			idEspecie = this.state.form.idEspecie
		}
		monitoreos[this.props.location.state.monitoreo].registros.entradas[this.props.location.state.registro].id.push(idEntrada)
		monitoreos[this.props.location.state.monitoreo].registros.entradas[this.props.location.state.registro].curado.push(false)
		monitoreos[this.props.location.state.monitoreo].registros.entradas[this.props.location.state.registro].img.push(null)
		monitoreos[this.props.location.state.monitoreo].registros.entradas[this.props.location.state.registro].especies.push(idEspecie)
		monitoreos[this.props.location.state.monitoreo].registros.entradas[this.props.location.state.registro].idLocalizacion.push(this.state.form.idLocalizacion)
		var idVariableRegistro = []; var valores = []; var variables =[];
		for(const i in this.state.form.variables){
			if ( this.state.form.valores[i] !== undefined ){
				var id = uuidv4()
				idVariableRegistro.push(id)
				valores.push(this.state.form.valores[i])
				variables.push(this.state.form.idVariables[i])
			}
		}
		monitoreos[this.props.location.state.monitoreo].registros.entradas[this.props.location.state.registro].valores.push(valores)
		monitoreos[this.props.location.state.monitoreo].registros.entradas[this.props.location.state.registro].variables.push(variables)
		monitoreos[this.props.location.state.monitoreo].registros.entradas[this.props.location.state.registro].idVariablesRegistro.push(idVariableRegistro)

		console.log(monitoreos)
		localStorage.setItem('monitoreos', JSON.stringify(monitoreos))
	})  
	}
	ingresarVariableRegistro=async(body)=>{
	var baseurl = "https://api.restaured.net"
	var url = baseurl+'/restaured/variableregistro'
	var data = JSON.stringify(body)
	var config = {
		method: 'post',
		url: url,
		headers: { 
		'Content-Type': 'application/json'
		},
		withCredentials:true,
		data: data
	};
	await axios(config)
	.catch(err => console.log(err))
	}
	//   LLAMADO A TABLAS 
	renderTablaEntradas=()=>{
	var dataset=[]
	var location = this.props.location;
	var selectedMonitoreo=(location.state)
	if(this.state.monitoreos.length > 0){
		var columns = [
			{
				Header: 'Entradas de Monitoreo',
				columns: [
				{
				Header: 'Especies',
				accessor: 'especies'
				},
				{
					Header: 'Localizacion',
					accessor: 'local'
				},
				],
			},
		]
		for(const j in this.state.monitoreos[selectedMonitoreo.monitoreo].variables){
			if(this.state.monitoreos[selectedMonitoreo.monitoreo].variables[j].tipo !=='Nominal'){
			if(this.props.Iduser === this.state.monitoreos[selectedMonitoreo.monitoreo].registros.iduser[selectedMonitoreo.registro]){
				var column={
					Header:this.state.monitoreos[selectedMonitoreo.monitoreo].variables[j].nombre,
					accessor: this.state.monitoreos[selectedMonitoreo.monitoreo].variables[j].nombre,
					Cell: EditableCell
				}
			}else{
				var column={
					Header:this.state.monitoreos[selectedMonitoreo.monitoreo].variables[j].nombre,
					accessor: this.state.monitoreos[selectedMonitoreo.monitoreo].variables[j].nombre,
					Cell: NoNEditableCell
				}
			}
		}else{
			var column={
				Header:this.state.monitoreos[selectedMonitoreo.monitoreo].variables[j].nombre,
				accessor: this.state.monitoreos[selectedMonitoreo.monitoreo].variables[j].nombre,
				Cell: NoNEditableCell
			}
			// if(this.props.Iduser === this.state.monitoreos[selectedMonitoreo.monitoreo].registros.iduser[selectedMonitoreo.registro]){
			// 	var column={
			// 		Header:this.state.monitoreos[selectedMonitoreo.monitoreo].variables[j].nombre,
			// 		accessor: this.state.monitoreos[selectedMonitoreo.monitoreo].variables[j].nombre,
			// 		Cell: EditableCellNominal(this.state.monitoreos[selectedMonitoreo.monitoreo].variables[j], )
			// 	}
			// }else{
			// 	var column={
			// 		Header:this.state.monitoreos[selectedMonitoreo.monitoreo].variables[j].nombre,
			// 		accessor: this.state.monitoreos[selectedMonitoreo.monitoreo].variables[j].nombre,
			// 		Cell: NoNEditableCell
			// 	}
			// }
		}
			columns[0].columns.push(column)
		}
		// console.log(columns)
		var especiesTabla=[];
		var valorTabla=[]; var valoresTabla=[];
		var variableTabla=[]; var variablesTabla=[];
		var localizacionesTabla=[];
		var nombreVariableTabla=[];
		for(const l in this.state.monitoreos[selectedMonitoreo.monitoreo].registros.entradas[selectedMonitoreo.registro].especies){
		for(const m in this.state.especie.id){
			if(this.state.especie.id[m] === this.state.monitoreos[selectedMonitoreo.monitoreo].registros.entradas[selectedMonitoreo.registro].especies[l])
			especiesTabla.push(this.state.especie.nombreCientifico[m])
		}
		for(const m in this.state.monitoreos[selectedMonitoreo.monitoreo].registros.entradas[selectedMonitoreo.registro].valores[l]){
			valorTabla.push(this.state.monitoreos[selectedMonitoreo.monitoreo].registros.entradas[selectedMonitoreo.registro].valores[l][m])
			variableTabla.push(this.state.monitoreos[selectedMonitoreo.monitoreo].registros.entradas[selectedMonitoreo.registro].variables[l[m]])
			}
			for(const m in this.state.datasetLocalizaciones.id){
				if(this.state.datasetLocalizaciones.id[m] === this.state.monitoreos[selectedMonitoreo.monitoreo].registros.entradas[selectedMonitoreo.registro].idLocalizacion[l]){
					if(this.state.datasetLocalizaciones.transecto[m] !== null)
						localizacionesTabla.push(this.state.datasetLocalizaciones.sitio[m].nombre + ' '+ this.state.datasetLocalizaciones.transecto[m].nombre)
					if(this.state.datasetLocalizaciones.PO[m] !== null)
						localizacionesTabla.push(this.state.datasetLocalizaciones.sitio[m].nombre + ' '+this.state.datasetLocalizaciones.PO[m].nombre)
					if(this.state.datasetLocalizaciones.parcela[m] !== null)
						localizacionesTabla.push(this.state.datasetLocalizaciones.sitio[m].nombre + ' '+ this.state.datasetLocalizaciones.parcela[m].nombre)
				}
			}
		variablesTabla.push(variableTabla)
		valoresTabla.push(valorTabla)
		variableTabla=[]; valorTabla=[];
		}
		var nombresVariableTabla =[]
		for(const l in variablesTabla){
		for(const m in variablesTabla[l][0]){
			for(const k in this.state.monitoreos[selectedMonitoreo.monitoreo].variables){
			if(variablesTabla[l][0][m] === this.state.monitoreos[selectedMonitoreo.monitoreo].variables[k].id){
				nombreVariableTabla.push(this.state.monitoreos[selectedMonitoreo.monitoreo].variables[k].nombre)
			}
			}
		}
		nombresVariableTabla.push(nombreVariableTabla)
		nombreVariableTabla=[]
		}
		for(const k in especiesTabla){
			var datos
			if(nombresVariableTabla[k].length > 1){
				datos ={
					especies:especiesTabla[k],
					local:localizacionesTabla[k],
				}
				for(const m in nombresVariableTabla[k]){
					datos[nombresVariableTabla[k][m]] = valoresTabla[k][m]
				}
			}else{
				datos ={
					especies:especiesTabla[k],
					local:localizacionesTabla[k],
					[nombresVariableTabla[k]]:valoresTabla[k]
				}
		}
		dataset.push(datos)
		}
		datos={}
		nombreVariableTabla=[]		
		var html = TablaEntradas(dataset,columns,this.state.monitoreos,this.props.Iduser,this.props.location)
		return(<>
		{html}
		</>)
	}else{
		return(<></>)
	}
	}
	// FUNCIONES DE BOTONES
	agregarEspecieTemporal=async()=>{
		var baseurl = "https://api.restaured.net"
		var url = baseurl+'/restaured/especie'
		var body = {
		nombreCientifico:this.state.selectedEspecie,
		nombreComun:this.state.selectedVernacular,
		familia: this.state.selectedFamily,
		genero: this.state.selectedGenus,
		orden: this.state.selectedOrder,
		activo: true,
		idLocalizacion:this.state.form.idLocalizacion
		}
		var data = JSON.stringify([body])
		var config = {
		method: 'post',
		url: url,
		headers: { 
			'Content-Type': 'application/json'
		},
		withCredentials:true,
		data: data
		};
		await axios(config)
		.then(response => {
		this.setState({ form:{ ...this.state.form, idEspecie:response.data.body[0]._id}	})
		})
		.catch( async error => {
			await this.setState({ temporalEspecie : config})	
		})  
	}
	agregarImagen=async()=>{
		var id = this.state.ingresarEntradaMonitoreo.body[0]._id
		const form = new FormData()
		form.append('img', this.state.imgEntrada)
		form.append('name', this.props.Nombre)
		var baseurl = "https://api.restaured.net"
		var url = baseurl+'/restaured/entradamonitoreo/'+id+'/upload'
		var config = {
			method: 'post',
			url: url,
			headers: { 
				'Content-Type': 'application/json'
			},
			withCredentials:true,
			data: form
		};
		await axios(config)
		.then(response => {
			console.log(response)
		})
		.catch( error => console.log(error))  
	}
	confirmarAgregar= async()=>{
	var estaEnBD=false
	var idEspecie;
	for(const i in this.state.especie.id){
		if(this.state.selectedEspecie === this.state.especie.nombreCientifico[i]){
			estaEnBD=true
			idEspecie= this.state.especie.id[i]
		}
	}
	if(this.state.form.idLocalizacion ===""){
		await this.setState({form:{ ...this.state.form, idLocalizacion:this.state.datasetLocalizaciones.id[0]}  })
	}
	if(estaEnBD){
		await this.setState({ form:{ ...this.state.form, idEspecie:idEspecie } })
	}else{
		await this.agregarEspecieTemporal()
	}
	var entradaMonitoreo={ 
		idRegistro:this.state.form.idRegistro,
		idLocalizacion: this.state.form.idLocalizacion  ,
		idEspecie:this.state.form.idEspecie,
		tipo:"Especie", //Si es Individuo, Muestra o Especie
		curado:estaEnBD,
	}
	await this.ingresarEntradaMonitoreo(entradaMonitoreo)
	var body = []
	if(this.state.ingresarEntradaMonitoreo.status === "OK"){
		for(const i in this.state.form.variables){
		if ( this.state.form.valores[i] !== undefined ){
			var variableRegistro={
			idEntrada:this.state.ingresarEntradaMonitoreo.body[0]._id,
			idVariable: this.state.form.idVariables[i],
			valor: this.state.form.valores[i],
			}
			body.push(variableRegistro)
			variableRegistro={
			idEntrada:"",
			idVariable: "",
			valor: "",
			}
		}
		}
		await this.ingresarVariableRegistro(body)
		await this.setState({alertAgrego:true})
		await this.agregarImagen()
		await this.Getespecies()
		this.setOpen()
		this.setState({reload : true})

	}else{
		await this.setState({alertAgrego:true})
		await this.Getespecies()
		this.setOpen()
		this.setState({reload : true})
	} 
	}
	renderSingleMap=()=>{
		for(const i in this.state.datasetLocalizaciones.id){
			if(this.state.form.idLocalizacion === this.state.datasetLocalizaciones.id[i]){
				var sitios=[]; var parcelas=[]; var puntos=[]; var transectos=[];
				sitios.push(this.state.datasetLocalizaciones.sitio[i].geos)
				if(this.state.datasetLocalizaciones.parcela[i]){
					parcelas.push(this.state.datasetLocalizaciones.parcela[i].geos)
				}
				if(this.state.datasetLocalizaciones.PO[i]){
					puntos.push(this.state.datasetLocalizaciones.PO[i].geos)
				}
				if(this.state.datasetLocalizaciones.transecto[i]){
					transectos.push(this.state.datasetLocalizaciones.transecto[i].geos)
				}
				const center = [
					[4.7308746259696,-75.90724920155998],
					[4.86862619541959,-75.42094544938094]
				]
				const onEachFeaturePoint=(feature, layer)=> {
					if(feature.properties.Sitio !== undefined){
						var popup = L.popup()
						.setContent(feature.properties.Sitio + ' ' + feature.properties.Codigo + ' ' + feature.properties.COD_PNT)
						layer.bindPopup( popup);
					}else{
						var popup = L.popup()
						.setContent('Sin Nombre Definido')
						layer.bindPopup( popup);
					}
		
				  }
				  
				  const onEachFeaturePolygon=(feature, layer) =>{
					layer.on({
					  'click': function (e) {
					   }
					})
				  }
				  
				  const pointToLayer=(feature, latlng) =>{
					const parkIcon = new L.Icon({
						iconUrl: Icono,
						iconSize: [26, 26],
						popupAnchor: [0, -15],
						shadowAnchor: [13, 28]
					  });
					var geojsonMarkerOptions = {
						radius: 8,
						icon: parkIcon,
						fillColor: "#ff7800",
						color: "#000",
						weight: 1,
						opacity: 1,
						fillOpacity: 0.8
					};
					var marker = L.marker(latlng,geojsonMarkerOptions)
					marker.setIcon(parkIcon);
					return marker;
				  }
				const styleMap = { "width": "300%", "height": "200px", "left":"-200%"}
				console.log(parcelas,sitios)
				console.log(transectos,puntos)
				const html = 
				<MapContainer style={styleMap}  zoom={15} bounds={center} scrollWheelZoom={false} >
						<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
						<GeoJSON data={sitios} onEachFeature={onEachFeaturePolygon.bind()} pointToLayer={pointToLayer.bind()} />
						{parcelas.length > 0?  <GeoJSON data={parcelas} onEachFeature={onEachFeaturePolygon.bind()}  pointToLayer={pointToLayer.bind()} /> :null} 
						{puntos.length > 0? <GeoJSON data={puntos} onEachFeature={onEachFeaturePoint.bind()} pointToLayer={pointToLayer.bind()}/>:null}
						{transectos.length > 0?  <GeoJSON data={transectos} onEachFeature={onEachFeaturePolygon.bind()} pointToLayer={pointToLayer.bind()}/>:null}
				</MapContainer>
				const renderSingleMap=(
					<Popover id="popover-basic-2">
					<Popover.Title as="h3">Localización</Popover.Title>
					<Popover.Content>
						{html}
					</Popover.Content>
					</Popover>
					)
				return (<div className="col-lg-3">
				<OverlayTrigger trigger="click" placement="left" overlay={renderSingleMap}>
					<button className="btn"> <BsGeoAlt/> </button>
				</OverlayTrigger>
				</div>)
			}
		}
	}
	selectLocalizacion=()=>{
		var localizacion=[]; var idLocalizacion =[];
		for(const i in this.state.datasetLocalizaciones.id){
			idLocalizacion.push(this.state.datasetLocalizaciones.id[i])
			localizacion.push( this.state.datasetLocalizaciones.sitio[i].nombre + " "+
			(this.state.datasetLocalizaciones.transecto[i]? this.state.datasetLocalizaciones.transecto[i].nombre : " " ) +
			(this.state.datasetLocalizaciones.parcela[i]? this.state.datasetLocalizaciones.parcela[i].nombre : " " ) +
			(this.state.datasetLocalizaciones.PO[i]? this.state.datasetLocalizaciones.PO[i].nombre : " " ))
		}
		const setInputValue = (inputValueCurrency) => {
			this.setState({inputLocalizacion:inputValueCurrency })
			// this.setState({ form:{
			// 	...this.state.form,
			// 	idLocalizacion: inputValueCurrency
			// }})
		}
		const onChange =async (newValue,localizacion, idLocalizacion)=>{
			var id;
			for(const i in localizacion){
				if(localizacion[i] === newValue)
					id = idLocalizacion[i]
			}
			await this.setState({ form:{
				...this.state.form,
				idLocalizacion: id
			}})
		}
		
		return(	<div className="col-xl-12"> 
					<div className="row">
						<div className="col-lg-9">
							<Autocomplete
								onChange={(event, newValue) => {
								onChange(newValue,localizacion, idLocalizacion);
								}}
								inputValue={this.state.inputLocalizacion}
								onInputChange={(event, newInputValue) => {
								setInputValue(newInputValue);
								}}
								id="controllable-states-demo"
								options={localizacion}
								renderInput={(params) =><TextField
									style={{"border":"none","margin-bottom":"5%"}}
									{...params} label="Buscar Localizacion"  />}
							/> 
						</div>
						{this.renderSingleMap()}
					</div>
				</div>)
	}
	AgregarEntrada=()=>{
	var location = this.props.location;
	var selectedMonitoreo=(location.state)
	if(this.state.monitoreos.length > 0){
		for(const i in this.state.monitoreos){
			if(selectedMonitoreo.monitoreo === i){
				var variables=[]
				var entradas=[]
				for(const j in this.state.monitoreos[i].variables){
					variables.push(this.state.monitoreos[i].variables[j].nombre)
				}
				for(const j in this.state.monitoreos[i].variables){
					if(this.state.monitoreos[i].variables[j].pubObj === "Web" || 
					this.state.monitoreos[i].variables[j].pubObj === "Cientifico"|| 
					this.state.monitoreos[i].variables[j].pubObj === "Cient_y_Cient_Ciudadano" ){
					var valorTabla; 
					if (this.state.monitoreos[i].variables[j].tipo !== 'Nominal'){
						valorTabla= <td> <input type="number"className="form-control"  name={"valores"} defaultValue={null} onChange={(event)=> this.onChange(event,j,variables,selectedMonitoreo)}/> </td>
					}else{
						var optionsValor = this.state.monitoreos[i].variables[j].unidades.split(',')
						var selectValor = <select className="form-control"  name={"valores"} onChange={(event)=> this.onChange(event,j,variables,selectedMonitoreo)} > <option value={null}> {null} </option> { optionsValor.map(option => <option value = {option} >{ option} </option>) }</select>
						valorTabla= <td> {selectValor} </td>
					}
					var entrada = 
					<>
					<tr>
						{valorTabla}
						<td> <input type="text"className="form-control" name="variable" value={this.state.monitoreos[i].variables[j].nombre}/></td>
						<td> <input type="text"className="form-control" name={"especie"}  value={this.state.selectedEspecie}/> </td>
						<td> <input type="text"className="form-control" name={"genus"}  value={this.state.selectedGenus}/> </td>
						<td> <input type="text"className="form-control" name={"family"}  value={this.state.selectedFamily}/> </td>
						<td> <input type="text"className="form-control" name={"order"}  value={this.state.selectedOrder}/> </td>
					</tr>
					</>
					entradas.push(entrada)
				}
			}
		var tabla=
		<>
		<table className=" center table-responsive col-xl-12">
		<thead>
			<tr>
				<th> Valor</th>
				<th> Variable</th>
				<th> Especie</th>
				<th> Genero</th>
				<th> Familia</th>
				<th> Orden </th>
			</tr>
		</thead>
		<tbody>               
			{entradas}
		</tbody>
		</table>
		</>
		return (tabla)
			}
		}
	}else{
		return([])
	}
	}
	cancelarAgregar=()=>{
	this.setState({
		open:false,
		reload:true,
		form:{
		fecha:"",
		nombre:"",
		valor:"",
		variable:"",
		especie:"",
		idMonitoreo:"",
		idUser:"",
		idRegistro:"",
		idLocalizacion:"",
		idEspecie:"",
		idEntrada:"",
		idVariable:"",
		variables:[],
		}

	})
	}
	// Visualización Condicional

	renderEspecies=()=>{
		try{
			const suggestions = this.state.suggestions
			var html = null;
			if(suggestions.length === 0){
				html= null
			}else{
				html= <ul className="autoCompleteText"> {suggestions.map((item,i)=> <li className="autoCompleteText" onClick={(e)=> this.suggestionsSelected(item,i)} onTouchStart={(e)=> this.suggestionsSelected(item,i)}> {item}</li>)}</ul>
			}
	
			return(
			<div className="col-lg-4">
				<input className="autoCompleteText" placeholder="Ingrese el nombre de la especie" value={this.state.selectedEspecie} onChange={(e)=>this.onChangedText(e)} type="text"/>
				{html}
				{/* <p>Hola</p> */}
			</div>
			)

		}catch(error){
			console.log(error)
			return null
		}
	}
	renderIngresarEntrada=()=> {
	var location = this.props.location
	var selectedMonitoreo=(location.state)
	if(this.state.monitoreos.length > 1){
		if(this.props.Iduser === 
		this.state.monitoreos[selectedMonitoreo.monitoreo].registros.iduser[selectedMonitoreo.registro]){
			return(
			<>
			<h1><span><p> Ingresar Entrada Monitoreo </p></span></h1>
			<h5><span><p> 
			<div className="row">
			{this.renderEspecies()}
				<div className="col-lg-8">
				<p  onClick={this.setOpen} aria-controls="collapse-new-entry" 
				aria-expanded={this.state.open} className="cta-btn page-link " type="button" >
				<BsPlusCircle/> <dr/>
				Nueva Entrada</p>
				<Collapse in={this.state.open}>
				<div id="collapse-new-entry">
					<div className="row"> 
					{this.selectLocalizacion()}
									{this.AgregarEntrada()}
					</div>
					<div className="row">
					<input type="file" name="file" accept='.png,.jpg,.jpeg,.webp' className="form-control col-xl-12 mt-3" onChange={(e)=> this.onChangeFiles(e.target.files)}/>
										<img style={{width:'100%', 'margin-top':'2%'}} src={this.state.filepreview}/>
					<div className="col-lg-6">
						<button className="btn-primary" type="submit" onClick={this.cancelarAgregar}> Cancelar</button>
					</div>
					<div className="col-lg-6">
						<button className="btn-primary"  type="submit" onClick={this.confirmarAgregar}> Confirmar</button>
					</div>
					</div>
				</div>
				</Collapse>
				</div>
			</div>
			</p></span></h5>
			</>)
		}else{
			return(
			<>
			<h1><span><p> Ver Entrada Monitoreo </p></span></h1>
			</>)
		}
	}else{
		return(
		<>
		<h1><span><p> Ver Entrada Monitoreo </p></span></h1>
		</>)
	}
	}
	render(){
		const alertOffline =()=>{
			var online = navigator.onLine ? "ONLINE" : "OFFLINE";
			if(online === 'OFFLINE')
			return  <Alert variant={'primary'} > Te encuentras Offline, Únicamente puedes Agregar o eliminar elementos </Alert>
			else return null
		}
	return(
	<div>
			<title>RestauRed Cientifico - Dashboard</title>
				<div id="wrapper">
					<NavbarC/>
					<div id="content-wrapper" className="d-flex flex-column">
						<div id="content">
							<HeaderUsers Iduser={this.props.Iduser} Rol={this.props.Rol} Nombre={this.props.Nombre} External={this.props.External}/>
							{this.props.Nombre ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
						{this.props.Iduser ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
						{this.props.Rol ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
							{ this.state.reload ? <Redirect push to={{ pathname:"/restaured.CzScie/reloaderentrada",state:this.props.location.state}}> </Redirect>: null}
							{this.state.alertAgrego? <Alert  variant={'primary'}> Se agregó correctamente</Alert> : null}
							{this.state.alertError? <Alert  variant={'primary'}> Ocurrió un error</Alert> : null}
							{alertOffline()}
							<div className="container-fluid">
								<this.renderIngresarEntrada/>
							{/* DROPDOWN DE INDIVIDUOS PARA DARWIN CORE */}
							<h4> Seleccione las filas y borrelas o edite su valor y guarde los cambios  </h4>
							<this.renderTablaEntradas/>
									<br/>
									<br/>
										<div className="row"> 
										<div className="col-lg-6">
											<Link to={{pathname:"/restaured.CzScie/registrosusers",state:this.props.location.state.monitoreo}}>
											<button className="btn border"> Volver</button>
											</Link>
										</div>
										<div className="col-lg-6">
											<Link to={{pathname:"/restaured.CzScie/cargarusers",state:this.props.location.state.monitoreo}}>
											<button className="btn border"> Cargar Registro</button>
											</Link>
										</div>
										</div>
										<Logout/>
							</div>
						</div>
					</div>
				</div>
			</div>
	)
    
  }
}