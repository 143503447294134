import React from 'react'
// import '../assets/vendor/aos/aos.css'
import '../assets/vendor/icofont/icofont.min.css'
import '../assets/vendor/boxicons/css/boxicons.min.css'
import '../assets/vendor/venobox/venobox.css'
import '../assets/vendor/owl.carousel/assets/owl.carousel.min.css'
import '../assets/vendor/bootstrap/css/bootstrap.css'
import '../assets/css/style.css'
import NavbarL from './NavBar/NavbarLanding'
import Dexie from 'dexie'
import { useLiveQuery } from "dexie-react-hooks";
const axios = require('axios');

export default class Landing extends React.Component{
	constructor(props){
		super(props)
		this.state = {
			especies:0,
			sitios:0,
			monitoreos:0,
			colaboradores:0,
			monitoreo:{
				id:[],
				nombre:[],
				descripcion:[],
				img:[],
			},
			registroMonitoreo:{
				id:[],
				idMonitoreo:[],
				idUser:[],
				date:[],
				nombre:[],
			},
			monitoreos_card:{
				nombre:[],
				descripcion:[],
				img:[],
			},
			imagenes:{
				src:[],
				idRegistro:[],
			},
		}
		this.initial_state()
	}

	getImagenes=async()=>{
		var baseurl = "https://api.restaured.net"
		  var url = baseurl+'/restaured/entradamonitoreo'
		  await axios.get(url, {withCredentials:true})
		  .then(response => response.data)
		  .then(async data => {
			  var images =[]; var idRegistro=[]
			  for(const i in data.body){
				  images.push(data.body[i].img)
				  idRegistro.push(data.body[i].idRegistro)
			  };
		localStorage.setItem('imagenesMain',JSON.stringify({images:{ src: images, idRegistro:idRegistro}}))
		this.setState({imagenes:{src:images, idRegistro:idRegistro}})
		  })
		  .catch( async error =>{
			  var images = localStorage.getItem('imagenesMain')
				if (images) {
					images = JSON.parse(images)
			  	console.log(error,images)
			  	this.setState({imagenes:{ src: images.images.src, idRegistro: images.images.idRegistro}})
				}
		})	
  
	}
	 
	getAppData=async()=>{
		var baseurl = "https://api.restaured.net"
		var url = baseurl+'/restaured/variable'
		await axios.get(url,{withCredentials: true})
		.then(response => response.data)
		.then(async data => {
			// console.log(data)
			var id =[]; var idMonitoreo =[]; var nombre =[]; var pubObj =[];
			const addItemToDb = async (id,idMonitoreo,nombre,pubObj) => {
				const db = new Dexie('restaured')
				await db.version(1).stores({ variable: "id, idMonitoreo, nombre, pubObj" })
				console.log(id,idMonitoreo, nombre, pubObj)
				await db.variable.add({
					id:id,
					idMonitoreo:idMonitoreo,
					nombre:nombre,
					pubObj:pubObj,
				})
			}
			for(const i in data.body){
				id.push(data.body[i]._id);
				idMonitoreo.push(data.body[i].idMonitoreo);
				nombre.push(data.body[i].nombre);
				pubObj.push(data.body[i].pubObj);
				await addItemToDb(data.body[i]._id, data.body[i].idMonitoreo, data.body[i].nombre, data.body[i].pubObj)
			};
			this.setState({
				variable:{
							id:id,
							idMonitoreo:idMonitoreo,
							nombre:nombre,
							pubObj:pubObj,
			}})
		})
		.catch( error => {
			console.log(error)
		})
		url = baseurl+'/restaured/variablexindicador'
		await axios.get(url,{withCredentials:true})
		.then(response => response.data)
		.then(data => {
			// console.log(data)
			var id =[];
			var idIndicador =[];
			var idVariable =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				idIndicador.push(data.body[i].idIndicador);
				idVariable.push(data.body[i].idVariable);
			};
			this.setState({
				variablexindicador:{
							id:id,
							idIndicador:idIndicador,
							idVariable:idVariable,
			}})
		})
		url = baseurl+'/restaured/indicador'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			// console.log(data)
			var id =[];
			var idMonitoreo =[];
			var formula =[];
			var nombre =[];
			var pubObj =[];
			var tipo =[];
			var tipoGrafico =[];
			var unidades =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				idMonitoreo.push(data.body[i].idMonitoreo);
				formula.push(data.body[i].formula);
				nombre.push(data.body[i].nombre);
				pubObj.push(data.body[i].pubObj);
				tipo.push(data.body[i].tipo);
				tipoGrafico.push(data.body[i].tipoGrafico);
				unidades.push(data.body[i].unidades);
			};
			this.setState({
				indicador:{
						id:id,
						idMonitoreo:idMonitoreo,
						formula:formula,
						nombre:nombre,
						pubObj:pubObj,
						tipo:tipo,
						tipoGrafico:tipoGrafico,
						unidades:unidades,
			}})
		})
		url = baseurl+'/restaured/localizacion'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			console.log(data)
			var id =[];
			var idSitio =[];
			var idParcela =[];
			var idTransecto =[];
			var idPO =[];
			var nombre =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				idSitio.push(data.body[i].idSitio);
				idParcela.push(data.body[i].idParcela);
				idTransecto.push(data.body[i].idTransecto);
				idPO.push(data.body[i].idPO);
				nombre.push(data.body[i].nombre);
			};
			this.setState({
				localizacion:{
							id:id,
							idSitio:idSitio,
							idParcela:idParcela,
							idTransecto:idTransecto,
							idPO:idPO,
							nombre:nombre,
			}})
		})
		url = baseurl+'/restaured/sitio'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			var id =[];
			var nombre =[];
			var geos =[];
			var descripcion =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				geos.push(data.body[i].geos);
				descripcion.push(data.body[i].descripcion);
				nombre.push(data.body[i].nombre);
			};
			this.setState({
				sitio:{
							id:id,
							nombre:nombre,
							geos:geos,
							descripcion:descripcion,
			}})
		})
		url = baseurl+'/restaured/puntoobservacion'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			var id =[];
			var nombre =[];
			var geos =[];
			var descripcion =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				geos.push(data.body[i].geos);
				descripcion.push(data.body[i].descripcion);
				nombre.push(data.body[i].nombre);
			};
			this.setState({
				PO:{
							id:id,
							nombre:nombre,
							geos:geos,
							descripcion:descripcion,
			}})
		})
		url = baseurl+'/restaured/transecto'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			var id =[];
			var nombre =[];
			var geos =[];
			var descripcion =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				geos.push(data.body[i].geos);
				descripcion.push(data.body[i].descripcion);
				nombre.push(data.body[i].nombre);
			};
			this.setState({
				transecto:{
							id:id,
							nombre:nombre,
							geos:geos,
							descripcion:descripcion,
			}})
		})
		url = baseurl+'/restaured/parcela'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			var id =[];
			var nombre =[];
			var geos =[];
			var descripcion =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				geos.push(data.body[i].geos);
				descripcion.push(data.body[i].descripcion);
				nombre.push(data.body[i].nombre);
			};
			this.setState({
				parcela:{
							id:id,
							nombre:nombre,
							geos:geos,
							descripcion:descripcion,
			}})
		})
		url = baseurl+'/restaured/monitoreo'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			// console.log(data)
			var id =[];
			var nombre =[];
			var descripcion =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				nombre.push(data.body[i].nombre);
				descripcion.push(data.body[i].descripcion);
			};
			this.setState({
				monitoreo:{
					id:id,
					nombre:nombre,
					descripcion:descripcion,
			}})
		})
		url = baseurl+'/restaured/registromonitoreo'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			var id =[];
			var nombre =[];
			var idMonitoreo =[];
			var idUser =[];
			var date=[]
			for(const i in data.body){
				id.push(data.body[i]._id);
				nombre.push(data.body[i].nombre);
				idUser.push(data.body[i].idUser);
				idMonitoreo.push(data.body[i].idMonitoreo);
				date.push(data.body[i].date);
			};
			this.setState({
				registroMonitoreo:{
					id:id,
					nombre:nombre,
					idUser:idUser,
					idMonitoreo:idMonitoreo,
					date:date,
			}})
		})
		url = baseurl+'/restaured/entradamonitoreo'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			var id =[];
			var idLocalizacion =[];
			var curado =[];
			var idRegistro =[];
			var idEspecies =[];
			var img=[]
			for(const i in data.body){
				id.push(data.body[i]._id);
				idLocalizacion.push(data.body[i].idLocalizacion);
				curado.push(data.body[i].curado);
				idRegistro.push(data.body[i].idRegistro);
				idEspecies.push(data.body[i].idEspecie);
				img.push(data.body[i].img);
			};
			this.setState({
				entradaMonitoreo:{
					id:id,
					idLocalizacion:idLocalizacion,
					curado:curado,
					idRegistro:idRegistro,
					idEspecies:idEspecies,
					img:img,
			}})
		})
		url = baseurl+'/restaured/variableregistro'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			var id =[];
			var idEntrada =[];
			var idVariable =[];
			var valor =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				idEntrada.push(data.body[i].idEntrada);
				idVariable.push(data.body[i].idVariable);
				valor.push(data.body[i].valor);
			};
			this.setState({
				variableRegistro:{
							id:id,
							idEntrada:idEntrada,
							idVariable:idVariable,
							valor:valor,
			}})
		})
	}
	getCounts=async()=>{
		var baseurl = "https://api.restaured.net"
		var url = baseurl+'/restaured/especie/count'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(async data => {
			localStorage.setItem('especieCount', JSON.stringify(data.body) )
			this.setState({especies:data.body})
		})
		.catch (async error => {
			var counts =  localStorage.getItem('especieCount')
			counts = JSON.parse(counts)
			this.setState({especies:counts})
		})
		url = baseurl+'/restaured/sitio/count'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(async data => {
			localStorage.setItem('sitioCount',JSON.stringify(data.body))
			this.setState({sitios:data.body})
		})
		.catch (async error => {
			var counts =localStorage.getItem('sitioCount')
			counts = JSON.parse(counts)
			this.setState({sitios:counts})
		})
		url = baseurl+'/restaured/monitoreo/count'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then( async data => {
			localStorage.setItem('monitoreoCount', JSON.stringify(data.body))
			this.setState({monitoreos:data.body})
		})
		.catch (async error => {
			var counts =  localStorage.getItem('monitoreoCount')
			counts = JSON.parse(counts)
			this.setState({monitoreos:counts})
		})
		var idrolCientitifo = "f7a649d1-5a3e-41ec-bbda-d1db699ecbea"
		var idrolCCiudadano = "8e187064-5b1d-4d76-876b-36a0137f415e"
		var idrolCurador = "099e715e-8ec1-4cdf-8e70-5fe43024b752"
		var idrolAdmin = "99819870-9831-4a19-98e7-ec3e45a91532"
		url = baseurl+'/auth/user/count/'+idrolCientitifo
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			this.setState({colaboradores:data.body})
		})
		.catch( async err => { 
			var counts = localStorage.getItem('colaboradorCount')
			counts = JSON.parse(counts)
			this.setState({colaboradores:counts})
		})
		url = baseurl+'/auth/user/count/'+idrolCCiudadano
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			var colab = this.state.colaboradores
			colab = colab + data.body
			this.setState({colaboradores:colab})
		})
		.catch( error => console.log(error))
		url = baseurl+'/auth/user/count/'+idrolCurador
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			var colab = this.state.colaboradores
			colab = colab + data.body
			this.setState({colaboradores:colab})
		})
			.catch( error => console.log(error))
			url = baseurl+'/auth/user/count/'+idrolAdmin
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(async data => {
			var colab = this.state.colaboradores
			colab = colab + data.body
			localStorage.setItem('colaboradorCount', JSON.stringify(colab))
			this.setState({colaboradores:colab})
		})
		.catch( error => console.log(error))
		
	}
	getMonitoreos=async()=>{
		var baseurl = "https://api.restaured.net"
		var url = baseurl+'/restaured/monitoreo'
		try{
			await axios.get(url)
			.then(response => response.data)
			.then(data => {
				var id =[];
				var nombre =[];
				var descripcion =[];
				var img=[]
				for(const i in data.body){
					id.push(data.body[i]._id);
					nombre.push(data.body[i].nombre);
					descripcion.push(data.body[i].descripcion);
					img.push(data.body[i].img);
				};
				this.setState({
					monitoreo:{
						id:id,
						nombre:nombre,
						descripcion:descripcion,
						img:img,
				}})
			})
			url = baseurl+'/restaured/registromonitoreo'
			await axios.get(url)
			.then(response => response.data)
			.then(data => {
				var id =[]; var nombre =[]; var idMonitoreo =[]; var idUser =[];
				var date=[]
				for(const i in data.body){
					id.push(data.body[i]._id);
					nombre.push(data.body[i].nombre);
					idUser.push(data.body[i].idUser);
					idMonitoreo.push(data.body[i].idMonitoreo);
					date.push(data.body[i].date);
				};
				this.setState({
					registroMonitoreo:{
						id:id,
						nombre:nombre,
						idUser:idUser,
						idMonitoreo:idMonitoreo,
						date:date,
				}})
			})
			await this.viewdata()
		}
		catch (e) {
			console.log(e)
			var monitoreos = localStorage.getItem('monitoreosMain')
			monitoreos = JSON.parse(monitoreos)
			this.setState({monitoreos_card: monitoreos})
		}
		
	}
	initial_state=async()=>{
		await this.getImagenes()
		await this.getCounts()
		await this.getMonitoreos()
	}
	viewdata=async()=>{
		var monitoreos= []
		if(this.state.monitoreo.nombre.length > 0){
			for(const i in this.state.monitoreo.id){
				var cantRegistros=0
				for(const j in this.state.registroMonitoreo.idMonitoreo){
					if(this.state.monitoreo.id[i] === this.state.registroMonitoreo.idMonitoreo[j])
					cantRegistros = cantRegistros + 1
				}
				var monitoreo = {
					cantRegistros:cantRegistros,
					nombre:this.state.monitoreo.nombre[i],
					descripcion:this.state.monitoreo.descripcion[i],
					img:this.state.monitoreo.img[i],
				}
				monitoreos.push(monitoreo)
			}
			await monitoreos.sort((a, b) => (a.cantRegistros < b.cantRegistros) ? 1 : -1)
			localStorage.setItem('monitoreosMain', JSON.stringify(monitoreos))
			this.setState({monitoreos_card: monitoreos})
		}
	}
	portafolio =()=>{
		var html = []
		var imagenes = this.state.imagenes
		console.log(imagenes)
		for(const i in imagenes){
			if(imagenes[i]){
				html.push( 
					<div className="col-lg-4 col-md-6 portfolio-item filter-app">
						<div className="portfolio-wrap">
							<img src={imagenes[i]} className="img-fluid" alt="" />
							<div className="portfolio-links">
								<a href={imagenes[i]} data-gall="portfolioGallery" className="venobox" title="App 1"><i className="bx bx-plus" /></a>
								</div>
						</div>
					</div>
				)
			}
	
		}
		return (
			<section style={{'max-height': '50vh', 'overflow-y': 'auto'}} id="portfolio" className="portfolio">
				<div className="container">
					<div className="section-title" data-aos="fade-in" data-aos-delay={100}>
						<h2>Galería de Fotos</h2>
					</div>			
					<div className="row portfolio-container" data-aos="fade-up">
						{html}
					</div>
				</div>
			</section>
		)
	}

	monitoreos=()=>{
		if(this.state.monitoreos_card && this.state.monitoreos_card.length > 0 ){
			var html =[]
			console.log(this.state.imagenes)
			for(const i in this.state.monitoreos_card){
				html.push(
				<div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay={100}>
					<img style={{'width':'30%', 'border-radius':'50%'}} src={this.state.monitoreos_card[i].img} />
					<h4>{this.state.monitoreos_card[i].nombre}</h4>
					<p>{this.state.monitoreos_card[i].descripcion}</p>
				</div>
				)
			}
			return(
			<div className="col-xl-7 d-flex align-items-stretch">
				<div className="icon-boxes d-flex flex-column justify-content-center">
					<div className="row">
						{html}
					</div>  
				</div>{/* End .content*/}  
			</div>  
			)
		}else return null
	}
	render(){
	return (
		<div>
			<NavbarL/>
			{/* ======= Hero Section ======= */}
			<section id="hero">
				<div className="hero-container" data-aos="fade-up">
					<h1>¡Te damos la Bienvenida!</h1>
					<h2>El proyecto tiene como objetivo identificar, analizar, actualizar y / o implementar rápidamente planes y estrategias  de Restauración de Paisajes Degradados</h2>
					<a href="../#about" className="btn-get-started scrollto"><i className="bx bx-chevrons-down" /></a>
				</div>
			</section>
			{/* End Hero */}
			<main id="main">
				{/* ======= Counts Section ======= */}
				<section id="counts" className="counts  section-bg">
					<div className="container">
						<div className="row no-gutters">
							<div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
								<div className="count-box">
									<i className="icofont-woodpecker" />
									<span data-toggle="counter-up">{this.state.especies}</span>
									<p><strong>Número de especies </strong> Monitoreadas</p>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
								<div className="count-box">
									<i className="icofont-map" />
									<span data-toggle="counter-up">{this.state.sitios}</span>
									<p><strong>Número de localizaciones </strong> de Monitoreo </p>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
								<div className="count-box">
									<i className="icofont-search-2" />
									<span data-toggle="counter-up">{this.state.monitoreos}</span>
									<p><strong>Número</strong> de Monitoreos</p>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
								<div className="count-box">
									<i className="icofont-users-alt-5" />
									<span data-toggle="counter-up">{this.state.colaboradores}</span>
									<p><strong>Colaboradores </strong> Científicos </p>
								</div>
							</div>
						</div>
					</div>
				</section>{/* End Counts Section */}
				{/* ======= Reports Section ======= */}
				<section id="about" className="about">
					<div className="container">
						<div className="row no-gutters">
							<div className="content col-xl-5 d-flex align-items-stretch" data-aos="fade-up">
								<div className="content">
									<h3>REPORTES</h3>
									<p>
										Aquí encontrarás todos los monitoreos que se tienen registrados y podras ver algunos de los gráficos resultado del análisis del monitoreo de la restauración 
									</p>  
									<a href="/reportes" className="about-btn">Ver todos <i className="bx bx-chevron-right" /></a>
								</div>  
							</div>  
							{this.monitoreos()}
						</div>  
					</div>  
				</section>{/* End Reports Section */}  
				{this.state.imagenes.length >0 ? this.portafolio():null}
				
				{/* ======= Network Section ======= */}
				<section id="services" className="services">
					<div className="container">
						<div className="section-title" data-aos="fade-in" data-aos-delay={100}>
							<h2>Colaboradores</h2>
							<p>Gracias a la contribución de diferentes entidades ahora podemos ofrecer este servicio</p>
						</div>
						<div className="row">
							<div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
								<div className="icon-box" data-aos="fade-up">
									<img src="img/canada.png" alt="Risaralda Bosque Modelo" style={{height: '60px'}} />
								</div>
							</div>
							<div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
								<div className="icon-box" data-aos="fade-up" data-aos-delay={100}>
									<img src="img/risaraldaBM.png" alt="Risaralda Bosque Modelo" />
								</div>
							</div>
							<div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
								<div className="icon-box" data-aos="fade-up" data-aos-delay={200}>
									<img src="img/riabm.jpg" alt="Risaralda Bosque Modelo" style={{height: '80px'}} />
								</div>
							</div>
							<div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
								<div className="icon-box" data-aos="fade-up" data-aos-delay={300}>
									<img src="img/ribm.jpg" alt="Risaralda Bosque Modelo" style={{height: '80px'}} />
								</div>
							</div>
						</div>
					</div>
				</section>{/* End Network Section */}
				{/* ======= Portfolio Section ======= */}
				</main>
				{/* End #main */}
			{/* ======= Footer ======= */}
			<footer id="footer">
				{/* <div className="footer-top">
					<div className="row justify-content-md-center">
						<div className="col-lg-3 col-md-6">
							<div className="social-links mt-3">
								<a href="../#" className="twitter"><i className="bx bxl-twitter" /></a>
								<a href="../#" className="facebook"><i className="bx bxl-facebook" /></a>
								<a href="../#" className="instagram"><i className="bx bxl-instagram" /></a>
								<a href="../#" className="google-plus"><i className="bx bxl-skype" /></a>
								<a href="../#" className="linkedin"><i className="bx bxl-linkedin" /></a>
							</div>
						</div>
					</div>
				</div> */}
				<div className="container footer-top">
					<div className="copyright">
						© Copyright <strong><span>Restaured 2024</span></strong>. All Rights Reserved
					</div>
				</div>
			</footer>{/* End Footer */}
			<a href="../#" className="back-to-top"><i className="icofont-simple-up" /></a>
			{/* Vendor JS Files */}
			{/* Template Main JS File */}
		</div>
	)};
	}