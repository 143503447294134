import React from "react";
import { MapContainer, TileLayer,SVGOverlay } from "react-leaflet";
import "leaflet/dist/leaflet.css";

class MapView extends React.Component {
  render() {
    const styleMap = { "width": "100%", "height": "60vh" }
    const position = [4.7221, -75.5699]
    const bounds = [
    [4.7353, -75.5967],
    [4.7091, -75.5568],
    ]
    const r = 5
    return (
      <MapContainer
        style={styleMap}
        center={position}
        zoom={15}
        scrollWheelZoom={false}>

        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
        />
        {/* <Marker position={position}>
          <Popup>
            A pretty CSS3 popup. <br /> Easily customizable.
          </Popup>
        </Marker> */}
        <SVGOverlay attributes={{ stroke: 'red' }} bounds={bounds}>
            <circle cx="50%" cy="50%" r={r} fill="#67b0d1" />
        </SVGOverlay>

      </MapContainer>
    )
  }

}

export default MapView;