import React, { Component } from 'react'
import '../assets/vendor/bootstrap/css/bootstrap.css'
import '../assets/css/style.css'
import NavbarL from './NavBar/NavbarLanding'
import FilterTableComponent from './TablaMonitoreos'
import { Line, Bar } from '@reactchartjs/react-chart.js'
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import Spinner from 'react-bootstrap/Spinner'
const axios = require('axios');

class ReportesMonitoreos extends Component{
	constructor(props){
    super(props);
    this.state={
        Rol: this.props.Rol,
        Nombre: this.props.Nombre,
        Iduser: this.props.Iduser,
        seleccion:{
          monitoreo:"",
          listIndicadores:[],
          indicador:""
        },
        graphics:[],
        controllerGraficosDataset:[],
        actionsGraficosDataset:{
          idLocalizacion:'',
          orden:'',
          genero:'',
          familia:'',
          from:'',
          toMonth:'',
        },
        viewTablaEntradas: [],
        selectedLocalizacion:[],
        datasetLocalizaciones: [],
        especie:{
          id:[],
          nombreCientifico:[],
          nombreComun:[],
          familia:[],
          orden:[],
          genero:[],
          activo:[],
          idLocalizacion:[],
        },
        variableRegistro:{
          id:[],
          idEntrada:[],
          idVariable:[],
          valor:[],
        },
        entradaMonitoreo:{
          id:[],
          idLocalizacion:[],
          curado:[],
          idRegistro:[],
          idEspecies:[],
        },
        variable:{
          id:[],
          idMonitoreo:[],
          nombre:[],
          pubObj:[]
        },
        variablexindicador:{
          id:[],
          idIndicador:[],
          idVariable:[]
        },
        indicador:{
          id:[],
          idMonitoreo:[],
          formula:[],
          nombre:[],
          pubObj:[],
          tipo:[],
          tipoGrafico:[],
          unidades:[],
        },
        monitoreo:{
          id:[],
          nombre:[],
          descripcion:[],
        },
        registroMonitoreo:{
          id:[],
          nombre:[],
          idUser:[],
          idMonitoreo:[],
        },
        entradaMonitoreo:{
          id:[],
          idLocalizacion:[],
          curado:[],
          img:[],
          idRegistro:[],
          idEspecies:[],
        },
        variableRegistro:{
          id:[],
          idEntrada:[],
          idVariable:[],
          valor:[],
        },
        localizacion:{
          id:[],
          idSitio:[],
          isParcela:[],
          idTransecto:[],
          idPO:[],
        },
        sitio:{
          id:[],
          nombre:[],
          geos:[],
          descripcion:[],
        },
        parcela:{
          id:[],
          nombre:[],
          geos:[],
          descripcion:[],
        },
        transecto:{
          id:[],
          nombre:[],
          geos:[],
          descripcion:[],
        },
        PO:{
          id:[],
          nombre:[],
          geos:[],
          descripcion:[],
        },
        ingresarEntradaMonitoreo:{
          body:[],
          status:"",
        },
        monitoreos:[],
        imagenes:{
          src:[],
          idRegistro:[],
        },
      }
    this.initial_state();
    }
  
  //PETICIONES
    getImagenes=async()=>{
      var baseurl = "https://api.restaured.net"
		var url = baseurl+'/restaured/entradamonitoreo'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(async data => {
			var images =[]; var idRegistro=[]
			for(const i in data.body){
				images.push(data.body[i].img)
				idRegistro.push(data.body[i].idRegistro)
			};
      localStorage.setItem('imagenesMain',JSON.stringify({images:{ src: images, idRegistro:idRegistro}}))
      this.setState({imagenes:{src:images, idRegistro:idRegistro}})
		})
		.catch( async error =>{
			var images = localStorage.getItem('imagenesMain')
			images = JSON.parse(images)
			this.setState({imagenes:{ src: images.images.src, idRegistro: images.images.idRegistro}})
		})	

	  }
    Getespecies=async()=>{
      var baseurl = "https://api.restaured.net"
      const url = baseurl+'/restaured/especie'
      await axios.get(url, {withCredentials:true})
      .then(response => response.data)
      .then(data => {
        var id =[];var nombreCientifico =[]; var nombreComun =[]; var idLocalizacion =[];
        var activo =[]; var familia =[]; var genero =[]; var orden =[];
        for(const i in data.body){
          id.push(data.body[i]._id)
          idLocalizacion.push(data.body[i].idLocalizacion)
          nombreCientifico.push(data.body[i].nombreCientifico)
          nombreComun.push(data.body[i].nombreComun)
          familia.push(data.body[i].familia)
          orden.push(data.body[i].orden)
          genero.push(data.body[i].genero)
          activo.push(data.body[i].activo)
        };
        localStorage.setItem('especies', JSON.stringify({
          especies:{
            id:id,
            idLocalizacion:idLocalizacion,
            nombreCientifico:nombreCientifico,
            nombreComun:nombreComun,
            orden:orden,
            familia:familia,
            genero:genero,
            activo:activo, }}))
        this.setState({
          especie:{
            id:id,
            idLocalizacion:idLocalizacion,
            nombreCientifico:nombreCientifico,
            nombreComun:nombreComun,
            orden:orden,
            familia:familia,
            genero:genero,
            activo:activo,
          }});
      })
      .catch( error => {
        console.log(error)
        var local = localStorage.getItem('especies');
        local = JSON.parse(local)
        this.setState({
          especie:{
            id:local.especies.id,
            idLocalizacion:local.especies.idLocalizacion,
            nombreCientifico:local.especies.nombreCientifico,
            nombreComun:local.especies.nombreComun,
            orden:local.especies.orden,
            familia:local.especies.familia,
            genero:local.especies.genero,
            activo:local.especies.activo,
          }});
      })
      // console.log(this.state.especies);
    } 
    getData=async()=>{
      var baseurl = "https://api.restaured.net"
      var url = baseurl+'/restaured/variable'
      try {
        await axios.get(url,{withCredentials: true})
        .then(response => response.data)
        .then(data => {
          // console.log(data)
          var id =[];
          var idMonitoreo =[];
          var nombre =[];
          var pubObj =[];
          for(const i in data.body){
            id.push(data.body[i]._id);
            idMonitoreo.push(data.body[i].idMonitoreo);
            nombre.push(data.body[i].nombre);
            pubObj.push(data.body[i].pubObj);
          };
          this.setState({
            variable:{
                  id:id,
                  idMonitoreo:idMonitoreo,
                  nombre:nombre,
                  pubObj:pubObj,
          }})
        })
        url = baseurl+'/restaured/variablexindicador'
        await axios.get(url,{withCredentials:true})
        .then(response => response.data)
        .then(data => {
          // console.log(data)
          var id =[];
          var idIndicador =[];
          var idVariable =[];
          for(const i in data.body){
            id.push(data.body[i]._id);
            idIndicador.push(data.body[i].idIndicador);
            idVariable.push(data.body[i].idVariable);
          };
          this.setState({
            variablexindicador:{
                  id:id,
                  idIndicador:idIndicador,
                  idVariable:idVariable,
          }})
        })
        url = baseurl+'/restaured/indicador'
        await axios.get(url, {withCredentials:true})
        .then(response => response.data)
        .then(data => {
          // console.log(data)
          var id =[];
          var idMonitoreo =[];
          var formula =[];
          var nombre =[];
          var pubObj =[];
          var tipo =[];
          var tipoGrafico =[];
          var unidades =[];
          for(const i in data.body){
            id.push(data.body[i]._id);
            idMonitoreo.push(data.body[i].idMonitoreo);
            formula.push(data.body[i].formula);
            nombre.push(data.body[i].nombre);
            pubObj.push(data.body[i].pubObj);
            tipo.push(data.body[i].tipo);
            tipoGrafico.push(data.body[i].tipoGrafico);
            unidades.push(data.body[i].unidades);
          };
          this.setState({
            indicador:{
                id:id,
                idMonitoreo:idMonitoreo,
                formula:formula,
                nombre:nombre,
                pubObj:pubObj,
                tipo:tipo,
                tipoGrafico:tipoGrafico,
                unidades:unidades,
          }})
        })
        url = baseurl+'/restaured/localizacion'
        await axios.get(url, {withCredentials:true})
        .then(response => response.data)
        .then(data => {
          console.log(data)
          var id =[];
          var idSitio =[];
          var idParcela =[];
          var idTransecto =[];
          var idPO =[];
          var nombre =[];
          for(const i in data.body){
            id.push(data.body[i]._id);
            idSitio.push(data.body[i].idSitio);
            idParcela.push(data.body[i].idParcela);
            idTransecto.push(data.body[i].idTransecto);
            idPO.push(data.body[i].idPO);
            nombre.push(data.body[i].nombre);
          };
          this.setState({
            localizacion:{
                  id:id,
                  idSitio:idSitio,
                  idParcela:idParcela,
                  idTransecto:idTransecto,
                  idPO:idPO,
                  nombre:nombre,
          }})
        })
        url = baseurl+'/restaured/sitio'
        await axios.get(url, {withCredentials:true})
        .then(response => response.data)
        .then(data => {
          var id =[];
          var nombre =[];
          var geos =[];
          var descripcion =[];
          for(const i in data.body){
            id.push(data.body[i]._id);
            geos.push(data.body[i].geos);
            descripcion.push(data.body[i].descripcion);
            nombre.push(data.body[i].nombre);
          };
          this.setState({
            sitio:{
                  id:id,
                  nombre:nombre,
                  geos:geos,
                  descripcion:descripcion,
          }})
        })
        url = baseurl+'/restaured/puntoobservacion'
        await axios.get(url, {withCredentials:true})
        .then(response => response.data)
        .then(data => {
          var id =[];
          var nombre =[];
          var geos =[];
          var descripcion =[];
          for(const i in data.body){
            id.push(data.body[i]._id);
            geos.push(data.body[i].geos);
            descripcion.push(data.body[i].descripcion);
            nombre.push(data.body[i].nombre);
          };
          this.setState({
            PO:{
                  id:id,
                  nombre:nombre,
                  geos:geos,
                  descripcion:descripcion,
          }})
        })
        url = baseurl+'/restaured/transecto'
        await axios.get(url, {withCredentials:true})
        .then(response => response.data)
        .then(data => {
          var id =[];
          var nombre =[];
          var geos =[];
          var descripcion =[];
          for(const i in data.body){
            id.push(data.body[i]._id);
            geos.push(data.body[i].geos);
            descripcion.push(data.body[i].descripcion);
            nombre.push(data.body[i].nombre);
          };
          this.setState({
            transecto:{
                  id:id,
                  nombre:nombre,
                  geos:geos,
                  descripcion:descripcion,
          }})
        })
        url = baseurl+'/restaured/parcela'
        await axios.get(url, {withCredentials:true})
        .then(response => response.data)
        .then(data => {
          var id =[];
          var nombre =[];
          var geos =[];
          var descripcion =[];
          for(const i in data.body){
            id.push(data.body[i]._id);
            geos.push(data.body[i].geos);
            descripcion.push(data.body[i].descripcion);
            nombre.push(data.body[i].nombre);
          };
          this.setState({
            parcela:{
                  id:id,
                  nombre:nombre,
                  geos:geos,
                  descripcion:descripcion,
          }})
        })
        url = baseurl+'/restaured/monitoreo'
        await axios.get(url, {withCredentials:true})
        .then(response => response.data)
        .then(data => {
          // console.log(data)
          var id =[];
          var nombre =[];
          var descripcion =[];
          for(const i in data.body){
            id.push(data.body[i]._id);
            nombre.push(data.body[i].nombre);
            descripcion.push(data.body[i].descripcion);
          };
          this.setState({
            monitoreo:{
              id:id,
              nombre:nombre,
              descripcion:descripcion,
          }})
        })
        url = baseurl+'/restaured/registromonitoreo'
        await axios.get(url, {withCredentials:true})
        .then(response => response.data)
        .then(data => {
          var id =[];
          var nombre =[];
          var idMonitoreo =[];
          var idUser =[];
          var date=[]
          for(const i in data.body){
            id.push(data.body[i]._id);
            nombre.push(data.body[i].nombre);
            idUser.push(data.body[i].idUser);
            idMonitoreo.push(data.body[i].idMonitoreo);
            date.push(data.body[i].date);
          };
          this.setState({
            registroMonitoreo:{
              id:id,
              nombre:nombre,
              idUser:idUser,
              idMonitoreo:idMonitoreo,
              date:date,
          }})
        })
        url = baseurl+'/restaured/entradamonitoreo'
        await axios.get(url, {withCredentials:true})
        .then(response => response.data)
        .then(data => {
          var id =[];
          var idLocalizacion =[];
          var curado =[];
          var idRegistro =[];
          var idEspecies =[];
          var img =[];
          for(const i in data.body){
            id.push(data.body[i]._id);
            idLocalizacion.push(data.body[i].idLocalizacion);
            curado.push(data.body[i].curado);
            idRegistro.push(data.body[i].idRegistro);
            img.push(data.body[i].img);
            idEspecies.push(data.body[i].idEspecie);
          };
          this.setState({
            entradaMonitoreo:{
                  id:id,
                  idLocalizacion:idLocalizacion,
                  curado:curado,
                  img:img,
                  idRegistro:idRegistro,
                  idEspecies:idEspecies,
          }})
        })
        url = baseurl+'/restaured/variableregistro'
        await axios.get(url, {withCredentials:true})
        .then(response => response.data)
        .then(data => {
          // console.log(data)
          var id =[];
          var idEntrada =[];
          var idVariable =[];
          var valor =[];
          for(const i in data.body){
            id.push(data.body[i]._id);
            idEntrada.push(data.body[i].idEntrada);
            idVariable.push(data.body[i].idVariable);
            valor.push(data.body[i].valor);
          };
          this.setState({
            variableRegistro:{
                  id:id,
                  idEntrada:idEntrada,
                  idVariable:idVariable,
                  valor:valor,
          }})
        })
        await this.loadLocalizaciones()
        await this.loadMonitoreos()
      }
      catch (error){
        var localizaciones = localStorage.getItem('localizaciones')
        var monitoreos = localStorage.getItem('monitoreos')
        localizaciones = JSON.parse(localizaciones)
        monitoreos = JSON.parse(monitoreos)
        console.log(localizaciones, monitoreos)
        this.setState({monitoreos:monitoreos, datasetLocalizaciones:localizaciones})

      }
    }
    loadMonitoreos=async()=>{
      var monitoreo={
        id:0,
        nombre:"",
        descripcion:"",
        variables:[],
        registros:[],
        indicadores:[],
      };
      var indicadores = {
        id:[],
        idMonitoreo:[],
        formula:[],
        nombre:[],
        pubObj:[],
        tipo:[],
        tipoGrafico:[],
        unidades:[],
      };
      var registros={
          id:[],
          iduser:[],
          nombre:[],
          entradas:[],
          date:[],
      };
      var entradas={
          id:[],  
          idLocalizacion:[],  
          idVariablesRegistro:[],  
          variables:[],
          especies:[],
          valores:[],
          curado:[],
          img:[],
      };
      var monitoreos=[]
      var valores=[];
      var variables=[];
      var idvariableRegistro=[]
       var idvariablesRegistro=[]
      var variableEntrada=[];
      var valorEntrada=[];
      for(const i in this.state.monitoreo.id){
        monitoreo.id=this.state.monitoreo.id[i]
        monitoreo.nombre=this.state.monitoreo.nombre[i]
        monitoreo.descripcion=this.state.monitoreo.descripcion[i]
        for(const j in this.state.variable.nombre){
          if(this.state.variable.idMonitoreo[j]===this.state.monitoreo.id[i]){
            var variable={
              id:this.state.variable.id[j],
              nombre:this.state.variable.nombre[j],
              pubObj:this.state.variable.pubObj[j]
            }
            monitoreo.variables.push(variable);
          }
        }
        for(const j in this.state.indicador.id){
          if(this.state.indicador.idMonitoreo[j]===this.state.monitoreo.id[i]){
            indicadores.id.push(this.state.indicador.id[j])
            indicadores.idMonitoreo.push(this.state.indicador.idMonitoreo[j])
            indicadores.formula.push(this.state.indicador.formula[j])
            indicadores.nombre.push(this.state.indicador.nombre[j])
            indicadores.pubObj.push(this.state.indicador.pubObj[j])
            indicadores.tipo.push(this.state.indicador.tipo[j])
            indicadores.tipoGrafico.push(this.state.indicador.tipoGrafico[j])
            indicadores.unidades.push(this.state.indicador.unidades[j])
          }
        }
        // console.log(this.state.entradaMonitoreo)
        for(const j in this.state.registroMonitoreo.id){
          if(this.state.monitoreo.id[i] === this.state.registroMonitoreo.idMonitoreo[j]){
            registros.id.push(this.state.registroMonitoreo.id[j])
            registros.iduser.push(this.state.registroMonitoreo.idUser[j])
            registros.nombre.push(this.state.registroMonitoreo.nombre[j])
            registros.date.push(this.state.registroMonitoreo.date[j])
            for(const k in this.state.entradaMonitoreo.id){
              if(this.state.registroMonitoreo.id[j] === this.state.entradaMonitoreo.idRegistro[k]){
                entradas.id.push(this.state.entradaMonitoreo.id[k]);
                entradas.idLocalizacion.push(this.state.entradaMonitoreo.idLocalizacion[k]);
                entradas.curado.push(this.state.entradaMonitoreo.curado[k]);
                entradas.img.push(this.state.entradaMonitoreo.img[k]);
                entradas.especies.push(this.state.entradaMonitoreo.idEspecies[k]);
                  for(const l in this.state.variableRegistro.id){
                    if(this.state.entradaMonitoreo.id[k] === this.state.variableRegistro.idEntrada[l]){
                      idvariableRegistro.push(this.state.variableRegistro.id[l]);
                      valorEntrada.push(Number(this.state.variableRegistro.valor[l]));
                      variableEntrada.push(this.state.variableRegistro.idVariable[l]);
                    }
                  }
                idvariablesRegistro.push(idvariableRegistro)  
                variables.push(variableEntrada)  
                valores.push(valorEntrada)  
          idvariableRegistro=[]
                valorEntrada=[]
                variableEntrada=[]
                }
              }
              entradas.variables=variables;
              entradas.valores=valores;
              entradas.idVariablesRegistro=idvariablesRegistro;
              variables=[];
              idvariablesRegistro=[];
              valores=[];
              registros.entradas.push(entradas);
              entradas={id:[], idLocalizacion:[], idVariablesRegistro:[], variables:[], valores:[], curado:[],especies:[], img:[]}
          }
        }
        monitoreo.indicadores=indicadores;
        monitoreo.registros=registros;
        registros={ id:[], iduser:[], nombre:[], entradas:[], date:[] };
        indicadores = { id:[], idMonitoreo:[], formula:[], nombre:[], pubObj:[], tipo:[], tipoGrafico:[], unidades:[],};
        monitoreos.push(monitoreo);
        monitoreo={ id:0, nombre:"", descripcion:"", variables:[],indicadores:[],};
      }
      console.log(monitoreos)
      localStorage.setItem('monitoreos', JSON.stringify(monitoreos))
      this.setState({
        monitoreos:monitoreos
      })
    }
    loadLocalizaciones =async()=>{
      var localizacion={
        id:[],
        sitio:[],
        parcela:[],
        transecto:[],
        PO:[],    
      }
      for(const i in this.state.localizacion.id){
        for(const j in this.state.sitio.id){
          if(this.state.localizacion.idSitio[i] === this.state.sitio.id[j]){
            var sitio= {
              id:this.state.sitio.id[j],
              nombre:this.state.sitio.nombre[j],
              geos:this.state.sitio.geos[j],
              descripcion:this.state.sitio.descripcion[j],
            }
            localizacion.id.push(this.state.localizacion.id[i])
            localizacion.sitio.push(sitio)
            var verify = localizacion.PO.length
            for(const k in this.state.PO.id){
              if(this.state.PO.id[k]=== this.state.localizacion.idPO[i]){
                var ubicacionpo = {
                  id:this.state.PO.id[k],
                  geos:this.state.PO.geos[k],
                  nombre:this.state.PO.nombre[k],
                  descripcion:this.state.PO.descripcion[k],
                }
                localizacion.PO.push(ubicacionpo)
              }
            }
            if(verify === localizacion.PO.length)
              localizacion.PO.push(null)
            verify=localizacion.transecto.length
            for(const k in this.state.transecto.id){
              if(this.state.transecto.id[k]=== this.state.localizacion.idTransecto[i]){
                var ubicaciontrans = {
                  id:this.state.transecto.id[k],
                  geos:this.state.transecto.geos[k],
                  nombre:this.state.transecto.nombre[k],
                  descripcion:this.state.transecto.descripcion[k],
                }
                localizacion.transecto.push(ubicaciontrans)
              }
            }
            if(verify === localizacion.transecto.length)
              localizacion.transecto.push(null)
            verify=localizacion.parcela.length
            for(const k in this.state.parcela.id){
              if(this.state.parcela.id[k]=== this.state.localizacion.idParcela[i]){
                var ubicacionparce = {
                  id:this.state.parcela.id[k],
                  geos:this.state.parcela.geos[k],
                  nombre:this.state.parcela.nombre[k],
                  descripcion:this.state.parcela.descripcion[k],
                }
                localizacion.parcela.push(ubicacionparce)
              }
            }
            if(verify === localizacion.parcela.length)
              localizacion.parcela.push(null)
          }
        }
      }
      localStorage.setItem('localizaciones', JSON.stringify(localizacion))
      this.setState({
        datasetLocalizaciones:localizacion
      })
    }
    initial_state=async()=>{
      await this.getImagenes();
      await this.Getespecies();
      await this.getData();
      console.log(this.state.monitoreos, this.state.datasetLocalizaciones, this.state.especie)
      var indicadores=[]
      if(this.state.monitoreos.length > 0){
        for(const j in this.state.monitoreos[0].indicadores.id){
          var indicador = { id: this.state.monitoreos[0].indicadores.id[j], nombre: this.state.monitoreos[0].indicadores.nombre[j]}
          indicadores.push(indicador)
        }
        await this.setState({ seleccion:{ monitoreo: this.state.monitoreos[0].id, listIndicadores:indicadores, indicador:'all'}})
      }
    }
    handleSelectLocalizacion=async(e)=>{
      await this.controladorGraficos()
      console.log(e.target.value)
      var data = this.state.controllerGraficosDataset
      for(const j in this.state.controllerGraficosDataset){
        var newDataset =[]
        if(this.state.controllerGraficosDataset[j]){
          if(e.target.value !== 'all'){
            for(const i in this.state.controllerGraficosDataset[j].data.datasets){
              if(this.state.controllerGraficosDataset[j].data.datasets[i].idLocalizacion === e.target.value)
                newDataset.push(this.state.controllerGraficosDataset[j].data.datasets[i])
            }
          }else{
            newDataset= this.state.controllerGraficosDataset[j].data.datasets
          }
          data[j].data.datasets = newDataset
        }
      }
      await this.setState({ actionsGraficosDataset:{
        ...this.state.actionsGraficosDataset,
        idLocalizacion: e.target.value
      }})
      await this.setState({ controllerGraficosDataset: data})
      await this.renderControladorGraficos()
    }
    handleSelectFamilia=async(e)=>{
      await this.controladorGraficos()
      console.log(e.target.value)
      var data = this.state.controllerGraficosDataset
      for(const j in this.state.controllerGraficosDataset){
        var newDataset =[]
        if(this.state.controllerGraficosDataset[j]){
          if(e.target.value !== 'all'){
            for(const i in this.state.controllerGraficosDataset[j].data.datasets){
              if(this.state.controllerGraficosDataset[j].data.datasets[i].familia === e.target.value)
                newDataset.push(this.state.controllerGraficosDataset[j].data.datasets[i])
            }
          }else{
            newDataset= this.state.controllerGraficosDataset[j].data.datasets
          }
          data[j].data.datasets = newDataset
        }
      }
      await this.setState({ actionsGraficosDataset:{
        ...this.state.actionsGraficosDataset,
        familia: e.target.value
      }})
      await this.setState({ controllerGraficosDataset: data})
      await this.renderControladorGraficos()
    }
    handleSelectOrden=async(e)=>{
      await this.controladorGraficos()
      console.log(e.target.value)
      var data = this.state.controllerGraficosDataset
      for(const j in this.state.controllerGraficosDataset){
        var newDataset =[]
        if(this.state.controllerGraficosDataset[j]){
          if(e.target.value !== 'all'){
            for(const i in this.state.controllerGraficosDataset[j].data.datasets){
              if(this.state.controllerGraficosDataset[j].data.datasets[i].orden === e.target.value)
                newDataset.push(this.state.controllerGraficosDataset[j].data.datasets[i])
            }
          }else{
            newDataset= this.state.controllerGraficosDataset[j].data.datasets
          }
          data[j].data.datasets = newDataset
        }
      }
      await this.setState({ actionsGraficosDataset:{
        ...this.state.actionsGraficosDataset,
        orden: e.target.value
      }})
      await this.setState({ controllerGraficosDataset: data})
      await this.renderControladorGraficos()
    }
    handleSelectGenero=async(e)=>{
      await this.controladorGraficos()
      console.log(e.target.value)
      var data = this.state.controllerGraficosDataset
      for(const j in this.state.controllerGraficosDataset){
        var newDataset =[]
        if(this.state.controllerGraficosDataset[j]){
          if(e.target.value !== 'all'){
            for(const i in this.state.controllerGraficosDataset[j].data.datasets){
              if(this.state.controllerGraficosDataset[j].data.datasets[i].genero === e.target.value)
                newDataset.push(this.state.controllerGraficosDataset[j].data.datasets[i])
            }
          }else{
            newDataset= this.state.controllerGraficosDataset[j].data.datasets
          }
          data[j].data.datasets = newDataset
        }
      }
      await this.setState({ actionsGraficosDataset:{
        ...this.state.actionsGraficosDataset,
        genero: e.target.value
      }})
      await this.setState({ controllerGraficosDataset: data})
      await this.renderControladorGraficos()
    }
    handleSelectfechaToMonth=async(e)=>{
      await this.setState({ actionsGraficosDataset:{
        ...this.state.actionsGraficosDataset,
        toMonth: Number( e.target.value)
      }})
    }
    handleSelectfechaFrom=async(e)=>{
      await this.setState({ actionsGraficosDataset:{
        ...this.state.actionsGraficosDataset,
        from: Number(e.target.value)
      }})
    }
    filterDate=async(e)=>{
      await this.controladorGraficos()
      var data = this.state.controllerGraficosDataset
      console.log(this.state.actionsGraficosDataset)
      if(this.state.actionsGraficosDataset.toMonth >=0 && this.state.actionsGraficosDataset.from >= 0){
        for(const j in data){
          if(data[j]){
            var newLabels = data[j].data.labels.slice( this.state.actionsGraficosDataset.from, this.state.actionsGraficosDataset.toMonth +1 )
            data[j].data.labels = newLabels
            for(const i in data[j].data.datasets){
              var newData = data[j].data.datasets[i].data.slice( this.state.actionsGraficosDataset.from, this.state.actionsGraficosDataset.toMonth +1)
              data[j].data.datasets[i].data = newData
            }
          }
        }
        await this.setState({ controllerGraficosDataset: data})
        await this.renderControladorGraficos()
        // await this.controladorGraficos()
      }else{
        await this.controladorGraficos()
        // await this.setState({ controllerGraficosDataset: data})
        await this.renderControladorGraficos()
  
      }
    }
    //GRAFICOS
    
    renderControladorGraficos=async()=>{
      await this.setState({ graphics : null})
      var graphics = []
      if(this.state.monitoreos.length > 0 ) {
        for(const i in this.state.monitoreos){
          if(this.state.monitoreos[i].id === this.state.seleccion.monitoreo){
            for(const j in this.state.monitoreos[i].indicadores.id){
              if(this.state.monitoreos[i].indicadores.pubObj[j] === 'Web' ||
                this.state.monitoreos[i].indicadores.pubObj[j] === 'Cientifico'){
                if(this.state.monitoreos[i].indicadores.tipoGrafico[j] === 'Sparkline'){
                  var variables =[]
                  for(const k in this.state.variablexindicador.id){
                    if(this.state.variablexindicador.idIndicador[k] === this.state.monitoreos[i].indicadores.id[j])
                    variables.push(this.state.variablexindicador.idVariable[k])
                  }
                  var LineChart = this.renderLineChart(this.state.monitoreos[i].indicadores.id[j],this.state.monitoreos[i].indicadores.id.length)
                  graphics.push(LineChart)
                }
                if(this.state.monitoreos[i].indicadores.tipoGrafico[j] === 'Barras'){
                  var variables =[]
                  for(const k in this.state.variablexindicador.id){
                    if(this.state.variablexindicador.idIndicador[k] === this.state.monitoreos[i].indicadores.id[j])
                    variables.push(this.state.variablexindicador.idVariable[k])
                  }
                  var BarChart = this.renderBarChart(this.state.monitoreos[i].indicadores.id[j],this.state.monitoreos[i].indicadores.id.length)
                  graphics.push(BarChart)
                }
                if(this.state.monitoreos[i].indicadores.tipoGrafico[j] === 'BarrasApiladas'){
                var variables =[]
                for(const k in this.state.variablexindicador.id){
                  if(this.state.variablexindicador.idIndicador[k] === this.state.monitoreos[i].indicadores.id[j])
                  variables.push(this.state.variablexindicador.idVariable[k])
                }
                var BarChart = this.renderStackedBarChart(this.state.monitoreos[i].indicadores.id[j],this.state.monitoreos[i].indicadores.id.length)
                graphics.push(BarChart)
              }
                }
              }
          }
        }
      }
      console.log(graphics)
      var html = <div id="graphics"> { graphics}</div>
      await this.setState({graphics: html})
    }
    controladorGraficos=async()=>{
        var graphics = []
      for(const i in this.state.monitoreos){
        if(this.state.monitoreos[i].id === this.state.seleccion.monitoreo){
          for(const j in this.state.monitoreos[i].indicadores.id){
            if(this.state.monitoreos[i].indicadores.pubObj[j] === 'Web' || 
            this.state.monitoreos[i].indicadores.pubObj[j] === 'Cientifico'){
              if(this.state.seleccion.indicador !== 'all'){
                if(this.state.monitoreos[i].indicadores.id[j] === 
                  this.state.seleccion.indicador){
                  var variables =[]	
                  if(this.state.monitoreos[i].indicadores.tipoGrafico[j] === 'Sparkline'){
                    variables =[]
                    for(const k in this.state.variablexindicador.id){
                      if(this.state.variablexindicador.idIndicador[k] === this.state.monitoreos[i].indicadores.id[j])
                      variables.push(this.state.variablexindicador.idVariable[k])
                    }
                    var LineChart = this.LineChart(variables,
                      this.state.monitoreos[i].indicadores.nombre[j],
                      this.state.monitoreos[i].indicadores.id[j],
                      this.state.monitoreos[i].indicadores.tipo[j],
                      this.state.monitoreos[i].indicadores.formula[j],
                      this.state.monitoreos[i].indicadores.unidades[j],
                      this.state.monitoreos[i], this.state.especie)
                    graphics.push(LineChart)
                  }
                  if(this.state.monitoreos[i].indicadores.tipoGrafico[j] === 'Barras'){
                    variables =[]
                    for(const k in this.state.variablexindicador.id){
                      if(this.state.variablexindicador.idIndicador[k] === this.state.monitoreos[i].indicadores.id[j])
                      variables.push(this.state.variablexindicador.idVariable[k])
                    }
                    var BarChart = this.BarChart(variables,
                      this.state.monitoreos[i].indicadores.nombre[j],
                      this.state.monitoreos[i].indicadores.id[j],
                      this.state.monitoreos[i].indicadores.tipo[j],
                      this.state.monitoreos[i].indicadores.formula[j],
                      this.state.monitoreos[i].indicadores.unidades[j],
                      this.state.monitoreos[i], this.state.especie)
                    graphics.push(BarChart)
                  }
                  if(this.state.monitoreos[i].indicadores.tipoGrafico[j] === 'BarrasApiladas'){
                    variables =[]
                    for(const k in this.state.variablexindicador.id){
                      if(this.state.variablexindicador.idIndicador[k] === this.state.monitoreos[i].indicadores.id[j])
                      variables.push(this.state.variablexindicador.idVariable[k])
                    }
                    var StackedBarChart = this.StackedBarChart(variables,
                      this.state.monitoreos[i].indicadores.nombre[j],
                      this.state.monitoreos[i].indicadores.id[j],
                      this.state.monitoreos[i].indicadores.tipo[j],
                      this.state.monitoreos[i].indicadores.formula[j],
                      this.state.monitoreos[i].indicadores.unidades[j],
                      this.state.monitoreos[i], this.state.especie)
                    // var StackedBarChart = null
                    graphics.push(StackedBarChart)
                  }
                }else{
                  graphics.push(null)
                }
              }
              if(this.state.seleccion.indicador === 'all'){
                if(this.state.monitoreos[i].indicadores.tipoGrafico[j] === 'Sparkline'){
                  variables =[]
                  for(const k in this.state.variablexindicador.id){
                    if(this.state.variablexindicador.idIndicador[k] === this.state.monitoreos[i].indicadores.id[j])
                    variables.push(this.state.variablexindicador.idVariable[k])
                  }
                  var LineChart2 = this.LineChart(variables,
                    this.state.monitoreos[i].indicadores.nombre[j],
                    this.state.monitoreos[i].indicadores.id[j],
                    this.state.monitoreos[i].indicadores.tipo[j],
                    this.state.monitoreos[i].indicadores.formula[j],
                    this.state.monitoreos[i].indicadores.unidades[j],
                    this.state.monitoreos[i], this.state.especie)
                  graphics.push(LineChart2)
                }
                if(this.state.monitoreos[i].indicadores.tipoGrafico[j] === 'Barras'){
                  variables =[]
                  for(const k in this.state.variablexindicador.id){
                    if(this.state.variablexindicador.idIndicador[k] === this.state.monitoreos[i].indicadores.id[j])
                    variables.push(this.state.variablexindicador.idVariable[k])
                  }
                  var BarChart2 = this.BarChart(variables,
                    this.state.monitoreos[i].indicadores.nombre[j],
                    this.state.monitoreos[i].indicadores.id[j],
                    this.state.monitoreos[i].indicadores.tipo[j],
                    this.state.monitoreos[i].indicadores.formula[j],
                    this.state.monitoreos[i].indicadores.unidades[j],
                    this.state.monitoreos[i], this.state.especie)
                  graphics.push(BarChart2)
                }
                if(this.state.monitoreos[i].indicadores.tipoGrafico[j] === 'BarrasApiladas'){
                  variables =[]
                  for(const k in this.state.variablexindicador.id){
                    if(this.state.variablexindicador.idIndicador[k] === this.state.monitoreos[i].indicadores.id[j])
                    variables.push(this.state.variablexindicador.idVariable[k])
                  }
                  var StackedBarChart2 = this.StackedBarChart(variables,
                    this.state.monitoreos[i].indicadores.nombre[j],
                    this.state.monitoreos[i].indicadores.id[j],
                    this.state.monitoreos[i].indicadores.tipo[j],
                    this.state.monitoreos[i].indicadores.formula[j],
                    this.state.monitoreos[i].indicadores.unidades[j],
                    this.state.monitoreos[i], this.state.especie)
                  graphics.push(StackedBarChart2)
                }	
              }
            }
          }
        }
      }
      console.log(graphics)
    await this.setState({controllerGraficosDataset:graphics})
    this.renderControladorGraficos()
    }
    BarChart =(variables,nombre,idindicador,tipo,formula,unidades,monitoreos,especies)=>{
      const options = {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      }
      var data;
      var title;
      var datasets=[]
      var labels=[]
      for(const j in monitoreos.registros.entradas){
        var realdate =  new Date (monitoreos.registros.date[j]*1000)
        if(labels.length > 0 ){
          var replydate= false
          for(const i in labels){
            if (labels[i] === realdate.getDate()+"/"+realdate.getMonth()+"/"+realdate.getFullYear()){
              replydate= true
            }
          }
          if(!replydate)
          labels.push(realdate.getDate()+"/"+realdate.getMonth()+"/"+realdate.getFullYear())
        }else{
          labels.push(realdate.getDate()+"/"+realdate.getMonth()+"/"+realdate.getFullYear())
        }
        for(const k in monitoreos.registros.entradas[j].variables){
          for(const l in monitoreos.registros.entradas[j].variables[k]){
            for(const m in variables){
            if(monitoreos.registros.entradas[j].variables[k][l] === variables[m]){
                title= monitoreos.nombre + ' ' + nombre;
                var R = Math.floor(Math.random()*(256));
                var G = Math.floor(Math.random()*(256));
                var B = Math.floor(Math.random()*(256));
                var dataset={
                  label:"",
                  data:[],
                  idLocalizacion:"",
                  familia:"",
                  orden:"",
                  genero:"",
                  backgroundColor:"rgb( "+String(R)+","+String(G)+","+String(B)+")",
                };
                for(const n in especies.id){
                if(especies.id[n]===monitoreos.registros.entradas[j].especies[k]){
                  if(datasets.length > 0){
                    var unique = false
                    var reply= false
                    var val
                    for(const o in datasets){
                      if(datasets[o].label !== especies.nombreCientifico[n]){
                        unique = true
                        reply = false
                      }
                      if(datasets[o].label === especies.nombreCientifico[n]){
                        reply = true
                        unique = false
                        val = o
                      }
                    }
                    if(unique){
                      dataset.label= especies.nombreCientifico[n];
                      dataset.data.push(monitoreos.registros.entradas[j].valores[k][l]);
                      dataset.idLocalizacion=monitoreos.registros.entradas[j].idLocalizacion[k];
                      dataset.familia= especies.familia[n];
                      dataset.orden= especies.orden[n];
                      dataset.genero= especies.genero[n];
                      datasets.push(dataset)
                    }if(reply){
                      datasets[val].data.push(monitoreos.registros.entradas[j].valores[k][l]);
                      }
                  }else{
                    dataset.label= especies.nombreCientifico[n];
                    dataset.data.push(monitoreos.registros.entradas[j].valores[k][l]);
                    dataset.idLocalizacion=monitoreos.registros.entradas[j].idLocalizacion[k];
                    dataset.familia= especies.familia[n];
                    dataset.orden= especies.orden[n];
                    dataset.genero= especies.genero[n];
                    datasets.push(dataset)
                  }
                }
                }
              }
            }
          }
        }
      }
      data={
        labels:labels,
        datasets:datasets,
      }
      //aramar el objeto idindicador, data
      var controler= { id: idindicador, data:data, title: title, options: options}
      //capturara del state la lista de controladorGraficos
      // var controllerGraficos = this.state.controllerGraficosDataset
      //adcionar el objeto creado
      // controllerGraficos.push(controler)
      //setear el controlado nuevamente async 
      return controler
      //recorrer el nuevo controlador
    }  
    LineChart =(variables,nombre,idindicador,tipo,formula,unidades,monitoreos,especies)=>{
      const options = {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      }
      var data;
      var title;
      var datasets=[]
      var labels=[]
      for(const j in monitoreos.registros.entradas){
        var realdate =  new Date (monitoreos.registros.date[j]*1000)
        if(labels.length > 0 ){
          var replydate= false
          for(const i in labels){
            if (labels[i] === realdate.getDate()+"/"+realdate.getMonth()+"/"+realdate.getFullYear()){
              replydate= true
            }
          }
          if(!replydate)
          labels.push(realdate.getDate()+"/"+realdate.getMonth()+"/"+realdate.getFullYear())
        }else{
          labels.push(realdate.getDate()+"/"+realdate.getMonth()+"/"+realdate.getFullYear())
        }
        for(const k in monitoreos.registros.entradas[j].variables){
          for(const l in monitoreos.registros.entradas[j].variables[k]){
            for(const m in variables){
              if(monitoreos.registros.entradas[j].variables[k][l] === variables[m]){
                title= monitoreos.nombre + ' ' + nombre;
                var R = Math.floor(Math.random()*(256));
                var G = Math.floor(Math.random()*(256));
                var B = Math.floor(Math.random()*(256));
                var dataset={
                  label:"",
                  data:[],
                  fill:false,
                  idLocalizacion:"",
                  familia:"",
                  orden:"",
                  genero:"",
                  backgroundColor:"rgb( "+String(R)+","+String(G)+","+String(B)+")",
                  borderColor:"rgb( "+String(R)+","+String(G)+","+String(B)+")",
                };
                for(const n in especies.id){
                if(especies.id[n]===monitoreos.registros.entradas[j].especies[k]){
                  if(datasets.length > 0){
                    var unique = false
                    var reply= false
                    var val
                    for(const o in datasets){
                      if(datasets[o].label !== especies.nombreCientifico[n]){
                        unique = true
                        reply = false
                      }
                      if(datasets[o].label === especies.nombreCientifico[n]){
                        reply = true
                        unique = false
                        val = o
                      }
                    }
                    if(unique){
                      dataset.label= especies.nombreCientifico[n];
                      dataset.data.push(monitoreos.registros.entradas[j].valores[k][l]);
                      dataset.idLocalizacion=monitoreos.registros.entradas[j].idLocalizacion[k];
                      dataset.familia= especies.familia[n];
                      dataset.orden= especies.orden[n];
                      dataset.genero= especies.genero[n];
                      datasets.push(dataset)
                    }if(reply){
                      datasets[val].data.push(monitoreos.registros.entradas[j].valores[k][l]);
                      }
                  }else{
                    dataset.label= especies.nombreCientifico[n];
                    dataset.data.push(monitoreos.registros.entradas[j].valores[k][l]);
                    dataset.idLocalizacion=monitoreos.registros.entradas[j].idLocalizacion[k];
                    dataset.familia= especies.familia[n];
                    dataset.orden= especies.orden[n];
                    dataset.genero= especies.genero[n];
                    datasets.push(dataset)
                  }
                }
                }
              }
            }
          }
        }
      }
        data={
        labels:labels,
        datasets:datasets,
      }
      //aramar el objeto idindicador, data
      var controler= { id: idindicador, data:data, title: title, options: options}
      //capturara del state la lista de controladorGraficos
      // var controllerGraficos = this.state.controllerGraficosDataset
      //adcionar el objeto creado
      // controllerGraficos.push(controler)
      //setear el controlado nuevamente async 
      return controler
      //recorrer el nuevo controlador
    }
    StackedBarChart =(variables,nombre,idindicador,tipo,formula,unidades,monitoreos,especies)=>{
      const options = {
        scales: {
          yAxes: [
          {
            stacked: true,
            ticks: {
            beginAtZero: true,
            },
          },
          ],
          xAxes: [
          {
            stacked: true,
          },
          ],
        },
        };		  
      var data;
      var title;
      var datasets=[]
      var labels=[]
      for(const j in monitoreos.registros.entradas){
        var realdate =  new Date (monitoreos.registros.date[j]*1000)
        if(labels.length > 0 ){
          var replydate= false
          for(const i in labels){
            if (labels[i] === realdate.getDate()+"/"+realdate.getMonth()+"/"+realdate.getFullYear()){
              replydate= true
            }
          }
          if(!replydate)
          labels.push(realdate.getDate()+"/"+realdate.getMonth()+"/"+realdate.getFullYear())
        }else{
          labels.push(realdate.getDate()+"/"+realdate.getMonth()+"/"+realdate.getFullYear())
        }
        for(const k in monitoreos.registros.entradas[j].variables){
          for(const l in monitoreos.registros.entradas[j].variables[k]){
            for(const m in variables){
            if(monitoreos.registros.entradas[j].variables[k][l] === variables[m]){
                title= monitoreos.nombre + ' ' + nombre;
                var R = Math.floor(Math.random()*(256));
                var G = Math.floor(Math.random()*(256));
                var B = Math.floor(Math.random()*(256));
                var dataset={
                  label:"",
                  data:[],
                  idLocalizacion:"",
                  familia:"",
                  orden:"",
                  genero:"",
                  backgroundColor:"rgb( "+String(R)+","+String(G)+","+String(B)+")",
                };
                for(const n in especies.id){
                if(especies.id[n]===monitoreos.registros.entradas[j].especies[k]){
                  if(datasets.length > 0){
                    var unique = false
                    var reply= false
                    var val
                    for(const o in datasets){
                      if(datasets[o].label !== especies.nombreCientifico[n]){
                        unique = true
                        reply = false
                      }
                      if(datasets[o].label === especies.nombreCientifico[n]){
                        reply = true
                        unique = false
                        val = o
                      }
                    }
                    if(unique){
                      dataset.label= especies.nombreCientifico[n];
                      dataset.data.push(monitoreos.registros.entradas[j].valores[k][l]);
                      dataset.idLocalizacion=monitoreos.registros.entradas[j].idLocalizacion[k];
                      dataset.familia= especies.familia[n];
                      dataset.orden= especies.orden[n];
                      dataset.genero= especies.genero[n];
                      datasets.push(dataset)
                    }if(reply){
                      datasets[val].data.push(monitoreos.registros.entradas[j].valores[k][l]);
                      }
                  }else{
                    dataset.label= especies.nombreCientifico[n];
                    dataset.data.push(monitoreos.registros.entradas[j].valores[k][l]);
                    dataset.idLocalizacion=monitoreos.registros.entradas[j].idLocalizacion[k];
                    dataset.familia= especies.familia[n];
                    dataset.orden= especies.orden[n];
                    dataset.genero= especies.genero[n];
                    datasets.push(dataset)
                  }
                }
                }
              }
            }
          }
        }
      }
      data={
        labels:labels,
        datasets:datasets,
      }
      //aramar el objeto idindicador, data
      var controler= { id: idindicador, data:data, title: title, options: options}
      //capturara del state la lista de controladorGraficos
      // var controllerGraficos = this.state.controllerGraficosDataset
      //adcionar el objeto creado
      // controllerGraficos.push(controler)
      //setear el controlado nuevamente async 
      console.log(controler)
      return controler
      //recorrer el nuevo controlador
    }
    selectLocalizacion=()=>{
      var localizacion=[]
      if(this.state.controllerGraficosDataset.length>0){
        for(const i in this.state.datasetLocalizaciones.id){
          localizacion.push( <option value={this.state.datasetLocalizaciones.id[i]}> 
          {this.state.datasetLocalizaciones.sitio[i].nombre + " "+
          (this.state.datasetLocalizaciones.transecto[i]? this.state.datasetLocalizaciones.transecto[i].nombre : " " ) +
          (this.state.datasetLocalizaciones.parcela[i]? this.state.datasetLocalizaciones.parcela[i].nombre : " " ) +
          (this.state.datasetLocalizaciones.PO[i]? this.state.datasetLocalizaciones.PO[i].nombre : " " )}</option> )
        }
        return(	<div className="row col-lg-2"> 
              <p className="col-xl-12"> Localización </p>
              <select className="form-control" onChange={(event)=> this.handleSelectLocalizacion(event)}>
                <option value='all'> Todas </option>
                {localizacion}
              </select>
            </div>)
      }else{
        return null
      }
    }
    selectFecha=()=>{
      var fechas=[]
      if(this.state.controllerGraficosDataset.length>0){
        var fully = true
        for(const j in this.state.controllerGraficosDataset){
          if(this.state.controllerGraficosDataset[j] && fully){
            for(const i in this.state.controllerGraficosDataset[j].data.labels){
              fechas.push( <option value={i}> {this.state.controllerGraficosDataset[j].data.labels[i]}</option> )
            }
            fully = false
          }
        }
  
        return(
        <div className="row col-lg-6"> 
          <p className="col-xl-12"> Fechas </p> 
          <div className="row col-lg-1"/>
          <div className="row col-lg-5">
            <p className="col-lg-4 pt-2"> De: </p> 
            <select className="form-control col-lg-8" onChange={(event)=> this.handleSelectfechaFrom(event)}>
              <option value={-1}> Todas </option>
                {fechas}
            </select>
          </div>
          <div className="row col-lg-5">
            <p className="col-lg-4 pt-2">Hasta: </p> 
            <select className="form-control col-lg-8" onChange={(event)=> this.handleSelectfechaToMonth(event)}>
              <option value={-1}> Todas </option>
              {fechas}
            </select>
          </div>
          <button className="btn-primary col-lg-2" onClick={(e) => this.filterDate()}> Filtrar </button>
        </div>)
      }else{
        return null;
      }							
    }
    selectTaxonomia=()=>{
      var familia=[]; var orden=[]; var genero=[];
      var arrFamilia=[]; var arrOrden=[]; var arrGenero=[];
      if(this.state.controllerGraficosDataset.length>0){
        if(this.state.especie.id.length>0){
          for(const i in this.state.especie.id){
            if(this.state.especie.familia[i]){
              if(!arrFamilia.includes(this.state.especie.familia[i])){
                arrFamilia.push(this.state.especie.familia[i])
                familia.push(<option value={this.state.especie.familia[i]}>{this.state.especie.familia[i]} </option>)
              }
            }
            if(this.state.especie.orden[i]){
              if(!arrOrden.includes(this.state.especie.orden[i])){
                arrOrden.push(this.state.especie.orden[i])
                orden.push(<option value={this.state.especie.orden[i]}>{this.state.especie.orden[i]} </option>)
              }
            }
            if(this.state.especie.genero[i]){
              if(!arrGenero.includes(this.state.especie.genero[i])){
                arrGenero.push(this.state.especie.genero[i])
                genero.push(<option value={this.state.especie.genero[i]}>{this.state.especie.genero[i]} </option>)
              }
            }
            
          }
          return(	<div className="row col-lg-4"> 
                <p className="col-xl-12"> Categoría Taxonómica</p>
                <div className="row col-lg-4 ml-2"> 
                <p className="col-xl-12"> Familia</p>
                <select className="form-control" onChange={(event)=> this.handleSelectFamilia(event)}>
                  <option value='all'> Todas </option>
                  {familia}
                </select>
                </div>
                <div className="row col-lg-4 ml-2"> 
                <p className="col-xl-12"> Género</p>
                <select className="form-control" onChange={(event)=> this.handleSelectGenero(event)}>
                  <option value='all'> Todas </option>
                  {genero}
                </select>
                </div>
                <div className="row col-lg-4 ml-2"> 
                <p className="col-xl-12"> Orden</p>
                <select className="form-control" onChange={(event)=> this.handleSelectOrden(event)}>
                  <option value='all'> Todas </option>
                  {orden}
                </select>
                </div>
              </div>)
        }else{
          return null
        }
      }else{
        return null
      }
  
    }
    renderLineChart=(idindicador,size)=>{
      var index
      if(this.state.controllerGraficosDataset.length >= size){
        for(const i in this.state.controllerGraficosDataset){
          if(this.state.controllerGraficosDataset[i]){
            if(this.state.controllerGraficosDataset[i].id === idindicador)
              index = i
          }
        }
        if(index){
          return(
            <>
            {/* <div id={'graphics'+index} className="row mt-5" > */}
            <div className="row ml-5 mr-5 mb-5" >
              <div className='header'>
                <h3 className='title'>{this.state.controllerGraficosDataset[index].title}</h3>
              </div>
              <Line data={this.state.controllerGraficosDataset[index].data} options={this.state.controllerGraficosDataset[index].options}/>
            </div>
            </>
          );
        }else{
          return(<></>)
        }
      }else{
        return(<></>)
      }
    }
    renderBarChart=(idindicador,size)=>{
      var index
      if(this.state.controllerGraficosDataset.length > size -1){
      // if(true){
        for(const i in this.state.controllerGraficosDataset){
          if(this.state.controllerGraficosDataset[i]){
            if(this.state.controllerGraficosDataset[i].id === idindicador)
              index = i
          }
        }
        if(index){
          return(
            <>
            {/* <div id={'graphics'+index} className="row mt-5" > */}
            <div className="row ml-5 mr-5 mb-5" >
              <div className='header'>
                <h3 className='title'>{this.state.controllerGraficosDataset[index].title}</h3>
              </div>
              <Bar data={this.state.controllerGraficosDataset[index].data} options={this.state.controllerGraficosDataset[index].options}/>
            </div>
            </>
          );
        }else{
          return(<></>)
        }
      }else{
        return(<></>)
      }
    }
    renderStackedBarChart=(idindicador,size)=>{
      var index
      if(this.state.controllerGraficosDataset.length > size -1){
        for(const i in this.state.controllerGraficosDataset){
          if(this.state.controllerGraficosDataset[i]){
            if(this.state.controllerGraficosDataset[i].id === idindicador)
              index = i
          }
        }
        if(index){
          return(
            <>
            {/* <div id={'graphics'+index} className="row mt-5" > */}
            <div className="row ml-5 mr-5 mb-5" >
              <div className='header'>
                <h3 className='title'>{this.state.controllerGraficosDataset[index].title}</h3>
              </div>
              <Bar data={this.state.controllerGraficosDataset[index].data} options={this.state.controllerGraficosDataset[index].options}/>
            </div>
            </>
          );
        }else{
          return(<></>)
        }
      }else{
        return(<></>)
      }
    }
    Especies=()=>{
      var html=[];
      for(const i in this.state.especies){
        html.push(
          <option> {this.state.especies[i]}</option>
        )
      }
      return(
        <select> 
          {html}
        </select>
      )
    }
    onChangeSeleccion=async(e)=>{
      var indicadores=[]
      for(const i in this.state.monitoreos){
        console.log(e.target.value, this.state.monitoreos[i].id)
        if(this.state.monitoreos[i].id === e.target.value){
          for(const j in this.state.monitoreos[i].indicadores.id){
            var indicador = { id: this.state.monitoreos[i].indicadores.id[j], nombre: this.state.monitoreos[i].indicadores.nombre[j]}
            indicadores.push(indicador)
          }
        }
      }
      await this.setState({ seleccion:{
        ...this.state.seleccion,
        listIndicadores:indicadores,
        [e.target.name]: e.target.value
      }})	
    }
    onChangeSeleccionindicador=async(e)=>{
      await this.setState({ seleccion:{
        ...this.state.seleccion,
        [e.target.name]: e.target.value
      }})	
    }
    renderSeleccionMonitoreoIndicador=()=>{
      if(this.state.monitoreos.length > 0){
        return(
        <div className="row">
          <div className="col-lg-4">
            <select className="form-control" name="monitoreo" value={this.state.seleccion.monitoreo} onChange={(e)=> this.onChangeSeleccion(e)}>
              {this.state.monitoreos.map((monitoreo) =>
                <option value={monitoreo.id}> {monitoreo.nombre}</option>
               )}
            </select>
          </div>
          <div className="col-lg-4">
          <select className="form-control" name="indicador" value={this.state.seleccion.indicador} onChange={this.onChangeSeleccionindicador}>
            <option value="all"> Todos </option>
              {this.state.seleccion.listIndicadores.map((indicador) => 
                <option value={indicador.id}> {indicador.nombre}</option>
              )}
            </select>
          </div>
          <div className="col-lg-4">
            <button type="button" onClick={(e)=> this.controladorGraficos()} className="btn-primary form-control"> Graficar</button>
          </div>
        </div> )
      }else{
        return(
        <h2>
          <Spinner animation="border" />
          Cargando
        </h2>
        )
      }
  
    }
    printDocument=()=> {
      const input = document.getElementById('graphics');
      console.log(this.state.controllerGraficosDataset, this.state.graphics.props.children[1].length)
      var graphs =0
      for(const i in this.state.controllerGraficosDataset)
        if(this.state.controllerGraficosDataset[i]) graphs=graphs+1
      console.log(graphs)
      var size = graphs * 2
      // if(this.state.graphics.props.children[1].length >= 3) size = 20
      // if(this.state.graphics.props.children[1].length >= 6) size = 40
      // if(this.state.graphics.props.children[1].length >= 9) size = 60
      html2canvas(input)
        .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        
        const pdf = new jsPDF({
          unit: "in",
          format: [12, size]});
        pdf.addImage(imgData, 'PNG', 0, 0);
        // pdf.output('dataurlnewwindow');
        pdf.save("graficos.pdf");
        })
      ;
    }
    
    portafolio =()=>{
      var html = []
      var imagenes = this.state.imagenes
      console.log(imagenes)
      if(this.state.monitoreos.length > 0){
        for(const i in imagenes.src){
          for(const j in this.state.monitoreos){
            if(this.state.monitoreos[j].id === this.state.seleccion.monitoreo){
              for(const k in this.state.monitoreos[j].registros.id){
                if(this.state.monitoreos[j].registros.id[k] === imagenes.idRegistro[i])	{
                  if(imagenes.src[i]){
                    html.push( 
                      <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                        <div className="portfolio-wrap">
                          <img src={imagenes.src[i]} className="img-fluid" alt="" />
                          <div className="portfolio-links">
                            <a href={imagenes.src[i]} data-gall="portfolioGallery" className="venobox" title="App 1"><i className="bx bx-plus" /></a>
                            </div>
                        </div>
                      </div>
                    )
                  }
                }
              }
            }
          }
      
        }
      }
      return (
        <section style={{'max-height': '50vh', 'overflow-y': 'auto'}} id="portfolio" className="portfolio">
          <div className="container">
            <div className="section-title" data-aos="fade-in" data-aos-delay={100}>
              <h2>Galería de Fotos</h2>
            </div>			
            <div className="row portfolio-container" data-aos="fade-up">
              {html}
            </div>
          </div>
        </section>
      )
    }
      
  render(){
        return (
            <div>
            <div>
              <NavbarL />
              <main id="main">
                  {/* ========== Cta Section ========== */}
                  <section id="cta" className="cta"> 
                  <div className="container" data-aos="zoom-in">
                      <div className="text-center">
                      <h1>Monitoreos</h1>
                      </div>
                  </div></section>{/* End Cta Section */}  
                  {/* ========== Services Section ========== */}
                  <section id="services" className="services">

                  <div className="container">
                      <div className="section-title" data-aos="fade-in" data-aos-delay={100}>
                      <h2>Descripción</h2>
                      </div>
                      <FilterTableComponent/>
                      <div >
									<h4>Eliga el monitoreo y el indicador que desea graficar</h4> 
									{this.renderSeleccionMonitoreoIndicador()}
									{/* <div className="row"> 
									<div className="col-lg-6"> 
											<button type="button" className="btn border"> Ver Reportes Especifico</button>
									</div>
									<div className="col-lg-6"> 
											<button type="button" className="btn border"> Descargar Reporte Especifico</button>
									</div>
									</div> */}
									{ this.state.controllerGraficosDataset.length >0 ? <h5> Filtrado </h5>:null}
									<div className="row">
										{this.selectLocalizacion()}
										{this.selectFecha()}
										{this.selectTaxonomia()}
									</div>

									{this.state.graphics}
									{ this.state.controllerGraficosDataset.length >0 ? this.portafolio():null}
								</div>
                    </div>
                  </section>
                  {/* End Services Section */}
                  {/* ========== Portfolio Section ========== */}
            				{this.state.imagenes.length >0 ? this.portafolio():null}
              </main>
          {/* //   End #main */}
                          {/* //   Footer */}
               <footer id="footer" className="sticky-footer">
                  <div className="container my-auto">
                      <div className="copyright text-center my-auto">
                          © Copyright <strong><span>Restaured 2024</span></strong>. All Rights Reserved
                      </div>
                  </div>
              </footer>
          </div>
      </div>)}
  };

export default ReportesMonitoreos