import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import '../../assets/vendor/bootstrap/css/bootstrap.css'
import '../../assets/css/sb-admin-2.css'
import {Link} from 'react-router-dom'
const axios = require('axios')

export default class Header extends React.Component{
	constructor(props){
		super(props);
		this.state={
		  Rol: this.props.Rol,
		  Nombre: this.props.Nombre,
		  Iduser: this.props.Iduser,
		  External: this.props.External,
		}
	  }
	render(){
		const QueryLogout =()=>{
			var baseurl = "https://api.restaured.net"
			var url = baseurl+'/auth/user/logout' 
			var config = {
				method: 'post',
				url: url,
				headers: { 
				'Content-Type': 'application/json'
				},
				withCredentials:true,
			}
			axios(config)
			.then(response =>{
				if(response.status === 200){
					window.location.href = "/"
				}
			})
		}
		return(
			<>
			{/* Topbar */}
			<nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
			{/* Sidebar Toggle (Topbar) */}
			<button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
				<i className="fa fa-bars" />
			</button>
			
			{/* Topbar Navbar */}
			<ul className="navbar-nav ml-auto">
				{/* Nav Item - Search Dropdown (Visible Only XS) */}
				<li className="nav-item dropdown no-arrow d-sm-none">
				<p className="nav-link   dropdown-toggle" href="#" id="searchDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
					<i className="fas fa-search fa-fw" />
				</p>
				{/* Dropdown - Messages */}
				<div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in" aria-labelledby="searchDropdown">
					<form className="form-inline mr-auto w-100 navbar-search">
					<div className="input-group">
						<input type="text" className="form-control bg-light border-0 small" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2" />
						<div className="input-group-append">
						<button className="btn btn-primary" type="button">
							<i className="fas fa-search fa-sm" />
						</button>
						</div>
					</div>
					</form>
				</div>
				</li>
				<li className="nav-item dropdown no-arrow">
				<Dropdown navbar={true} >
				<Dropdown.Toggle> 
					<p className="nav-link" id="userDropdown">
					<span className="mr-2 color">{this.state.Nombre}</span>
					<img alt='img_profile' className="img-profile rounded-circle" src="../img/undraw_profile.svg" />
					</p>
				</Dropdown.Toggle>
				<Dropdown.Menu> 
						{!this.props.External? (
						<Dropdown.Item>
							<Link to={{pathname:"/changepassword"}}>
								<p className="dropdown-item page-link" >
								Cambiar Contraseña
								</p>
							</Link>
						</Dropdown.Item>	
						):null}
						<Dropdown.Item> 
						<div className="dropdown-divider" />
						<p className="dropdown-item  " onClick={QueryLogout}>
						<i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />
						Logout
						</p>
						</Dropdown.Item>                          
				</Dropdown.Menu>
				</Dropdown>
				{/* Dropdown - User Information */}
				</li>
			</ul>
			</nav>
			</>
			)
		}		
	}
