import React, {Component} from 'react'
import NavbarC from '../../NavBar/NavbarUsersC'
import HeaderUsers from './HeaderUsers'
import '../../../assets/vendor/bootstrap/css/bootstrap.css'
import '../../../assets/css/sb-admin-2.css'
import {Link} from 'react-router-dom'
import { Redirect } from 'react-router'
import { BsDownload } from "react-icons/bs";
import { useTable, useFilters, useGlobalFilter,useRowSelect, useAsyncDebounce, usePagination } from 'react-table'
import Logout from './Logout'
import {CSVLink} from 'react-csv';
const axios = require('axios');

// Create an editable cell renderer

function GlobalFilter({
	preGlobalFilteredRows,
	globalFilter,
	setGlobalFilter,
	}) {
	const count = preGlobalFilteredRows.length
	const [value, setValue] = React.useState(globalFilter)
	const onChange = useAsyncDebounce(value => {
			setGlobalFilter(value || undefined)
	}, 200)
	
	return (
			<span>
			Search:{' '}
			<input
					value={value || ""}
					onChange={e => {
					setValue(e.target.value);
					onChange(e.target.value);
					}}
					placeholder={`${count} records...`}
					style={{
					fontSize: '1.1rem',
					border: '0',
					}}
			/>
			</span>
	)
	}
	
	function DefaultColumnFilter({
	column: { filterValue, preFilteredRows, setFilter },
	}) {
	const count = preFilteredRows.length
	
	return (
			<input
					className="form-control"
					value={filterValue || ''}
					onChange={e => {
							setFilter(e.target.value || undefined)
					}}
					placeholder={`Search ${count} records...`}
			/>
	)
	}
	
	
	function Table({ columns, data, setSelectedRows }) {
	
	const defaultColumn = React.useMemo(
			() => ({
					// Default Filter UI
					Filter: DefaultColumnFilter,
			}),
			[]
	)
	
	const {
			getTableProps,
			getTableBodyProps,
			headerGroups,
			prepareRow,
			page,
			canPreviousPage,
			canNextPage,
			pageOptions,
			pageCount,
			gotoPage,
			nextPage,
			previousPage,
			setPageSize,
			state,
			preGlobalFilteredRows,
			setGlobalFilter,
			selectedFlatRows,
			state: { pageIndex, pageSize, selectedRowIds },
	} = useTable(
			{
					columns,
					data,
					defaultColumn,
					initialState:{pageSize:5}
			},
			useFilters,
			useGlobalFilter,
			usePagination,
			useRowSelect,
	)
	React.useEffect(() => {
			setSelectedRows(selectedRowIds);
	}, [setSelectedRows, selectedRowIds]);
	return (
			<div>
					<GlobalFilter
							preGlobalFilteredRows={preGlobalFilteredRows}
							globalFilter={state.globalFilter}
							setGlobalFilter={setGlobalFilter}
					/>
					<table className="table" {...getTableProps()}>
							<thead>
									{headerGroups.map(headerGroup => (
											<tr {...headerGroup.getHeaderGroupProps()}>
													{headerGroup.headers.map(column => (
															<th {...column.getHeaderProps()}>
																	{column.render('Header')}
																	{/* Render the columns filter UI */}
																	<div>{column.canFilter ? column.render('Filter') : null}</div>
															</th>
													))}
											</tr>
									))}
							</thead>
							<tbody {...getTableBodyProps()}>
							{page.map((row, i) => {
									prepareRow(row)
									return (
									<tr {...row.getRowProps()}>
											{row.cells.map(cell => {
											return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
											})}
									</tr>
									)
							})}
							</tbody>
					</table>
					<br />
					<div className="pagination">
			<button className="page-link"onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
					{'<<'}
			</button>{' '}
			<button className="page-link"onClick={() => previousPage()} disabled={!canPreviousPage}>
					{'<'}
			</button>{' '}
			<button className="page-link"onClick={() => nextPage()} disabled={!canNextPage}>
					{'>'}
			</button>{' '}
			<button className="page-link"onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
					{'>>'}
			</button>{' '}
			<span className="page-link">
					Page{' '}
					<strong>
					{pageIndex + 1} of {pageOptions.length}
					</strong>{' '}
			</span>
			<span className="page-link">
					| Go to page:{' '}
					<input
					type="number"
					defaultValue={pageIndex + 1}
					onChange={e => {
							const page = e.target.value ? Number(e.target.value) - 1 : 0
							gotoPage(page)
					}}
					style={{ width: '100px' }}
					/>
			</span>{' '}
			<select
			className="page-link"
					value={pageSize}
					onChange={e => {
					setPageSize(Number(e.target.value))
					}}
			>
					{[5, 10, 15, 20, 25].map(pageSize => (
					<option key={pageSize} value={pageSize}>
							Show {pageSize}
					</option>
					))}
			</select>
			</div>
			</div>
	)
	}
function TablaReporte(dataset,columnas){
	const columns = React.useMemo(() => columnas)
	const [data, setData] = React.useState(() =>dataset)
	const [selectedRows, setSelectedRows] = React.useState({});
	return (
	<div className="table-responsive">
			<Table
			columns={columns}
			data={data}
			setSelectedRows={setSelectedRows}
			/>
			<br/>
			<br/>

	</div>
)
}


class GenerarReporte extends Component {
	constructor(props){
		super(props);
		this.state={
			Rol: this.props.Rol,
			Nombre: this.props.Nombre,
			Iduser: this.props.Iduser,
			piechartdata: [],
			dataset:[],
			columns:[],
			especie:{
				id:[],
				nombreCientifico:[],
				nombreComun:[],
				activo:[],
				idLocalizacion:[],
			},
			variableRegistro:{
				id:[],
				idEntrada:[],
				idVariable:[],
				valor:[],
			},
			entradaMonitoreo:{
				id:[],
				idLocalizacion:[],
				curado:[],
				img:[],
				idRegistro:[],
				idEspecies:[],
			},
			variable:{
				id:[],
				idMonitoreo:[],
				nombre:[]
			},
			variablexindicador:{
				id:[],
				idIndicador:[],
				idVariable:[]
			},
			monitoreo:{
				id:[],
				nombre:[],
				descripcion:[],
			},
			registroMonitoreo:{
				id:[],
				nombre:[],
				idUser:[],
				idMonitoreo:[],
				date:[],
			},
			monitoreos:[],
			location:[],
		}
		this.initial_state();
	}
	//PETICIONES
	
    //PETICIONES
	Getespecies=async()=>{
		var baseurl = "https://api.restaured.net"
		const url = baseurl+'/restaured/especie'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
		  var id =[];var nombreCientifico =[]; var nombreComun =[]; var idLocalizacion =[];
		  var activo =[]; var familia =[]; var genero =[]; var orden =[];
		  for(const i in data.body){
			id.push(data.body[i]._id)
			idLocalizacion.push(data.body[i].idLocalizacion)
			nombreCientifico.push(data.body[i].nombreCientifico)
			nombreComun.push(data.body[i].nombreComun)
			familia.push(data.body[i].familia)
			orden.push(data.body[i].orden)
			genero.push(data.body[i].genero)
			activo.push(data.body[i].activo)
		  };
		  localStorage.setItem('especies', JSON.stringify({
			especies:{
			  id:id,
			  idLocalizacion:idLocalizacion,
			  nombreCientifico:nombreCientifico,
			  nombreComun:nombreComun,
			  orden:orden,
			  familia:familia,
			  genero:genero,
			  activo:activo, }}))
		  this.setState({
			especie:{
			  id:id,
			  idLocalizacion:idLocalizacion,
			  nombreCientifico:nombreCientifico,
			  nombreComun:nombreComun,
			  orden:orden,
			  familia:familia,
			  genero:genero,
			  activo:activo,
			}});
		})
		.catch( error => {
		  console.log(error)
		  var local = localStorage.getItem('especies');
		  local = JSON.parse(local)
		  this.setState({
			especie:{
			  id:local.especies.id,
			  idLocalizacion:local.especies.idLocalizacion,
			  nombreCientifico:local.especies.nombreCientifico,
			  nombreComun:local.especies.nombreComun,
			  orden:local.especies.orden,
			  familia:local.especies.familia,
			  genero:local.especies.genero,
			  activo:local.especies.activo,
			}});
		})
		// console.log(this.state.especies);
	} 
	getData=async()=>{
		var baseurl = "https://api.restaured.net"
		var url = baseurl+'/restaured/variable'
		try {
			await axios.get(url,{withCredentials: true})
			.then(response => response.data)
			.then(data => {
			// console.log(data)
			var id =[];
			var idMonitoreo =[];
			var nombre =[];
			var pubObj =[];
			var tipo =[];
			var unidades =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				idMonitoreo.push(data.body[i].idMonitoreo);
				nombre.push(data.body[i].nombre);
				pubObj.push(data.body[i].pubObj);
				unidades.push(data.body[i].unidades);
				tipo.push(data.body[i].tipo);
			};
			this.setState({
				variable:{
					id:id,
					idMonitoreo:idMonitoreo,
					nombre:nombre,
					pubObj:pubObj,
					unidades:unidades,
					tipo:tipo,
			}})
			})
			url = baseurl+'/restaured/variablexindicador'
			await axios.get(url,{withCredentials:true})
			.then(response => response.data)
			.then(data => {
			// console.log(data)
			var id =[];
			var idIndicador =[];
			var idVariable =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				idIndicador.push(data.body[i].idIndicador);
				idVariable.push(data.body[i].idVariable);
			};
			this.setState({
				variablexindicador:{
					id:id,
					idIndicador:idIndicador,
					idVariable:idVariable,
			}})
			})
			url = baseurl+'/restaured/indicador'
			await axios.get(url, {withCredentials:true})
			.then(response => response.data)
			.then(data => {
			// console.log(data)
			var id =[];
			var idMonitoreo =[];
			var formula =[];
			var nombre =[];
			var pubObj =[];
			var tipo =[];
			var tipoGrafico =[];
			var unidades =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				idMonitoreo.push(data.body[i].idMonitoreo);
				formula.push(data.body[i].formula);
				nombre.push(data.body[i].nombre);
				pubObj.push(data.body[i].pubObj);
				tipo.push(data.body[i].tipo);
				tipoGrafico.push(data.body[i].tipoGrafico);
				unidades.push(data.body[i].unidades);
			};
			this.setState({
				indicador:{
					id:id,
					idMonitoreo:idMonitoreo,
					formula:formula,
					nombre:nombre,
					pubObj:pubObj,
					tipo:tipo,
					tipoGrafico:tipoGrafico,
					unidades:unidades,
			}})
			})
			url = baseurl+'/restaured/localizacion'
			await axios.get(url, {withCredentials:true})
			.then(response => response.data)
			.then(data => {
			console.log(data)
			var id =[];
			var idSitio =[];
			var idParcela =[];
			var idTransecto =[];
			var idPO =[];
			var nombre =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				idSitio.push(data.body[i].idSitio);
				idParcela.push(data.body[i].idParcela);
				idTransecto.push(data.body[i].idTransecto);
				idPO.push(data.body[i].idPO);
				nombre.push(data.body[i].nombre);
			};
			this.setState({
				localizacion:{
					id:id,
					idSitio:idSitio,
					idParcela:idParcela,
					idTransecto:idTransecto,
					idPO:idPO,
					nombre:nombre,
			}})
			})
			url = baseurl+'/restaured/sitio'
			await axios.get(url, {withCredentials:true})
			.then(response => response.data)
			.then(data => {
			var id =[];
			var nombre =[];
			var geos =[];
			var descripcion =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				geos.push(data.body[i].geos);
				descripcion.push(data.body[i].descripcion);
				nombre.push(data.body[i].nombre);
			};
			this.setState({
				sitio:{
					id:id,
					nombre:nombre,
					geos:geos,
					descripcion:descripcion,
			}})
			})
			url = baseurl+'/restaured/puntoobservacion'
			await axios.get(url, {withCredentials:true})
			.then(response => response.data)
			.then(data => {
			var id =[];
			var nombre =[];
			var geos =[];
			var descripcion =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				geos.push(data.body[i].geos);
				descripcion.push(data.body[i].descripcion);
				nombre.push(data.body[i].nombre);
			};
			this.setState({
				PO:{
					id:id,
					nombre:nombre,
					geos:geos,
					descripcion:descripcion,
			}})
			})
			url = baseurl+'/restaured/transecto'
			await axios.get(url, {withCredentials:true})
			.then(response => response.data)
			.then(data => {
			var id =[];
			var nombre =[];
			var geos =[];
			var descripcion =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				geos.push(data.body[i].geos);
				descripcion.push(data.body[i].descripcion);
				nombre.push(data.body[i].nombre);
			};
			this.setState({
				transecto:{
					id:id,
					nombre:nombre,
					geos:geos,
					descripcion:descripcion,
			}})
			})
			url = baseurl+'/restaured/parcela'
			await axios.get(url, {withCredentials:true})
			.then(response => response.data)
			.then(data => {
			var id =[];
			var nombre =[];
			var geos =[];
			var descripcion =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				geos.push(data.body[i].geos);
				descripcion.push(data.body[i].descripcion);
				nombre.push(data.body[i].nombre);
			};
			this.setState({
				parcela:{
					id:id,
					nombre:nombre,
					geos:geos,
					descripcion:descripcion,
			}})
			})
			url = baseurl+'/restaured/monitoreo'
			await axios.get(url, {withCredentials:true})
			.then(response => response.data)
			.then(data => {
			// console.log(data)
			var id =[];
			var nombre =[];
			var descripcion =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				nombre.push(data.body[i].nombre);
				descripcion.push(data.body[i].descripcion);
			};
			this.setState({
				monitoreo:{
				id:id,
				nombre:nombre,
				descripcion:descripcion,
			}})
			})
			url = baseurl+'/restaured/registromonitoreo'
			await axios.get(url, {withCredentials:true})
			.then(response => response.data)
			.then(data => {
			var id =[];
			var nombre =[];
			var idMonitoreo =[];
			var idUser =[];
			var date=[]
			for(const i in data.body){
				id.push(data.body[i]._id);
				nombre.push(data.body[i].nombre);
				idUser.push(data.body[i].idUser);
				idMonitoreo.push(data.body[i].idMonitoreo);
				date.push(data.body[i].date);
			};
			this.setState({
				registroMonitoreo:{
				id:id,
				nombre:nombre,
				idUser:idUser,
				idMonitoreo:idMonitoreo,
				date:date,
			}})
			})
			url = baseurl+'/restaured/entradamonitoreo'
			await axios.get(url, {withCredentials:true})
			.then(response => response.data)
			.then(data => {
			var id =[];
			var idLocalizacion =[];
			var curado =[];
			var idRegistro =[];
			var idEspecies =[];
			var img =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				idLocalizacion.push(data.body[i].idLocalizacion);
				curado.push(data.body[i].curado);
				idRegistro.push(data.body[i].idRegistro);
				img.push(data.body[i].img);
				idEspecies.push(data.body[i].idEspecie);
			};
			this.setState({
				entradaMonitoreo:{
					id:id,
					idLocalizacion:idLocalizacion,
					curado:curado,
					img:img,
					idRegistro:idRegistro,
					idEspecies:idEspecies,
			}})
			})
			url = baseurl+'/restaured/variableregistro'
			await axios.get(url, {withCredentials:true})
			.then(response => response.data)
			.then(data => {
			var id =[];
			var idEntrada =[];
			var idVariable =[];
			var valor =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				idEntrada.push(data.body[i].idEntrada);
				idVariable.push(data.body[i].idVariable);
				valor.push(data.body[i].valor);
			};
			this.setState({
				variableRegistro:{
					id:id,
					idEntrada:idEntrada,
					idVariable:idVariable,
					valor:valor,
			}})
			})
			url = baseurl+'/auth/user'
			await axios.get(url, {withCredentials:true})
			.then(response => response.data)
			.then(data => {
				// console.log(data)
				var id =[];
				var nombre =[];
				for(const i in data.body){
					id.push(data.body[i]._id);
					nombre.push(data.body[i].nombre);
				};
				this.setState({
					users:{
						id:id,
						nombre:nombre,
				}})
			})
			localStorage.setItem('users', JSON.stringify({users:{id:this.state.users.id, nombre:this.state.users.nombre}}))
			await this.loadLocalizaciones()
			await this.loadMonitoreos()
		}
		catch (error){
		var localizaciones = localStorage.getItem('localizaciones')
		var monitoreos = localStorage.getItem('monitoreos')
		var users = localStorage.getItem('users')
		localizaciones = JSON.parse(localizaciones)
		users = JSON.parse(users)
		monitoreos = JSON.parse(monitoreos)
		this.setState({monitoreos:monitoreos, datasetLocalizaciones:localizaciones, users:{ id: users.users.id, nombre: users.users.nombre}})

		}
	}
	loadMonitoreos=async()=>{
	var monitoreo={
		id:0,
		nombre:"",
		descripcion:"",
		variables:[],
		registros:[],
		indicadores:[],
	};
	var indicadores = {
		id:[],
		idMonitoreo:[],
		formula:[],
		nombre:[],
		pubObj:[],
		tipo:[],
		tipoGrafico:[],
		unidades:[],
	};
	var registros={
		id:[],
		iduser:[],
		nombre:[],
		entradas:[],
		date:[],
	};
	var entradas={
		id:[],  
		idLocalizacion:[],  
		idVariablesRegistro:[],  
		variables:[],
		especies:[],
		valores:[],
		curado:[],
		img:[],
	};
	var monitoreos=[]
	var valores=[];
	var variables=[];
	var idvariableRegistro=[]
	var idvariablesRegistro=[]
	var variableEntrada=[];
	var valorEntrada=[];
	for(const i in this.state.monitoreo.id){
		monitoreo.id=this.state.monitoreo.id[i]
		monitoreo.nombre=this.state.monitoreo.nombre[i]
		monitoreo.descripcion=this.state.monitoreo.descripcion[i]
		for(const j in this.state.variable.nombre){
		if(this.state.variable.idMonitoreo[j]===this.state.monitoreo.id[i]){
			var variable={
			id:this.state.variable.id[j],
			nombre:this.state.variable.nombre[j],
			pubObj:this.state.variable.pubObj[j],
			tipo:this.state.variable.tipo[j],
			unidades:this.state.variable.unidades[j],
			}
			monitoreo.variables.push(variable);
		}
		}
		for(const j in this.state.indicador.id){
		if(this.state.indicador.idMonitoreo[j]===this.state.monitoreo.id[i]){
			indicadores.id.push(this.state.indicador.id[j])
			indicadores.idMonitoreo.push(this.state.indicador.idMonitoreo[j])
			indicadores.formula.push(this.state.indicador.formula[j])
			indicadores.nombre.push(this.state.indicador.nombre[j])
			indicadores.pubObj.push(this.state.indicador.pubObj[j])
			indicadores.tipo.push(this.state.indicador.tipo[j])
			indicadores.tipoGrafico.push(this.state.indicador.tipoGrafico[j])
			indicadores.unidades.push(this.state.indicador.unidades[j])
		}
		}
		// console.log(this.state.entradaMonitoreo)
		for(const j in this.state.registroMonitoreo.id){
		if(this.state.monitoreo.id[i] === this.state.registroMonitoreo.idMonitoreo[j]){
			registros.id.push(this.state.registroMonitoreo.id[j])
			registros.iduser.push(this.state.registroMonitoreo.idUser[j])
			registros.nombre.push(this.state.registroMonitoreo.nombre[j])
			registros.date.push(this.state.registroMonitoreo.date[j])
			for(const k in this.state.entradaMonitoreo.id){
			if(this.state.registroMonitoreo.id[j] === this.state.entradaMonitoreo.idRegistro[k]){
				entradas.id.push(this.state.entradaMonitoreo.id[k]);
				entradas.idLocalizacion.push(this.state.entradaMonitoreo.idLocalizacion[k]);
				entradas.curado.push(this.state.entradaMonitoreo.curado[k]);
				entradas.img.push(this.state.entradaMonitoreo.img[k]);
				entradas.especies.push(this.state.entradaMonitoreo.idEspecies[k]);
				for(const l in this.state.variableRegistro.id){
					if(this.state.entradaMonitoreo.id[k] === this.state.variableRegistro.idEntrada[l]){
					idvariableRegistro.push(this.state.variableRegistro.id[l]);
					valorEntrada.push(this.state.variableRegistro.valor[l]);
					variableEntrada.push(this.state.variableRegistro.idVariable[l]);
					}
				}
				idvariablesRegistro.push(idvariableRegistro)  
				variables.push(variableEntrada)  
				valores.push(valorEntrada)  
		idvariableRegistro=[]
				valorEntrada=[]
				variableEntrada=[]
				}
			}
			entradas.variables=variables;
			entradas.valores=valores;
			entradas.idVariablesRegistro=idvariablesRegistro;
			variables=[];
			idvariablesRegistro=[];
			valores=[];
			registros.entradas.push(entradas);
			entradas={id:[], idLocalizacion:[], idVariablesRegistro:[], variables:[], valores:[], curado:[],especies:[], img:[]}
		}
		}
		monitoreo.indicadores=indicadores;
		monitoreo.registros=registros;
		registros={ id:[], iduser:[], nombre:[], entradas:[], date:[] };
		indicadores = { id:[], idMonitoreo:[], formula:[], nombre:[], pubObj:[], tipo:[], tipoGrafico:[], unidades:[],};
		monitoreos.push(monitoreo);
		monitoreo={ id:0, nombre:"", descripcion:"", variables:[],indicadores:[],};
	}
	console.log(monitoreos)
	localStorage.setItem('monitoreos', JSON.stringify(monitoreos))
	this.setState({
		monitoreos:monitoreos
	})
	}
	loadLocalizaciones =async()=>{
	var localizacion={
		id:[],
		sitio:[],
		parcela:[],
		transecto:[],
		PO:[],    
	}
	for(const i in this.state.localizacion.id){
		for(const j in this.state.sitio.id){
		if(this.state.localizacion.idSitio[i] === this.state.sitio.id[j]){
			var sitio= {
			id:this.state.sitio.id[j],
			nombre:this.state.sitio.nombre[j],
			geos:this.state.sitio.geos[j],
			descripcion:this.state.sitio.descripcion[j],
			}
			localizacion.id.push(this.state.localizacion.id[i])
			localizacion.sitio.push(sitio)
			var verify = localizacion.PO.length
			for(const k in this.state.PO.id){
			if(this.state.PO.id[k]=== this.state.localizacion.idPO[i]){
				var ubicacionpo = {
				id:this.state.PO.id[k],
				geos:this.state.PO.geos[k],
				nombre:this.state.PO.nombre[k],
				descripcion:this.state.PO.descripcion[k],
				}
				localizacion.PO.push(ubicacionpo)
			}
			}
			if(verify === localizacion.PO.length)
			localizacion.PO.push(null)
			verify=localizacion.transecto.length
			for(const k in this.state.transecto.id){
			if(this.state.transecto.id[k]=== this.state.localizacion.idTransecto[i]){
				var ubicaciontrans = {
				id:this.state.transecto.id[k],
				geos:this.state.transecto.geos[k],
				nombre:this.state.transecto.nombre[k],
				descripcion:this.state.transecto.descripcion[k],
				}
				localizacion.transecto.push(ubicaciontrans)
			}
			}
			if(verify === localizacion.transecto.length)
			localizacion.transecto.push(null)
			verify=localizacion.parcela.length
			for(const k in this.state.parcela.id){
			if(this.state.parcela.id[k]=== this.state.localizacion.idParcela[i]){
				var ubicacionparce = {
				id:this.state.parcela.id[k],
				geos:this.state.parcela.geos[k],
				nombre:this.state.parcela.nombre[k],
				descripcion:this.state.parcela.descripcion[k],
				}
				localizacion.parcela.push(ubicacionparce)
			}
			}
			if(verify === localizacion.parcela.length)
			localizacion.parcela.push(null)
		}
		}
	}
	localStorage.setItem('localizaciones', JSON.stringify(localizacion))
	this.setState({
		datasetLocalizaciones:localizacion
	})
	}
	initial_state=async()=>{
	await this.Getespecies();
	await this.getData()
	}
	TablaEntradas=()=>{
		var dataset=[]
		var location = this.props.location;
		var selectedMonitoreo=(location.state)
		if(this.state.monitoreos.length > 0){
			var columns = [
				{
					Header: 'Entradas de Monitoreo',
					columns: [
					{
					Header: 'Especies',
					accessor: 'especies'
					},
					{
					Header: 'Autor',
					accessor: 'autor'
					},  
					{
					Header: 'Nombre Registro',
					accessor: 'nombre'
					},  
					{
					Header: 'Fecha Registro',
					accessor: 'fecha'
					},  
					],
				},
			]
			for(const j in this.state.monitoreos[selectedMonitoreo].variables){
				var column={
				Header:this.state.monitoreos[selectedMonitoreo].variables[j].nombre,
				accessor: this.state.monitoreos[selectedMonitoreo].variables[j].nombre,
				}
				columns[0].columns.push(column)
			}
			var especiesTabla=[];var valorTabla=[]; var valoresTabla=[]; var variableTabla=[];
			var usuariosTables =[];var variablesTabla=[]; var nombreVariableTabla=[];
			var nombreRegistro=[]; var fechas=[]
			for(const j in this.state.monitoreos[selectedMonitoreo].registros.entradas){
				for(const l in this.state.monitoreos[selectedMonitoreo].registros.entradas[j].especies){
					for(const m in this.state.especie.id){
						if(this.state.especie.id[m] === this.state.monitoreos[selectedMonitoreo].registros.entradas[j].especies[l])
							especiesTabla.push(this.state.especie.nombreCientifico[m])
							var realdate =  new Date (this.state.monitoreos[selectedMonitoreo].registros.date[j]*1000)
							fechas.push(realdate.getDate()+"/"+realdate.getMonth()+"/"+realdate.getFullYear())
							nombreRegistro.push(this.state.monitoreos[selectedMonitoreo].registros.nombre[j])
							for(const n in this.state.users.id){
								if(this.state.users.id[n] === this.state.monitoreos[selectedMonitoreo].registros.iduser[j])
								usuariosTables.push(this.state.users.nombre[n])
							}
					}
				for(const m in this.state.monitoreos[selectedMonitoreo].registros.entradas[j].valores[l]){
					valorTabla.push(this.state.monitoreos[selectedMonitoreo].registros.entradas[j].valores[l][m])
					variableTabla.push(this.state.monitoreos[selectedMonitoreo].registros.entradas[j].variables[l[m]])
				}
				variablesTabla.push(variableTabla)
				valoresTabla.push(valorTabla)
				variableTabla=[]
				valorTabla=[]
				}
			}
			var nombresVariableTabla =[]
			for(const l in variablesTabla){
				for(const m in variablesTabla[l][0]){
					for(const k in this.state.monitoreos[selectedMonitoreo].variables){
						if(variablesTabla[l][0][m] === this.state.monitoreos[selectedMonitoreo].variables[k].id){
							nombreVariableTabla.push(this.state.monitoreos[selectedMonitoreo].variables[k].nombre)
						}
					}
				}
				nombresVariableTabla.push(nombreVariableTabla)
				nombreVariableTabla=[]
			}
			for(const k in especiesTabla){
				var datos;
				if(nombresVariableTabla[k].length > 1){
					datos ={
						autor:usuariosTables[k],
						especies:especiesTabla[k],
						nombre: nombreRegistro[k],
						fecha:fechas[k]
					}
					for(const m in nombresVariableTabla[k]){
						datos[nombresVariableTabla[k][m]] = valoresTabla[k][m]
					}     
				}else{
					datos ={
						autor:usuariosTables[k],
						especies:especiesTabla[k],
						nombre: nombreRegistro[k],
						fecha:fechas[k],
						[nombresVariableTabla[k]]:valoresTabla[k]
					}
			}
			dataset.push(datos)
			}
			datos={}
			nombreVariableTabla=[]
		var html = TablaReporte(dataset,columns)
		if(this.state.dataset.length === 0){
			console.log(dataset,columns)	
			this.setState({dataset:dataset, columns:columns})
		}
		console.log(html)
		return(<>{html} </>)
	}else{ return(<> </>) }
	}
		
	render(){
		return(
		<div>
			<div>
				<title>RestauRed Cientifico - Dashboard</title>
						<div id="wrapper">
								<NavbarC/>
								<div id="content-wrapper" className="d-flex flex-column">
										<div id="content">
										<HeaderUsers Iduser={this.props.Iduser} Rol={this.props.Rol} Nombre={this.props.Nombre} External={this.props.External}/>
										{this.props.Nombre ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
										{this.props.Iduser ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
										{this.props.Rol ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
												<div className="container-fluid">
												<h2><span><p> Entradas del Reporte Monitoreo </p></span></h2>
												
												<h4><span><p> 
												<div className="row">
														<div className="col-lg-3">
												<CSVLink data={this.state.dataset} filename={"monitoreo.csv"} >
													Descargar <BsDownload/>
												</CSVLink>
														</div>
												</div>
												</p></span></h4>
												{/* DROPDOWN DE INDIVIDUOS PARA DARWIN CORE */}
												<this.TablaEntradas/>
												<div className="col-xl-12">
												<Link to={{pathname:"/restaured.scie/monitoreosusers",state:this.props.location.state}}>
													<button className="btn border"> Volver a Administracion de monitoreos</button>
												</Link>
												</div>
														<Logout/>
												</div>
										</div>
								</div>
						</div>
				</div>
		</div>
		)
	}
}

export default GenerarReporte