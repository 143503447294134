import React , {Component } from 'react'
import '../../assets/css/sb-admin-2.css'
import '../../assets/vendor/bootstrap/css/bootstrap.css'
import { Link } from "react-router-dom";


class NavbarU extends Component{
  constructor(props){
    super(props);
    this.state={
      Rol: this.props.Rol,
      Token: this.props.Token,
      Iduser: this.props.Iduser,
      open: false,
    }
  }
  setOpen=(e)=>{
    e = !e;
    this.setState({
          open: e
    })
  } 
  render(){
    return(
        <div id="wrapper" >
              {/* Sidebar */}
              <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
                {/* Sidebar - Brand */}
                <p className="sidebar-brand d-flex align-items-center justify-content-center" href="/">
                  <div className="sidebar-brand-icon rotate-n-15">
                    <i className="fas fa-laugh-wink" />
                  </div>
                  <div className="sidebar-brand-text mx-3">RestauRed  <sup>Cientifico</sup></div>
                </p>
                {/* Divider */}
                <hr className="sidebar-divider my-0" />
                {/* Nav Item - Dashboard */}
                <li className="nav-item active">
                  <Link to="/homeusers"> 
                        <p className="nav-link" >
                        <i className="fas fa-fw fa-tachometer-alt" />
                         <span>Dashboard</span>
                          </p>
                  </Link>
                </li>
                {/* Divider */}
                <hr className="sidebar-divider" />
                {/* Heading */}
                <div className="sidebar-heading">
                  Sistema
                </div>
                {/* Nav Item - Pages Collapse Menu */}
                {/* <li className="nav-item">
                    <p
                      onClick={() => this.setOpen(this.state.open)}
                      aria-controls="users-control"
                      aria-expanded={this.state.open}
                      type="button"
                      className ="nav-link"
                    >
                      Usuarios
                    </p>
                    <Collapse in={this.state.open}>
                      <div id="users-control">
                          <div className="bg-white py-2 collapse-inner rounded">
                            <p className="collapse-item" href="/nusers">Crear Nuevo Usuario</p>
                            <p className="collapse-item" href="/vusers">Usuarios Registrados</p>
                        </div>
                      </div>
                    </Collapse>
                </li> */}
                {/* Nav Item - Charts */}
                <li className="nav-item">
                  <Link to="/locationusers">
                  <p className="nav-link" >
                    <i className="fas fa-fw fa-chart-area" />
                    <span>Localizaciones</span></p>
                  </Link>
                </li>
                {/* Nav Item - Tables */}
                <li className="nav-item">
                  <Link to="/userscharts">
                    <p className="nav-link" >
                    <i className="fas fa-fw fa-table" />
                    <span>Darwin Core</span></p>
                  </Link>
                </li>
                {/* Nav Item - Charts */}
                <li className="nav-item">
                  <Link to="/usersmonitoreos">
                    <p className="nav-link" >
                    <i className="fas fa-fw fa-chart-area" />
                    <span>Monitoreos</span></p>
                  </Link>
                </li>
                {/* Divider */}
                <hr className="sidebar-divider d-none d-md-block" />
                {/* Sidebar Toggler (Sidebar) */}
                <div className="text-center d-none d-md-inline">
                  <button className="rounded-circle border-0" id="sidebarToggle" />
                </div>
              </ul>
              {/* End of Sidebar */}
        </div>
     )};
};   
   
export default NavbarU