import React,{ Component} from 'react'
import NavbarC from '../../NavBar/NavbarUsersC'
import HeaderUsers from './HeaderUsers'
import '../../../assets/vendor/bootstrap/css/bootstrap.css'
import '../../../assets/css/sb-admin-2.css'
import { Redirect } from 'react-router'
import {Link} from 'react-router-dom'
import { BsFillAwardFill } from "react-icons/bs"
import { useTable, usePagination,useRowSelect } from 'react-table'
import Dexie from 'dexie'
import Logout from './Logout'
const axios = require('axios');

const IndeterminateCheckboxBody = (row) => {
	var entrada=row.selectedRowIds.id
    return (
        <>
        <Link to={{pathname:"/restaured.scie/curacionentradasusers" ,state:entrada}} >
            <p> <BsFillAwardFill/> </p>
        </Link>
        </>
    )
}

function Table({ columns, data, updateMyData, skipPageReset}) {
		const {
			getTableProps,
			getTableBodyProps,
			headerGroups,
			prepareRow,
			page,
			canPreviousPage,
			canNextPage,
			pageOptions,
			pageCount,
			gotoPage,
			nextPage,
			previousPage,
			setPageSize,
			state: { pageIndex, pageSize},
		} = useTable(
			{
				columns,
				data,
				initialState:{pageSize:5},
				// use the skipPageReset option to disable page resetting temporarily
				autoResetPage: !skipPageReset,
				// updateMyData isn't part of the API, but
				// anything we put into these options will
				// automatically be available on the instance.
				// That way we can call this function from our
				// cell renderer!
				updateMyData,
			},
			usePagination,
			useRowSelect,
			hooks => {
				hooks.visibleColumns.push(columns => [
					// Let's make a column for selection
					{
						id: 'selection',
						// The header can use the table's getToggleAllRowsSelectedProps method
						// to render a checkbox
						Header: ({ getToggleAllRowsSelectedProps }) => (<></>
						),
						// The cell can use the individual row's getToggleRowSelectedProps method
						// to the render a checkbox
						Cell: ({ row }) => (
							<div>
								<IndeterminateCheckboxBody selectedRowIds={row} />
							</div>
						),
					},
					...columns,
				])
			}
		)  
		// Render the UI for your table
		return (
			<>
			<table className="table table-h-scroll"{...getTableProps()}>
				<thead>
					{headerGroups.map(headerGroup => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map(column => (
								<th {...column.getHeaderProps()}>{column.render('Header')}</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{page.map((row, i) => {
						prepareRow(row)
						return (
							<tr {...row.getRowProps()}>
								{row.cells.map(cell => {
									return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
								})}
							</tr>
						)
					})}
				</tbody>
			</table>
			<div className="pagination">
				<button className="page-link"onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
					{'<<'}
				</button>{' '}
				<button className="page-link"onClick={() => previousPage()} disabled={!canPreviousPage}>
					{'<'}
				</button>{' '}
				<button className="page-link"onClick={() => nextPage()} disabled={!canNextPage}>
					{'>'}
				</button>{' '}
				<button className="page-link"onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
					{'>>'}
				</button>{' '}
				<span className="page-link">
					Page{' '}
					<strong>
						{pageIndex + 1} of {pageOptions.length}
					</strong>{' '}
				</span>
				<span className="page-link">
					| Go to page:{' '}
					<input
						type="number"
						defaultValue={pageIndex + 1}
						onChange={e => {
							const page = e.target.value ? Number(e.target.value) - 1 : 0
							gotoPage(page)
						}}
						style={{ width: '100px' }}
					/>
				</span>{' '}
				<select
					className="page-link"
					value={pageSize}
					onChange={e => {
						setPageSize(Number(e.target.value))
					}}
				>
					{[5, 10, 15, 20, 25].map(pageSize => (
						<option key={pageSize} value={pageSize}>
							Show {pageSize}
						</option>
					))}
				</select>
			</div>
		</>
		)
}
function TableRegistros(dataset){
	const columns = React.useMemo(
		() => [
			{
				Header: 'Monitoreo',
				columns: [
					{
						Header: 'Fecha',
						accessor: 'date',
					},
					{
						Header: 'Nombre',
						accessor: 'nombre'
					},
					{
						Header: '#Entradas Sin Curar',
						accessor: 'numEntradas'
					},
				],
			},
		],
		[]
	)
	const [data] = React.useState(() =>dataset)
	const [skipPageReset, setSkipPageReset] = React.useState(false)
	React.useEffect(() => {
		setSkipPageReset(false)
	}, [data])

	return (
		<div className="table-responsive">
			<Table
				columns={columns}
				data={data}
				skipPageReset={skipPageReset}
			/>
		</div>
	)
}

export default class Curacion extends Component{
	constructor(props){
		super(props);
		this.state={
				Rol: this.props.Rol,
				Nombre: this.props.Nombre,
				Iduser: this.props.Iduser,
				open:false,
				reload:false,
				viewTablaEntradas: [],
				suggestions: [],
				gbifespecies: [],
				datasetLocalizaciones: [],
				especie:{
					id:[],
					nombreCientifico:[],
					nombreComun:[],
					activo:[],
					idLocalizacion:[],
				},
				selectedEspecie:"",
				selectedVariable:"",
				form:{
					fecha:"",
					nombre:"",
					valores:[],
					variable:"",
					especie:"",
					idMonitoreo:"",
					idUser:"",
					idRegistro:"",
					idLocalizacion:"",
					idEspecie:"",
					idEntrada:"",
					idVariables:[],
					variables:[],

				},
				variable:{
					id:[],
					idMonitoreo:[],
					nombre:[],
					pubObj:[]
				},
				variablexindicador:{
					id:[],
					idIndicador:[],
					idVariable:[]
				},
				indicador:{
					id:[],
					idMonitoreo:[],
					formula:[],
					nombre:[],
					pubObj:[],
					tipo:[],
					tipoGrafico:[],
					unidades:[],
				},
				monitoreo:{
					id:[],
					nombre:[],
					descripcion:[],
				},
				registroMonitoreo:{
					id:[],
					nombre:[],
					idUser:[],
					idMonitoreo:[],
				},
				entradaMonitoreo:{
					id:[],
					idLocalizacion:[],
					curado:[],
					idRegistro:[],
					idEspecies:[],
				},
				variableRegistro:{
					id:[],
					idEntrada:[],
					idVariable:[],
					valor:[],
				},
				localizacion:{
					id:[],
					idSitio:[],
					isParcela:[],
					idTransecto:[],
					idPO:[],
				},
				sitio:{
					id:[],
					nombre:[],
					geos:[],
					descripcion:[],
				},
				parcela:{
					id:[],
					nombre:[],
					geos:[],
					descripcion:[],
				},
				transecto:{
					id:[],
					nombre:[],
					geos:[],
					descripcion:[],
				},
				PO:{
					id:[],
					nombre:[],
					geos:[],
					descripcion:[],
				},
				ingresarEntradaMonitoreo:{
					body:[],
					status:"",
				},
				monitoreos:[],

		}
		this.Getespecies();
		this.initial_state();
	}
	//PETICIONES
	Getespecies=async()=>{
		var baseurl = "https://api.restaured.net"
		const url = baseurl+'/restaured/especie'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			var id =[];
			var nombreCientifico =[];
			var nombreComun =[];
			var idLocalizacion =[];
			var activo =[];
			for(const i in data.body){
				id.push(data.body[i]._id)
				idLocalizacion.push(data.body[i].idLocalizacion)
				nombreCientifico.push(data.body[i].nombreCientifico)
				nombreComun.push(data.body[i].nombreComun)
				activo.push(data.body[i].activo)
			};
			this.setState({
				especie:{
					id:id,
					idLocalizacion:idLocalizacion,
					nombreCientifico:nombreCientifico,
					nombreComun:nombreComun,
					activo:activo,
				}});
		})
		// console.log(this.state.especies);
	} 
	GetDataTable=async()=>{
		var baseurl = "https://api.restaured.net"
		var url = baseurl+'/restaured/variable'
		await axios.get(url,{withCredentials: true})
		.then(response => response.data)
		.then(async data => {
			// console.log(data)
			var id =[]; var idMonitoreo =[]; var nombre =[]; var pubObj =[];
			// const addItemToDb = async (id,idMonitoreo,nombre,pubObj) => {
			// 	const db = new Dexie('restaured')
			// 	await db.version(1).stores({ variable: "id, idMonitoreo, nombre, pubObj" })
			// 	console.log(id,idMonitoreo, nombre, pubObj)
			// 	await db.variable.add({
			// 		id:id,
			// 		idMonitoreo:idMonitoreo,
			// 		nombre:nombre,
			// 		pubObj:pubObj,
			// 	})
			// }
			for(const i in data.body){
				id.push(data.body[i]._id);
				idMonitoreo.push(data.body[i].idMonitoreo);
				nombre.push(data.body[i].nombre);
				pubObj.push(data.body[i].pubObj);
				// await addItemToDb(data.body[i]._id, data.body[i].idMonitoreo, data.body[i].nombre, data.body[i].pubObj)
			};
			this.setState({
				variable:{
							id:id,
							idMonitoreo:idMonitoreo,
							nombre:nombre,
							pubObj:pubObj,
			}})
		})
		// .catch( error => {
		// 	const db = new Dexie('restaured')
		// 	var variable = db.variable.toArray()
		// 	console.log(variable, error)
		// })
		url = baseurl+'/restaured/variablexindicador'
		await axios.get(url,{withCredentials:true})
		.then(response => response.data)
		.then(data => {
			// console.log(data)
			var id =[];
			var idIndicador =[];
			var idVariable =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				idIndicador.push(data.body[i].idIndicador);
				idVariable.push(data.body[i].idVariable);
			};
			this.setState({
				variablexindicador:{
							id:id,
							idIndicador:idIndicador,
							idVariable:idVariable,
			}})
		})
		url = baseurl+'/restaured/indicador'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			// console.log(data)
			var id =[];
			var idMonitoreo =[];
			var formula =[];
			var nombre =[];
			var pubObj =[];
			var tipo =[];
			var tipoGrafico =[];
			var unidades =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				idMonitoreo.push(data.body[i].idMonitoreo);
				formula.push(data.body[i].formula);
				nombre.push(data.body[i].nombre);
				pubObj.push(data.body[i].pubObj);
				tipo.push(data.body[i].tipo);
				tipoGrafico.push(data.body[i].tipoGrafico);
				unidades.push(data.body[i].unidades);
			};
			this.setState({
				indicador:{
						id:id,
						idMonitoreo:idMonitoreo,
						formula:formula,
						nombre:nombre,
						pubObj:pubObj,
						tipo:tipo,
						tipoGrafico:tipoGrafico,
						unidades:unidades,
			}})
		})
		url = baseurl+'/restaured/localizacion'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			console.log(data)
			var id =[];
			var idSitio =[];
			var idParcela =[];
			var idTransecto =[];
			var idPO =[];
			var nombre =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				idSitio.push(data.body[i].idSitio);
				idParcela.push(data.body[i].idParcela);
				idTransecto.push(data.body[i].idTransecto);
				idPO.push(data.body[i].idPO);
				nombre.push(data.body[i].nombre);
			};
			this.setState({
				localizacion:{
							id:id,
							idSitio:idSitio,
							idParcela:idParcela,
							idTransecto:idTransecto,
							idPO:idPO,
							nombre:nombre,
			}})
		})
		url = baseurl+'/restaured/sitio'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			var id =[];
			var nombre =[];
			var geos =[];
			var descripcion =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				geos.push(data.body[i].geos);
				descripcion.push(data.body[i].descripcion);
				nombre.push(data.body[i].nombre);
			};
			this.setState({
				sitio:{
							id:id,
							nombre:nombre,
							geos:geos,
							descripcion:descripcion,
			}})
		})
		url = baseurl+'/restaured/puntoobservacion'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			var id =[];
			var nombre =[];
			var geos =[];
			var descripcion =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				geos.push(data.body[i].geos);
				descripcion.push(data.body[i].descripcion);
				nombre.push(data.body[i].nombre);
			};
			this.setState({
				PO:{
							id:id,
							nombre:nombre,
							geos:geos,
							descripcion:descripcion,
			}})
		})
		url = baseurl+'/restaured/transecto'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			var id =[];
			var nombre =[];
			var geos =[];
			var descripcion =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				geos.push(data.body[i].geos);
				descripcion.push(data.body[i].descripcion);
				nombre.push(data.body[i].nombre);
			};
			this.setState({
				transecto:{
							id:id,
							nombre:nombre,
							geos:geos,
							descripcion:descripcion,
			}})
		})
		url = baseurl+'/restaured/parcela'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			var id =[];
			var nombre =[];
			var geos =[];
			var descripcion =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				geos.push(data.body[i].geos);
				descripcion.push(data.body[i].descripcion);
				nombre.push(data.body[i].nombre);
			};
			this.setState({
				parcela:{
							id:id,
							nombre:nombre,
							geos:geos,
							descripcion:descripcion,
			}})
		})
		url = baseurl+'/restaured/monitoreo'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			// console.log(data)
			var id =[];
			var nombre =[];
			var descripcion =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				nombre.push(data.body[i].nombre);
				descripcion.push(data.body[i].descripcion);
			};
			this.setState({
				monitoreo:{
					id:id,
					nombre:nombre,
					descripcion:descripcion,
			}})
		})
		url = baseurl+'/restaured/registromonitoreo'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			//Extraer Fecha y agegarla al modelo Monitoreo
			// var date= new Date(data.body[0].date*1000);
			// console.log(date);
			// console.log(data.body[0]);
			var id =[];
			var nombre =[];
			var idMonitoreo =[];
			var idUser =[];
			var date=[]
			for(const i in data.body){
				id.push(data.body[i]._id);
				nombre.push(data.body[i].nombre);
				idUser.push(data.body[i].idUser);
				idMonitoreo.push(data.body[i].idMonitoreo);
				date.push(data.body[i].date);
			};
			this.setState({
				registroMonitoreo:{
					id:id,
					nombre:nombre,
					idUser:idUser,
					idMonitoreo:idMonitoreo,
					date:date,
			}})
		})
		url = baseurl+'/restaured/entradamonitoreo'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			//Extraer Localización y agegarla al modelo Monitoreo
			// console.log(data)
			var id =[];
			var idLocalizacion =[];
			var curado =[];
			var idRegistro =[];
			var idEspecies =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				idLocalizacion.push(data.body[i].idLocalizacion);
				curado.push(data.body[i].curado);
				idRegistro.push(data.body[i].idRegistro);
				idEspecies.push(data.body[i].idEspecie);
			};
			this.setState({
				entradaMonitoreo:{
							id:id,
							idLocalizacion:idLocalizacion,
							curado:curado,
							idRegistro:idRegistro,
							idEspecies:idEspecies,
			}})
		})
		url = baseurl+'/restaured/variableregistro'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			// console.log(data)
			var id =[];
			var idEntrada =[];
			var idVariable =[];
			var valor =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				idEntrada.push(data.body[i].idEntrada);
				idVariable.push(data.body[i].idVariable);
				valor.push(data.body[i].valor);
			};
			this.setState({
				variableRegistro:{
							id:id,
							idEntrada:idEntrada,
							idVariable:idVariable,
							valor:valor,
			}})
		})
	}
	viewdata=async()=>{
		var monitoreo={
			id:0,
			nombre:"",
			descripcion:"",
			variables:[],
			registros:[],
			indicadores:[],
		};
		var indicadores = {
			id:[],
			idMonitoreo:[],
			formula:[],
			nombre:[],
			pubObj:[],
			tipo:[],
			tipoGrafico:[],
			unidades:[],
		};
		var registros={
				id:[],
				iduser:[],
				nombre:[],
				entradas:[],
				date:[],
		};
		var entradas={
				id:[],  
				idLocalizacion:[],  
				idVariablesRegistro:[],  
				variables:[],
				especies:[],
				valores:[],
				curado:[],
		};
		var monitoreos=[]
		var valores=[];
		var variables=[];
		var idvariableRegistro=[]
		var idvariablesRegistro=[]
		var variableEntrada=[];
		var valorEntrada=[];
		for(const i in this.state.monitoreo.id){
			monitoreo.id=this.state.monitoreo.id[i]
			monitoreo.nombre=this.state.monitoreo.nombre[i]
			monitoreo.descripcion=this.state.monitoreo.descripcion[i]
			for(const j in this.state.variable.nombre){
				if(this.state.variable.idMonitoreo[j]===this.state.monitoreo.id[i]){
					var variable={
						id:this.state.variable.id[j],
						nombre:this.state.variable.nombre[j],
						pubObj:this.state.variable.pubObj[j]
					}
					monitoreo.variables.push(variable);
				}
			}
			for(const j in this.state.indicador.id){
				if(this.state.indicador.idMonitoreo[j]===this.state.monitoreo.id[i]){
					indicadores.id.push(this.state.indicador.id[j])
					indicadores.idMonitoreo.push(this.state.indicador.idMonitoreo[j])
					indicadores.formula.push(this.state.indicador.formula[j])
					indicadores.nombre.push(this.state.indicador.nombre[j])
					indicadores.pubObj.push(this.state.indicador.pubObj[j])
					indicadores.tipo.push(this.state.indicador.tipo[j])
					indicadores.tipoGrafico.push(this.state.indicador.tipoGrafico[j])
					indicadores.unidades.push(this.state.indicador.unidades[j])
				}
			}
			// console.log(this.state.entradaMonitoreo)
			for(const j in this.state.registroMonitoreo.id){
				if(this.state.monitoreo.id[i] === this.state.registroMonitoreo.idMonitoreo[j]){
					registros.id.push(this.state.registroMonitoreo.id[j])
					registros.iduser.push(this.state.registroMonitoreo.idUser[j])
					registros.nombre.push(this.state.registroMonitoreo.nombre[j])
					registros.date.push(this.state.registroMonitoreo.date[j])
					for(const k in this.state.entradaMonitoreo.id){
						if(this.state.registroMonitoreo.id[j] === this.state.entradaMonitoreo.idRegistro[k]){
							entradas.id.push(this.state.entradaMonitoreo.id[k]);
							entradas.idLocalizacion.push(this.state.entradaMonitoreo.idLocalizacion[k]);
							entradas.curado.push(this.state.entradaMonitoreo.curado[k]);
							entradas.especies.push(this.state.entradaMonitoreo.idEspecies[k]);
								for(const l in this.state.variableRegistro.id){
									if(this.state.entradaMonitoreo.id[k] === this.state.variableRegistro.idEntrada[l]){
										idvariableRegistro.push(this.state.variableRegistro.id[l]);
										valorEntrada.push(Number(this.state.variableRegistro.valor[l]));
										variableEntrada.push(this.state.variableRegistro.idVariable[l]);
									}
								}
							idvariablesRegistro.push(idvariableRegistro)  
							variables.push(variableEntrada)  
							valores.push(valorEntrada)  
				idvariableRegistro=[]
							valorEntrada=[]
							variableEntrada=[]
							}
						}
						entradas.variables=variables;
						entradas.valores=valores;
						entradas.idVariablesRegistro=idvariablesRegistro;
						variables=[];
						idvariablesRegistro=[];
						valores=[];
						registros.entradas.push(entradas);
						entradas={id:[], idLocalizacion:[], idVariablesRegistro:[], variables:[], valores:[], curado:[],especies:[],}
				}
			}
			monitoreo.indicadores=indicadores;
			monitoreo.registros=registros;
			registros={ id:[], iduser:[], nombre:[], entradas:[], date:[] };
			indicadores = { id:[], idMonitoreo:[], formula:[], nombre:[], pubObj:[], tipo:[], tipoGrafico:[], unidades:[],};
			monitoreos.push(monitoreo);
			monitoreo={ id:0, nombre:"", descripcion:"", variables:[],indicadores:[],};
		}
		console.log(monitoreos)
		this.setState({
			monitoreos:monitoreos
		})
	}
	loadLocalizaciones =async()=>{
		await this.GetDataTable()
		var localizacion={
			id:[],
			sitio:[],
			parcela:[],
			transecto:[],
			PO:[],    
		}
		for(const i in this.state.localizacion.id){
			for(const j in this.state.sitio.id){
				if(this.state.localizacion.idSitio[i] === this.state.sitio.id[j]){
					var sitio= {
						id:this.state.sitio.id[j],
						nombre:this.state.sitio.nombre[j],
						geos:this.state.sitio.geos[j],
						descripcion:this.state.sitio.descripcion[j],
					}
					localizacion.id.push(this.state.localizacion.id[i])
					localizacion.sitio.push(sitio)
					var verify = localizacion.PO.length
					for(const k in this.state.PO.id){
						if(this.state.PO.id[k]=== this.state.localizacion.idPO[i]){
							var ubicacionpo = {
								id:this.state.PO.id[k],
								geos:this.state.PO.geos[k],
								nombre:this.state.PO.nombre[k],
								descripcion:this.state.PO.descripcion[k],
							}
							localizacion.PO.push(ubicacionpo)
						}
					}
					if(verify === localizacion.PO.length)
						localizacion.PO.push(null)
					verify=localizacion.transecto.length
					for(const k in this.state.transecto.id){
						if(this.state.transecto.id[k]=== this.state.localizacion.idTransecto[i]){
							var ubicaciontrans = {
								id:this.state.transecto.id[k],
								geos:this.state.transecto.geos[k],
								nombre:this.state.transecto.nombre[k],
								descripcion:this.state.transecto.descripcion[k],
							}
							localizacion.transecto.push(ubicaciontrans)
						}
					}
					if(verify === localizacion.transecto.length)
						localizacion.transecto.push(null)
					verify=localizacion.parcela.length
					for(const k in this.state.parcela.id){
						if(this.state.parcela.id[k]=== this.state.localizacion.idParcela[i]){
							var ubicacionparce = {
								id:this.state.parcela.id[k],
								geos:this.state.parcela.geos[k],
								nombre:this.state.parcela.nombre[k],
								descripcion:this.state.parcela.descripcion[k],
							}
							localizacion.parcela.push(ubicacionparce)
						}
					}
					if(verify === localizacion.parcela.length)
						localizacion.parcela.push(null)
				}
			}
		}
		this.setState({
			datasetLocalizaciones:localizacion
		})
	}
	initial_state=async()=>{
		await this.loadLocalizaciones();
		await this.viewdata()
	}
	//   LLAMADO A TABLAS 
	TablaRegistro=()=>{
		var dataset=[]
		if(this.state.monitoreos.length > 0){
			for(const i in this.state.monitoreos){
				var datos={}
					for(const j in this.state.monitoreos[i].registros.entradas){
						var curados=true
						var numentradas = 0
						for(const k in this.state.monitoreos[i].registros.entradas[j].curado){
							if(!(this.state.monitoreos[i].registros.entradas[j].curado[k])){
								curados = false
								numentradas = numentradas + 1
							}
						}
						if(!curados){
						var realdate =  new Date (this.state.monitoreos[i].registros.date[j]*1000)
						datos.date = realdate.getDate()+"/"+realdate.getMonth()+"/"+realdate.getFullYear()
						datos.nombre = this.state.monitoreos[i].registros.nombre[j]
						datos.numEntradas=numentradas
						dataset.push(datos)
						datos={
							date:"",
							nombre:"",
							numEntradas:"",
							}
						}
					}
				}
				console.log(dataset)
				var html = TableRegistros(dataset)
			return(<>{html}</>)
		}else{
			return(<></>)
		}
	}
	// VISUALIZACIÓN CONDICIONAL 
	renderTablaEntradas=()=> {
		var html = this.TablaRegistro()
			return (<> 
			{html}
			</>
			)
	}

    render(){
        return(
            <div>
			<div id="wrapper">
				<NavbarC/>
				<div id="content-wrapper" className="d-flex flex-column">
					<div id="content">
					<HeaderUsers Iduser={this.props.Iduser} Rol={this.props.Rol} Nombre={this.props.Nombre} External={this.props.External}/>
					{this.props.Nombre ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
					{this.props.Iduser ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
					{this.props.Rol ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
						<div className="container-fluid">							
							<h1 className="h3 mb-2 text-gray-800">Curación</h1>
							<p> <strong> Seleccione las entradas que desee modificar o verificar su veracidad</strong></p>
							<this.TablaRegistro/>
						</div>
					</div>
					 <footer id="footer" className="sticky-footer">
						<div className="container my-auto">
							<div className="copyright text-center my-auto">
								© Copyright <strong><span>Restaured 2024</span></strong>. All Rights Reserved
							</div>
						</div>
					</footer>
				</div>
			</div>
			<a className="scroll-to-top rounded" href="#page-top">
			<i className="fas fa-angle-up" />
			</a>
			<Logout/>
		</div>
        )
    }
}