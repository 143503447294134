import React,{ Component} from 'react'
import { Redirect } from 'react-router'
import NavbarC from '../../NavBar/NavbarUsersC'
import HeaderUsers from './HeaderUsers'
import '../../../assets/vendor/bootstrap/css/bootstrap.css'
import '../../../assets/css/sb-admin-2.css'
import Logout from './Logout'
const axios = require('axios');

export default class Curacion extends Component{
	constructor(props){
		super(props);
		this.state={
				Rol: this.props.Rol,
				Nombre: this.props.Nombre,
				Iduser: this.props.Iduser,
				open:false,
				volver:false,
				location:{},
				viewTablaEntradas: [],
				suggestions: [],
				gbifespecies:{
					canonicalName:[],
					order:[],
					key:[],
					family:[],
					genus:[],
				},
				datasetLocalizaciones: [],
				especie:{
					id:[],
					nombreCientifico:[],
					nombreComun:[],
					familiar:[],
					orden:[],
					genero:[],
					activo:[],
					idLocalizacion:[],
				},
				selectedEspecie:"",
				selectedOrder:"",
				selectedFamily:"",
				selectedVernacular:"",
				selectedGenus:"",
				selectedVariable:"",
				form:{
					fecha:"",
					nombre:"",
					valores:[],
					variable:"",
					especie:"",
					idMonitoreo:"",
					idUser:"",
					idRegistro:"",
					idLocalizacion:"",
					idEspecie:"",
					idEntrada:"",
					idVariables:[],
					variables:[],

				},
				variable:{
					id:[],
					idMonitoreo:[],
					nombre:[],
					pubObj:[]
				},
				variablexindicador:{
					id:[],
					idIndicador:[],
					idVariable:[]
				},
				indicador:{
					id:[],
					idMonitoreo:[],
					formula:[],
					nombre:[],
					pubObj:[],
					tipo:[],
					tipoGrafico:[],
					unidades:[],
				},
				monitoreo:{
					id:[],
					nombre:[],
					descripcion:[],
				},
				registroMonitoreo:{
					id:[],
					nombre:[],
					idUser:[],
					idMonitoreo:[],
				},
				entradaMonitoreo:{
					id:[],
					idLocalizacion:[],
					curado:[],
					idRegistro:[],
					idEspecies:[],
				},
				variableRegistro:{
					id:[],
					idEntrada:[],
					idVariable:[],
					valor:[],
				},
				localizacion:{
					id:[],
					idSitio:[],
					isParcela:[],
					idTransecto:[],
					idPO:[],
				},
				sitio:{
					id:[],
					nombre:[],
					geos:[],
					descripcion:[],
				},
				parcela:{
					id:[],
					nombre:[],
					geos:[],
					descripcion:[],
				},
				transecto:{
					id:[],
					nombre:[],
					geos:[],
					descripcion:[],
				},
				PO:{
					id:[],
					nombre:[],
					geos:[],
					descripcion:[],
				},
				ingresarEntradaMonitoreo:{
					body:[],
					status:"",
				},
				users:{
					id:[],
					nombre:[],
					correo:[],
				},
				monitoreos:[],

		}
		this.Getespecies();
		this.initial_state();
	}
	//HANDLEE
	setOpen=()=>{
		this.setState({open:!this.state.open})
	}
	onChange = (event ) => {
		this.setState({
			 dataset: {
					...this.state.dataset,
					[event.target.name]:event.target.value,
			 }
		});
	};
	onChangeEspecie = (event ) => {
		this.setState({
			 dataset: {
				...this.state.dataset,
				especies: {
					...this.state.dataset.especies,
					[event.target.name]:event.target.value,
				}
			 }
		});
	};
	handleSelect = (event , view) => {
	var select
	for(const i in view.id){
		if(view.id[i]=== event.target.value )
		select = view.nombre[i]
	}
		this.setState({
			selectedEspecie:select,
			form:{
				...this.state.form,
				especie: event.target.value
			}
		});
	};
	handleSelectLocalizacion=(e)=>{
		this.setState({ dataset:{
			...this.state.dataset,
			localizacion: e.target.value
		}})
	}
	handleSelectLocalizacionEspecie=(e)=>{
		this.setState({ dataset:{
			...this.state.dataset,
			especies:{
				...this.state.dataset.especies,
				idLocalizacion: e.target.value
			}
		}})
	}
	handleSelectV = event => {
		this.setState({
			selectedVariable:event.target.value,
			form: {
				...this.state.form,
				[event.target.name]: event.target.value
		 }
		});
	};
	onChangedText=async(e)=>{
		const value = e.target.value
		let suggestions =[]
		var config = {
		method: 'get',
		url: 'https://api.gbif.org/v1/species/suggest',
		params:{ q: value, limit:5},
		headers: { }
		};
		axios(config)
		.then(response => {
			var canonicalName=[]; var family=[]; var order=[]; var genus=[]; var key=[]
			for(const i in response.data){
				canonicalName.push(response.data[i].canonicalName)
				family.push(response.data[i].family)
				key.push(response.data[i].key)
				genus.push(response.data[i].genus)
				order.push(response.data[i].order)
			}
			this.setState({gbifespecies: {...this.state.gbifespecies, key:key, family: family, order: order, genus: genus ,canonicalName:canonicalName}});
		})
		.catch(error =>{
		console.log(error);
		});
		let items =this.state.gbifespecies.canonicalName
		if (value.length > 0){
			const regex = new RegExp(`${value}`,'i')
			suggestions =items.sort().filter( v => regex.test(v))
		}
		this.setState({ suggestions : suggestions,dataset:{ ...this.state.dataset, especies:{ ...this.state.dataset.especies, nombreCientifico:value }},selectedEspecie:value})
	}
	suggestionsSelected=async(value,i)=>{
		console.log(value)
		var config = {
			method: 'get',
			url: 'https://api.gbif.org/v1/species/'+this.state.gbifespecies.key[i],
			// params:{ name: this.state.gbifespecies.key[i], limit:1},
			headers: { }
			};
		await axios(config)
		.then(async response => {
			console.log(response)
			if( response.data.vernacularName !== undefined ){
				var vernacularName = response.data.vernacularName;
				await this.setState({ dataset:{ ...this.state.dataset, especies:{ ...this.state.dataset.especies,
					nombreComun:vernacularName}}});
			}else{
				await this.setState({ dataset:{ ...this.state.dataset, especies:{ ...this.state.dataset.especies,
					nombreComun:''}}});
			}
		})
		await this.setState({ suggestions:[], dataset:{ ...this.state.dataset, especies:{ ...this.state.dataset.especies,
			nombreCientifico:value,
			family: this.state.gbifespecies.family[i],
			order: this.state.gbifespecies.order[i],
			genus: this.state.gbifespecies.genus[i],
		}}})
		console.log(this.state.dataset.especies.nombreComun)
	}
	//PETICIONES
	Getespecies=async()=>{
		var baseurl = "https://api.restaured.net"
		var url = baseurl+'/restaured/especie'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			var id =[];
			var nombreCientifico =[];
			var nombreComun =[];
			var idLocalizacion =[];
			var activo =[];
			for(const i in data.body){
				id.push(data.body[i]._id)
				idLocalizacion.push(data.body[i].idLocalizacion)
				nombreCientifico.push(data.body[i].nombreCientifico)
				nombreComun.push(data.body[i].nombreComun)
				activo.push(data.body[i].activo)
			};
			this.setState({
				especie:{
					id:id,
					idLocalizacion:idLocalizacion,
					nombreCientifico:nombreCientifico,
					nombreComun:nombreComun,
					activo:activo,
				}});
		})
		// console.log(this.state.especies);
	} 
	GetDataTable=async()=>{
		var baseurl = "https://api.restaured.net"
		var url = baseurl+'/auth/user'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			var id =[];
			var nombre =[];
			var email =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				nombre.push(data.body[i].nombre);
				email.push(data.body[i].email);
			};
			this.setState({
				users:{
					id:id,
					nombre:nombre,
					correo:email,
			}})
		})
		url = baseurl+'/restaured/variable'
		await axios.get(url,{withCredentials: true})
		.then(response => response.data)
		.then(data => {
			// console.log(data)
			var id =[];
			var idMonitoreo =[];
			var nombre =[];
			var pubObj =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				idMonitoreo.push(data.body[i].idMonitoreo);
				nombre.push(data.body[i].nombre);
				pubObj.push(data.body[i].pubObj);
			};
			this.setState({
				variable:{
							id:id,
							idMonitoreo:idMonitoreo,
							nombre:nombre,
							pubObj:pubObj,
			}})
		})
		url = baseurl+'/restaured/variablexindicador'
		await axios.get(url,{withCredentials:true})
		.then(response => response.data)
		.then(data => {
			var id =[];
			var idIndicador =[];
			var idVariable =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				idIndicador.push(data.body[i].idIndicador);
				idVariable.push(data.body[i].idVariable);
			};
			this.setState({
				variablexindicador:{
							id:id,
							idIndicador:idIndicador,
							idVariable:idVariable,
			}})
		})
		url = baseurl+'/restaured/indicador'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			// console.log(data)
			var id =[];
			var idMonitoreo =[];
			var formula =[];
			var nombre =[];
			var pubObj =[];
			var tipo =[];
			var tipoGrafico =[];
			var unidades =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				idMonitoreo.push(data.body[i].idMonitoreo);
				formula.push(data.body[i].formula);
				nombre.push(data.body[i].nombre);
				pubObj.push(data.body[i].pubObj);
				tipo.push(data.body[i].tipo);
				tipoGrafico.push(data.body[i].tipoGrafico);
				unidades.push(data.body[i].unidades);
			};
			this.setState({
				indicador:{
						id:id,
						idMonitoreo:idMonitoreo,
						formula:formula,
						nombre:nombre,
						pubObj:pubObj,
						tipo:tipo,
						tipoGrafico:tipoGrafico,
						unidades:unidades,
			}})
		})
		url = baseurl+'/restaured/localizacion'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			console.log(data)
			var id =[];
			var idSitio =[];
			var idParcela =[];
			var idTransecto =[];
			var idPO =[];
			var nombre =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				idSitio.push(data.body[i].idSitio);
				idParcela.push(data.body[i].idParcela);
				idTransecto.push(data.body[i].idTransecto);
				idPO.push(data.body[i].idPO);
				nombre.push(data.body[i].nombre);
			};
			this.setState({
				localizacion:{
							id:id,
							idSitio:idSitio,
							idParcela:idParcela,
							idTransecto:idTransecto,
							idPO:idPO,
							nombre:nombre,
			}})
		})
		url = baseurl+'/restaured/sitio'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			var id =[];
			var nombre =[];
			var geos =[];
			var descripcion =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				geos.push(data.body[i].geos);
				descripcion.push(data.body[i].descripcion);
				nombre.push(data.body[i].nombre);
			};
			this.setState({
				sitio:{
							id:id,
							nombre:nombre,
							geos:geos,
							descripcion:descripcion,
			}})
		})
		url = baseurl+'/restaured/puntoobservacion'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			var id =[];
			var nombre =[];
			var geos =[];
			var descripcion =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				geos.push(data.body[i].geos);
				descripcion.push(data.body[i].descripcion);
				nombre.push(data.body[i].nombre);
			};
			this.setState({
				PO:{
							id:id,
							nombre:nombre,
							geos:geos,
							descripcion:descripcion,
			}})
		})
		url = baseurl+'/restaured/transecto'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			var id =[];
			var nombre =[];
			var geos =[];
			var descripcion =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				geos.push(data.body[i].geos);
				descripcion.push(data.body[i].descripcion);
				nombre.push(data.body[i].nombre);
			};
			this.setState({
				transecto:{
							id:id,
							nombre:nombre,
							geos:geos,
							descripcion:descripcion,
			}})
		})
		url = baseurl+'/restaured/parcela'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			var id =[];
			var nombre =[];
			var geos =[];
			var descripcion =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				geos.push(data.body[i].geos);
				descripcion.push(data.body[i].descripcion);
				nombre.push(data.body[i].nombre);
			};
			this.setState({
				parcela:{
							id:id,
							nombre:nombre,
							geos:geos,
							descripcion:descripcion,
			}})
		})
		url = baseurl+'/restaured/monitoreo'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			// console.log(data)
			var id =[];
			var nombre =[];
			var descripcion =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				nombre.push(data.body[i].nombre);
				descripcion.push(data.body[i].descripcion);
			};
			this.setState({
				monitoreo:{
					id:id,
					nombre:nombre,
					descripcion:descripcion,
			}})
		})
		url = baseurl+'/restaured/registromonitoreo'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			//Extraer Fecha y agegarla al modelo Monitoreo
			// var date= new Date(data.body[0].date*1000);
			// console.log(date);
			// console.log(data.body[0]);
			var id =[];
			var nombre =[];
			var idMonitoreo =[];
			var idUser =[];
			var date=[]
			for(const i in data.body){
				id.push(data.body[i]._id);
				nombre.push(data.body[i].nombre);
				idUser.push(data.body[i].idUser);
				idMonitoreo.push(data.body[i].idMonitoreo);
				date.push(data.body[i].date);
			};
			this.setState({
				registroMonitoreo:{
					id:id,
					nombre:nombre,
					idUser:idUser,
					idMonitoreo:idMonitoreo,
					date:date,
			}})
		})
		url = baseurl+'/restaured/entradamonitoreo'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			//Extraer Localización y agegarla al modelo Monitoreo
			// console.log(data)
			var id =[];
			var idLocalizacion =[];
			var curado =[];
			var idRegistro =[];
			var idEspecies =[];
			var img =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				idLocalizacion.push(data.body[i].idLocalizacion);
				curado.push(data.body[i].curado);
				idRegistro.push(data.body[i].idRegistro);
				idEspecies.push(data.body[i].idEspecie);
				img.push(data.body[i].img);
			};
			this.setState({
			entradaMonitoreo:{
				id:id,
				idLocalizacion:idLocalizacion,
				curado:curado,
				idRegistro:idRegistro,
				idEspecies:idEspecies,
				img:img,
			}})
		})
		url = baseurl+'/restaured/variableregistro'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			// console.log(data)
			var id =[];
			var idEntrada =[];
			var idVariable =[];
			var valor =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				idEntrada.push(data.body[i].idEntrada);
				idVariable.push(data.body[i].idVariable);
				valor.push(data.body[i].valor);
			};
			this.setState({
				variableRegistro:{
							id:id,
							idEntrada:idEntrada,
							idVariable:idVariable,
							valor:valor,
			}})
		})
	}
	viewdata=async()=>{
		var monitoreo={
			id:0,
			nombre:"",
			descripcion:"",
			variables:[],
			registros:[],
			indicadores:[],
		};
		var indicadores = {
			id:[],
			idMonitoreo:[],
			formula:[],
			nombre:[],
			pubObj:[],
			tipo:[],
			tipoGrafico:[],
			unidades:[],
		};
		var registros={
				id:[],
				iduser:[],
				nombre:[],
				entradas:[],
				date:[],
		};
		var entradas={
				id:[],  
				idLocalizacion:[],  
				idVariablesRegistro:[],  
				variables:[],
				especies:[],
				valores:[],
				curado:[],
				img:[],
		};
		var monitoreos=[]
		var valores=[];
		var variables=[];
		var idvariableRegistro=[]
		var idvariablesRegistro=[]
		var variableEntrada=[];
		var valorEntrada=[];
		for(const i in this.state.monitoreo.id){
			monitoreo.id=this.state.monitoreo.id[i]
			monitoreo.nombre=this.state.monitoreo.nombre[i]
			monitoreo.descripcion=this.state.monitoreo.descripcion[i]
			for(const j in this.state.variable.nombre){
				if(this.state.variable.idMonitoreo[j]===this.state.monitoreo.id[i]){
					var variable={
						id:this.state.variable.id[j],
						nombre:this.state.variable.nombre[j],
						pubObj:this.state.variable.pubObj[j]
					}
					monitoreo.variables.push(variable);
				}
			}
			for(const j in this.state.indicador.id){
				if(this.state.indicador.idMonitoreo[j]===this.state.monitoreo.id[i]){
					indicadores.id.push(this.state.indicador.id[j])
					indicadores.idMonitoreo.push(this.state.indicador.idMonitoreo[j])
					indicadores.formula.push(this.state.indicador.formula[j])
					indicadores.nombre.push(this.state.indicador.nombre[j])
					indicadores.pubObj.push(this.state.indicador.pubObj[j])
					indicadores.tipo.push(this.state.indicador.tipo[j])
					indicadores.tipoGrafico.push(this.state.indicador.tipoGrafico[j])
					indicadores.unidades.push(this.state.indicador.unidades[j])
				}
			}
			// console.log(this.state.entradaMonitoreo)
			for(const j in this.state.registroMonitoreo.id){
				if(this.state.monitoreo.id[i] === this.state.registroMonitoreo.idMonitoreo[j]){
					registros.id.push(this.state.registroMonitoreo.id[j])
					registros.iduser.push(this.state.registroMonitoreo.idUser[j])
					registros.nombre.push(this.state.registroMonitoreo.nombre[j])
					registros.date.push(this.state.registroMonitoreo.date[j])
					for(const k in this.state.entradaMonitoreo.id){
						if(this.state.registroMonitoreo.id[j] === this.state.entradaMonitoreo.idRegistro[k]){
							entradas.id.push(this.state.entradaMonitoreo.id[k]);
							entradas.idLocalizacion.push(this.state.entradaMonitoreo.idLocalizacion[k]);
							entradas.curado.push(this.state.entradaMonitoreo.curado[k]);
							entradas.img.push(this.state.entradaMonitoreo.img[k]);
							entradas.especies.push(this.state.entradaMonitoreo.idEspecies[k]);
								for(const l in this.state.variableRegistro.id){
									if(this.state.entradaMonitoreo.id[k] === this.state.variableRegistro.idEntrada[l]){
										idvariableRegistro.push(this.state.variableRegistro.id[l]);
										valorEntrada.push(Number(this.state.variableRegistro.valor[l]));
										variableEntrada.push(this.state.variableRegistro.idVariable[l]);
									}
								}
							idvariablesRegistro.push(idvariableRegistro)  
							variables.push(variableEntrada)  
							valores.push(valorEntrada)  
				idvariableRegistro=[]
							valorEntrada=[]
							variableEntrada=[]
							}
						}
						entradas.variables=variables;
						entradas.valores=valores;
						entradas.idVariablesRegistro=idvariablesRegistro;
						variables=[];
						idvariablesRegistro=[];
						valores=[];
						registros.entradas.push(entradas);
						entradas={id:[], idLocalizacion:[], idVariablesRegistro:[], variables:[], valores:[], curado:[],especies:[],img:[],}
				}
			}
			monitoreo.indicadores=indicadores;
			monitoreo.registros=registros;
			registros={ id:[], iduser:[], nombre:[], entradas:[], date:[] };
			indicadores = { id:[], idMonitoreo:[], formula:[], nombre:[], pubObj:[], tipo:[], tipoGrafico:[], unidades:[],};
			monitoreos.push(monitoreo);
			monitoreo={ id:0, nombre:"", descripcion:"", variables:[],indicadores:[],};
		}
		console.log(monitoreos)
		this.setState({
			monitoreos:monitoreos
		})
	}
	loadLocalizaciones =async()=>{
		await this.GetDataTable()
		var localizacion={
			id:[],
			sitio:[],
			parcela:[],
			transecto:[],
			PO:[],    
		}
		for(const i in this.state.localizacion.id){
			for(const j in this.state.sitio.id){
				if(this.state.localizacion.idSitio[i] === this.state.sitio.id[j]){
					var sitio= {
						id:this.state.sitio.id[j],
						nombre:this.state.sitio.nombre[j],
						geos:this.state.sitio.geos[j],
						descripcion:this.state.sitio.descripcion[j],
					}
					localizacion.id.push(this.state.localizacion.id[i])
					localizacion.sitio.push(sitio)
					var verify = localizacion.PO.length
					for(const k in this.state.PO.id){
						if(this.state.PO.id[k]=== this.state.localizacion.idPO[i]){
							var ubicacionpo = {
								id:this.state.PO.id[k],
								geos:this.state.PO.geos[k],
								nombre:this.state.PO.nombre[k],
								descripcion:this.state.PO.descripcion[k],
							}
							localizacion.PO.push(ubicacionpo)
						}
					}
					if(verify === localizacion.PO.length)
						localizacion.PO.push(null)
					verify=localizacion.transecto.length
					for(const k in this.state.transecto.id){
						if(this.state.transecto.id[k]=== this.state.localizacion.idTransecto[i]){
							var ubicaciontrans = {
								id:this.state.transecto.id[k],
								geos:this.state.transecto.geos[k],
								nombre:this.state.transecto.nombre[k],
								descripcion:this.state.transecto.descripcion[k],
							}
							localizacion.transecto.push(ubicaciontrans)
						}
					}
					if(verify === localizacion.transecto.length)
						localizacion.transecto.push(null)
					verify=localizacion.parcela.length
					for(const k in this.state.parcela.id){
						if(this.state.parcela.id[k]=== this.state.localizacion.idParcela[i]){
							var ubicacionparce = {
								id:this.state.parcela.id[k],
								geos:this.state.parcela.geos[k],
								nombre:this.state.parcela.nombre[k],
								descripcion:this.state.parcela.descripcion[k],
							}
							localizacion.parcela.push(ubicacionparce)
						}
					}
					if(verify === localizacion.parcela.length)
						localizacion.parcela.push(null)
				}
			}
		}
		this.setState({
			datasetLocalizaciones:localizacion
		})
	}
	GetEntrada=()=>{
		var dataset=[]
		var location_props = this.props.location.state
		var entrada = location_props.entrada
		if(this.state.monitoreos.length > 0 && this.state.datasetLocalizaciones.length === undefined){
			var especiesTabla=[];
			var valorTabla=[]; var valoresTabla=[];
			var variableTabla=[]; var variablesTabla=[];
			var localizacionesTabla=[];
			var nombreVariable=[];
			for(const l in this.state.monitoreos[location_props.monitoreo].registros.entradas[location_props.registro].id){
				if(!(this.state.monitoreos[location_props.monitoreo].registros.entradas[location_props.registro].curado[l])){
					entrada = entrada -1 
					if(entrada < 0){
						var location= {
							monitoreo:location_props.monitoreo,
							registro:location_props.registro,
							entrada:l,
						}
						for(const m in this.state.especie.id){
							if(this.state.especie.id[m] === this.state.monitoreos[location_props.monitoreo].registros.entradas[location_props.registro].especies[l])
								especiesTabla.push({
									id: this.state.especie.id[m],
									nombreCientifico : this.state.especie.nombreCientifico[m],
									nombreComun: this.state.especie.nombreComun[m],
									idLocalizacion:this.state.especie.idLocalizacion[m], 
								})
						}
						for(const m in this.state.monitoreos[location_props.monitoreo].registros.entradas[location_props.registro].valores[l]){
							valorTabla.push(this.state.monitoreos[location_props.monitoreo].registros.entradas[location_props.registro].valores[l][m])
							variableTabla.push(this.state.monitoreos[location_props.monitoreo].registros.entradas[location_props.registro].variables[l][m])
						}
						for(const m in this.state.datasetLocalizaciones.id){
							if(this.state.datasetLocalizaciones.id[m] === this.state.monitoreos[location_props.monitoreo].registros.entradas[location_props.registro].idLocalizacion[l]){
								localizacionesTabla.push(this.state.datasetLocalizaciones.id[m])
							}
						}
						variablesTabla=variableTabla
						valoresTabla=[]
						var nombresVariableTotal =[]
						var nombresVariablesExistentes=[]
						for(const k in this.state.monitoreos[location_props.monitoreo].variables){
							for(const l in variablesTabla){
								if(variablesTabla[l] === this.state.monitoreos[location_props.monitoreo].variables[k].id){
									nombreVariable.push(this.state.monitoreos[location_props.monitoreo].variables[k].nombre)
									valoresTabla.push(valorTabla[l])
								}									
							}
							nombresVariableTotal.push(this.state.monitoreos[location_props.monitoreo].variables[k].nombre)
						}
						nombresVariablesExistentes=nombreVariable
						for(const k in especiesTabla){
							var datos;
							if(nombresVariablesExistentes.length > 1){
								datos ={
									id:this.state.monitoreos[location_props.monitoreo].registros.entradas[location_props.registro].id[l],
									especies:especiesTabla[k],
									localizacion:localizacionesTabla[k],
									img: this.state.monitoreos[location_props.monitoreo].registros.entradas[location_props.registro].img[l],
									idUser: this.state.monitoreos[location_props.monitoreo].registros.iduser[location_props.registro]
								}
								for(const m in nombresVariableTotal){
									for(const n in nombresVariablesExistentes){
										if(nombresVariableTotal[m] === nombresVariablesExistentes[n] )
											datos[nombresVariableTotal[m]] = valoresTabla[n]
									}
								}
							}else{
								datos ={
									id:this.state.monitoreos[location_props.monitoreo].registros.entradas[location_props.registro].id[l],
									especies:especiesTabla[k],
									localizacion:localizacionesTabla[k],
									img: this.state.monitoreos[location_props.monitoreo].registros.entradas[location_props.registro].img[l],
									idUser: this.state.monitoreos[location_props.monitoreo].registros.iduser[location_props.registro]
								}
								for(const m in nombresVariableTotal){
									for(const n in nombresVariablesExistentes){
										if(nombresVariableTotal[m] === nombresVariablesExistentes[n] )
											datos[nombresVariableTotal[m]] = valoresTabla[n]
										else
											datos[nombresVariableTotal[m]] = null
									}
								}
							}
							dataset=datos
						}			
						datos={}
						nombreVariable=[]
						console.log(dataset)
						this.setState({dataset:dataset,location:location})
						return
					}
				}
			}
		}
			
	}
	initial_state=async()=>{
		await this.loadLocalizaciones();
		await this.viewdata()
		await this.GetEntrada()
	}
	// PETICIONES DE BOOTNES
	validarEspecie=async(body)=>{
		var baseurl = "https://api.restaured.net"
		var url = baseurl+'/restaured/especie'
		var data; var config;
		if(body._id===undefined){
			data = JSON.stringify([body])
			config = {
				method: 'post',
				url: url,
				headers: { 
					'Content-Type': 'application/json'
				},
				withCredentials:true,
				data: data
			};
		}else{
			data = JSON.stringify([body])
			config = {
				method: 'put',
				url: url,
				headers: { 
					'Content-Type': 'application/json'
				},
				withCredentials:true,
				data: data
			};
		}
		await axios(config)
		.then(response => {
			console.log(response)
			this.setState({ dataset:{ ...this.state.dataset, especies:{ ...this.state.dataset.especies, id: response.data.body[0]._id}}})
		})
		.catch( error => console.log(error))  
	}
	validarEntrada=async()=>{
		var monitoreos = this.state.monitoreos
		var variablesActualizar=[];	var variablesAgregar=[]
		for(const k in monitoreos[this.state.location.monitoreo].variables){
			for(const l in monitoreos[this.state.location.monitoreo].registros.entradas[this.state.location.registro].variables[this.state.location.entrada]){
				var variable;
				if(monitoreos[this.state.location.monitoreo].variables[k].id === 
				monitoreos[this.state.location.monitoreo].registros.entradas[this.state.location.registro].variables[this.state.location.entrada][l]){
					variable = {
						_id: monitoreos[this.state.location.monitoreo].registros.entradas[this.state.location.registro].idVariablesRegistro[this.state.location.entrada][l],
						// variable:monitoreos[this.state.location.monitoreo].variables[k].nombre,
						idvariable:monitoreos[this.state.location.monitoreo].variables[k].id,
						valor: Number(this.state.dataset[monitoreos[this.state.location.monitoreo].variables[k].nombre])
					}
					variablesActualizar.push(variable)
				}else{
					if(this.state.dataset[monitoreos[this.state.location.monitoreo].variables[k].nombre] !== 0){
						variable ={
							idEntrada : monitoreos[this.state.location.monitoreo].registros.entradas[this.state.location.registro].id[this.state.location.entrada],
							idVariable:monitoreos[this.state.location.monitoreo].variables[k].id,
							valor: Number(this.state.dataset[monitoreos[this.state.location.monitoreo].variables[k].nombre])
	
						}
						variablesAgregar.push(variable)
					}
				}
			}
		}
		var baseurl = "https://api.restaured.net"
		var url = baseurl+'/restaured/variableregistro'
		// ACTUALIZAR DATOS
		var data = JSON.stringify(variablesActualizar)
		var config = {
			method: 'put',
			url: url,
			headers: { 
			'Content-Type': 'application/json'
			},
			withCredentials:true,
			data: data
		};
		await axios(config)
		.then(response => console.log(response))
		// // AGREGAR NUEVOS DATOS
		var data2 = JSON.stringify(variablesAgregar)
		var config2={
		method: 'post',
		url: url,
		headers: { 
		'Content-Type': 'application/json'
		},
		withCredentials:true,
		data: data2
		}
		await axios(config2)
		.then(response => console.log(response))
		var body = {
			_id: this.state.dataset.id,
			idEspecie: this.state.dataset.especies.id,
			idLocalizacion: this.state.dataset.localizacion,
			curado:true,
		}
		url = baseurl+'/restaured/entradamonitoreo'
		var data3 = JSON.stringify([body])
		var config3 = {
			method: 'put',
			url: url,
			headers: { 
				'Content-Type': 'application/json'
			},
			withCredentials:true,
			data: data3
		};
		await axios(config3)
		.then(response => {
			console.log(response)
		})
		.catch( error => console.log(error))  
	}
	//ACCIONES DE BOTONES
	cancelar=async()=>{
		await this.setState({volver:true})
	}
	validarDatos=async()=>{
		var body={}
		for(const i in this.state.especie.id){
			if(this.state.especie.nombreCientifico[i] === this.state.dataset.especies.nombreCientifico){
				body = {
					_id:this.state.especie.id[i],
					idLocalizacion: this.state.dataset.especies.idLocalizacion,
					nombreCientifico: this.state.dataset.especies.nombreCientifico,
					nombreComun: this.state.dataset.especies.nombreComun,
				}				
			}
		}
		if(body._id === undefined){
			body = {
				idLocalizacion: this.state.dataset.especies.idLocalizacion,
				nombreCientifico: this.state.dataset.especies.nombreCientifico,
				nombreComun: this.state.dataset.especies.nombreComun,
			}
		}
		await this.validarEspecie(body)
		await this.validarEntrada()
		this.setState({volver : true})
	}
	// VISUALIZACION
	Especies=()=>{
		return(
		<>
			<input className="autoCompleteText form-control" name="nombreCientifico" value={this.state.dataset.especies.nombreCientifico} onChange={this.onChangedText} type="text"/>
			{this.renderSuggestions()}
		</>
		)
	}
	renderSuggestions=()=>{
		const suggestions = this.state.suggestions
		if(suggestions.length === 0){
			return null
		}
		return ( <ul className="autoCompleteText"> {suggestions.map((item,i)=> <li className="autoCompleteText" onClick={(e)=> this.suggestionsSelected(item,i)} onTouchStart={(e)=> this.suggestionsSelected(item,i)} > {item}</li>)}</ul>)
	}
	renderSelectLocalizacionEntrada=()=>{
		var localizacion=[]
		for(const i in this.state.datasetLocalizaciones.id){
			if(this.state.dataset.especies.idLocalizacion === this.state.datasetLocalizaciones.id[i]){
				localizacion.push( <option selected value={this.state.datasetLocalizaciones.id[i]}> 
				{this.state.datasetLocalizaciones.sitio[i].nombre + " "+
				(this.state.datasetLocalizaciones.transecto[i]? this.state.datasetLocalizaciones.transecto[i].nombre : " " ) +
				(this.state.datasetLocalizaciones.parcela[i]? this.state.datasetLocalizaciones.parcela[i].nombre : " " ) +
				(this.state.datasetLocalizaciones.PO[i]? this.state.datasetLocalizaciones.PO[i].nombre : " " )}</option> )
			}else{
				localizacion.push( <option value={this.state.datasetLocalizaciones.id[i]}> 
					{this.state.datasetLocalizaciones.sitio[i].nombre + " "+
					(this.state.datasetLocalizaciones.transecto[i]? this.state.datasetLocalizaciones.transecto[i].nombre : " " ) +
					(this.state.datasetLocalizaciones.parcela[i]? this.state.datasetLocalizaciones.parcela[i].nombre : " " ) +
					(this.state.datasetLocalizaciones.PO[i]? this.state.datasetLocalizaciones.PO[i].nombre : " " )}</option> )
			}
		}
		return(	<select className="form-control" onSelect={(event)=> this.handleSelectLocalizacion(event)}>
					{localizacion}
				</select>
				)
	}
	renderSelectLocalizacionEspecie=()=>{
		var localizacion=[]
		for(const i in this.state.datasetLocalizaciones.id){
			if(this.state.dataset.especies.idLocalizacion === this.state.datasetLocalizaciones.id[i]){
				localizacion.push( <option selected value={this.state.datasetLocalizaciones.id[i]}> 
				{this.state.datasetLocalizaciones.sitio[i].nombre + " "+
				(this.state.datasetLocalizaciones.transecto[i]? this.state.datasetLocalizaciones.transecto[i].nombre : " " ) +
				(this.state.datasetLocalizaciones.parcela[i]? this.state.datasetLocalizaciones.parcela[i].nombre : " " ) +
				(this.state.datasetLocalizaciones.PO[i]? this.state.datasetLocalizaciones.PO[i].nombre : " " )}</option> )
			}else{
				localizacion.push( <option value={this.state.datasetLocalizaciones.id[i]}> 
					{this.state.datasetLocalizaciones.sitio[i].nombre + " "+
					(this.state.datasetLocalizaciones.transecto[i]? this.state.datasetLocalizaciones.transecto[i].nombre : " " ) +
					(this.state.datasetLocalizaciones.parcela[i]? this.state.datasetLocalizaciones.parcela[i].nombre : " " ) +
					(this.state.datasetLocalizaciones.PO[i]? this.state.datasetLocalizaciones.PO[i].nombre : " " )}</option> )
			}
		}
		return(	<select className="form-control" onSelect={(event)=> this.handleSelectLocalizacionEspecie(event)}>
					{localizacion}
				</select>
				)
	}
	renderEntradaACurar=()=>{
		if(this.state.dataset !== undefined && this.state.location !== undefined){
			var location = this.state.location
			var entradashtml=[]
			entradashtml.push(<>
				<h2> Entrada </h2>
				<div className="row mt-5">
					<div className="col-lg-6">
						<p> <strong> Localizacion </strong></p>	
					</div>
					<div className="col-lg-6">
						{this.renderSelectLocalizacionEntrada()}
					</div>
				</div>		
			</>)
			for(const j in this.state.monitoreos[location.monitoreo].variables){
				entradashtml.push(<>
					<div className="row mt-3">
						<div className="col-lg-6">
							<p><strong>{this.state.monitoreos[location.monitoreo].variables[j].nombre}</strong></p>
						</div>
						<div className="col-lg-6">								
							<input className="form-control"
							value={this.state.dataset[this.state.monitoreos[location.monitoreo].variables[j].nombre]}
							name = {this.state.monitoreos[location.monitoreo].variables[j].nombre}
							onChange={this.onChange}
							/>
						</div>
					</div>
				</>)
			}				
			return (<div className="mt-5">{entradashtml}</div>)
		}else{
			return (<></>)
		}
	}
	renderEspecieACurar=()=>{
		if(this.state.dataset !== undefined && this.state.location !== undefined){
			var especiehtml=[]
			especiehtml.push(<>
				<h2> Especie </h2>
				<div className="row mt-5">
					<div className="col-lg-6">
					<p><strong> Nombre Cientifico: </strong></p>	
					</div>
					<div className="col-lg-6">
						{this.Especies()}
					</div>
				</div>
				<div className="row mt-3">
					<div className="col-lg-6">
					<p><strong> Familia: </strong></p>	
					</div>
					<div className="col-lg-6">
						<input className="form-control" disabled type="text" value={this.state.dataset.especies.family} name="familiy" />
					</div>
				</div>		
				<div className="row mt-3">
					<div className="col-lg-6">
					<p><strong> Género: </strong></p>	
					</div>
					<div className="col-lg-6">
						<input className="form-control" disabled type="text" value={this.state.dataset.especies.genus} name="genus" />
					</div>
				</div>		<div className="row mt-3">
					<div className="col-lg-6">
					<p><strong> Orden: </strong></p>	
					</div>
					<div className="col-lg-6">
						<input className="form-control" disabled type="text" value={this.state.dataset.especies.order} name="order" />
					</div>
				</div>		
				<div className="row mt-3">
					<div className="col-lg-6">
					<p><strong> Nombre Común: </strong></p>	
					</div>
					<div className="col-lg-6">
						<input className="form-control" type="text" value={this.state.dataset.especies.nombreComun} name="nombreComun" onChange={this.onChangeEspecie} />
					</div>
				</div>		
				<div className="row mt-3">
					<div className="col-lg-6">
						<p> <strong> Localizacion Especie: </strong></p>	
					</div>
					<div className="col-lg-6">
						{this.renderSelectLocalizacionEspecie()}
					</div>
				</div>		
			</>)		
			return (<div className="mt-5">{especiehtml}</div>)
		}else{
			return (<></>)
		}
	}
	renderImagen=()=>{
		if(this.state.dataset !== undefined && this.state.location !== undefined){
			var entradashtml=[]
			if(this.state.dataset.img){
				entradashtml.push(<>
				<div className="co-lg-12 mt-5">
					<h2> Imagen de la entrada</h2>
					<img style={{width:'100%'}} src={this.state.dataset.img}/>
				</div>
				</>)
			return (<>{entradashtml}</>)

			}else {
				return (<div className="co-lg-12 mt-5">
							<h2>No hay una imagen cargada para esta entrada</h2>
					</div>)				
			}
		}else{
			return (<></>)
		}
	}
	renderAuthor=()=>{
		if(this.state.dataset !== undefined && this.state.location !== undefined){
			var entradashtml=[]
			for(const i in this.state.users.id){
				if(this.state.users.id[i] === this.state.dataset.idUser){
					entradashtml.push(<>
						<div className="co-lg-12">
							<h4> Autor: {this.state.users.nombre[i]} </h4>
							<h4> Correo: {this.state.users.correo[i]} </h4>
						</div>
						</>)
					return (<>{entradashtml}</>)
				}
			}
			if(this.state.dataset.img){
				

			}else {
				return (<div className="co-lg-12 mt-5">
							<h2>No hay una imagen cargada para esta entrada</h2>
					</div>)				
			}
		}else{
			return (<></>)
		}
	}

    render(){
        return(
            <div>
			<div id="wrapper">
				<NavbarC/>
				<div id="content-wrapper" className="d-flex flex-column">
					<div id="content">
					<HeaderUsers Iduser={this.props.Iduser} Rol={this.props.Rol} Nombre={this.props.Nombre} External={this.props.External}/>
					{this.props.Nombre ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
					{this.props.Iduser ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
					{this.props.Rol ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
						<div className="container-fluid">							
							<h1 className="h1 mb-2 text-gray-800">Curación</h1>
							{ this.state.volver ? <Redirect push to={{ pathname:"/restaured.scie/curacion",state:this.props.location.state.monitoreo}}> </Redirect>: null }
							{this.renderAuthor()}
							<div className="row mt-5">
								<div className="col-lg-6">
									{this.renderEspecieACurar()}
									{this.renderEntradaACurar()}
								</div>
								<div className="col-lg-6">
									{this.renderImagen()}
								</div>
							</div>
							
							<div className="row mt-5">
								<br/>
								<div className="col-lg-6">
									<button onClick={(e) => this.cancelar(e)} className="btn btn-primary"> 
										<strong> Cancelar </strong>
									</button>
								</div>
								<br/>
								<br/>
								<br/>
								<div className="col-lg-6">
								<button  onClick={(e)=>this.validarDatos(e)} className="btn btn-primary"> 
									<strong> Validar datos </strong>
								</button>
								</div>
								<br/>
							</div>
						</div>
					</div>
					 <footer id="footer" className="sticky-footer">
						<div className="container my-auto">
							<div className="copyright text-center my-auto">
								© Copyright <strong><span>Restaured 2024</span></strong>. All Rights Reserved
							</div>
						</div>
					</footer>
				</div>
			</div>
			<a className="scroll-to-top rounded" href="#page-top">
			<i className="fas fa-angle-up" />
			</a>
			<Logout/>
		</div>
        )
    }
}