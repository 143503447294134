import React from 'react'
import '../../../assets/vendor/bootstrap/css/bootstrap.css'
import '../../../assets/css/sb-admin-2.css'
import NavbarA from '../../NavBar/NavbarAdmin'
import { Redirect} from 'react-router'
import HeaderUsers from './HeaderUsers'
import MapView from '../../Charts/MapView'
import Logout from './Logout'
import { BsFillEyeFill, BsFillEyeSlashFill,BsPencil, BsTrashFill } from "react-icons/bs";
import { useTable, usePagination, useRowSelect } from 'react-table'


const IndeterminateCheckbox = React.forwardRef(
		({ indeterminate, ...rest }, ref) => {
			const defaultRef = React.useRef()
			const resolvedRef = ref || defaultRef
	
			React.useEffect(() => {
				resolvedRef.current.indeterminate = indeterminate
			}, [resolvedRef, indeterminate])
	
			return (
				<>
					<input type="checkbox" ref={resolvedRef} {...rest} />
				</>
			)
		}
	)

function Table({ columns, data }) {
		// Use the state and functions returned from useTable to build your UI
		const {
			getTableProps,
			getTableBodyProps,
			headerGroups,
			prepareRow,
			page,
			canPreviousPage,
			canNextPage,
			pageOptions,
			pageCount,
			gotoPage,
			nextPage,
			previousPage,
			setPageSize,
			state: { selectedRowIds, pageIndex, pageSize },
		} = useTable(
		{
				columns,
				data,
				initialState: { pageIndex: 1, pageSize: 5 },
		},
			usePagination,
			useRowSelect,
			hooks => {
				hooks.visibleColumns.push(columns => [
					// Let's make a column for selection
					{
						id: 'selection',
						// The header can use the table's getToggleAllRowsSelectedProps method
						// to render a checkbox
						Header: ({ getToggleAllRowsSelectedProps }) => (
							<div>
								<IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
							</div>
						),
						// The cell can use the individual row's getToggleRowSelectedProps method
						// to the render a checkbox
						Cell: ({ row }) => (
							<div>
								<IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
							</div>
						),
					},
					...columns,
				])
			}
		)
	
		// Render the UI for your table
		return (
			<>
				<table className="table table-bordered" width="100%"id="dataTable" {...getTableProps()}>
					<thead>
						{headerGroups.map(headerGroup => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map(column => (
									<th {...column.getHeaderProps()}>{column.render('Header')}</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{page.slice(0, 10).map((row, i) => {
							prepareRow(row)
							return (
								<tr {...row.getRowProps()}>
									{row.cells.map(cell => {
										return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
									})}
								</tr>
							)
						})}
					</tbody>
				</table>
				<p>Selected Rows: {Object.keys(selectedRowIds).length}</p>
					<ul className="pagination">
						<li className="page-item" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
							<p className="page-link">First</p>
						</li>
						<li className="page-item" onClick={() => previousPage()} disabled={!canPreviousPage}>
							<p className="page-link">{'<'}</p>
						</li>
						<li className="page-item" onClick={() => nextPage()} disabled={!canNextPage}>
							<p className="page-link">{'>'}</p>
						</li>
						<li className="page-item" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
							<p className="page-link">Last</p>
						</li>
						<li>
							<p className="page-link">
								Page{' '}
								<strong>
									{pageIndex + 1} of {pageOptions.length}
								</strong>{' '}
							</p>
						</li>
						<li>
								<p className="page-link">
										<input
												className="form-control"
												type="number"
												defaultValue={pageIndex }
												onChange={e => {
														const page = e.target.value ? Number(e.target.value) - 1 : 0
														gotoPage(page)
												}}
												style={{ width: '100px', height: '20px' }}
										/>
								</p>
						</li>{' '}
						<select
								className="form-control"
								value={pageSize}
								onChange={e => {
										setPageSize(Number(e.target.value))
								}}
								style={{ width: '120px', height: '38px' }}
						>
								{[5, 10, 20, 30, 40, 50].map(pageSize => (
										<option key={pageSize} value={pageSize}>
												Show {pageSize}
										</option>
								))}
						</select>
					</ul>
			</>
		)
	}


function TableLocalizacion() {
		const columns = React.useMemo(
				() => [
								{
										Header: 'Nombre Localización',
										accessor: 'nombre',
								},
								{
										Header: 'Sitio',
										accessor: 'sitio',
								},
								{
										Header: 'Parcela',
										accessor: 'parcela',
								},
								{
										Header: 'Transpecto',
										accessor: 'transpecto',
								},
								{
										Header: 'Punto Observacion',
										accessor: 'punto',
								},
								{
										Header: 'Mapa',
										accessor: 'mapa',
								},
				],
				[]
		)

		const data = [
				{
						"nombre": "X",
						"sitio": "Barbas Bremen",     
						"parcela": "X",
						"transpecto": "X",
						"punto": "X",
						"mapa": "Ver Coordenadas",
				},
				{
						"nombre": "X",
						"sitio": "Lisbrán",     
						"parcela": "X",
						"transpecto": "X",
						"punto": "X",
						"mapa": "Ver Coordenadas",
				},
				{
						"nombre": "X",
						"sitio": "Yarima",     
						"parcela": "X",
						"transpecto": "X",
						"punto": "X",
						"mapa": "Ver Coordenadas",
				},
				
				
		
		]
		console.log(JSON.stringify(data));


		return (
				<Table columns={columns} data={data} />
		)
}

export default function ViewLUsersCU(){
	return (
		<div>
			<div>
				<div id="wrapper">
				<NavbarA/>
				{/* Content Wrapper */}
				<div id="content-wrapper" className="d-flex flex-column">
						{/* Main Content */}
						<div id="content">
						<HeaderUsers Iduser={this.props.Iduser} Rol={this.props.Rol} Nombre={this.props.Nombre} External={this.props.External}/>
					{this.props.Nombre ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
					{this.props.Iduser ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
					{this.props.Rol ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
						{/* Begin Page Content */}
						<div className="container-fluid">
								{/* Page Heading */}
								<h1 className="h3 mb-2 text-gray-800">Localizaciones</h1>
								{/* DataTales Example */}
								<div className="card shadow mb-4">
										
								<div className="card-header py-3">
										<h6 className="m-0 font-weight-bold text-primary">DataTables Example</h6>
								</div>
								<div className="card-body">
										<TableLocalizacion/>
										<div className="row">
												<div className="col-xl-12">
														<BsTrashFill type="button" className="btn-icon-split" />
														<BsFillEyeFill type="button" className="btn-icon-split"/>
														<BsFillEyeSlashFill type="button" className="btn-icon-split"/>
														<BsPencil type="button" className="btn-icon-split"/>
												</div>
										</div>
								</div>
								</div>
						</div>
						{/* /.container-fluid */}
						<div className="col-xl-12 col-lg-12">
								<div className="card shadow mb-4">
									{/* Card Header - Dropdown */}
									<div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
										<h6 className="m-0 font-weight-bold text-primary">Grafico de Reportes de Aves por zonas</h6>                            
									</div>
									{/* Card Body */}
									<div className="card-body" height={800}>
										<div id="mapid" >
										<MapView />
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* End of Main Content */}
						{/* Footer */}
						 <footer id="footer" className="sticky-footer">
						<div className="container my-auto">
								<div className="copyright text-center my-auto">
								© Copyright <strong><span>Restaured 2024</span></strong>. All Rights Reserved
								</div>
						</div>
						</footer>
						{/* End of Footer */}
				</div>
				{/* End of Content Wrapper */}
				</div>
				{/* Logout Modal*/}
				<Logout/>
			</div>
	</div>
	)};