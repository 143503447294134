import React,{Component} from 'react'
import NavbarC from '../../NavBar/NavbarUsersC'
import HeaderUsers from './HeaderUsers'
import '../../../assets/vendor/bootstrap/css/bootstrap.css'
import '../../../assets/css/sb-admin-2.css'
import {Link} from 'react-router-dom'
import TablaMonitoreos from './TablaMonitoreos'
import { Redirect } from 'react-router'
import Logout from './Logout'

class GenerarReporte extends Component {
  constructor(props){
    super(props);
    this.state={
        Rol: this.props.Rol,
        Token: this.props.Token,
        Iduser: this.props.Iduser,
    }
  }
  
  render(){

    return(
    <div>
          <div>
            <title>RestauRed Cientifico - Dashboard</title>
                <div id="wrapper">
                    <NavbarC/>
                    <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content">
                        <HeaderUsers Iduser={this.props.Iduser} Rol={this.props.Rol} Nombre={this.props.Nombre} External={this.props.External}/>
                        {this.props.Nombre ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
										{this.props.Iduser ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
										{this.props.Rol ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
                            <div className="container-fluid">
                            <h1><span><p> Administración de Monitoreos </p></span></h1>
                            
                            <h5><span><p> 
                            Visualiza, Edita o Agregar datos a cada monitoreo
                            </p></span></h5>
                            <TablaMonitoreos/>
                            <Logout/>
                            </div>
                        </div>
                    <footer id="footer" className="sticky-footer">
							<div className="container my-auto">
									<div className="copyright text-center my-auto">
											© Copyright <strong><span>Restaured 2024</span></strong>. All Rights Reserved
									</div>
							</div>
					</footer>
                    </div>
                </div>
            </div>
        </div>
    )
    
  }
}
export default GenerarReporte