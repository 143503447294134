import React,{ Component} from 'react'
import { Redirect } from 'react-router'
import '../../assets/vendor/bootstrap/css/bootstrap.css'
import '../../assets/css/sb-admin-2.css'
const axios = require('axios');

export default class VerificationAccount extends Component{
	constructor(props){
		super(props);
		this.state={
				Rol: this.props.Rol,
				Nombre: this.props.Nombre,
				Iduser: this.props.Iduser,
				External: this.props.External,
				open:false,
				volver:false,
				form:{
					password:"",
					email:"",
				},
		}
	}
	//HANDLEE
	onChange = (event ) => {
		this.setState({
			 form: {
					...this.state.form,
					[event.target.name]:event.target.value,
			 }
		});
	};
	//ACCIONES DE BOTONES
	Cancelar=async()=>{
		this.setState({volver : true})
	}
	Guardar=async()=>{
		var body = {
				token:this.props.match.params.token,
				password: this.state.form.password,
				email: this.state.form.email,
			}
		var baseurl = "https://api.restaured.net"
		var url = baseurl+'/auth/user/accountvalidator'
		var data = JSON.stringify([body])
		var config = {
			method: 'post',
			url: url,
			headers: { 
				'Content-Type': 'application/json'
			},
			withCredentials:true,
			data: data
		};
		await axios(config)
		.then(response => console.log(response))
		this.setState({volver : true})
	}
	// VISUALIZACION
	renderCambiarPasswordEdit=()=>{
		if(!this.state.External){
			var form=[]
			form.push(<>
				<div className="row">
					<div className="col-lg-6">
					<p><strong> Email: </strong></p>	
					</div>
					<div className="col-lg-4">
						<input className="form-control" type="email" value={this.state.form.email} name="email" onChange={this.onChange} />
					</div>
				</div>		
				<div className="row">
					<div className="col-lg-6">
					<p><strong> Nueva Contraseña: </strong></p>	
					</div>
					<div className="col-lg-4">
						<input className="form-control" type="password" value={this.state.form.password} name="password" onChange={this.onChange} />
					</div>
				</div>		
				
			</>)		
			return (<>{form}</>)
		}else{
			return (<></>)
		}
	}
    render(){
        return(
            <div>
			<div id="wrapper">
				<div id="content-wrapper" className="d-flex flex-column">
					<div id="content">
						<div className="container-fluid">							
							<h1 className="h1 mb-2 text-gray-800">Cambio de Contraseña</h1>
							{ this.state.volver ? <Redirect push to={{ pathname:'/login'}}> </Redirect>: null }
							{this.renderCambiarPasswordEdit()}
							<br/>
							<br/>
							<br/>
							<div className="row">
								<br/>
								<div className="col-lg-6">
									<button className="btn btn-primary" onClick={(e) =>this.Cancelar()}> 
										<strong> Cancelar </strong>
									</button>
								</div>
								<br/>
								<br/>
								<br/>
								<div className="col-lg-6">
								<button  onClick={(e)=> this.Guardar()} className="btn btn-primary"> 
									<strong> Guardar </strong>
								</button>
								</div>
								<br/>
							</div>
						</div>
					</div>
					 <footer id="footer" className="sticky-footer">
						<div className="container my-auto">
							<div className="copyright text-center my-auto">
								© Copyright <strong><span>Restaured 2024</span></strong>. All Rights Reserved
							</div>
						</div>
					</footer>
				</div>
			</div>
			<a className="scroll-to-top rounded" href="#page-top">
			<i className="fas fa-angle-up" />
			</a>
		</div>
        )
    }
}