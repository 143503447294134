import React from 'react'
import '../../assets/vendor/bootstrap/css/bootstrap.css'
import '../../assets/css/sb-admin-2.css'
import NavbarA from '../NavBar/NavbarAdmin'
import Header from './Header'
import Logout from './Logout'
import { Redirect } from 'react-router'
import {BsTrash} from "react-icons/bs"
import { useTable, usePagination, useRowSelect } from 'react-table'
import {Link} from 'react-router-dom'
const axios = require('axios');
const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef()
      const resolvedRef = ref || defaultRef
  
      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
      }, [resolvedRef, indeterminate])
  
      return (
        <>
          <input type="checkbox" ref={resolvedRef} {...rest} />
        </>
      )
    }
)
async function CambiarRol (selectedRowIds,users,value){
	var body=[]
	for(const i in selectedRowIds){
		body.push(
			{
				_id:users.id[i],
				rol:value

			}
		)
	}
	console.log(body)
	var baseurl = "https://api.restaured.net"
	var url = baseurl+'/auth/user'
	var data = JSON.stringify(body)
	var config = {
		method: 'put',
		url: url,
		headers: { 
		'Content-Type': 'application/json'
		},
		withCredentials:true,
		data: data
	};
	await axios(config)
	.then(response => console.log(response))
}
function DeleteUser(event,selectedRowIds,users,roles){
	var body=[]
	for(const i in selectedRowIds){
		body.push(
			{
				_id:users.id[i],
				enabled:false
			}
		)
	}
	var baseurl = "https://api.restaured.net"
	var url = baseurl+'/auth/user'
	var data = JSON.stringify(body)
	var config = {
		method: 'put',
		url: url,
		headers: { 
		'Content-Type': 'application/json'
		},
		withCredentials:true,
		data: data
	};
	console.log(config)
	axios(config)
	.then(response => {console.log(response);	})
}
function RenderCambiarRol({selectedRowIds,users,roles}){
	const onSelect = e =>{
		setValue(e.target.value)
	} 
	const [value, setValue] = React.useState(roles.id[0]) 
	return	 <div className="row">
				<div className="col-lg-1"/>
				<div className="col-lg-4">
					<p> Selecciona los usuarios y cambia el rol de todos ellos al que desees</p>
				</div>
				<select type="text" className="col-lg-3 form-control" value ={value} onChange={onSelect} >
					{roles.nombre.map((nombre,i) => <option value={roles.id[i]}> {nombre} </option>)}
				</select>
				<div className="col-lg-1"/>
				<div className="col-lg-3">
					<Link  to={{pathname:"/restaured.admin/reloadereditusers"}}>
						<button className="btn border"type="button"  onClick={(e) =>{CambiarRol(selectedRowIds,users,value)}}> Aplicar Cambios </button>
					</Link>
				</div>
			</div>
}
function RenderDeleteUser({selectedRowIds,users,roles}){
	return(
		<div className="row">
			<div className="col-lg-6">
				<p> Selecciona Usuarios para deshabilitarlos </p>
			</div>
			<div className="col-lg-6"> 
				<Link to={{pathname:"/restaured.admin/reloadereditusers"}}>
					<button type="button" className="btn border" onClick={(event) => DeleteUser(event,selectedRowIds,users,roles)}> <BsTrash/><span>Deshabilitar Usuarios </span></button>
				</Link>
			</div>
		</div>
	)
}
  function Table({ columns, data, updateMyData, skipPageReset,users,roles}) {
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex, pageSize, selectedRowIds },
	} = useTable(
		{
			columns,
			data,
			initialState:{pageSize:5},
			autoResetPage: !skipPageReset,
			updateMyData,
		},
		usePagination,
		useRowSelect,
		hooks => {
			hooks.visibleColumns.push(columns => [
				// Let's make a column for selection
				{
					id: 'selection',
					// The header can use the table's getToggleAllRowsSelectedProps method
					// to render a checkbox
					Header: ({ getToggleAllRowsSelectedProps }) => (
						<div>
							<IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
						</div>
					),
					// The cell can use the individual row's getToggleRowSelectedProps method
					// to the render a checkbox
					Cell: ({ row }) => (
						<div>
							<IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
						</div>
					),
				},
				...columns,
			])
		}
	)  
	// Render the UI for your table
	return (
		<>
			<table className="table table-h-scroll"{...getTableProps()}>
				<thead>
					{headerGroups.map(headerGroup => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map(column => (
								<th {...column.getHeaderProps()}>{column.render('Header')}</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{page.map((row, i) => {
						prepareRow(row)
						return (
							<tr {...row.getRowProps()}>
								{row.cells.map(cell => {
									return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
								})}
							</tr>
						)
					})}
				</tbody>
			</table>
			<div className="pagination">
				<button className="page-link" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
					{'<<'}
				</button>{' '}
				<button className="page-link" onClick={() => previousPage()} disabled={!canPreviousPage}>
					{'<'}
				</button>{' '}
				<button className="page-link" onClick={() => nextPage()} disabled={!canNextPage}>
					{'>'}
				</button>{' '}
				<button className="page-link" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
					{'>>'}
				</button>{' '}
				<span className="page-link">
					Page{' '}
					<strong>
						{pageIndex + 1} of {pageOptions.length}
					</strong>{' '}
				</span>
				<span className="page-link">
					| Go to page:{' '}
					<input
						type="number"
						defaultValue={pageIndex + 1}
						onChange={e => {
							const page = e.target.value ? Number(e.target.value) - 1 : 0
							gotoPage(page)
						}}
						style={{ width: '100px' }}
					/>
				</span>{' '}
				<select
					className="page-link"
					value={pageSize}
					onChange={e => {
						setPageSize(Number(e.target.value))
					}}
				>
					{[5, 10, 15, 20, 25].map(pageSize => (
						<option key={pageSize} value={pageSize}>
							Show {pageSize}
						</option>
					))}
				</select>
			</div>
	<br/>
	<br/>
	<div className="row" >
		<RenderCambiarRol selectedRowIds={selectedRowIds} users={users} roles={roles} />
	</div>
	<br/>
	<br/>
		<RenderDeleteUser selectedRowIds={selectedRowIds} users={users} roles={roles} />
	<br/>
	<br/>
	</>
	)
}
function TableUsers(dataset,columnas,users,roles) {
	const columns = React.useMemo(() => columnas,[])
    const [data, setData] = React.useState( dataset)
	const [originalData] = React.useState(data)
	const [skipPageReset, setSkipPageReset] = React.useState(false)
	const updateMyData = (rowIndex, columnId, value) => {
		// We also turn on the flag to not reset the page
		setSkipPageReset(true)
		setData(old =>
			old.map((row, index) => {
				if (index === rowIndex) {
					return {
						...old[rowIndex],
						[columnId]: value,
					}
				}
				return row
			})
		)
	}

	// After data chagnes, we turn the flag back off
	// so that if data actually changes when we're not
	// editing it, the page is reset
	React.useEffect(() => {
		setSkipPageReset(false)
	}, [data])

	// Let's add a data resetter/randomizer to help
	// illustrate that flow...
	const resetData = () => setData(originalData)
	return (
		<div className="table-responsive">
			<button className="btn border" onClick={resetData}><span><strong>Reestablecer Datos </strong></span></button>
			<Table
				columns={columns}
				data={data}
				updateMyData={updateMyData}
				skipPageReset={skipPageReset}
				users={users}
				roles={roles}
			/>
		</div>
	)
}
function ActivateUser(event,selectedRowIds,users,roles){
	var body=[]
	for(const i in selectedRowIds){
		body.push(
			{
				_id:users.id[i],
				enabled:true
			}
		)
	}
	var baseurl = "https://api.restaured.net"
	var url = baseurl+'/auth/user'
	var data = JSON.stringify(body)
	var config = {
		method: 'put',
		url: url,
		headers: { 
		'Content-Type': 'application/json'
		},
		withCredentials:true,
		data: data
	};
	console.log(config)
	axios(config)
	.then(response => {console.log(response);	})
}
function RenderActivateUser({selectedRowIds,users,roles}){
	return(
		<div className="row">
			<div className="col-lg-6">
				<p> Selecciona Usuarios para activarlos </p>
			</div>
			<div className="col-lg-6"> 
				<Link to={{pathname:"/restaured.admin/reloadereditusers"}}>
					<button type="button" className="btn border" onClick={(event) => ActivateUser(event,selectedRowIds,users,roles)}> <BsTrash/><span>Activar Usuarios </span></button>
				</Link>
			</div>
		</div>
	)
}
  function Table2({ columns, data, updateMyData, skipPageReset,users,roles}) {
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex, pageSize, selectedRowIds },
	} = useTable(
		{
			columns,
			data,
			initialState:{pageSize:5},
			autoResetPage: !skipPageReset,
			updateMyData,
		},
		usePagination,
		useRowSelect,
		hooks => {
			hooks.visibleColumns.push(columns => [
				// Let's make a column for selection
				{
					id: 'selection',
					// The header can use the table's getToggleAllRowsSelectedProps method
					// to render a checkbox
					Header: ({ getToggleAllRowsSelectedProps }) => (
						<div>
							<IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
						</div>
					),
					// The cell can use the individual row's getToggleRowSelectedProps method
					// to the render a checkbox
					Cell: ({ row }) => (
						<div>
							<IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
						</div>
					),
				},
				...columns,
			])
		}
	)  
	// Render the UI for your table
	return (
		<>
			<table className="table table-h-scroll"{...getTableProps()}>
				<thead>
					{headerGroups.map(headerGroup => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map(column => (
								<th {...column.getHeaderProps()}>{column.render('Header')}</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{page.map((row, i) => {
						prepareRow(row)
						return (
							<tr {...row.getRowProps()}>
								{row.cells.map(cell => {
									return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
								})}
							</tr>
						)
					})}
				</tbody>
			</table>
			<div className="pagination">
				<button className="page-link" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
					{'<<'}
				</button>{' '}
				<button className="page-link" onClick={() => previousPage()} disabled={!canPreviousPage}>
					{'<'}
				</button>{' '}
				<button className="page-link" onClick={() => nextPage()} disabled={!canNextPage}>
					{'>'}
				</button>{' '}
				<button className="page-link" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
					{'>>'}
				</button>{' '}
				<span className="page-link">
					Page{' '}
					<strong>
						{pageIndex + 1} of {pageOptions.length}
					</strong>{' '}
				</span>
				<span className="page-link">
					| Go to page:{' '}
					<input
						type="number"
						defaultValue={pageIndex + 1}
						onChange={e => {
							const page = e.target.value ? Number(e.target.value) - 1 : 0
							gotoPage(page)
						}}
						style={{ width: '100px' }}
					/>
				</span>{' '}
				<select
					className="page-link"
					value={pageSize}
					onChange={e => {
						setPageSize(Number(e.target.value))
					}}
				>
					{[5, 10, 15, 20, 25].map(pageSize => (
						<option key={pageSize} value={pageSize}>
							Show {pageSize}
						</option>
					))}
				</select>
			</div>
	<br/>
	<br/>
		<RenderActivateUser selectedRowIds={selectedRowIds} users={users} roles={roles} />
	<br/>
	<br/>
	</>
	)
}
function TableUsersDisabled(dataset,columnas,users,roles) {
	const columns = React.useMemo(() => columnas,[])
    const [data, setData] = React.useState( dataset)
	const [originalData] = React.useState(data)
	const [skipPageReset, setSkipPageReset] = React.useState(false)
	const updateMyData = (rowIndex, columnId, value) => {
		// We also turn on the flag to not reset the page
		setSkipPageReset(true)
		setData(old =>
			old.map((row, index) => {
				if (index === rowIndex) {
					return {
						...old[rowIndex],
						[columnId]: value,
					}
				}
				return row
			})
		)
	}

	// After data chagnes, we turn the flag back off
	// so that if data actually changes when we're not
	// editing it, the page is reset
	React.useEffect(() => {
		setSkipPageReset(false)
	}, [data])

	// Let's add a data resetter/randomizer to help
	// illustrate that flow...
	const resetData = () => setData(originalData)
	return (
		<div className="table-responsive">
			<button className="btn border" onClick={resetData}><span><strong>Reestablecer Datos </strong></span></button>
			<Table2
				columns={columns}
				data={data}
				updateMyData={updateMyData}
				skipPageReset={skipPageReset}
				users={users}
				roles={roles}
			/>
		</div>
	)
}
export default class EditUsers extends React.Component {
	
	constructor(props) {
		super(props)
		this.state= {
			Rol: this.props.Rol,
			Nombre: this.props.Nombre,
			Iduser: this.props.Iduser,
			users: {
				id:[],
				email:[],
				nombre:[],
				rol:[],
			},
			usersDisabled: {
				id:[],
				email:[],
				nombre:[],
				rol:[],
			},
			roles:{
				id:[],
				nombre:[],
			},
			changeRol:{
				id:[],
				idUser:[],
				idRol:[],
				idChangeRol:[],
			}
		}
		this.initial_state()
	}
	getData=async()=>{
		var baseurl = "https://api.restaured.net"
		var url = baseurl+'/auth/user'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			console.log(data)
			var id =[]; var email=[]; var nombre=[]; var rol=[];
			for(const i in data.body){
				id.push(data.body[i]._id)
				email.push(data.body[i].email)
				nombre.push(data.body[i].nombre)
				rol.push(data.body[i].rol)
			};
			this.setState({
				users:{
					id:id,
					email:email,
					nombre:nombre,
					rol:rol,
				}});
		})
		url = baseurl+'/auth/user/0'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			console.log(data)
			var id =[]; var email=[]; var nombre=[]; var rol=[];
			for(const i in data.body){
				id.push(data.body[i]._id)
				email.push(data.body[i].email)
				nombre.push(data.body[i].nombre)
				rol.push(data.body[i].rol)
			};
			this.setState({
				usersDisabled:{
					id:id,
					email:email,
					nombre:nombre,
					rol:rol,
				}});
		})
		url = baseurl+'/auth/rol'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			console.log(data)
			var id =[]; var nombre=[];
			for(const i in data.body){
				id.push(data.body[i]._id)
				nombre.push(data.body[i].name)
			};
			this.setState({
				roles:{
					id:id,
					nombre:nombre,
				}});
		})
		url = baseurl+'/auth/changerol'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			console.log(data)
			var id =[]; var idUser=[]; var idRol =[]; var idChangeRol=[];
 			for(const i in data.body){
				id.push(data.body[i]._id)
				idUser.push(data.body[i].idUser)
				idRol.push(data.body[i].idRol)
				idChangeRol.push(data.body[i].idRolChange)
			};
			this.setState({
				changeRol:{
					id:id,
					idUser:idUser,
					idRol:idRol,
					idChangeRol:idChangeRol,
				}});
		})
	}
	initial_state=async()=>{
		const sleep = (milliseconds) => {
			return new Promise(resolve => setTimeout(resolve, milliseconds))
		  }
		await sleep(2000)
		await this.getData()
	}
	TablaUsuarios=()=> {
		var data = []
		if(this.state.roles.id.length > 0){
			for(const i in this.state.users.id){
				var rol ="";
				for(const j in this.state.roles.id){
					if (this.state.users.rol[i] === this.state.roles.id[j])
						rol = this.state.roles.nombre[j]
				}
				data.push({
					id:this.state.users.id[i],
					correo:this.state.users.email[i],
					nombre:this.state.users.nombre[i],
					rol:rol,
				})
			}
			var columnas = [
				{
				  Header: 'Usuarios',
				  columns: [
					{
					  Header: 'Nombre',
					  accessor: 'nombre',
					},
					{
						Header: 'Correo',
						accessor: 'correo',
					},
					{
						Header: 'Rol',
						accessor: 'rol',
					},
				  ],
				},
			]
			console.log(data)
			var dataset = data
			var table = TableUsers(dataset, columnas,this.state.users,this.state.roles)
			return table
		}else{
			return <></>
		}
	}
	TablaUsuariosDesactivados=()=> {
		var data = []
		if(this.state.usersDisabled.id.length > 0 && this.state.roles.id.length>0){
			console.log(this.state.usersDisabled)
			for(const i in this.state.usersDisabled.id){
				var rol ="";
				for(const j in this.state.roles.id){
					if (this.state.usersDisabled.rol[i] === this.state.roles.id[j])
						rol = this.state.roles.nombre[j]
				}
				data.push({
					id:this.state.usersDisabled.id[i],
					correo:this.state.usersDisabled.email[i],
					nombre:this.state.usersDisabled.nombre[i],
					rol:rol,
				})
			}
			var columnas = [
				{
				  Header: 'Usuarios',
				  columns: [
					{
					  Header: 'Nombre',
					  accessor: 'nombre',
					},
					{
						Header: 'Correo',
						accessor: 'correo',
					},
					{
						Header: 'Rol',
						accessor: 'rol',
					},
				  ],
				},
			]
			console.log(data)
			var dataset = data
			var table = TableUsersDisabled(dataset, columnas,this.state.usersDisabled,this.state.roles)
			return table
		}else{
			return (<h1> No Hay Usuarios Deshabilitados </h1>)
		}
	}
	negarSolicitud=(idSolicitud)=>{
		var body = [ {_id:idSolicitud}]
		var baseurl = "https://api.restaured.net"
		var url = baseurl+'/auth/changerol'
		var data = JSON.stringify(body)
		var config = {
			method: 'delete',
			url: url,
			headers: { 
			'Content-Type': 'application/json'
			},
			withCredentials:true,
			data: data
		};
		axios(config)
		.then(response => {console.log(response);	})
	}
	aceptarSolicitud=(idSolicitud, iduser,idchangerol)=>{
		var body = [ {_id:idSolicitud}]
		var baseurl = "https://api.restaured.net"
		var url = baseurl+'/auth/changerol'
		var data2 = JSON.stringify(body)
		var config = {
			method: 'delete',
			url: url,
			headers: { 
			'Content-Type': 'application/json'
			},
			withCredentials:true,
			data: data2
		};
		axios(config)
		.then(response => {console.log(response);	})
		console.log(idchangerol)
		var body2 = [ {_id: iduser, rol:idchangerol }]
		url = baseurl+'/auth/user'
		var data3 = JSON.stringify(body2)
		var config2 = {
			method: 'put',
			url: url,
			headers: { 
			'Content-Type': 'application/json'
			},
			withCredentials:true,
			data: data3
		};
		axios(config2)
		.then(response => console.log(response))

	}
	renderSolicitudes=()=>{
		console.log(this.state.changeRol)
		if(this.state.changeRol.id.length > 0){
			var html= []
			for(const i in this.state.changeRol.id){
				var rolName; var userName;
				console.log(this.state.changeRol)
				console.log(this.state.roles)
				for(const j in this.state.roles.id){
					if(this.state.changeRol.idChangeRol[i] === this.state.roles.id[j])
						rolName = this.state.roles.nombre[j]
				}
				for(const j in this.state.users.id){
					if(this.state.changeRol.idUser[i] === this.state.users.id[j])
						userName = this.state.users.nombre[j]
				}
				console.log(userName,rolName)
				html.push(
				<div className="row border"> 
				<div className="col-lg-3"> 
					Usuario : {userName}
				</div>
				<div className="col-lg-3"> 
					Solicitud : {rolName}
				</div>
				<div className="col-lg-3"> 
					<Link to={{pathname:"/restaured.admin/reloadereditusers"}}> 
						<button className="btn border bg-red" onClick={(e)=> this.negarSolicitud(this.state.changeRol.id[i])}> Negar </button>
					</Link>
				</div>
				<div className="col-lg-3">
					<Link to={{pathname:"/restaured.admin/reloadereditusers"}}>
					<button className="btn border bg-red" onClick={(e)=> this.aceptarSolicitud(this.state.changeRol.id[i],this.state.changeRol.idUser[i], this.state.changeRol.idChangeRol[i])}> Aceptar </button>
					</Link>
				</div>
				</div>)
			}
			return html
		}else{
			return <h2> No hay solicitudes</h2>
		}
	}

	render(){
		return (
		<div>
		<div>
			<div id="wrapper">
				<NavbarA/>
			{/* Content Wrapper */}
			<div id="content-wrapper" className="d-flex flex-column">
				{/* Main Content */}
				<div id="content">
				<Header Iduser={this.props.Iduser} Rol={this.props.Rol} Nombre={this.props.Nombre}/>
				{this.props.Nombre ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
				{this.props.Iduser ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
				{this.props.Rol ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
				{/* Begin Page Content */}
				<div className="container-fluid">
					<div className="card shadow mb-4">
						<div className="card-header py-3">
							<h6 className="m-0 font-weight-bold text-primary">Solicitudes de Cambio de permisos</h6>
							<p> Acepta o Niega las soliciudes hechas por los usuarios para modificar su rol</p> 
						</div>
						<div className="card-body">
							{this.renderSolicitudes()}
							<div className="table">
							</div>
						</div>
					</div>
					<div className="card shadow mb-4">
						<div className="card-header py-3">
							<h6 className="m-0 font-weight-bold text-primary">Gestión de Usuarios</h6>
							<p>Puedes cambiar los permisos, o eliminar usuarios</p> 
						</div>
						<div className="card-body">
							<this.TablaUsuarios/>
						</div>
					</div>
					<div className="card shadow mb-4">
						<div className="card-header py-3">
							<p>Puedes activar nuevamente usuarios deshabililtados</p> 
						</div>
						<div className="card-body">
							<this.TablaUsuariosDesactivados/>
						</div>
					</div>
				</div>
				{/* /.container-fluid */}
				</div>
				{/* End of Main Content */}
				{/* Footer */}
				 <footer id="footer" className="sticky-footer">
				<div className="container my-auto">
					<div className="copyright text-center my-auto">
					© Copyright <strong><span>Restaured 2024</span></strong>. All Rights Reserved
					</div>
				</div>
				</footer>
				{/* End of Footer */}
			</div>
			{/* End of Content Wrapper */}
			</div>
			{/* End of Page Wrapper */}
			{/* Scroll to Top Button*/}
			<a className="scroll-to-top rounded" href="#page-top">
			<i className="fas fa-angle-up" />
			</a>
			<Logout/>
			{/* Bootstrap core JavaScript*/}
			{/* Core plugin JavaScript*/}
			{/* Custom scripts for all pages*/}
			{/* Page level plugins */}
			{/* Page level custom scripts */}
		</div>
	
		</div>
	)};
  }
    