import React, { Component } from 'react'
import '../assets/vendor/icofont/icofont.min.css'
import '../assets/vendor/boxicons/css/boxicons.min.css'
import '../assets/vendor/venobox/venobox.css'
import '../assets/vendor/owl.carousel/assets/owl.carousel.min.css'
import '../assets/vendor/bootstrap/css/bootstrap.css'
import '../assets/css/style.css'
import GoogleLogin from 'react-google-login';
import NavbarL from './NavBar/NavbarLanding'
import {Link} from 'react-router-dom'
const axios = require('axios');



class Login extends Component{
    constructor(props){
        super(props)
        this.state={
            redirect:"",
            login:{
                email:"",
                password:"",
            },
            register:{
                _id:"",
                email:"",
                password:"",                
            },
            responseLogin:{}
        }

    }

    onChange =(event)=>{
        this.setState({
            login: {
            ...this.state.login,
            [event.target.name]:event.target.value,
        }
    })
}
    directLogin=async ()=>{
        var baseurl = "https://api.restaured.net"
        const url = baseurl+'/auth/user/login'
        await axios.post(url,{},{
            withCredentials: true,
            auth:{
                username: this.state.login.email,
                password: this.state.login.password
            }
        })
        .then(response => {
            console.log(response)
            this.setState({
                redirect: response.data.body.userData.rolName})
            localStorage.setItem('currentSesion', JSON.stringify({currentSesion: {
                Nombre: response.data.body.userData.nombre,
                Rol: response.data.body.userData.rolName,
                Iduser: response.data.body.userData._id,
                External: response.data.body.userData.external,
                }}))
            this.props.setRol(response.data.body.userData.rolName)
            this.props.setNombre(response.data.body.userData.nombre)
            this.props.setIduser(response.data.body.userData._id)
            this.props.setExternal(response.data.body.userData.external)
        })
    }
    responseGoogleFail=async(response)=>{
        alert('Ha ocurrido un error, \n Inténtelo de nuevo')
    }
    responseGoogleSuccess=async(response)=>{
        // console.log(response.profileObj)
        var data = JSON.stringify([
            {
            "nombre":response.profileObj.givenName + " "+ response.profileObj.familyName,
            "email":response.profileObj.email,
            "password":response.profileObj.googleId,
            "external": true}
        ]);
        var baseurl = "https://api.restaured.net"
        const url = baseurl+'/auth/user'
        var config = {
            method: 'post',
            url: url,
            headers: { 
              'Content-Type': 'application/json'
            },
            withCredentials:true,
            data: data
          };
        try{
            await axios(config)
            .then(register => {
                if(register.status === 200){
                    axios.post(url+"/login",{},{
                    withCredentials: true,
                    auth:{
                        username: register.body.email,
                        password: response.profileObj.googleId
                    }
                    })
                    .then(login => {
                        this.setState({
                            redirect: login.data.body.userData.rolName})
                        console.log(login.data.body.userData)
                        localStorage.setItem('currentSesion', JSON.stringify({currentSesion: {
                            Nombre: login.data.body.userData.nombre,
                            Rol: login.data.body.userData.rolName,
                            Iduser: login.data.body.userData._id,
                            External: login.data.body.userData.external,
                            }}))
                        this.props.setRol(login.data.body.userData.rolName)
                        this.props.setNombre(login.data.body.userData.nombre)
                        this.props.setIduser(login.data.body.userData._id)
                        this.props.setExternal(login.data.body.userData.external)
                    })
                }
            })

        }
        catch(error){
            // console.log(error)
            await axios.post(url+"/login",{},{
            withCredentials: true,
            auth:{
                username: response.profileObj.email,
                password: response.profileObj.googleId
            }
            })
            .then(login => {
                this.setState({
                    redirect: login.data.body.userData.rolName})
                console.log(login.data.body.userData)
                localStorage.setItem('currentSesion', JSON.stringify({currentSesion: {
                    Nombre: login.data.body.userData.nombre,
                    Rol: login.data.body.userData.rolName,
                    Iduser: login.data.body.userData._id,
                    External: login.data.body.userData.external,
                    }}))
                this.props.setRol(login.data.body.userData.rolName)
                this.props.setNombre(login.data.body.userData.nombre)
                this.props.setIduser(login.data.body.userData._id)
                this.props.setExternal(login.data.body.userData.external)

                // window.location.href = login.data.body.userData.rolName;
            })
        }
    }

    render(){
        return(
            <div> 
              <div>
                <NavbarL/>
                {/* ======= Header ======= */}
                <main id="main">
                <div className="container">
                    <div className="row">
                    <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                        <div className="card card-signin my-5">
                        <div className="card-body">
                            <h5 className="card-title text-center">Iniciar Sesión</h5>
                            <form className="form-signin mt-3 mb-3">
                            <div className="form-label-group">
                                <input type="email" name="email" id="inputEmail" onChange={this.onChange} className="form-control" placeholder="Correo Electrónico" required autofocus />
                                <label htmlFor="inputEmail">Correo Electrónico</label>
                            </div>
                            <div className="form-label-group">
                                <input type="password" name="password" id="inputPassword" onChange={this.onChange} className="form-control" placeholder="Contraseña" required />
                                <label htmlFor="inputPassword">Contraseña</label>
                            </div>
                            {/*}
                            <Link  className="btn-block" to="/register"> 
                                <p style={{'font-size':'1em'}}> ¿Aún no tienes cuenta? Regístrate </p>
                            </Link>
        */}
                            {/*<Link className="btn-block" to="/recoverPassword"> 
                                <p style={{'font-size':'1em'}}> ¿Olvidaste tu contraseña? </p>
                            </Link>*/}
                            <Link > 
                                <button onClick={this.directLogin} onEnter={this.directLogin} className="btn btn-lg btn-primary btn-block text-uppercase" >Iniciar Sesión</button>
                            </Link>
                            {/*
                                <GoogleLogin className="btn btn-lg btn-google btn-block text-uppercase center mt-3" 
                                clientId="832803189322-ju4cf1jkjgafbchbimp3h2l9uoj6h86s.apps.googleusercontent.com"
                                buttonText="Iniciar Sesión con  Google"
                                onSuccess={this.responseGoogleSuccess}
                                onFailure={()=> this.responseGoogleFail()}
                                cookiePolicy={'single_host_origin'}/>   
        */} 
                            </form>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </main>
                {/* End #main */}
                <style dangerouslySetInnerHTML={{__html: "\n      :root {\n  --input-padding-x: 1.5rem;\n  --input-padding-y: .75rem;\n}\n\nbody {\n  background: #1cc88a;\n  background: linear-gradient(to right, #1cc88a, #67b0d1);" }} />
            </div>
            </div>
        );
    }
}

export default  Login