import React from 'react'
import '../../assets/vendor/bootstrap/css/bootstrap.css'
import '../../assets/css/sb-admin-2.css'
import NavbarA from '../NavBar/NavbarAdmin'
import { Redirect } from 'react-router'
import Header from './Header'
import Logout from './Logout'
const axios = require('axios')

export default class CreateUsers extends React.Component{
	constructor(props){
		super(props)
		this.state={
			Rol: this.props.Rol,
			Nombre: this.props.Nombre,
			Iduser: this.props.Iduser,
			volver:false,
			kmlfile:[],
			form:{
				nombre:"",
				email:"",
				password:"",
				rol:"",
			},
			users: {
				id:[],
				email:[],
				nombre:[],
				rol:[],
			},
			roles:{
				id:[],
				nombre:[],
			},
			changeRol:{
				id:[],
				idUser:[],
				idRol:[],
				idChangeRol:[],
			},
			value:0,
		}
		this.initial_state()
	}
	getData=async()=>{
		var baseurl = "https://api.restaured.net"
		var url = baseurl+'/auth/user'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			console.log(data)
			var id =[]; var email=[]; var nombre=[]; var rol=[];
			for(const i in data.body){
				id.push(data.body[i]._id)
				email.push(data.body[i].email)
				nombre.push(data.body[i].nombre)
				rol.push(data.body[i].rol)
			};
			this.setState({
				users:{
					id:id,
					email:email,
					nombre:nombre,
					rol:rol,
				}});
		})
		url = baseurl+'/auth/rol'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			console.log(data)
			var id =[]; var nombre=[];
			for(const i in data.body){
				id.push(data.body[i]._id)
				nombre.push(data.body[i].name)
			};
			this.setState({
				roles:{
					id:id,
					nombre:nombre,
				}});
		})
		url = baseurl+'/auth/changerol'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			console.log(data)
			var id =[]; var idUser=[]; var idRol =[]; var idChangeRol=[];
 			for(const i in data.body){
				id.push(data.body[i]._id)
				idUser.push(data.body[i].idUser)
				idRol.push(data.body[i].idRol)
				idChangeRol.push(data.body[i].idRolChange)
			};
			this.setState({
				changeRol:{
					id:id,
					idUser:idUser,
					idRol:idRol,
					idChangeRol:idChangeRol,
				}});
		})
	}
	initial_state=async()=>{
		const sleep = (milliseconds) => {
			return new Promise(resolve => setTimeout(resolve, milliseconds))
		  }
		await sleep(2000)
		await this.getData()
	}
	crearUsuario=async()=>{
		if(this.state.form.rol === '')
		await this.setState({form:{ ...this.state.form, rol:this.state.roles.id[0] }})
		var data = JSON.stringify([
            {"nombre":this.state.form.nombre,
			"email":this.state.form.email,
			"rol":this.state.form.rol}
        ]);
        var baseurl = "https://api.restaured.net"
        const url = baseurl+'/auth/user/admin'
        var config = {
            method: 'post',
            url: url,
            headers: { 
              'Content-Type': 'application/json'
            },
            withCredentials:true,
            data: data
		};
		await axios(config)
		.then(response =>console.log(response))
	}
	onChange=e => {
		this.setState({
			form:{
				...this.state.form,
				[e.target.name]:e.target.value
			}
		})
	}	
	RenderCambiarRol=()=>{
		const onSelect = e =>{
			this.setState({value:e.target.value, form:{ ...this.state.form, rol:e.target.value}})
		}
		return	 <div className="row pb-5">
					<div className="col-lg-3 ml-5">
						<p> <strong> Rol : </strong></p>
					</div>
					<select type="text" className="col-lg-4 form-control ml-4" value ={this.state.value} onChange={onSelect} >
						{this.state.roles.nombre.map((nombre,i) => <option value={this.state.roles.id[i]}> {nombre} </option>)}
					</select>
					<div className="col-lg-1"/>
				</div>
	}
	render(){
		return (
		  <div>
			<div id="wrapper">
			<NavbarA/>        
				<div id="content-wrapper" className="d-flex flex-column">
					<div id="content">
					<Header Iduser={this.props.Iduser} Rol={this.props.Rol} Nombre={this.props.Nombre}/>
					{this.props.Nombre ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
					{this.props.Iduser ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
					{this.props.Rol ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
						<div className="container-fluid">							
							<h1 className="h1 mb-5 text-gray-800">Creación de Usuarios</h1>
							{/* { this.state.volver ? <Redirect push to={{ pathname:"/restaured.scie/curacion",state:this.props.location.state.monitoreo}}> </Redirect>: null } */}
							<div > 
								<div className="row">
									<div className="col-lg-6">
										<div className="card shadow pb-5 pt-5 ">
											<div className="row pb-4">
												<div className="col-lg-3 ml-5">
													<p><strong> Nombre :</strong></p>	
												</div>
												<div className="col-lg-4 ml-5">
													<input className="form-control" type="text" value={this.state.form.nombre} name="nombre" onChange={this.onChange} />
												</div>
											</div>
											<div className="row pb-4">
												<div className="col-lg-3 ml-5">
												<p><strong>Email :</strong></p>	
												</div>
												<div className="col-lg-4 ml-5">
													<input className="form-control" type="email" value={this.state.form.email} name="email" onChange={this.onChange} />
												</div>
											</div>
											{this.RenderCambiarRol()}	
										</div>
									</div>
									<div className="col-lg-6 card shadow mb-5">
										<h2 className="title pt-4 pb-3"> ¿Cómo funciona? </h2>
										<ol className="pl-5 pr-5 pb-3"> 
											<li className="mb-3" >Agrega los datos de la persona que va a registrar y selecciona su rol respectivo </li>
											<li className="mb-3">Oprime el botón enviar y notifica al usuario para que revise su correo  </li>
											<li className="mb-3">Verifica que el usuario haya quedado agregado en la tabla de usuarios registrados </li>
										</ol>
									</div>
								</div>								
							</div>
							<div className="row mt-2 mb-5 pb-5">
								<div className="col-lg-12">
								<button  onClick={this.crearUsuario} className="btn btn-primary"> 
									<strong> Enviar </strong>
								</button>
								</div>
							</div>
						</div>
					</div>
					<footer id="footer" className=" pb-5">
						<div className="container footer-top">
							<div className="copyright">
								© Copyright <strong><span>Restaured 2024</span></strong>. All Rights Reserved
							</div>
						</div>
					</footer>
				</div>
			</div>
			<a className="scroll-to-top rounded" href="#page-top">
			<i className="fas fa-angle-up" />
			</a>
			<Logout/>
		  </div>)
  
	  }
}
