import React, { Component } from 'react'
import '../../../assets/vendor/bootstrap/css/bootstrap.css'
import '../../../assets/css/sb-admin-2.css'
import NavbarUC from '../../NavBar/NavbarUsersCC'
import { Redirect} from 'react-router'
import HeaderUsers from './HeaderUsers'
import {Link} from 'react-router-dom'
import TablaMonitoreos from './TablaMonitoreos'
import Logout from './Logout'


class HomeUsersC extends Component {
  constructor(props){
    super(props);
    this.state={
        Rol: this.props.Rol,
        Nombre: this.props.Nombre,
        Iduser: this.props.Iduser,
        piechartdata: [],
        open:false,
        especies:[],
    }
  }

    render(){
      return (
        <div>
          <div>
            <title>RestauRed Cientifico Ciudadano - Dashboard</title>
            {/* Page Wrapper */}
            <div id="wrapper">
              {/* Content Wrapper */}
              <NavbarUC/>
              <div id="content-wrapper" className="d-flex flex-column">
                {/* Main Content */}
                <div id="content">
                <HeaderUsers Iduser={this.props.Iduser} Rol={this.props.Rol} Nombre={this.props.Nombre} External={this.props.External}/>
                {this.props.Nombre ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
						{this.props.Iduser ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
						{this.props.Rol ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
                  {/* Begin Page Content */}
                  <div className="container-fluid">
                {/* End Cta Section */}  
                {/* ======= Services Section ======= */}
                <section id="services" className="services"> 
                  <div className="container">
                    <div className="section-title" >
                      <h2>Monitoreos</h2>
                      <h4 className="title mb-5 mt-5">
                      <Link to='/restaured.CzScie/reportesusers'> 
                        <p className=' '>Ver Monitoreos</p>
                        </Link>
                      </h4>
                      <TablaMonitoreos/>
                  </div>
                  </div>
                </section>
                  </div>
                  {/* /.container-fluid */}
                </div>
                {/* End of Main Content */}
                {/* Footer */}
                 <footer id="footer" className="sticky-footer">
                  <div className="container my-auto">
                    <div className="copyright text-center my-auto">
                      © Copyright <strong><span>Restaured 2024</span></strong>. All Rights Reserved
                    </div>
                  </div>
                </footer>
                {/* End of Footer */}
              </div>
              {/* End of Content Wrapper */}
            </div>
            {/* Logout Modal*/}
            <Logout/>
          </div>
        </div>
  )}
};
export default HomeUsersC
