import React from 'react'
import '../../assets/vendor/icofont/icofont.min.css'
import '../../assets/vendor/boxicons/css/boxicons.min.css'
import '../../assets/vendor/venobox/venobox.css'
import '../../assets/vendor/owl.carousel/assets/owl.carousel.min.css'
import '../../assets/vendor/bootstrap/css/bootstrap.css'
import '../../assets/css/style.css'
import HeaderUsers from './Header'
import NavbarA from '../NavBar/NavbarAdmin'
import {Collapse} from 'react-bootstrap'
import { BsPlusCircle } from "react-icons/bs"
import { Redirect } from 'react-router'
import {Link} from 'react-router-dom'
import Logout from './Logout'
import TablaMonitoreos from './TablaMonitoreos'
const axios = require('axios');


export default class Reportes extends React.Component{
  constructor(props) {
		super(props)
		this.state= {
			Rol: this.props.Rol,
			Nombre: this.props.Nombre,
			Iduser: this.props.Iduser,
			open:false,
			openAgregarVariables:false,
			variablesAgregar:[],
			form:{
				nombre:"",
				descripcion:"",
			}
		}
	}

	setOpen=()=>{
		this.setState({open:!this.state.open})
	}
	cancelarAgregar=()=>{
		this.setState({open:false , form:{ nombre:"", descripcion:""}})
	}
	onChange=(e)=>{
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]:e.target.value
			}
		})
	}
	onChangeFiles=async(File)=>{
		this.setState({imgEntrada:File[0]})
	}
	agregarImagen=async(id)=>{
		const form = new FormData()
		form.append('img', this.state.imgEntrada)
		form.append('name', this.props.Nombre)
		var baseurl = "https://api.restaured.net"
		var url = baseurl+'/restaured/monitoreo/'+id+'/upload'
		var config = {
			method: 'post',
			url: url,
			headers: { 
				'Content-Type': 'application/json'
			},
			withCredentials:true,
			data: form
		};
		await axios(config)
		.then(response => {
			console.log(response)
		})
		.catch( error => console.log(error))  
	}
	confirmarAgregar=async()=>{
		console.log(this.state.form)
		var baseurl = "https://api.restaured.net"
		var url = baseurl+'/restaured/monitoreo'
		var body = { nombre: this.state.form.nombre, descripcion:this.state.form.descripcion }
		var data = JSON.stringify([body])
		var config = {
		method: 'post',
		url: url,
		headers: { 
			'Content-Type': 'application/json'
		},
		withCredentials:true,
		data: data
		};
		console.log(config);
		await axios(config)
		.then(response =>response.data)
		.then(async data => {
			var id = data.body[0]._id
			await this.agregarImagen(id)
		})
	}
	renderCrearMonitoreo=()=> {
		return(
		<>
		<h1><span><p> Administracion de Monitoreos </p></span></h1>
		<h5><span><p> 
		<div className="row">
		<div className="col-xl-12">
			<p  onClick={this.setOpen} aria-controls="collapse-new-entry" 
			aria-expanded={this.state.open} className="cta-btn" type="button" >
			<BsPlusCircle/> <dr/> Nuevo Monitoreo</p>
			<Collapse in={this.state.open}>
				<div id="collapse-new-entry">
					<div className="row">
						<p className="col-lg-4"> Nombre</p>
						<input type="text" className="form-control col-lg-4" name="nombre" onChange={(event)=> this.onChange(event)}/>
					</div>
					<div className="row">
						<p className="col-lg-4"> Descripcion </p>
						<input type="text"className="form-control col-lg-4"  name="descripcion" onChange={(event)=> this.onChange(event)}/> 
					</div>
					<div className="row">
						<p className="col-lg-4"> Imagen</p>
						<input type="file"className="form-control col-lg-4"  accept='.png,.jpg,.jpeg,.webp' name="imagen" onChange={(e)=> this.onChangeFiles(e.target.files)}/> 
					</div>
					<div className="row">
						<div className="col-lg-6">
							<button className="btn-primary" type="submit" onClick={this.cancelarAgregar}> Cancelar</button>
						</div>
						<div className="col-lg-6">
							<Link to={{pathname:"/restaured.admin/reloadermonitoreo"}}> 
								<button className="btn-primary" type="submit" onClick={(e)=>this.confirmarAgregar(e)}>
								Confirmar</button>
							</Link>
						</div>
					</div>
				</div>
			</Collapse>
			</div>
		</div>
		</p></span></h5>
		</>)
	}

	render(){
		return (
		<div>
			<div id="wrapper">
			<NavbarA/>    
			<div id="content-wrapper" className="d-flex flex-column">
				<div id="content">
				<HeaderUsers Iduser={this.props.Iduser} Rol={this.props.Rol} Nombre={this.props.Nombre} External={this.props.External}/>
				{this.props.Nombre ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
				{this.props.Iduser ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
				{this.props.Rol ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
				<main id="main">
						{this.renderCrearMonitoreo()}
						<TablaMonitoreos/>
				</main>{/* End #main */}
				</div>
				{/* ======= Footer ======= */}
				<footer id="footer">
					<div className="footer-top">
					<div className="copyright">
						© Copyright <strong><span>Restaured</span></strong>. All Rights Reserved
					</div>
					</div>
				</footer>{/* End Footer */}
			</div>
			</div>
			<Logout/>
			{/* Vendor JS Files */}
			{/* Template Main JS File */}
		</div>
	)};
}
	// {/* ======= Cta Section ======= */}
	// <section id="cta" className="cta"> 
	// <div className="container" >
	// 	<div className="text-center">
	// 	<h1><p>Reportes </p></h1>
	// 	<h3>A continuación encontrarás...</h3>
	// 	<p> Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
	// 	<p  onClick={() => this.setState({ open:!this.state.open})}
	// 		aria-controls="collapse-search-input"
	// 		aria-expanded={this.state.open} className="cta-btn" type="button" >Buscar palabra clave</p>
	// 	<Collapse in={this.state.open}>
	// 		<div id="colllapse-search-input">
	// 		<input type="text" name="search" className="form-control mt-auto " id="search" placeholder="Búsqueda" data-msg="Please enter at least 4 chars" />
	// 		</div>
	// 	</Collapse>
			
	// 	</div>
	// </div>
	// </section>
	// {/* End Cta Section */}  
	// {/* ======= Services Section ======= */}
	// <section id="services" className="services">
	// <div className="container">
	// 	<div className="section-title" >
	// 	<h2>Monitoreos</h2>
	// 	<p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
	// 	</div>
	// 	<div className="row">
	// 	<div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
	// 		<div className="icon-box" >
	// 		<div className="icon"><i className="icofont-leaf" /></div>
	// 		<h4 className="title"><a href="/publicharts">Vegetación</a></h4>
	// 		<p className="description">Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi</p>
	// 		</div>
	// 	</div>
	// 	<div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
	// 		<div className="icon-box" >
	// 		<div className="icon"><i className="icofont-bat" /></div>
	// 		<h4 className="title"><a href="/publicharts">Murciélagos</a></h4>
	// 		<p className="description">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore</p>
	// 		</div>
	// 	</div>
	// 	<div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
	// 		<div className="icon-box" >
	// 		<div className="icon"><i className="icofont-bug" /></div>
	// 		<h4 className="title"><a href="/publicharts">Hormigas</a></h4>
	// 		<p className="description">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia</p>
	// 		</div>
	// 	</div>
	// 	<div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
	// 		<div className="icon-box" >
	// 		<div className="icon"><i className="icofont-bird-alt" /></div>
	// 		<h4 className="title"><a href="/publicharts">Aves</a></h4>
	// 		<p className="description">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis</p>
	// 		</div>
	// 	</div>
	// 	</div>
	// </div>
	// </section>{/* End Services Section */}
	// {/* ======= Portfolio Section ======= */}
	// <section id="portfolio" className="portfolio">
	// <div className="container">
	// 	<div className="section-title" >
	// 	<h2>Galería</h2>
	// 	<p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
	// 	</div>
	// 	<div className="row" >
	// 	<div className="col-lg-12 d-flex justify-content-center">
	// 		<ul id="portfolio-flters">
	// 		<li data-filter="*" className="filter-active">Todos</li>
	// 		<li data-filter=".filter-app">Aves</li>
	// 		<li data-filter=".filter-card">Hormigas</li>
	// 		<li data-filter=".filter-web">Vegetación</li>
	// 		</ul>
	// 	</div>
	// 	</div>
	// 	<div className="row portfolio-container" >
	// 	<div className="col-lg-4 col-md-6 portfolio-item filter-app">
	// 		<div className="portfolio-wrap">
	// 		<img src="img/portfolio/portfolio-1.jpg" className="img-fluid" alt="" />
	// 		<div className="portfolio-links">
	// 			<a href="img/portfolio/portfolio-1.jpg" data-gall="portfolioGallery" className="venobox" title="App 1"><i className="bx bx-plus" /></a>
	// 			<a href="portfolio-details.html" title="More Details"><i className="bx bx-link" /></a>
	// 		</div>
	// 		</div>
	// 	</div>
	// 	<div className="col-lg-4 col-md-6 portfolio-item filter-web">
	// 		<div className="portfolio-wrap">
	// 		<img src="img/portfolio/portfolio-2.jpg" className="img-fluid" alt="" />
	// 		<div className="portfolio-links">
	// 			<a href="img/portfolio/portfolio-2.jpg" data-gall="portfolioGallery" className="venobox" title="Web 3"><i className="bx bx-plus" /></a>
	// 			<a href="portfolio-details.html" title="More Details"><i className="bx bx-link" /></a>
	// 		</div>
	// 		</div>
	// 	</div>
	// 	<div className="col-lg-4 col-md-6 portfolio-item filter-app">
	// 		<div className="portfolio-wrap">
	// 		<img src="img/portfolio/portfolio-3.jpg" className="img-fluid" alt="" />
	// 		<div className="portfolio-links">
	// 			<a href="img/portfolio/portfolio-3.jpg" data-gall="portfolioGallery" className="venobox" title="App 2"><i className="bx bx-plus" /></a>
	// 			<a href="portfolio-details.html" title="More Details"><i className="bx bx-link" /></a>
	// 		</div>
	// 		</div>
	// 	</div>
	// 	<div className="col-lg-4 col-md-6 portfolio-item filter-card">
	// 		<div className="portfolio-wrap">
	// 		<img src="img/portfolio/portfolio-4.jpg" className="img-fluid" alt="" />
	// 		<div className="portfolio-links">
	// 			<a href="img/portfolio/portfolio-4.jpg" data-gall="portfolioGallery" className="venobox" title="Card 2"><i className="bx bx-plus" /></a>
	// 			<a href="portfolio-details.html" title="More Details"><i className="bx bx-link" /></a>
	// 		</div>
	// 		</div>
	// 	</div>
	// 	<div className="col-lg-4 col-md-6 portfolio-item filter-web">
	// 		<div className="portfolio-wrap">
	// 		<img src="img/portfolio/portfolio-5.jpg" className="img-fluid" alt="" />
	// 		<div className="portfolio-links">
	// 			<a href="img/portfolio/portfolio-5.jpg" data-gall="portfolioGallery" className="venobox" title="Web 2"><i className="bx bx-plus" /></a>
	// 			<a href="portfolio-details.html" title="More Details"><i className="bx bx-link" /></a>
	// 		</div>
	// 		</div>
	// 	</div>
	// 	<div className="col-lg-4 col-md-6 portfolio-item filter-app">
	// 		<div className="portfolio-wrap">
	// 		<img src="img/portfolio/portfolio-6.jpg" className="img-fluid" alt="" />
	// 		<div className="portfolio-links">
	// 			<a href="img/portfolio/portfolio-6.jpg" data-gall="portfolioGallery" className="venobox" title="App 3"><i className="bx bx-plus" /></a>
	// 			<a href="portfolio-details.html" title="More Details"><i className="bx bx-link" /></a>
	// 		</div>
	// 		</div>
	// 	</div>
	// 	<div className="col-lg-4 col-md-6 portfolio-item filter-card">
	// 		<div className="portfolio-wrap">
	// 		<img src="img/portfolio/portfolio-7.jpg" className="img-fluid" alt="" />
	// 		<div className="portfolio-links">
	// 			<a href="img/portfolio/portfolio-7.jpg" data-gall="portfolioGallery" className="venobox" title="Card 1"><i className="bx bx-plus" /></a>
	// 			<a href="portfolio-details.html" title="More Details"><i className="bx bx-link" /></a>
	// 		</div>
	// 		</div>
	// 	</div>
	// 	<div className="col-lg-4 col-md-6 portfolio-item filter-card">
	// 		<div className="portfolio-wrap">
	// 		<img src="img/portfolio/portfolio-8.jpg" className="img-fluid" alt="" />
	// 		<div className="portfolio-links">
	// 			<a href="img/portfolio/portfolio-8.jpg" data-gall="portfolioGallery" className="venobox" title="Card 3"><i className="bx bx-plus" /></a>
	// 			<a href="portfolio-details.html" title="More Details"><i className="bx bx-link" /></a>
	// 		</div>
	// 		</div>
	// 	</div>
	// 	<div className="col-lg-4 col-md-6 portfolio-item filter-web">
	// 		<div className="portfolio-wrap">
	// 		<img src="img/portfolio/portfolio-9.jpg" className="img-fluid" alt="" />
	// 		<div className="portfolio-links">
	// 			<a href="img/portfolio/portfolio-9.jpg" data-gall="portfolioGallery" className="venobox" title="Web 3"><i className="bx bx-plus" /></a>
	// 			<a href="portfolio-details.html" title="More Details"><i className="bx bx-link" /></a>
	// 		</div>
	// 		</div>
	// 	</div>
	// 	</div>
	// </div>
	// </section>{/* End Portfolio Section */}
	// {/* ======= Contact Section ======= */}
	// <section id="contact" className="contact section-bg">
	// <div className="container">
	// 	<div className="section-title" >
	// 	<h2>Contáctanos</h2>
	// 	<p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
	// 	</div>
	// 	<div className="row" >
	// 	<div className="col-lg-6 justify-content-md-center" >
	// 		<div className="info-box mb-4">
	// 		<form action="forms/contact.php" method="post" role="form" className="php-email-form">
	// 			<div className="form-row">
	// 			<div className="col-md-6 form-group">
	// 				<input type="text" name="name" className="form-control" id="name" placeholder="Tu nombre" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
	// 				<div className="validate" />
	// 			</div>
	// 			<div className="col-md-6 form-group">
	// 				<input type="email" className="form-control" name="email" id="email" placeholder="Tu correo" data-rule="email" data-msg="Please enter a valid email" />
	// 				<div className="validate" />
	// 			</div>
	// 			</div>
	// 			<div className="form-group">
	// 			<input type="text" className="form-control" name="subject" id="subject" placeholder="Asunto" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />
	// 			<div className="validate" />
	// 			</div>
	// 			<div className="form-group">
	// 			<textarea className="form-control" name="message" rows={5} data-rule="required" data-msg="Tu mensaje aquí" placeholder="Mensaje" defaultValue={""} />
	// 			<div className="validate" />
	// 			</div>
	// 			<div className="mb-3">
	// 			<div className="loading">Loading</div>
	// 			<div className="error-message" />
	// 			<div className="sent-message">Your message has been sent. Thank you!</div>
	// 			</div>
	// 			<div className="text-center"><button type="submit">Enviar Mensaje</button></div>
	// 		</form>
	// 		</div>
	// 	</div>
	// 	<div className="col-md-6 justify-content-md-center" >
	// 		<div className="info-box  mb-4">
	// 		<i className="bx bx-envelope" />
	// 		<h3>Hablemos por correo electrónico</h3>
	// 		<p>contact@example.com</p>
	// 		</div>
	// 		<div className="info-box  mb-4">
	// 		<i className="bx bx-phone-call" />
	// 		<h3>Llámanos</h3>
	// 		<p>+57 313 554889 55</p>
	// 		</div>
	// 	</div>
	// 	</div>
	// </div></section>{/* End Contact Section */}

