import React from 'react'
import '../../assets/vendor/bootstrap/css/bootstrap.css'
import '../../assets/css/sb-admin-2.css'
import NavbarA from '../NavBar/NavbarAdmin'
import { Redirect } from 'react-router'
import HeaderUsers from './Header'
import Logout from './Logout'


export default class Home extends React.Component {
  constructor(props) {
		super(props)
		this.state= {
			Rol: this.props.Rol,
			Nombre: this.props.Nombre,
			Iduser: this.props.Iduser,
      External: this.props.External,
		}
    console.log('hola');
	}
  render(){
    console.log(this.props)
    return (
      <div>
        <div>
          <title>RestauRed Admin - Dashboard</title>
          {/* Page Wrapper */}
          <div id="wrapper">
            {/* Content Wrapper */}
            <NavbarA/>
            <div id="content-wrapper" className="d-flex flex-column">
              {/* Main Content */}
              <div id="content">
              <HeaderUsers Iduser={this.props.Iduser} Rol={this.props.Rol} Nombre={this.props.Nombre} External={this.props.External}/>
                {this.props.Nombre ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
                {this.props.Iduser ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
                {this.props.Rol ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
                {/* Begin Page Content */}
                <div className="container-fluid">
                  {/* Page Heading */}
                  <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">Bienvenido</h1>
                  </div>
                  {/* Content Row */}
                  {/* <div className="row">
                    <div className="col-xl-3 col-md-6 mb-4">
                      <div className="card border-left-primary shadow h-100 py-2">
                        <div className="card-body">
                          <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                              <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                Registros de Aves del último año</div>
                              <div className="h5 mb-0 font-weight-bold text-gray-800">500</div>
                            </div>
                            <div className="col-auto">
                              <i className="fas fa-calendar fa-2x text-gray-300" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-md-6 mb-4">
                      <div className="card border-left-success shadow h-100 py-2">
                        <div className="card-body">
                          <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                              <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                              Registros de vegetación del último año</div>
                              <div className="h5 mb-0 font-weight-bold text-gray-800">750</div>
                            </div>
                            <div className="col-auto">
                              <i className="fas fa-dollar-sign fa-2x text-gray-300" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-md-6 mb-4">
                      <div className="card border-left-info shadow h-100 py-2">
                        <div className="card-body">
                          <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                              <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                                Porcentaje de Aves Hembra del último año
                              </div>
                              <div className="row no-gutters align-items-center">
                                <div className="col-auto">
                                  <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">58%</div>
                                </div>
                                <div className="col">
                                  <div className="progress progress-sm mr-2">
                                    <div className="progress-bar bg-info" role="progressbar" style={{width: '58%'}} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-auto">
                              <i className="fas fa-clipboard-list fa-2x text-gray-300" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-md-6 mb-4">
                      <div className="card border-left-warning shadow h-100 py-2">
                        <div className="card-body">
                          <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                              <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                Reportes Generados el último año</div>
                              <div className="h5 mb-0 font-weight-bold text-gray-800">60</div>
                            </div>
                            <div className="col-auto">
                              <i className="fas fa-comments fa-2x text-gray-300" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                   */}
                </div>
                {/* /.container-fluid */}
              </div>
              {/* End of Main Content */}
              {/* Footer */}
               <footer id="footer" className="sticky-footer">
                <div className="container my-auto">
                  <div className="copyright text-center my-auto">
                    © Copyright <strong><span>Restaured 2024</span></strong>. All Rights Reserved
                  </div>
                </div>
              </footer>
              {/* End of Footer */}
            </div>
            {/* End of Content Wrapper */}
          </div>
          {/* Logout Modal*/}
          <Logout/>
        </div>
      </div>
)};
}