import React from 'react'
import '../../assets/vendor/bootstrap/css/bootstrap.css'
import '../../assets/css/sb-admin-2.css'
import NavbarA from '../NavBar/NavbarAdmin'
import Header from './Header'
import { Redirect } from 'react-router'
import Logout from './Logout'
import { BsFillTrashFill, BsFillEyeFill, BsFillEyeSlashFill,BsPencil } from "react-icons/bs";
import { useTable, useFilters, useGlobalFilter,useRowSelect, useAsyncDebounce, usePagination } from 'react-table'
const axios = require('axios');

 

function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    }) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)
    
    return (
        <span>
        Search:{' '}
        <input
            value={value || ""}
            onChange={e => {
            setValue(e.target.value);
            onChange(e.target.value);
            }}
            placeholder={`${count} records...`}
            style={{
            fontSize: '1.1rem',
            border: '0',
            }}
        />
        </span>
    )
    }
    
    function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
    }) {
    const count = preFilteredRows.length
    
    return (
        <input
            className="form-control"
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
            placeholder={`Search ${count} records...`}
        />
    )
    }
function Table({ columns, data}) {

const defaultColumn = React.useMemo(
    () => ({
        // Default Filter UI
        Filter: DefaultColumnFilter,
    }),
    []
)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page
    
        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            initialState:{pageSize:5},
    
        },
        useFilters,
        useGlobalFilter,
        usePagination,
    )
    return (
        <div>
            <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
            />
            <table className="table" {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>
                                    {column.render('Header')}
                                    {/* Render the columns filter UI */}
                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                    prepareRow(row)
                    return (
                    <tr {...row.getRowProps()}>
                        {row.cells.map(cell => {
                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        })}
                    </tr>
                    )
                })}
                </tbody>
            </table>
            <br />
            <div className="pagination">
            <button className="page-link" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {'<<'}
            </button>{' '}
            <button className="page-link" onClick={() => previousPage()} disabled={!canPreviousPage}>
                {'<'}
            </button>{' '}
            <button className="page-link" onClick={() => nextPage()} disabled={!canNextPage}>
                {'>'}
            </button>{' '}
            <button className="page-link" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                {'>>'}
            </button>{' '}
            <span className="page-link">
                Page{' '}
                <strong>
                {pageIndex + 1} of {pageOptions.length}
                </strong>{' '}
            </span>
            <span className="page-link">
                | Go to page:{' '}
                <input
                type="number"
                defaultValue={pageIndex + 1}
                onChange={e => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                    gotoPage(page)
                }}
                style={{ width: '100px' }}
                />
            </span>{' '}
            <select className="page-link"
                value={pageSize}
                onChange={e => {
                setPageSize(Number(e.target.value))
                }}
            >
                {[5, 10, 15, 20, 25].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                </option>
                ))}
            </select>
          </div>
        </div>
    )
    }
function TablaMonitoreo(dataset,columnas){
		const columns = React.useMemo(()=>columnas,[])
		const [data] = React.useState(() =>dataset)
			return (
				<div >
					<Table
						columns={columns}
						data={data}
					/>
				</div>
			)
}


export default class Monitoreos extends React.Component{
    constructor(props) {
		super(props)
		this.state= {
			Rol: this.props.Rol,
			Nombre: this.props.Nombre,
			Iduser: this.props.Iduser,
            especie:{
				id:[],
				nombreCientifico:[],
				nombreComun:[],
				familia:[],
				orden:[],
				genero:[],
				activo:[],
				idLocalizacion:[],
			},
		}
        this.Getespecies()
	}
    Getespecies=async()=>{
		var baseurl = "https://api.restaured.net"
		const url = baseurl+'/restaured/especie'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			var id =[]; var nombreCientifico =[]; var nombreComun =[]; var familia =[];
			var genero =[]; var orden =[]; var idLocalizacion =[]; var activo =[];
			for(const i in data.body){
				id.push(data.body[i]._id)
				idLocalizacion.push(data.body[i].idLocalizacion)
				nombreCientifico.push(data.body[i].nombreCientifico)
				nombreComun.push(data.body[i].nombreComun)
				familia.push(data.body[i].familia)
				genero.push(data.body[i].genero)
				orden.push(data.body[i].orden)
				activo.push(data.body[i].activo)
			};
			this.setState({
				especie:{
					id:id,
					idLocalizacion:idLocalizacion,
					nombreCientifico:nombreCientifico,
					nombreComun:nombreComun,
					familia:familia,
					genero:genero,
					orden:orden,
					activo:activo,
				}});
		})
		// console.log(this.state.especies);
	} 

	TablaMonitoreos=()=>{
		if(this.state.especie.id.length > 0){
			var columns = [
				{
					Header: 'Especies Registradas',
					columns: [
					{
					Header: 'Nombre Cientifico',
					accessor: 'nombreCientifico'
					},
					{
					Header: 'Nombre Comun',
					accessor: 'nombreComun'
					},
					{
                    Header: 'Familia',
                    accessor: 'familia'
                    },
					{
                    Header: 'Orden',
                    accessor: 'orden'
                    },
					{
                    Header: 'Genero',
                    accessor: 'genero'
                    },
					],
				},
			]
            var dataset =[]
            for(const i in this.state.especie.id){
                var datos = {
                    nombreCientifico: this.state.especie.nombreCientifico[i],
                    nombreComun: this.state.especie.nombreComun[i],
                    familia: this.state.especie.familia[i],
                    orden: this.state.especie.orden[i],
                    genero: this.state.especie.genero[i],
                }
                dataset.push(datos)
            }			
            var html = TablaMonitoreo(dataset,columns)
		return(<>{html} </>)
	}else{ return(<> </>) }
	}


    
    render(){
        return (
            <div>
                <div>
                    <div id="wrapper">
                    <NavbarA/>
                    {/* Content Wrapper */}
                    <div id="content-wrapper" className="d-flex flex-column">
                        {/* Main Content */}
                        <div id="content">
                        <Header Iduser={this.props.Iduser} Rol={this.props.Rol} Nombre={this.props.Nombre} External={this.props.External}/>
                        {this.props.Nombre ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
                        {this.props.Iduser ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
                        {this.props.Rol ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
                        {/* Begin Page Content */}
                        <div className="container-fluid">
                            {/* Page Heading */}
                            <h1 className="h3 mb-2 text-gray-800">Administración Monitoreos</h1>
                            {/* DataTales Example */}
                            <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary">Administración</h6>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <this.TablaMonitoreos/>
                                </div>
                            </div>
                            </div>
                        </div>
                        {/* /.container-fluid */}
                        </div>
                        {/* End of Main Content */}
                        {/* Footer */}
                         <footer id="footer" className="sticky-footer">
                        <div className="container my-auto">
                            <div className="copyright text-center my-auto">
                            © Copyright <strong><span>Restaured 2024</span></strong>. All Rights Reserved
                            </div>
                        </div>
                        </footer>
                        {/* End of Footer */}
                    </div>
                    {/* End of Content Wrapper */}
                    </div>
                    {/* End of Page Wrapper */}
                    {/* Scroll to Top Button*/}
                    <a className="scroll-to-top rounded" href="#page-top">
                    <i className="fas fa-angle-up" />
                    </a>
                    <Logout/>
                </div>
            </div>
      )};
}
      