import React, { Component } from 'react'
import '../../../assets/vendor/bootstrap/css/bootstrap.css'
import '../../../assets/css/sb-admin-2.css'
import NavbarUC from '../../NavBar/NavbarUsersCC'
import HeaderUsers from './HeaderUsers'
import TablaRegistros from './TablaRegistros'
import {Collapse} from 'react-bootstrap'
import { Redirect} from 'react-router'
import { useTable, usePagination,useRowSelect } from 'react-table'
import { BsPlusCircle, BsTrash, BsArchive } from "react-icons/bs"
import {Link} from 'react-router-dom'
import {CSVLink} from 'react-csv';
import Spinner from 'react-bootstrap/Spinner'
import Alert from 'react-bootstrap/Alert'
import Logout from './Logout'
import {v4 as uuidv4} from 'uuid';
const axios = require('axios');

// Create an editable cell renderer
const EditableCell = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateMyData, // This is a custom function that we supplied to our table instance
  }) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue)
  
    const onChange = e => {
      setValue(e.target.value)
    }
  
    // We'll only update the external data when the input is blurred
    const onBlur = () => {
      updateMyData(index, id, value)
    }
  
    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
      setValue(initialValue)
    }, [initialValue])
  
    return <input className="form-control" value={value} onChange={onChange} onBlur={onBlur} />
}
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  }
)
function DeleteRegistro(event,data, selectedRowIds,monitoreos,Iduser,location){
	var registrosEliminar=[]
	for( const i in selectedRowIds){
		var registro={
			_id:data[i].id,
		}
		registrosEliminar.push(registro)
	}
	var baseurl = "https://api.restaured.net"
	var url = baseurl+'/restaured/registromonitoreo'
	var datos = JSON.stringify(registrosEliminar)
	var config = {
		method: 'delete',
		url: url,
		headers: { 
		'Content-Type': 'application/json'
		},
		withCredentials:true,
		data: datos
	};
	axios(config)
	.then( response => response.data)
	.then( data => console.log(data))
	.catch(error => {
		var local = localStorage.getItem('registroQueries')	
		var newRegsitro
		if(local !== null){
			local = JSON.parse(local)
		}else{
			local =[]
		}
		var idDelete=[]
		for(const i in registrosEliminar){
			for(const j in local){
				var id = JSON.parse(local[j].data)
				if(registrosEliminar[i]._id === id[0].id && local[j].method === 'post'){
					local.splice(j,1)
					idDelete.push(i)
				}
			}
		}
		console.log(local, config)
		localStorage.setItem('registroQueries', JSON.stringify(local))
		for(var i = idDelete.length -1;i>=0;i--){
			registrosEliminar.splice(idDelete[i], 1)
		}
		local.push({
			method: 'delete',
			url: url,
			headers: { 
			'Content-Type': 'application/json'
			},
			withCredentials:true,
			data: JSON.stringify(registrosEliminar)
		})
		localStorage.setItem('registroQueries', JSON.stringify(local))
		var monitoreos = localStorage.getItem('monitoreos')
		monitoreos = JSON.parse(monitoreos)
		for(const i in selectedRowIds){
			for(const j in monitoreos[location.state].registros.id){
				if(data[i].id === monitoreos[location.state].registros.id[j] ){
					monitoreos[location.state].registros.id.splice(j, 1)
					monitoreos[location.state].registros.iduser.splice(j, 1)
					monitoreos[location.state].registros.nombre.splice(j, 1)
					monitoreos[location.state].registros.entradas.splice(j, 1)
					monitoreos[location.state].registros.date.splice(j, 1)

				}
			}

		}
		localStorage.setItem('monitoreos', JSON.stringify(monitoreos))
	})

	
}
function EditarRegistro(event,selectedRowIds,monitoreos,Iduser,location,data){
	var selectedMonitoreo = location.state
	var registrosActualizar=[]
	for( const i in selectedRowIds){
		for(const k in monitoreos[selectedMonitoreo].registros.id){
			if(data[i].id === monitoreos[selectedMonitoreo].registros.id[k]
			&& data[i].numEntradas === monitoreos[selectedMonitoreo].registros.entradas[k].id.length){
				console.log(monitoreos[selectedMonitoreo].registros.nombre[k], monitoreos[selectedMonitoreo].registros.id[k])
				var realdate = new Date(data[i].date)
				var registro={
					_id:data[i].id,
					nombre:data[i].nombre,
				}
			registrosActualizar.push(registro)
			}				  
		}
	}
	console.log(registrosActualizar)
	var baseurl = "https://api.restaured.net"
	var url = baseurl+'/restaured/registromonitoreo'
	// ACTUALIZAR DATOS
	var datos = JSON.stringify(registrosActualizar)
	var config = {
		method: 'put',
		url: url,
		headers: { 
		'Content-Type': 'application/json'
		},
		withCredentials:true,
		data: datos
	};
	axios(config)
	.then( response => response.data)
	.then( data => console.log(data))
	.catch(error => {
		var local = localStorage.getItem('registroQueries')	
		if(local !== null){
			local = JSON.parse(local)
			console.log(error, config)
			local.push(config)
			localStorage.setItem('registroQueries', JSON.stringify(local))

		}else{
			local =[]
			local.push(config)
			localStorage.setItem('registroQueries', JSON.stringify(local))
		}
		var monitoreos = localStorage.getItem('monitoreos')
		monitoreos = JSON.parse(monitoreos)
		for(const i in registrosActualizar){
			for(const j in monitoreos[location.state].registros.id){
				if(registrosActualizar[i]._id === monitoreos[location.state].registros.id[j] ){
					monitoreos[location.state].registros.nombre[j] = registrosActualizar[i].nombre
				}
			}

		}
		console.log(monitoreos)
		localStorage.setItem('monitoreos', JSON.stringify(monitoreos))
	})
}
function ViewDeleteRegistro({data,selectedRowIds,monitoreos,Iduser,location}){
		return(
		<Link to={{pathname:"/restaured.CzScie/reloaderregistro",state:location.state}}>
			<button type="button" className="btn border" onClick={(event) => DeleteRegistro(event,data,selectedRowIds,monitoreos,Iduser,location)}> <BsTrash/><span>Eliminar Entradas </span></button>
		</Link>
		)
}
function ViewGuardarCambios({data,selectedRowIds,monitoreos,Iduser,location}){
		return(
			<Link to={{pathname:"/restaured.CzScie/reloaderregistro",state:location.state}}>
				<button type="button" className="btn border" onClick={(event) => EditarRegistro(event,selectedRowIds,monitoreos,Iduser,location,data)}> <BsArchive/><span>Guardar Cambios </span></button>
			</Link>
		)
	
}
function Table({ columns, data, updateMyData, skipPageReset,monitoreos, Iduser,location }) {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize,selectedRowIds },
    } = useTable(
      {
        columns,
        data,
        initialState:{pageSize:5},
        autoResetPage: !skipPageReset,
        updateMyData,
      },
      usePagination,
      useRowSelect,
      hooks => {
        hooks.visibleColumns.push(columns => [
          // Let's make a column for selection
          {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ])
      }
    )  
    // Render the UI for your table
    return (
      <>
        <table className="table table-h-scroll"{...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
        <div className="pagination">
          <button className="page-link" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {'<<'}
          </button>{' '}
          <button className="page-link" onClick={() => previousPage()} disabled={!canPreviousPage}>
            {'<'}
          </button>{' '}
          <button className="page-link" onClick={() => nextPage()} disabled={!canNextPage}>
            {'>'}
          </button>{' '}
          <button className="page-link" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {'>>'}
          </button>{' '}
          <span className="page-link">
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
          <span className="page-link">
            | Go to page:{' '}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                gotoPage(page)
              }}
              style={{ width: '100px' }}
            />
          </span>{' '}
          <select
            className="page-link"
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value))
            }}
          >
            {[5, 10, 15, 20, 25].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
		<br/>
		<br/>
		<div className="row">
			<div className="col-lg-6">
				<ViewDeleteRegistro data ={data} selectedRowIds={selectedRowIds} monitoreos={monitoreos} Iduser={Iduser} location={location}/>
			</div>
			<div className="col-lg-6"> 
				<ViewGuardarCambios  data ={data} selectedRowIds={selectedRowIds} monitoreos={monitoreos} Iduser={Iduser} location={location}/>
			</div>
		</div>
		<br/>
		<br/>
	  </>
    )
}
function TablaRegistro(dataset,columnas, monitoreos,Iduser,location){
    const columns = React.useMemo(() => columnas,[])
    const [data, setData] = React.useState(() =>dataset)
    const [originalData] = React.useState(data)
    const [skipPageReset, setSkipPageReset] = React.useState(false)
  
    // We need to keep the table from resetting the pageIndex when we
    // Update data. So we can keep track of that flag with a ref.
  
    // When our cell renderer calls updateMyData, we'll use
    // the rowIndex, columnId and new value to update the
    // original data
    const updateMyData = (rowIndex, columnId, value) => {
      // We also turn on the flag to not reset the page
      setSkipPageReset(true)
      setData(old =>
        old.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...old[rowIndex],
              [columnId]: value,
            }
          }
          return row
        })
      )
    }
  
    // After data chagnes, we turn the flag back off
    // so that if data actually changes when we're not
    // editing it, the page is reset
    React.useEffect(() => {
      setSkipPageReset(false)
    }, [data])
  
    // Let's add a data resetter/randomizer to help
    // illustrate that flow...
    const resetData = () => setData(originalData)
  
    return (
      <div className="table-responsive">
        <button className="btn border" onClick={resetData}><span><strong>Reestablecer Datos </strong></span></button>
        <Table
          columns={columns}
          data={data}
          updateMyData={updateMyData}
          skipPageReset={skipPageReset}
		  monitoreos={monitoreos}
		  Iduser={Iduser}
		  location={location}
        />
      </div>
    )
}

class RegistrosMonitoreos extends Component{
    constructor(props){
        super(props);
        this.state={
            Rol: this.props.Rol,
            Token: this.props.Token,
            Iduser: this.props.Iduser,
            open:false,
            redirect:false,
			data:[],
            selectedMonitoreo:{},
            form:{
                idMonitoreo:"",
                idRegistro:"",
                fecha:"",
                nombre:"",
                idUser:"",
                idLocalizacion:'',
            },            
			datasetLocalizaciones: [],
			variable:{
				id:[],
				idMonitoreo:[],
				nombre:[]
			},
			variablexindicador:{
				id:[],
				idIndicador:[],
				idVariable:[]
			},
			indicador:{
				id:[],
				idMonitoreo:[],
				formula:[],
				nombre:[],
				pubObj:[],
				tipo:[],
				tipoGrafico:[],
				unidades:[],
			},
			monitoreo:{
				id:[],
				nombre:[],
				descripcion:[],
			},
			registroMonitoreo:{
				id:[],
				nombre:[],
				idUser:[],
				idMonitoreo:[],
			},
			entradaMonitoreo:{
				id:[],
				idLocalizacion:[],
				curado:[],
				idRegistro:[],
				idEspecies:[],
			},
			variableRegistro:{
				id:[],
				idEntrada:[],
				idVariable:[],
				valor:[],
			},

			localizacion:{
				id:[],
				idSitio:[],
				isParcela:[],
				idTransecto:[],
				idPO:[],
			},
			sitio:{
				id:[],
				nombre:[],
				geos:[],
				descripcion:[],
			},
			parcela:{
				id:[],
				nombre:[],
				geos:[],
				descripcion:[],
			},
			transecto:{
				id:[],
				nombre:[],
				geos:[],
				descripcion:[],
			},
			PO:{
				id:[],
				nombre:[],
				geos:[],
				descripcion:[],
			},
			ingresarEntradaMonitoreo:{
				body:[],
				status:"",
			},
			monitoreos:[],
	
		}
		this.initial_state();
	}
	//PETICIONES
	Getespecies=async()=>{
		var baseurl = "https://api.restaured.net"
		const url = baseurl+'/restaured/especie'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
		  var id =[];var nombreCientifico =[]; var nombreComun =[]; var idLocalizacion =[];
		  var activo =[]; var familia =[]; var genero =[]; var orden =[];
		  for(const i in data.body){
			id.push(data.body[i]._id)
			idLocalizacion.push(data.body[i].idLocalizacion)
			nombreCientifico.push(data.body[i].nombreCientifico)
			nombreComun.push(data.body[i].nombreComun)
			familia.push(data.body[i].familia)
			orden.push(data.body[i].orden)
			genero.push(data.body[i].genero)
			activo.push(data.body[i].activo)
		  };
		  localStorage.setItem('especies', JSON.stringify({
			especies:{
			  id:id,
			  idLocalizacion:idLocalizacion,
			  nombreCientifico:nombreCientifico,
			  nombreComun:nombreComun,
			  orden:orden,
			  familia:familia,
			  genero:genero,
			  activo:activo, }}))
		  this.setState({
			especie:{
			  id:id,
			  idLocalizacion:idLocalizacion,
			  nombreCientifico:nombreCientifico,
			  nombreComun:nombreComun,
			  orden:orden,
			  familia:familia,
			  genero:genero,
			  activo:activo,
			}});
		})
		.catch( error => {
		  console.log(error)
		  var local = localStorage.getItem('especies');
		  local = JSON.parse(local)
		  this.setState({
			especie:{
			  id:local.especies.id,
			  idLocalizacion:local.especies.idLocalizacion,
			  nombreCientifico:local.especies.nombreCientifico,
			  nombreComun:local.especies.nombreComun,
			  orden:local.especies.orden,
			  familia:local.especies.familia,
			  genero:local.especies.genero,
			  activo:local.especies.activo,
			}});
		})
		// console.log(this.state.especies);
	} 
	getData=async()=>{
		var baseurl = "https://api.restaured.net"
		var url = baseurl+'/restaured/variable'
		try {
			await axios.get(url,{withCredentials: true})
			.then(response => response.data)
			.then(data => {
			// console.log(data)
			var id =[];
			var idMonitoreo =[];
			var nombre =[];
			var pubObj =[];
			var tipo =[];
			var unidades =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				idMonitoreo.push(data.body[i].idMonitoreo);
				nombre.push(data.body[i].nombre);
				pubObj.push(data.body[i].pubObj);
				unidades.push(data.body[i].unidades);
				tipo.push(data.body[i].tipo);
			};
			this.setState({
				variable:{
					id:id,
					idMonitoreo:idMonitoreo,
					nombre:nombre,
					pubObj:pubObj,
					unidades:unidades,
					tipo:tipo,
			}})
			})
			url = baseurl+'/restaured/variablexindicador'
			await axios.get(url,{withCredentials:true})
			.then(response => response.data)
			.then(data => {
			// console.log(data)
			var id =[];
			var idIndicador =[];
			var idVariable =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				idIndicador.push(data.body[i].idIndicador);
				idVariable.push(data.body[i].idVariable);
			};
			this.setState({
				variablexindicador:{
					id:id,
					idIndicador:idIndicador,
					idVariable:idVariable,
			}})
			})
			url = baseurl+'/restaured/indicador'
			await axios.get(url, {withCredentials:true})
			.then(response => response.data)
			.then(data => {
			// console.log(data)
			var id =[];
			var idMonitoreo =[];
			var formula =[];
			var nombre =[];
			var pubObj =[];
			var tipo =[];
			var tipoGrafico =[];
			var unidades =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				idMonitoreo.push(data.body[i].idMonitoreo);
				formula.push(data.body[i].formula);
				nombre.push(data.body[i].nombre);
				pubObj.push(data.body[i].pubObj);
				tipo.push(data.body[i].tipo);
				tipoGrafico.push(data.body[i].tipoGrafico);
				unidades.push(data.body[i].unidades);
			};
			this.setState({
				indicador:{
					id:id,
					idMonitoreo:idMonitoreo,
					formula:formula,
					nombre:nombre,
					pubObj:pubObj,
					tipo:tipo,
					tipoGrafico:tipoGrafico,
					unidades:unidades,
			}})
			})
			url = baseurl+'/restaured/localizacion'
			await axios.get(url, {withCredentials:true})
			.then(response => response.data)
			.then(data => {
			console.log(data)
			var id =[];
			var idSitio =[];
			var idParcela =[];
			var idTransecto =[];
			var idPO =[];
			var nombre =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				idSitio.push(data.body[i].idSitio);
				idParcela.push(data.body[i].idParcela);
				idTransecto.push(data.body[i].idTransecto);
				idPO.push(data.body[i].idPO);
				nombre.push(data.body[i].nombre);
			};
			this.setState({
				localizacion:{
					id:id,
					idSitio:idSitio,
					idParcela:idParcela,
					idTransecto:idTransecto,
					idPO:idPO,
					nombre:nombre,
			}})
			})
			url = baseurl+'/restaured/sitio'
			await axios.get(url, {withCredentials:true})
			.then(response => response.data)
			.then(data => {
			var id =[];
			var nombre =[];
			var geos =[];
			var descripcion =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				geos.push(data.body[i].geos);
				descripcion.push(data.body[i].descripcion);
				nombre.push(data.body[i].nombre);
			};
			this.setState({
				sitio:{
					id:id,
					nombre:nombre,
					geos:geos,
					descripcion:descripcion,
			}})
			})
			url = baseurl+'/restaured/puntoobservacion'
			await axios.get(url, {withCredentials:true})
			.then(response => response.data)
			.then(data => {
			var id =[];
			var nombre =[];
			var geos =[];
			var descripcion =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				geos.push(data.body[i].geos);
				descripcion.push(data.body[i].descripcion);
				nombre.push(data.body[i].nombre);
			};
			this.setState({
				PO:{
					id:id,
					nombre:nombre,
					geos:geos,
					descripcion:descripcion,
			}})
			})
			url = baseurl+'/restaured/transecto'
			await axios.get(url, {withCredentials:true})
			.then(response => response.data)
			.then(data => {
			var id =[];
			var nombre =[];
			var geos =[];
			var descripcion =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				geos.push(data.body[i].geos);
				descripcion.push(data.body[i].descripcion);
				nombre.push(data.body[i].nombre);
			};
			this.setState({
				transecto:{
					id:id,
					nombre:nombre,
					geos:geos,
					descripcion:descripcion,
			}})
			})
			url = baseurl+'/restaured/parcela'
			await axios.get(url, {withCredentials:true})
			.then(response => response.data)
			.then(data => {
			var id =[];
			var nombre =[];
			var geos =[];
			var descripcion =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				geos.push(data.body[i].geos);
				descripcion.push(data.body[i].descripcion);
				nombre.push(data.body[i].nombre);
			};
			this.setState({
				parcela:{
					id:id,
					nombre:nombre,
					geos:geos,
					descripcion:descripcion,
			}})
			})
			url = baseurl+'/restaured/monitoreo'
			await axios.get(url, {withCredentials:true})
			.then(response => response.data)
			.then(data => {
			// console.log(data)
			var id =[];
			var nombre =[];
			var descripcion =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				nombre.push(data.body[i].nombre);
				descripcion.push(data.body[i].descripcion);
			};
			this.setState({
				monitoreo:{
				id:id,
				nombre:nombre,
				descripcion:descripcion,
			}})
			})
			url = baseurl+'/restaured/registromonitoreo'
			await axios.get(url, {withCredentials:true})
			.then(response => response.data)
			.then(data => {
			var id =[];
			var nombre =[];
			var idMonitoreo =[];
			var idUser =[];
			var date=[]
			for(const i in data.body){
				id.push(data.body[i]._id);
				nombre.push(data.body[i].nombre);
				idUser.push(data.body[i].idUser);
				idMonitoreo.push(data.body[i].idMonitoreo);
				date.push(data.body[i].date);
			};
			this.setState({
				registroMonitoreo:{
				id:id,
				nombre:nombre,
				idUser:idUser,
				idMonitoreo:idMonitoreo,
				date:date,
			}})
			})
			url = baseurl+'/restaured/entradamonitoreo'
			await axios.get(url, {withCredentials:true})
			.then(response => response.data)
			.then(data => {
			var id =[];
			var idLocalizacion =[];
			var curado =[];
			var idRegistro =[];
			var idEspecies =[];
			var img =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				idLocalizacion.push(data.body[i].idLocalizacion);
				curado.push(data.body[i].curado);
				idRegistro.push(data.body[i].idRegistro);
				img.push(data.body[i].img);
				idEspecies.push(data.body[i].idEspecie);
			};
			this.setState({
				entradaMonitoreo:{
					id:id,
					idLocalizacion:idLocalizacion,
					curado:curado,
					img:img,
					idRegistro:idRegistro,
					idEspecies:idEspecies,
			}})
			})
			url = baseurl+'/restaured/variableregistro'
			await axios.get(url, {withCredentials:true})
			.then(response => response.data)
			.then(data => {
			var id =[];
			var idEntrada =[];
			var idVariable =[];
			var valor =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				idEntrada.push(data.body[i].idEntrada);
				idVariable.push(data.body[i].idVariable);
				valor.push(data.body[i].valor);
			};
			this.setState({
				variableRegistro:{
					id:id,
					idEntrada:idEntrada,
					idVariable:idVariable,
					valor:valor,
			}})
			})
			url = baseurl+'/auth/user'
			await axios.get(url, {withCredentials:true})
			.then(response => response.data)
			.then(data => {
				// console.log(data)
				var id =[];
				var nombre =[];
				for(const i in data.body){
					id.push(data.body[i]._id);
					nombre.push(data.body[i].nombre);
				};
				this.setState({
					users:{
						id:id,
						nombre:nombre,
				}})
			})
			localStorage.setItem('users', JSON.stringify({users:{id:this.state.users.id, nombre:this.state.users.nombre}}))
			await this.loadLocalizaciones()
			await this.loadMonitoreos()
		}
		catch (error){
		var localizaciones = localStorage.getItem('localizaciones')
		var monitoreos = localStorage.getItem('monitoreos')
		var users = localStorage.getItem('users')
		localizaciones = JSON.parse(localizaciones)
		users = JSON.parse(users)
		monitoreos = JSON.parse(monitoreos)
		this.setState({monitoreos:monitoreos, datasetLocalizaciones:localizaciones, users:{ id: users.users.id, nombre: users.users.nombre}})

		}
	}
	loadMonitoreos=async()=>{
	var monitoreo={
		id:0,
		nombre:"",
		descripcion:"",
		variables:[],
		registros:[],
		indicadores:[],
	};
	var indicadores = {
		id:[],
		idMonitoreo:[],
		formula:[],
		nombre:[],
		pubObj:[],
		tipo:[],
		tipoGrafico:[],
		unidades:[],
	};
	var registros={
		id:[],
		iduser:[],
		nombre:[],
		entradas:[],
		date:[],
	};
	var entradas={
		id:[],  
		idLocalizacion:[],  
		idVariablesRegistro:[],  
		variables:[],
		especies:[],
		valores:[],
		curado:[],
		img:[],
	};
	var monitoreos=[]
	var valores=[];
	var variables=[];
	var idvariableRegistro=[]
	var idvariablesRegistro=[]
	var variableEntrada=[];
	var valorEntrada=[];
	for(const i in this.state.monitoreo.id){
		monitoreo.id=this.state.monitoreo.id[i]
		monitoreo.nombre=this.state.monitoreo.nombre[i]
		monitoreo.descripcion=this.state.monitoreo.descripcion[i]
		for(const j in this.state.variable.nombre){
		if(this.state.variable.idMonitoreo[j]===this.state.monitoreo.id[i]){
			var variable={
			id:this.state.variable.id[j],
			nombre:this.state.variable.nombre[j],
			pubObj:this.state.variable.pubObj[j],
			tipo:this.state.variable.tipo[j],
			unidades:this.state.variable.unidades[j],
			}
			monitoreo.variables.push(variable);
		}
		}
		for(const j in this.state.indicador.id){
		if(this.state.indicador.idMonitoreo[j]===this.state.monitoreo.id[i]){
			indicadores.id.push(this.state.indicador.id[j])
			indicadores.idMonitoreo.push(this.state.indicador.idMonitoreo[j])
			indicadores.formula.push(this.state.indicador.formula[j])
			indicadores.nombre.push(this.state.indicador.nombre[j])
			indicadores.pubObj.push(this.state.indicador.pubObj[j])
			indicadores.tipo.push(this.state.indicador.tipo[j])
			indicadores.tipoGrafico.push(this.state.indicador.tipoGrafico[j])
			indicadores.unidades.push(this.state.indicador.unidades[j])
		}
		}
		// console.log(this.state.entradaMonitoreo)
		for(const j in this.state.registroMonitoreo.id){
		if(this.state.monitoreo.id[i] === this.state.registroMonitoreo.idMonitoreo[j]){
			registros.id.push(this.state.registroMonitoreo.id[j])
			registros.iduser.push(this.state.registroMonitoreo.idUser[j])
			registros.nombre.push(this.state.registroMonitoreo.nombre[j])
			registros.date.push(this.state.registroMonitoreo.date[j])
			for(const k in this.state.entradaMonitoreo.id){
			if(this.state.registroMonitoreo.id[j] === this.state.entradaMonitoreo.idRegistro[k]){
				entradas.id.push(this.state.entradaMonitoreo.id[k]);
				entradas.idLocalizacion.push(this.state.entradaMonitoreo.idLocalizacion[k]);
				entradas.curado.push(this.state.entradaMonitoreo.curado[k]);
				entradas.img.push(this.state.entradaMonitoreo.img[k]);
				entradas.especies.push(this.state.entradaMonitoreo.idEspecies[k]);
				for(const l in this.state.variableRegistro.id){
					if(this.state.entradaMonitoreo.id[k] === this.state.variableRegistro.idEntrada[l]){
					idvariableRegistro.push(this.state.variableRegistro.id[l]);
					valorEntrada.push(this.state.variableRegistro.valor[l]);
					variableEntrada.push(this.state.variableRegistro.idVariable[l]);
					}
				}
				idvariablesRegistro.push(idvariableRegistro)  
				variables.push(variableEntrada)  
				valores.push(valorEntrada)  
		idvariableRegistro=[]
				valorEntrada=[]
				variableEntrada=[]
				}
			}
			entradas.variables=variables;
			entradas.valores=valores;
			entradas.idVariablesRegistro=idvariablesRegistro;
			variables=[];
			idvariablesRegistro=[];
			valores=[];
			registros.entradas.push(entradas);
			entradas={id:[], idLocalizacion:[], idVariablesRegistro:[], variables:[], valores:[], curado:[],especies:[], img:[]}
		}
		}
		monitoreo.indicadores=indicadores;
		monitoreo.registros=registros;
		registros={ id:[], iduser:[], nombre:[], entradas:[], date:[] };
		indicadores = { id:[], idMonitoreo:[], formula:[], nombre:[], pubObj:[], tipo:[], tipoGrafico:[], unidades:[],};
		monitoreos.push(monitoreo);
		monitoreo={ id:0, nombre:"", descripcion:"", variables:[],indicadores:[],};
	}
	console.log(monitoreos)
	localStorage.setItem('monitoreos', JSON.stringify(monitoreos))
	this.setState({
		monitoreos:monitoreos
	})
	}
	loadLocalizaciones =async()=>{
	var localizacion={
		id:[],
		sitio:[],
		parcela:[],
		transecto:[],
		PO:[],    
	}
	for(const i in this.state.localizacion.id){
		for(const j in this.state.sitio.id){
		if(this.state.localizacion.idSitio[i] === this.state.sitio.id[j]){
			var sitio= {
			id:this.state.sitio.id[j],
			nombre:this.state.sitio.nombre[j],
			geos:this.state.sitio.geos[j],
			descripcion:this.state.sitio.descripcion[j],
			}
			localizacion.id.push(this.state.localizacion.id[i])
			localizacion.sitio.push(sitio)
			var verify = localizacion.PO.length
			for(const k in this.state.PO.id){
			if(this.state.PO.id[k]=== this.state.localizacion.idPO[i]){
				var ubicacionpo = {
				id:this.state.PO.id[k],
				geos:this.state.PO.geos[k],
				nombre:this.state.PO.nombre[k],
				descripcion:this.state.PO.descripcion[k],
				}
				localizacion.PO.push(ubicacionpo)
			}
			}
			if(verify === localizacion.PO.length)
			localizacion.PO.push(null)
			verify=localizacion.transecto.length
			for(const k in this.state.transecto.id){
			if(this.state.transecto.id[k]=== this.state.localizacion.idTransecto[i]){
				var ubicaciontrans = {
				id:this.state.transecto.id[k],
				geos:this.state.transecto.geos[k],
				nombre:this.state.transecto.nombre[k],
				descripcion:this.state.transecto.descripcion[k],
				}
				localizacion.transecto.push(ubicaciontrans)
			}
			}
			if(verify === localizacion.transecto.length)
			localizacion.transecto.push(null)
			verify=localizacion.parcela.length
			for(const k in this.state.parcela.id){
			if(this.state.parcela.id[k]=== this.state.localizacion.idParcela[i]){
				var ubicacionparce = {
				id:this.state.parcela.id[k],
				geos:this.state.parcela.geos[k],
				nombre:this.state.parcela.nombre[k],
				descripcion:this.state.parcela.descripcion[k],
				}
				localizacion.parcela.push(ubicacionparce)
			}
			}
			if(verify === localizacion.parcela.length)
			localizacion.parcela.push(null)
		}
		}
	}
	localStorage.setItem('localizaciones', JSON.stringify(localizacion))
	this.setState({
		datasetLocalizaciones:localizacion
	})
	}
	initial_state=async()=>{
	await this.Getespecies();
	await this.getData()
	}
    setOpen=()=>{
      this.setState({open:!this.state.open})
    }
	handleInputChange = (cellInfo, event) => {
		let data = [...this.state.data];
		data[cellInfo.index][cellInfo.column.id] = event.target.value;
	
		this.setState({ data });
	};
	onChange = (event, selectedMonitoreo ) => {
	this.setState({
		selectedMonitoreo:selectedMonitoreo,
		form: {
			...this.state.form,
			[event.target.name]:event.target.value,
		}
	});
	};
	AgregarRegistro=()=>{
		var location = this.props.location;
		var selectedMonitoreo=(location.state)
		// console.log(selectedMonitoreo)
		var tabla=
		<>
    <div className="row col-xl-12">
    <div className="col-lg-6 mt-5 pd-5">
      <p className="pd-5"> Nombre</p>
      <input type="text" className="form-control mt-5" name="nombre" onChange={(event)=> this.onChange(event, selectedMonitoreo)}/>
    </div>
    <div className="col-lg-6 mt-5 pd-5">
      <p className="pd-5"> Fecha</p>
      <input type="date"className="form-control mt-5"  name="fecha" onChange={(event)=> this.onChange(event, selectedMonitoreo)}/>
    </div>
  </div>
		</>
		return (tabla)
	}
	cancelarAgregar=()=>{
		this.setState({
		open:false,
		form:{
			fecha:"",
			nombre:"",
			idMonitoreo:"",
			idUser:"",
		}

		})
	}
	TablaRegistrosEditable=()=>{
		var location = this.props.location
		var selectedMonitoreo=(location.state)
		var dataset=[]
		var datos={
			id:"",
			date:"",
			nombre:"",
			numEntradas:"",
			}
		// console.log(selectedMonitoreo)
		if(this.state.monitoreos.length > 0){
		for(const i in this.state.monitoreos){
			if(i === selectedMonitoreo){
				for(const j in this.state.monitoreos[i].registros.date){
					if(this.state.monitoreos[i].registros.iduser[j]=== this.props.Iduser){
            var realdate =  new Date (this.state.monitoreos[i].registros.date[j]*1000)
            datos.date = realdate.getDate()+"/"+(realdate.getMonth()+1)+"/"+realdate.getFullYear()
						datos.id = this.state.monitoreos[i].registros.id[j]
						datos.nombre = this.state.monitoreos[i].registros.nombre[j]
						datos.numEntradas=this.state.monitoreos[i].registros.entradas[j].valores.length
						dataset.push(datos)
						datos={
							id:"",
							date:"",
							nombre:"",
							numEntradas:"",
							}
						}
				}
			}
		}
		var columnas = [
			{
				Header: 'Registros',
				columns: [
					{
						Header: 'Fecha',
						accessor: 'date',
					},
					{
						Header: 'Nombre',
						accessor: 'nombre',
						Cell:EditableCell
					},
					{
						Header: '#Entradas',
						accessor: 'numEntradas'
					},
				],
			},
		]
		var html = TablaRegistro(dataset,columnas,this.state.monitoreos,this.props.Iduser,this.props.location)
		return(<>
				{html}
				</>
		)
		}else{
		return(<>
			
			</>
		)
		}
	}
	ingresarregistroMonitoreo=async(body)=>{
		var baseurl = "https://api.restaured.net"
		var url = baseurl+'/restaured/registromonitoreo'
		var data = JSON.stringify([body])
		var config = {
		method: 'post',
		url: url,
		headers: { 
			'Content-Type': 'application/json'
		},
		withCredentials:true,
		data: data
		};
		await axios(config)
		.then( response => response.data)
		.catch(error => {
			var local = localStorage.getItem('registroQueries')	
			var id = uuidv4()
			if(local !== null){
				local = JSON.parse(local)
			}else{
				local =[]
			}
			console.log(error, config)
			var datosRM = JSON.stringify([{
				id:id,
				idMonitoreo:this.state.monitoreos[this.props.location.state].id,
				idUser:this.state.Iduser,
				nombre:this.state.form.nombre,
				date:(new Date(this.state.form.fecha).getTime()/1000)
			}])
			var datosRM = JSON.stringify([{
				id:id,
				idMonitoreo:this.state.monitoreos[this.props.location.state].id,
				idUser:this.state.Iduser,
				nombre:this.state.form.nombre,
				date:(new Date(this.state.form.fecha).getTime()/1000)
			}])
			local.push({
				method:'post',
				url:url,
				headers: { 
					'Content-Type': 'application/json'
				},
				withCredentials:true,
				data:datosRM
			})
			localStorage.setItem('registroQueries', JSON.stringify(local))
			var monitoreos = localStorage.getItem('monitoreos')
			monitoreos = JSON.parse(monitoreos)
			monitoreos[this.props.location.state].registros.id.push(id)
			monitoreos[this.props.location.state].registros.iduser.push(body.idUser)
			monitoreos[this.props.location.state].registros.nombre.push(body.nombre)
			monitoreos[this.props.location.state].registros.entradas.push({
				"id": [],
				"idLocalizacion": [],
				"idVariablesRegistro": [],
				"variables": [],
				"valores": [],
				"curado": [],
				"especies": [],
				"img": []
			  })
			monitoreos[this.props.location.state].registros.date.push(body.date)
			console.log(monitoreos)
			localStorage.setItem('monitoreos', JSON.stringify(monitoreos))
		})
	}
	confirmarAgregar= async()=>{
	console.log(this.props.location.state)
	var registroMonitoreo={ 
		idMonitoreo:this.state.monitoreos[this.props.location.state].id,
		idUser:this.state.Iduser,
		nombre:this.state.form.nombre,
		date:(new Date(this.state.form.fecha).getTime()/1000)
	}
	console.log(registroMonitoreo)
	await this.ingresarregistroMonitoreo(registroMonitoreo)
	this.setOpen()
	this.setState({redirect:true})

	}
	renderEditable = (cellInfo, data) => {
		const cellValue = this.state.data[cellInfo.index][cellInfo.column.id];

		return (
		<input
			placeholder="type here"
			name="input"
			type="text"
			onChange={this.handleInputChange.bind(null, cellInfo)}
			value={cellValue}
		/>
		);
	};
	viewTablaRegistros=()=> {
		var html = this.TablaRegistrosEditable()
		return (<> 
		{html}
		</>
		)
	}

    render(){
		const alertOffline =()=>{
			var online = navigator.onLine ? "ONLINE" : "OFFLINE";
			if(online === 'OFFLINE')
			return  <Alert variant={'primary'} > Te encuentras Offline, Únicamente puedes Agregar o eliminar elementos </Alert>
			else return null
		}
    return (
        <div>
            <div>
                <div id="wrapper">
                <NavbarUC/>
                {/* Content Wrapper */}
                <div id="content-wrapper" className="d-flex flex-column">
                    {/* Main Content */}
                    <div id="content">
                    <HeaderUsers Iduser={this.props.Iduser} Rol={this.props.Rol} Nombre={this.props.Nombre} External={this.props.External}/>
					{this.props.Nombre ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
						{this.props.Iduser ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
						{this.props.Rol ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
                    {/* Begin Page Content */}
                    <div className="container-fluid">
					{alertOffline()}
                        {/* Page Heading */}
                        { this.state.redirect ? <Redirect push to= {{pathname:"/restaured.CzScie/reloaderregistro",state:this.props.location.state}}></Redirect> : null}
                        <h1 className="h3 mb-2 text-gray-800">Registros Monitoreo</h1>
                        <h5><span><p> 
                        <div className="row">
                          <div className="col-lg-4"/>
                            <div className="col-lg-4">
                            <p onClick={this.setOpen} aria-controls="collapse-new-entry" 
                            aria-expanded={this.state.open} className="cta-btn page-link" type="button" >
                            <BsPlusCircle/> <dr/>
                                Nuevo Registro</p>
                                </div>
												</div>
                            <Collapse in={this.state.open}>
                            <div className="col-lg-4 card shadow mb-4 ml-3 mr-2">
																<h6 className="m-0 font-weight-bold text-primary pt-5">Ingresa un Nuevo Registros de Monitoreo</h6>
																<div className="row mt-5 mb-5"> 
																	<this.AgregarRegistro />
																</div>
																<div className="row mt-5 mb-5 pb-5">
																		<div className="col-lg-6 mb-5 pd-5">
																		<button className="btn-primary" onClick={this.cancelarAgregar}> Cancelar</button>
																		</div>
																		<div className="col-lg-6 mb-5 pd-5">
																				<button className="btn-primary" type="submit" onClick={this.confirmarAgregar}> Confirmar</button>
																		</div>
																</div>
															</div>
                            </Collapse>
                        </p></span></h5>
                        {/* DataTales Example */}
                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary">Administra tus Registros de Monitoreo</h6>
                            </div>
							              <h4> Seleccione los registros y borrelos o cambie su nombre y guarde los cambios  </h4>
                            <this.viewTablaRegistros/>
                            <div className="col-xl-12"> 
                              <Link to={{pathname:"/restaured.CzScie/monitoreosusers",state:this.props.location.state}}>
                                  <button className="btn border"> Volver</button>
                              </Link>
                          </div>
                        </div>
                        <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">Visualiza todos los Registros de Monitoreo</h6>
                        </div>
                        <TablaRegistros Iduser={this.props.Iduser} Rol={this.props.Rol} Nombre={this.props.Nombre} External={this.props.External}/>
                        </div>
                    </div>
                    {/* /.container-fluid */}
                    </div>
                    {/* End of Main Content */}
                    {/* Footer */}
                     <footer id="footer" className="sticky-footer">
                    <div className="container my-auto">
                        <div className="copyright text-center my-auto">
                        © Copyright <strong><span>Restaured 2024</span></strong>. All Rights Reserved
                        </div>
                    </div>
                    </footer>
                    {/* End of Footer */}
                </div>
                {/* End of Content Wrapper */}
                </div>
                {/* End of Page Wrapper */}
                {/* Scroll to Top Button*/}
                <a className="scroll-to-top rounded" href="#page-top">
                <i className="fas fa-angle-up" />
                </a>
                {/* Logout Modal*/}
                <Logout/>
                {/* Bootstrap core JavaScript*/}
                {/* Core plugin JavaScript*/}
                {/* Custom scripts for all pages*/}
                {/* Page level plugins */}
                {/* Page level custom scripts */}
            </div>
        </div>
      )}
  
};

export default RegistrosMonitoreos