import React, { Component } from 'react'
import '../../../assets/vendor/bootstrap/css/bootstrap.css'
import '../../../assets/css/sb-admin-2.css'
import NavbarA from '../../NavBar/NavbarAdmin'
import StackedBar from '../../Charts/StackedBar'
import { Redirect} from 'react-router'
import PieChart from '../../Charts/PieChart'
import HeaderUsers from './HeaderUsers'
import { BsFillTrashFill, BsFillEyeFill, BsFillEyeSlashFill,BsPencil } from "react-icons/bs";

class MonitoreosUsersCU extends Component{
    constructor(props){
        super(props);
        this.state={
            Rol: this.props.Rol,
            Token: this.props.Token,
            Iduser: this.props.Iduser,
        }
    }  

    

    render(){
    return (
        <div>
            <div>
                <div id="wrapper">
                <NavbarA/>
                {/* Content Wrapper */}
                <div id="content-wrapper" className="d-flex flex-column">
                    {/* Main Content */}
                    <div id="content">
                    <HeaderUsers Iduser={this.props.Iduser} Rol={this.props.Rol} Nombre={this.props.Nombre} External={this.props.External}/>
					{this.props.Nombre ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
					{this.props.Iduser ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
					{this.props.Rol ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
                    {/* Begin Page Content */}
                    <div className="container-fluid">
                        {/* Page Heading */}
                        <h1 className="h3 mb-2 text-gray-800">Administración Monitoreos</h1>
                        {/* DataTales Example */}
                        <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">Administración</h6>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                            <table className="table table-bordered" id="dataTable" width="100%" cellSpacing={0}>
                                <thead>
                                <tr>
                                    <th>Nombre Monitoreo</th>
                                    <th>Acción</th>
                                    <th>Edición</th>
                                    <th>Visualización</th>
                                </tr>
                                </thead>
                                <tfoot>
                                <tr>
                                    <th>Nombre Monitoreo</th>
                                    <th><i className="fas ffas fa-lock-openi></th>
                                <t  class=" fas ffas fa-lock-openi /></th>    <th><i className="fas fa-edit" /></th>
                                    <th><i className="far fa-eye" /></th>
                                </tr>
                                </tfoot>
                                <tbody>
                                <tr>
                                    <td>Vegetación</td>
                                    <td><BsFillTrashFill type="button"/> </td>
                                    <td><BsPencil type="button"/></td>
                                    <td><BsFillEyeFill type="button"/> <BsFillEyeSlashFill type="button"/></td>
                                </tr>
                                <tr>
                                    <td>Hormigas</td>
                                    <td><BsFillTrashFill type="button"/> </td>
                                    <td><BsPencil type="button"/></td>
                                    <td><BsFillEyeFill type="button"/> <BsFillEyeSlashFill type="button"/></td>
                                </tr>
                                <tr>
                                    <td>Aves</td>
                                    <td><BsFillTrashFill type="button"/> </td>
                                    <td><BsPencil type="button"/></td>
                                    <td><BsFillEyeFill type="button"/> <BsFillEyeSlashFill type="button" /></td>
                                </tr>
                                </tbody>
                            </table>
                            </div>
                        </div>
                        </div>
                        {/* Content Row */}
                        <div className="row">
                        {/* Pie Chart - StackBar Chart*/}
                        <div className="col-lg-6">
                            <div className="card shadow mb-4">
                                {/* Card Header - Dropdown */}
                                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                <h6 className="m-0 font-weight-bold text-primary">Numero de Hembras y Machos</h6>
                                </div>
                                {/* Card Body */}
                                <div className="card-body">
                                    <StackedBar/>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="card shadow mb-4">
                                {/* Card Header - Dropdown */}
                                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                <h6 className="m-0 font-weight-bold text-primary">Numero de Hembras y Machos</h6>
                                </div>
                                {/* Card Body */}
                                <div className="card-body">
                                    <PieChart/>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    {/* /.container-fluid */}
                    </div>
                    {/* End of Main Content */}
                    {/* Footer */}
                     <footer id="footer" className="sticky-footer">
                    <div className="container my-auto">
                        <div className="copyright text-center my-auto">
                        © Copyright <strong><span>Restaured 2024</span></strong>. All Rights Reserved
                        </div>
                    </div>
                    </footer>
                    {/* End of Footer */}
                </div>
                {/* End of Content Wrapper */}
                </div>
                {/* End of Page Wrapper */}
                {/* Scroll to Top Button*/}
                <a className="scroll-to-top rounded" href="#page-top">
                <i className="fas fa-angle-up" />
                </a>
                {/* Logout Modal*/}
                <div className="modal fade" id="logoutModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">¿Quieres salir?</h5>
                        <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">Selecciona Salir, si estás listo para cerrar sesión.</div>
                    <div className="modal-footer">
                        <button className="btn btn-secondary" type="button" data-dismiss="modal">Cancelar</button>
                        <a className="btn btn-primary" href="login.html">Salir</a>
                    </div>
                    </div>
                </div>
                </div>
                {/* Bootstrap core JavaScript*/}
                {/* Core plugin JavaScript*/}
                {/* Custom scripts for all pages*/}
                {/* Page level plugins */}
                {/* Page level custom scripts */}
            </div>
        </div>
      )}
  
};

export default MonitoreosUsersCU