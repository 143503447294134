import React from 'react'
import '../../assets/vendor/bootstrap/css/bootstrap.css'
import '../../assets/css/sb-admin-2.css'
import NavbarA from '../NavBar/NavbarAdmin'
import Header from './Header'
import Logout from './Logout'
import { Redirect } from 'react-router'
import { BsCloudUpload, BsPencil,BsEye, BsCloudDownload, } from "react-icons/bs";
import { useTable, useFilters, useGlobalFilter,useRowSelect, useAsyncDebounce, usePagination } from 'react-table'
import {Link} from 'react-router-dom'
import L from 'leaflet';
import Icono from '../../assets/img/iconMap.png'
import GeoFake from '../../assets/geo_fake.zip'
import { MapContainer, TileLayer,GeoJSON } from 'react-leaflet'

const axios = require('axios');

function GlobalFilter({
preGlobalFilteredRows,
globalFilter,
setGlobalFilter,
}) {
const count = preGlobalFilteredRows.length
const [value, setValue] = React.useState(globalFilter)
const onChange = useAsyncDebounce(value => {
		setGlobalFilter(value || undefined)
}, 200)

return (
		<span>
		Search:{' '}
		<input
				value={value || ""}
				onChange={e => {
				setValue(e.target.value);
				onChange(e.target.value);
				}}
				placeholder={`${count} records...`}
				style={{
				fontSize: '1.1rem',
				border: '0',
				}}
		/>
		</span>
)
}

function DefaultColumnFilter({
column: { filterValue, preFilteredRows, setFilter },
}) {
const count = preFilteredRows.length

return (
		<input
				className="form-control"
				value={filterValue || ''}
				onChange={e => {
						setFilter(e.target.value || undefined)
				}}
				placeholder={`Search ${count} records...`}
		/>
)
}
const IndeterminateEdit =(row)=>{
		return (
				<>
				<Link to={{pathname:"/restaured.scie/registrosusers" ,state: row.selectedRowIds.id}} >
						<p className=' ' type="button" > <BsPencil/> </p>
				</Link>
				</>
		)
}

const IndeterminateView =(row)=>{
		return (
				<>
				<Link to={{pathname:"/restaured.scie/cargarusers" ,state: row.selectedRowIds.id}}>
						<p className=' ' type="button"> <BsEye/> </p>
				</Link>
				</>
		)
}
function Table({ columns, data }) {

const defaultColumn = React.useMemo(
		() => ({
				// Default Filter UI
				Filter: DefaultColumnFilter,
		}),
		[]
)

const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state,
		preGlobalFilteredRows,
		setGlobalFilter,
		state: { pageIndex, pageSize },
} = useTable(
		{
				columns,
				data,
				defaultColumn,
				initialState:{pageSize:5}
		},
		useFilters,
		useGlobalFilter,
		usePagination,
		useRowSelect,
)
return (
		<div>
				<GlobalFilter
						preGlobalFilteredRows={preGlobalFilteredRows}
						globalFilter={state.globalFilter}
						setGlobalFilter={setGlobalFilter}
				/>
				<table className="table" {...getTableProps()}>
						<thead>
								{headerGroups.map(headerGroup => (
										<tr {...headerGroup.getHeaderGroupProps()}>
												{headerGroup.headers.map(column => (
														<th {...column.getHeaderProps()}>
																{column.render('Header')}
																{/* Render the columns filter UI */}
																<div>{column.canFilter ? column.render('Filter') : null}</div>
														</th>
												))}
										</tr>
								))}
						</thead>
						<tbody {...getTableBodyProps()}>
						{page.map((row, i) => {
								prepareRow(row)
								return (
								<tr {...row.getRowProps()}>
										{row.cells.map(cell => {
										return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
										})}
								</tr>
								)
						})}
						</tbody>
				</table>
				<br />
				<div className="pagination">
		<button className="page-link"onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
				{'<<'}
		</button>{' '}
		<button className="page-link"onClick={() => previousPage()} disabled={!canPreviousPage}>
				{'<'}
		</button>{' '}
		<button className="page-link"onClick={() => nextPage()} disabled={!canNextPage}>
				{'>'}
		</button>{' '}
		<button className="page-link"onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
				{'>>'}
		</button>{' '}
		<span className="page-link">
				Page{' '}
				<strong>
				{pageIndex + 1} of {pageOptions.length}
				</strong>{' '}
		</span>
		<span className="page-link">
				| Go to page:{' '}
				<input
				type="number"
				defaultValue={pageIndex + 1}
				onChange={e => {
						const page = e.target.value ? Number(e.target.value) - 1 : 0
						gotoPage(page)
				}}
				style={{ width: '100px' }}
				/>
		</span>{' '}
		<select
		 	className="page-link"
				value={pageSize}
				onChange={e => {
				setPageSize(Number(e.target.value))
				}}
		>
				{[5, 10, 15, 20, 25].map(pageSize => (
				<option key={pageSize} value={pageSize}>
						Show {pageSize}
				</option>
				))}
		</select>
		</div>
		</div>
)
}
function TablaLocalizaciones(dataset,columnas){
	const columns = React.useMemo(() => columnas)
	const [data] = React.useState(() =>dataset)
	return (
	<div className="table-responsive mb-5">
			<Table
			columns={columns}
			data={data}
			/>
	</div>
)
}

export default class Editlocation extends React.Component{
	constructor(props){
		super(props);
		this.state={
				Rol: this.props.Rol,
				Nombre: this.props.Nombre,
				Iduser: this.props.Iduser,
				piechartdata: [],
				kmlfile:null,
				previewMap:null,
				geoJsonParcela:null,
				geoJsonSitio:null,
				geoJsonPunto:null,
				geoJsonTransecto:null,
				datasetLocalizaciones: [],
				massiveSitio:[],
				massivePunto:[],
				massiveParcela:[],
				massiveTransecto:[],
				especie:{
						id:[],
						nombreCientifico:[],
						nombreComun:[],
						activo:[],
						idLocalizacion:[],
					},
				variableRegistro:{
					id:[],
					idEntrada:[],
					idVariable:[],
					valor:[],
				},
				entradaMonitoreo:{
					id:[],
					idLocalizacion:[],
					curado:[],
					idRegistro:[],
					idEspecies:[],
				},
				variable:{
					id:[],
					idMonitoreo:[],
					nombre:[]
				},
				variablexindicador:{
					id:[],
					idIndicador:[],
					idVariable:[]
				},
				indicador:{
						id:[],
						idIndicador:[],
						idVariable:[]
					},
				monitoreo:{
					id:[],
					nombre:[],
					descripcion:[],
				},
				registroMonitoreo:{
					id:[],
					nombre:[],
					idUser:[],
					idMonitoreo:[],
					date:[],
				},
				localizacion:{
					id:[],
					idSitio:[],
					isParcela:[],
					idTransecto:[],
					idPO:[],
				},
				sitio:{
					id:[],
					nombre:[],
					geos:[],
					descripcion:[],
				},
				parcela:{
					id:[],
					nombre:[],
					geos:[],
					descripcion:[],
				},
				transecto:{
					id:[],
					nombre:[],
					geos:[],
					descripcion:[],
				},
				PO:{
					id:[],
					nombre:[],
					geos:[],
					descripcion:[],
				},
				monitoreos:[],
				location:[],		
			}
				this.Getespecies();
				this.initial_state();
			}
	// HANDLE
	setFile=async(E)=>{
		console.log(E)
		const form = new FormData()
		await form.append('file', E[0])
		console.log(form)
		this.setState({kmlfile:E[0]})
	}
	//PETICIONES
	Getespecies=async()=>{
		var baseurl = "https://api.restaured.net"
		const url = baseurl+'/restaured/especie'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			var id =[];
			var nombreCientifico =[];
			var nombreComun =[];
			var idLocalizacion =[];
			var activo =[];
			for(const i in data.body){
				id.push(data.body[i]._id)
				idLocalizacion.push(data.body[i].idLocalizacion)
				nombreCientifico.push(data.body[i].nombreCientifico)
				nombreComun.push(data.body[i].nombreComun)
				activo.push(data.body[i].activo)
			};
			this.setState({
				especie:{
					id:id,
					idLocalizacion:idLocalizacion,
					nombreCientifico:nombreCientifico,
					nombreComun:nombreComun,
					activo:activo,
				}});
		})
		// console.log(this.state.especies);
	} 
	GetDataTable=async()=>{
		var baseurl = "https://api.restaured.net"
		var url = baseurl+'/restaured/variable'
		await axios.get(url,{withCredentials: true})
		.then(response => response.data)
		.then(data => {
			// console.log(data)
			var id =[];
			var idMonitoreo =[];
			var nombre =[];
			var pubObj =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				idMonitoreo.push(data.body[i].idMonitoreo);
				nombre.push(data.body[i].nombre);
				pubObj.push(data.body[i].pubObj);
			};
			this.setState({
				variable:{
							id:id,
							idMonitoreo:idMonitoreo,
							nombre:nombre,
							pubObj:pubObj,
			}})
		})
		url = baseurl+'/restaured/variablexindicador'
		await axios.get(url,{withCredentials:true})
		.then(response => response.data)
		.then(data => {
			// console.log(data)
			var id =[];
			var idIndicador =[];
			var idVariable =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				idIndicador.push(data.body[i].idIndicador);
				idVariable.push(data.body[i].idVariable);
			};
			this.setState({
				variablexindicador:{
							id:id,
							idIndicador:idIndicador,
							idVariable:idVariable,
			}})
		})
		url = baseurl+'/restaured/indicador'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			// console.log(data)
			var id =[];
			var idMonitoreo =[];
			var formula =[];
			var nombre =[];
			var pubObj =[];
			var tipo =[];
			var tipoGrafico =[];
			var unidades =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				idMonitoreo.push(data.body[i].idMonitoreo);
				formula.push(data.body[i].formula);
				nombre.push(data.body[i].nombre);
				pubObj.push(data.body[i].pubObj);
				tipo.push(data.body[i].tipo);
				tipoGrafico.push(data.body[i].tipoGrafico);
				unidades.push(data.body[i].unidades);
			};
			this.setState({
				indicador:{
						id:id,
						idMonitoreo:idMonitoreo,
						formula:formula,
						nombre:nombre,
						pubObj:pubObj,
						tipo:tipo,
						tipoGrafico:tipoGrafico,
						unidades:unidades,
			}})
		})
		url = baseurl+'/restaured/localizacion'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			console.log(data)
			var id =[];
			var idSitio =[];
			var idParcela =[];
			var idTransecto =[];
			var idPO =[];
			var nombre =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				idSitio.push(data.body[i].idSitio);
				idParcela.push(data.body[i].idParcela);
				idTransecto.push(data.body[i].idTransecto);
				idPO.push(data.body[i].idPO);
				nombre.push(data.body[i].nombre);
			};
			this.setState({
				localizacion:{
							id:id,
							idSitio:idSitio,
							idParcela:idParcela,
							idTransecto:idTransecto,
							idPO:idPO,
							nombre:nombre,
			}})
		})
		url = baseurl+'/restaured/sitio'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			var id =[];
			var nombre =[];
			var geos =[];
			var descripcion =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				geos.push(data.body[i].geos);
				descripcion.push(data.body[i].descripcion);
				nombre.push(data.body[i].nombre);
			};
			this.setState({
				sitio:{
							id:id,
							nombre:nombre,
							geos:geos,
							descripcion:descripcion,
			}})
		})
		url = baseurl+'/restaured/puntoobservacion'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			var id =[];
			var nombre =[];
			var geos =[];
			var descripcion =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				geos.push(data.body[i].geos);
				descripcion.push(data.body[i].descripcion);
				nombre.push(data.body[i].nombre);
			};
			this.setState({
				PO:{
							id:id,
							nombre:nombre,
							geos:geos,
							descripcion:descripcion,
			}})
		})
		url = baseurl+'/restaured/transecto'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			var id =[];
			var nombre =[];
			var geos =[];
			var descripcion =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				geos.push(data.body[i].geos);
				descripcion.push(data.body[i].descripcion);
				nombre.push(data.body[i].nombre);
			};
			this.setState({
				transecto:{
							id:id,
							nombre:nombre,
							geos:geos,
							descripcion:descripcion,
			}})
		})
		url = baseurl+'/restaured/parcela'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			var id =[];
			var nombre =[];
			var geos =[];
			var descripcion =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				geos.push(data.body[i].geos);
				descripcion.push(data.body[i].descripcion);
				nombre.push(data.body[i].nombre);
			};
			this.setState({
				parcela:{
							id:id,
							nombre:nombre,
							geos:geos,
							descripcion:descripcion,
			}})
		})
		url = baseurl+'/restaured/monitoreo'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			// console.log(data)
			var id =[];
			var nombre =[];
			var descripcion =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				nombre.push(data.body[i].nombre);
				descripcion.push(data.body[i].descripcion);
			};
			this.setState({
				monitoreo:{
					id:id,
					nombre:nombre,
					descripcion:descripcion,
			}})
		})
		url = baseurl+'/restaured/registromonitoreo'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			//Extraer Fecha y agegarla al modelo Monitoreo
			// var date= new Date(data.body[0].date*1000);
			// console.log(date);
			// console.log(data.body[0]);
			var id =[];
			var nombre =[];
			var idMonitoreo =[];
			var idUser =[];
			var date=[]
			for(const i in data.body){
				id.push(data.body[i]._id);
				nombre.push(data.body[i].nombre);
				idUser.push(data.body[i].idUser);
				idMonitoreo.push(data.body[i].idMonitoreo);
				date.push(data.body[i].date);
			};
			this.setState({
				registroMonitoreo:{
					id:id,
					nombre:nombre,
					idUser:idUser,
					idMonitoreo:idMonitoreo,
					date:date,
			}})
		})
		url = baseurl+'/restaured/entradamonitoreo'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			//Extraer Localización y agegarla al modelo Monitoreo
			// console.log(data)
			var id =[];
			var idLocalizacion =[];
			var curado =[];
			var idRegistro =[];
			var idEspecies =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				idLocalizacion.push(data.body[i].idLocalizacion);
				curado.push(data.body[i].curado);
				idRegistro.push(data.body[i].idRegistro);
				idEspecies.push(data.body[i].idEspecie);
			};
			this.setState({
				entradaMonitoreo:{
							id:id,
							idLocalizacion:idLocalizacion,
							curado:curado,
							idRegistro:idRegistro,
							idEspecies:idEspecies,
			}})
		})
		url = baseurl+'/restaured/variableregistro'
		await axios.get(url, {withCredentials:true})
		.then(response => response.data)
		.then(data => {
			// console.log(data)
			var id =[];
			var idEntrada =[];
			var idVariable =[];
			var valor =[];
			for(const i in data.body){
				id.push(data.body[i]._id);
				idEntrada.push(data.body[i].idEntrada);
				idVariable.push(data.body[i].idVariable);
				valor.push(data.body[i].valor);
			};
			this.setState({
				variableRegistro:{
							id:id,
							idEntrada:idEntrada,
							idVariable:idVariable,
							valor:valor,
			}})
		})
	}
	viewdata=async()=>{
		await this.GetDataTable()
		var monitoreo={
			id:0,
			nombre:"",
			descripcion:"",
			variables:[],
			registros:[],
			indicadores:[],
		};
		var indicadores = {
			id:[],
			idMonitoreo:[],
			formula:[],
			nombre:[],
			pubObj:[],
			tipo:[],
			tipoGrafico:[],
			unidades:[],
		};
		var registros={
				id:[],
				nombre:[],
				entradas:[],
				date:[],
		};
		var entradas={
				id:[],  
				variables:[],
				especies:[],
				valores:[],
				curado:[],
		};
		var monitoreos=[]
		var valores=[];
		var variables=[];
		var variableEntrada=[];
		var valorEntrada=[];
		for(const i in this.state.monitoreo.id){
			monitoreo.id=this.state.monitoreo.id[i]
			monitoreo.nombre=this.state.monitoreo.nombre[i]
			monitoreo.descripcion=this.state.monitoreo.descripcion[i]
			for(const j in this.state.variable.nombre){
				if(this.state.variable.idMonitoreo[j]===this.state.monitoreo.id[i]){
					var variable={
						id:this.state.variable.id[j],
						nombre:this.state.variable.nombre[j]
					}
					monitoreo.variables.push(variable);
				}
			}
			for(const j in this.state.indicador.id){
				if(this.state.indicador.idMonitoreo[j]===this.state.monitoreo.id[i]){
					indicadores.id.push(this.state.indicador.id[j])
					indicadores.idMonitoreo.push(this.state.indicador.idMonitoreo[j])
					indicadores.formula.push(this.state.indicador.formula[j])
					indicadores.nombre.push(this.state.indicador.nombre[j])
					indicadores.pubObj.push(this.state.indicador.pubObj[j])
					indicadores.tipo.push(this.state.indicador.tipo[j])
					indicadores.tipoGrafico.push(this.state.indicador.tipoGrafico[j])
					indicadores.unidades.push(this.state.indicador.unidades[j])
				}
			}
			// console.log(this.state.entradaMonitoreo)
			for(const j in this.state.registroMonitoreo.id){
				if(this.state.monitoreo.id[i] === this.state.registroMonitoreo.idMonitoreo[j]){
					registros.id.push(this.state.registroMonitoreo.id[j])
					registros.nombre.push(this.state.registroMonitoreo.nombre[j])
					registros.date.push(this.state.registroMonitoreo.date[j])
					for(const k in this.state.entradaMonitoreo.id){
						if(this.state.registroMonitoreo.id[j] === this.state.entradaMonitoreo.idRegistro[k]){
							entradas.id.push(this.state.entradaMonitoreo.id[k]);
							entradas.curado.push(this.state.entradaMonitoreo.curado[k]);
							entradas.especies.push(this.state.entradaMonitoreo.idEspecies[k]);
								for(const l in this.state.variableRegistro.id){
									if(this.state.entradaMonitoreo.id[k] === this.state.variableRegistro.idEntrada[l]){
										valorEntrada.push(Number(this.state.variableRegistro.valor[l]));
										variableEntrada.push(this.state.variableRegistro.idVariable[l]);
									}
								}
							variables.push(variableEntrada)  
							valores.push(valorEntrada)  
							valorEntrada=[]
							variableEntrada=[]
							}
						}
						entradas.variables=variables;
						entradas.valores=valores;
						variables=[];
						valores=[];
						registros.entradas.push(entradas);
						// console.log(entradas)
						entradas={id:[],variables:[], valores:[], curado:[],especies:[],}
				}
			}
			monitoreo.indicadores=indicadores;
			monitoreo.registros=registros;
			registros={ id:[],nombre:[], entradas:[], date:[] };
			indicadores = { id:[], idMonitoreo:[], formula:[], nombre:[], pubObj:[], tipo:[], tipoGrafico:[], unidades:[],};
			monitoreos.push(monitoreo);
			// console.log(monitoreo)
			monitoreo={ id:0, nombre:"", descripcion:"", variables:[],indicadores:[],};
		}
		// console.log(monitoreos)
		this.setState({
			monitoreos:monitoreos
		})
	}
	loadLocalizaciones =async()=>{
		var localizacion={
			id:[],
			sitio:[],
			parcela:[],
			transecto:[],
			PO:[],    
		}
		for(const i in this.state.localizacion.id){
			for(const j in this.state.sitio.id){
				if(this.state.localizacion.idSitio[i] === this.state.sitio.id[j]){
					var sitio= {
						id:this.state.sitio.id[j],
						nombre:this.state.sitio.nombre[j],
						geos:this.state.sitio.geos[j],
						descripcion:this.state.sitio.descripcion[j],
					}
					localizacion.id.push(this.state.localizacion.id[i])
					localizacion.sitio.push(sitio)
					var verify = localizacion.PO.length
					for(const k in this.state.PO.id){
						if(this.state.PO.id[k]=== this.state.localizacion.idPO[i]){
							var ubicacionpo = {
								id:this.state.PO.id[k],
								geos:this.state.PO.geos[k],
								nombre:this.state.PO.nombre[k],
								descripcion:this.state.PO.descripcion[k],
							}
							localizacion.PO.push(ubicacionpo)
						}
					}
					if(verify === localizacion.PO.length)
						localizacion.PO.push(null)
					verify=localizacion.transecto.length
					for(const k in this.state.transecto.id){
						if(this.state.transecto.id[k]=== this.state.localizacion.idTransecto[i]){
							var ubicaciontrans = {
								id:this.state.transecto.id[k],
								geos:this.state.transecto.geos[k],
								nombre:this.state.transecto.nombre[k],
								descripcion:this.state.transecto.descripcion[k],
							}
							localizacion.transecto.push(ubicaciontrans)
						}
					}
					if(verify === localizacion.transecto.length)
						localizacion.transecto.push(null)
					verify=localizacion.parcela.length
					for(const k in this.state.parcela.id){
						if(this.state.parcela.id[k]=== this.state.localizacion.idParcela[i]){
							var ubicacionparce = {
								id:this.state.parcela.id[k],
								geos:this.state.parcela.geos[k],
								nombre:this.state.parcela.nombre[k],
								descripcion:this.state.parcela.descripcion[k],
							}
							localizacion.parcela.push(ubicacionparce)
						}
					}
					if(verify === localizacion.parcela.length)
						localizacion.parcela.push(null)
				}
			}
		}
		this.setState({
			datasetLocalizaciones:localizacion
		})
	}
	initial_state=async()=>{
		await this.viewdata()
		await this.loadLocalizaciones();
	}

	TablaLocalizacion=()=>{
		var dataset=[]
		if(this.state.monitoreos.length > 0){
		if(this.state.datasetLocalizaciones.length === undefined){

			var datos={
					sitio:"",
					transecto:"",
					parcela:"",
					po:""
				}
			console.log(this.state.datasetLocalizaciones)
			for(const i in this.state.datasetLocalizaciones.id){
				datos.sitio = String(this.state.datasetLocalizaciones.sitio[i].nombre)
				if(this.state.datasetLocalizaciones.transecto[i] === null)
					datos.transecto=' '
				else
					datos.transecto = String(this.state.datasetLocalizaciones.transecto[i].nombre)
				if(this.state.datasetLocalizaciones.parcela[i] === null)
					datos.parcela=' '
				else
					datos.parcela = String(this.state.datasetLocalizaciones.parcela[i].nombre)
				if(this.state.datasetLocalizaciones.PO[i] === null)
					datos.po=' '
				else
					datos.po = String(this.state.datasetLocalizaciones.PO[i].nombre)
				dataset.push(datos)
				datos={ 
					sitio:" ",
					transecto:" ",
					parcela:" ",
					po:" "
				}
			}
			var columns = [{
				Header: 'Localizaciones',
				columns: [
					{
							Header: 'Sitio',
							accessor: 'sitio'
					},
					{
							Header: 'Transecto',
							accessor: 'transecto'
					},
					{
							Header: 'Punto de Observación',
							accessor: 'po'
					},
					{
							Header: 'Parcela',
							accessor: 'parcela'
					},
					],
				}]
			var html = TablaLocalizaciones(dataset,columns)
			return(<>{html}</>)
			}else{return(null)}
		}else{return(<></>)}
	}
	cargarKML=async()=>{
		const form = new FormData()
		form.append('zip_file', this.state.kmlfile)
		var baseurl = "https://api.restaured.net"
		const url = baseurl+'/restaured/geojson/upload'
		const config={
			method:'post',
			url:url,
			headers:{'Content-Type': 'multipart/form-data'},
			withCredentials:true,
			data:form
		}
		await axios(config)
		.then(response => response.data)
		.then(data => {
			var punto = data.body.punto
			var sitio = data.body.sitio
			var transecto = data.body.transecto
			var parcela = data.body.parcela
			this.setState({
				geoJsonSitio: sitio,
				geoJsonPunto: punto,
				geoJsonParcela: parcela,
				geoJsonTransecto: transecto,
			})
		})
		var geojsonParcela = {
			type : this.state.geoJsonParcela.type,
			features : this.state.geoJsonParcela.features,
			bbox: this.state.geoJsonParcela.bbox
		}
		var geoJsonSitio= {
			type : this.state.geoJsonSitio.type,
			features : this.state.geoJsonSitio.features,
			bbox: this.state.geoJsonSitio.bbox
		}
		var geoJsonPunto= {
			type : this.state.geoJsonPunto.type,
			features : this.state.geoJsonPunto.features,
			bbox: this.state.geoJsonPunto.bbox
		}
		var geoJsonTransecto= {
			type : this.state.geoJsonTransecto.type,
			features : this.state.geoJsonTransecto.features,
			bbox: this.state.geoJsonTransecto.bbox
		}
		const onEachFeaturePoint=(feature, layer)=> {
			if(feature.properties.Sitio !== undefined){
				var popup = L.popup()
				.setContent(feature.properties.Sitio + ' ' + feature.properties.Codigo + ' ' + feature.properties.COD_PNT)
				layer.bindPopup( popup);
			}else{
				var popup = L.popup()
				.setContent('Sin Nombre Definido')
				layer.bindPopup( popup);
			}
		  }
		const onEachFeaturePolygon=(feature, layer) =>{
		layer.on({
			'click': function (e) {
			}
		})
		}
		
		const pointToLayer=(feature, latlng) =>{
		const parkIcon = new L.Icon({
			iconUrl: Icono,
			iconSize: [26, 26],
			popupAnchor: [0, -15],
			shadowAnchor: [13, 28]
			});
		var geojsonMarkerOptions = {
			radius: 8,
			icon: parkIcon,
			fillColor: "#ff7800",
			color: "#000",
			weight: 1,
			opacity: 1,
			fillOpacity: 0.8
		};
		var marker = L.marker(latlng,geojsonMarkerOptions)
		marker.setIcon(parkIcon);
		return marker;
		}
		console.log(geojsonParcela)
		console.log(geoJsonSitio)
		console.log(geoJsonTransecto)
		console.log(geoJsonPunto)
		var bounds = [[this.state.geoJsonSitio.bbox[1],this.state.geoJsonSitio.bbox[0]],[this.state.geoJsonSitio.bbox[3],this.state.geoJsonSitio.bbox[2]]]
		const styleMap = { "width": "96%", "height": "50vh", "margin-left":"2%", "margin-right":"2%", "margin-bottom":"2%" }
		var html = 
		<div> 
			<h3>Este va a ser el mapa que se va a cargar</h3>
			<MapContainer style={styleMap}  zoom={10} bounds={bounds} scrollWheelZoom={false} >
					<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
					<GeoJSON data={geojsonParcela} onEachFeature={onEachFeaturePolygon.bind()}  />
					<GeoJSON data={geoJsonSitio} onEachFeature={onEachFeaturePolygon.bind()} />
					<GeoJSON data={geoJsonPunto} onEachFeature={onEachFeaturePoint.bind()} pointToLayer={pointToLayer.bind()}/>
					<GeoJSON data={geoJsonTransecto} onEachFeature={onEachFeaturePolygon.bind()}/>
			</MapContainer>
			<h4> 
				<button className="btn-primary mt-3 mb-3 col-lg-4 "onClick={(e) =>this.confirmarGeoJson(e)}> Confirmar</button>
			</h4>
		</div>
		await this.setState({previewMap:html})
		console.log(this.state.previewMap)
	}
	confirmarGeoJson =async()=>{
		// AGREGAR SITIOS
		var sitios = []
		for(const i in this.state.geoJsonSitio.features){
			var sitio = {
				nombre: this.state.geoJsonSitio.features[i].properties.Nombre,
				descripcion: this.state.geoJsonSitio.features[i].properties.Nombre +' '+this.state.geoJsonSitio.features[i].properties.id,
				geos:{
					type: this.state.geoJsonSitio.features[i].type,
					properties:{ Nombre: this.state.geoJsonSitio.features[i].properties.Nombre, id: this.state.geoJsonSitio.features[i].id},
					geometry: this.state.geoJsonSitio.features[i].geometry,
				}
			}
			sitios.push(sitio)

		}
		const baseurl = "https://api.restaured.net"
		var url = baseurl+'/restaured/sitio'
		var body = JSON.stringify(sitios)
		const config={
			method:'post',
			url:url,
			headers:{'Content-Type': 'application/json'},
			withCredentials:true,
			data:body
		}
		await axios(config)
		.then(response => response.data)
		.then(data => {
			this.setState({ massiveSitio:data.body})
		})
		// AGREGAR PARCELAS
		var parcelas = []
		for(const i in this.state.geoJsonParcela.features){
			var parcela = {
				nombre: this.state.geoJsonParcela.features[i].properties.Sitio + ' ' +  this.state.geoJsonParcela.features[i].properties.Codigo,
				descripcion: this.state.geoJsonParcela.features[i].properties.Sitio +' '+ this.state.geoJsonParcela.features[i].properties.Codigo,
				geos:{
					type: this.state.geoJsonParcela.features[i].type,
					properties:{Sitio: this.state.geoJsonParcela.features[i].properties.Sitio, Codigo: this.state.geoJsonParcela.features[i].properties.Codigo},
					geometry: this.state.geoJsonParcela.features[i].geometry,
				}
			}
			parcelas.push(parcela)

		}
		url = baseurl+'/restaured/parcela'
		var body2 = JSON.stringify(parcelas)
		const config2={
			method:'post',
			url:url,
			headers:{'Content-Type': 'application/json'},
			withCredentials:true,
			data:body2
		}
		await axios(config2)
		.then(response => response.data)
		.then(data => {
			this.setState({ massiveParcela:data.body})
		})
		// AGREGAR PUNTOS
		var puntos = []
		for(const i in this.state.geoJsonPunto.features){
			var punto = {
				nombre: this.state.geoJsonPunto.features[i].properties.Sitio + ' '+this.state.geoJsonPunto.features[i].properties.Codigo + ' '+ this.state.geoJsonPunto.features[i].properties.COD_PNT,
				descripcion: this.state.geoJsonPunto.features[i].properties.Sitio + ' '+ this.state.geoJsonPunto.features[i].properties.Codigo+ ' '+ this.state.geoJsonPunto.features[i].properties.COD_PNT,
				geos:{
					type: this.state.geoJsonPunto.features[i].type,
					properties:{Sitio: this.state.geoJsonPunto.features[i].properties.Sitio, Codigo: this.state.geoJsonPunto.features[i].properties.Codigo, COD_PNT:this.state.geoJsonPunto.features[i].properties.COD_PNT},
					geometry: this.state.geoJsonPunto.features[i].geometry,
				}
			}
			puntos.push(punto)

		}
		url = baseurl+'/restaured/puntoobservacion'
		var body3 = JSON.stringify(puntos)
		const config3={
			method:'post',
			url:url,
			headers:{'Content-Type': 'application/json'},
			withCredentials:true,
			data:body3
		}
		await axios(config3)
		.then(response => response.data)
		.then(data => {
			this.setState({ massivePunto:data.body})
		})
		// AGREGAR TRANSECTOS
		var transectos = []
		for(const i in this.state.geoJsonTransecto.features){
			var transecto = {
				nombre: this.state.geoJsonTransecto.features[i].properties.Sitio + ' '+ this.state.geoJsonTransecto.features[i].properties.Codigo,
				descripcion: this.state.geoJsonTransecto.features[i].properties.Sitio + ' '+ this.state.geoJsonTransecto.features[i].properties.Codigo,
				geos:{
					type: this.state.geoJsonTransecto.features[i].type,
					properties:{Sitio: this.state.geoJsonTransecto.features[i].properties.Sitio, Codigo: this.state.geoJsonTransecto.features[i].properties.Codigo},
					geometry: this.state.geoJsonTransecto.features[i].geometry,
				}
			}
			transectos.push(transecto)
		}
		url = baseurl+'/restaured/transecto'
		var body4 = JSON.stringify(transectos)
		const config4={
			method:'post',
			url:url,
			headers:{'Content-Type': 'application/json'},
			withCredentials:true,
			data:body4
		}
		await axios(config4)
		.then(response => response.data)
		.then(data => {
			this.setState({ massiveTransecto:data.body})
		})
		var localizaciones=[]
		for(const i in this.state.massiveSitio){
			for(const j in this.state.massiveParcela){
				if(this.state.massiveSitio[i].nombre ===
					this.state.massiveParcela[j].geos.properties.Sitio){
						localizaciones.push({
							idSitio: this.state.massiveSitio[i]._id,
							idParcela: this.state.massiveParcela[j]._id,
						})
					}
			}
			for(const j in this.state.massivePunto){
				if(this.state.massiveSitio[i].nombre ===
					this.state.massivePunto[j].geos.properties.Sitio){
						localizaciones.push({
							idSitio: this.state.massiveSitio[i]._id,
							idPO: this.state.massivePunto[j]._id,
						})
					}
			}
			for(const j in this.state.massiveTransecto){
				if(this.state.massiveSitio[i].nombre ===
					this.state.massiveTransecto[j].geos.properties.Sitio){
						localizaciones.push({
							idSitio: this.state.massiveSitio[i]._id,
							idTransecto: this.state.massiveTransecto[j]._id,
						})
					}
			}
		}
		url = baseurl+'/restaured/localizacion'
		var body5 = JSON.stringify(localizaciones)
		const config5={
			method:'post',
			url:url,
			headers:{'Content-Type': 'application/json'},
			withCredentials:true,
			data:body5
		}
		await axios(config5)
		.then(response => response.data)
		.then(data => {
			console.log(data)
		})
		// AGREGAR LOCALIZACIONES

	}
	MapView=()=>{
		if(this.state.datasetLocalizaciones.length === undefined){
			console.log(this.state.datasetLocalizaciones)
			var sitios=[]; var parcelas =[]; var puntos =[]; var transectos=[];
			for(const i in this.state.sitio.id){
				sitios.push(this.state.sitio.geos[i])
			}
			for(const i in this.state.datasetLocalizaciones.sitio){
				if(this.state.datasetLocalizaciones.parcela[i]){
					parcelas.push(this.state.datasetLocalizaciones.parcela[i].geos)
				}
				if(this.state.datasetLocalizaciones.PO[i]){
					puntos.push(this.state.datasetLocalizaciones.PO[i].geos)
				}
				if(this.state.datasetLocalizaciones.transecto[i]){
					transectos.push(this.state.datasetLocalizaciones.transecto[i].geos)
				}
			}
			const bounds = [
				[4.7308746259696,-75.90724920155998],
				[4.86862619541959,-75.42094544938094]
			]
			const onEachFeaturePoint=(feature, layer)=> {
				if(feature.properties.Sitio !== undefined){
					var popup = L.popup()
					.setContent(feature.properties.Sitio + ' ' + feature.properties.Codigo + ' ' + feature.properties.COD_PNT)
					layer.bindPopup( popup);
				}else{
					var popup = L.popup()
					.setContent('Sin Nombre Definido')
					layer.bindPopup( popup);
				}
	
			  }
			  
			  const onEachFeaturePolygon=(feature, layer) =>{
				layer.on({
				  'click': function (e) {
				   }
				})
			  }
			  
			  const pointToLayer=(feature, latlng) =>{
				const parkIcon = new L.Icon({
					iconUrl: Icono,
					iconSize: [26, 26],
					popupAnchor: [0, -15],
					shadowAnchor: [13, 28]
				  });
				var geojsonMarkerOptions = {
					radius: 8,
					icon: parkIcon,
					fillColor: "#ff7800",
					color: "#000",
					weight: 1,
					opacity: 1,
					fillOpacity: 0.8
				};
				var marker = L.marker(latlng,geojsonMarkerOptions)
				marker.setIcon(parkIcon);
				return marker;
			  }
			const styleMap = { "width": "96%", "height": "100vh", "margin-left":"2%", "margin-right":"2%", "margin-bottom":"2%" }
			console.log(parcelas,sitios)
			console.log(transectos,puntos)
			var html = 
			<MapContainer style={styleMap}  zoom={10} bounds={bounds} scrollWheelZoom={false} >
					<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
					<GeoJSON data={parcelas} onEachFeature={onEachFeaturePolygon.bind()}  pointToLayer={pointToLayer.bind()} />
					<GeoJSON data={sitios} onEachFeature={onEachFeaturePolygon.bind()} pointToLayer={pointToLayer.bind()} />
					<GeoJSON data={puntos} onEachFeature={onEachFeaturePoint.bind()} pointToLayer={pointToLayer.bind()}/>
					<GeoJSON data={transectos} onEachFeature={onEachFeaturePolygon.bind()} pointToLayer={pointToLayer.bind()}/>
			</MapContainer>
			return html
		}else{
			return null
		}
	}
	render(){
		return (
			<div>
				<div>
					<div id="wrapper">
					<NavbarA/>
					{/* Content Wrapper */}
					<div id="content-wrapper" className="d-flex flex-column">
							{/* Main Content */}
							<div id="content">
							<Header Iduser={this.props.Iduser} Rol={this.props.Rol} Nombre={this.props.Nombre}/>           
							{this.props.Nombre ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
							{this.props.Iduser ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
							{this.props.Rol ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
							{/* Begin Page Content */}
							<div className="container-fluid">
								<div className="col-lg-11 card shadow ml-5 mr-5 mb-4">
									<div className="row mt-5">
									<h2 className="mb-5 col-xl-12"> Carga de Archivos de localizacion</h2>
										<div className="col-lg-5">
											<h5><a className="mt-5 col-lg-12" href="https://restauredpublic.s3.amazonaws.com/assets/geo.zip" target="_blank" rel="noreferrer"> 
												Descargar .Zip de Ejemplo <BsCloudDownload/>
											</a></h5>
											<div className="row pt-5">
											<input type="file" accept='.zip' className="form-control ml-3 pt-5 pb-5 col-lg-7" onChange={(e)=> this.setFile(e.target.files)} placeholder="Subir Archivo"/>
											<div className="col-lg-4 ml-3"> 
												<h5><button type="button" className="btn-primary mt-5" onClick={this.cargarKML}> 
													Cargar .Zip <BsCloudUpload/>
												</button></h5>
												</div>
											</div>
										</div>
										<div className="col-lg-7">
											<p className="mb-5 text-left">
											El .zip debe contener archivos .shp para cada uno de los ubicaciones y deben de estar nombrados de la siguiente manera.
											<ul>
												<li>
												Parcelas.shp: Que corresponde a las parcelas donde se monitorea
												</li>
												<li>
												Puntos.shp: Que corresponde a los puntos de observacion
												</li>
												<li>
												Transectos.shp: Que corresponde a los transectos
												</li>
												<li>
												Sitios.shp: Que corresponde a las sitios, por ejemplo el Santuario de Fauna y Flora Otún Quimbaya
												</li>
											</ul>

											Adicional a esto cada uno de los archivos .shp deben de tener sus correspondientes archivos .cpg, .dbf, .prj y .shx y deberán tener el mismo nombre que el archivo principal .shp por ejemplo.
											<ul>
												<li>
													Parcelas.shp
												</li>
												<li>
													Parcelas.cpg
												</li>
												<li>
													Parcelas.dbf
												</li>
												<li>
													Parcelas.prj
												</li>
												<li>
													Parcelas.shx
												</li>
											</ul>
											</p>
										</div>
									</div>
									{this.state.previewMap}
								</div>
								{/* Page Heading */}
								<h1 className="h3 mb-2 text-gray-800">Localizaciones</h1>
								{/* DataTales Example */}
								<div className="card shadow mb-4">
										
								<div className="card-header py-3">
										<h6 className="m-0 font-weight-bold text-primary">Tabla</h6>
								</div>
								<div className="card-body">
									<this.TablaLocalizacion/>
								</div>
								</div>
							</div>
							{/* /.container-fluid */}
							<div className="col-xl-12 col-lg-12">
									<div className="card shadow mb-4">
										{/* Card Header - Dropdown */}
										<div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
											<h6 className="m-0 font-weight-bold text-primary">Grafico de Reportes de Aves por zonas</h6>                            
										</div>
										{/* Card Body */}
										<div className="card-body" height={800}>
											<div id="mapid" >
											{this.MapView()}
											</div>
										</div>
									</div>
								</div>
							</div>
							{/* End of Main Content */}
							{/* Footer */}
							 <footer id="footer" className="sticky-footer">
							<div className="container my-auto">
									<div className="copyright text-center my-auto">
									© Copyright <strong><span>Restaured 2024</span></strong>. All Rights Reserved
									</div>
							</div>
							</footer>
							{/* End of Footer */}
					</div>
					{/* End of Content Wrapper */}
					</div>
					<Logout/>
				</div>
			</div>
		)};
	}