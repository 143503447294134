import React, { Component } from 'react'
import '../../assets/vendor/bootstrap/css/bootstrap.css'
import '../../assets/css/sb-admin-2.css'
import NavbarAdmin from '../NavBar/NavbarAdmin'
import HeaderUsers from './Header'
import TablaIndicador from './TablaIndicador'
import {Collapse} from 'react-bootstrap'
import Alert from 'react-bootstrap/Alert'
import { Redirect} from 'react-router'
import { BsPlusCircle } from "react-icons/bs"
import Logout from './Logout'
const axios = require('axios');



class RegistrosMonitoreos extends Component{
    constructor(props){
        super(props);
        this.state={
            Rol: this.props.Rol,
            Token: this.props.Token,
            Iduser: this.props.Iduser,
            External: this.props.External,
            open:false,
            redirect:false,
            alertEmpty:null,
		      	data:[],
            selectedMonitoreo:{},
            form:{
				      id:"",
              nombre:"",
              unidades:"",
              tipo:"",
              pubObj:"",
              formula:"",
              tipoGrafico:"",
              variables:[],
            },            
            variable:{
              id:[],
              idMonitoreo:[],
              tipo:[],
              nombre:[]
            },
            variablexindicador:{
              id:[],
              idIndicador:[],
              idVariable:[]
            },
            indicador:{
              id:[],
              idMonitoreo:[],
              formula:[],
              nombre:[],
              pubObj:[],
              tipo:[],
              tipoGrafico:[],
              unidades:[],
            },
            monitoreo:{
              id:[],
              nombre:[],
              descripcion:[],
            },
            registroMonitoreo:{
              id:[],
              nombre:[],
              idUser:[],
              idMonitoreo:[],
            },
            entradaMonitoreo:{
              id:[],
              idLocalizacion:[],
              curado:[],
              idRegistro:[],
              idEspecies:[],
            },
            variableRegistro:{
              id:[],
              idEntrada:[],
              idVariable:[],
              valor:[],
            },
            ingresarEntradaMonitoreo:{
              body:[],
              status:"",
            },
            monitoreos:[],
	
		}
		this.Getespecies();
		this.initial_state();
		}
    //HANDLEE
  
    setOpen=()=>{
      this.setState({open:!this.state.open})
    }
	onChange = (event ) => {
	this.setState({
		form: {
			...this.state.form,
			[event.target.name]:event.target.value,
		}
	});
	}
	checkboxVariables=(e,id)=>{
    var variables
		if(e.target.checked){
			variables = this.state.form.variables
			variables.push(id)
			this.setState({
				form:{ ...this.state.form, variables:variables}
			})
		}else{
			variables = this.state.form.variables
			var index = variables.indexOf(id)
			if(index > -1) variables.splice(index, 1)
			this.setState({
				form:{ ...this.state.form, variables:variables}
			})
		}
	}

    //PETICIONES
    Getespecies=async()=>{
      var baseurl = "https://api.restaured.net"
      const url = baseurl+'/restaured/especie'
      await axios.get(url, {withCredentials:true})
      .then(response => response.data)
      .then(data => {
        var id =[];
        var nombreCientifico =[];
        var nombreComun =[];
        var idLocalizacion =[];
        var activo =[];
        for(const i in data.body){
          id.push(data.body[i]._id)
          idLocalizacion.push(data.body[i].idLocalizacion)
          nombreCientifico.push(data.body[i].nombreCientifico)
          nombreComun.push(data.body[i].nombreComun)
          activo.push(data.body[i].activo)
        };
        this.setState({
          especie:{
            id:id,
            idLocalizacion:idLocalizacion,
            nombreCientifico:nombreCientifico,
            nombreComun:nombreComun,
            activo:activo,
          }});
      })
      // console.log(this.state.especies);
    } 
    GetDataTable=async()=>{
      var baseurl = "https://api.restaured.net"
      var url = baseurl+'/restaured/variable'
      await axios.get(url,{withCredentials: true})
      .then(response => response.data)
      .then(data => {
        // console.log(data)
        var id =[]; var idMonitoreo =[]; var nombre =[]; var tipo=[]
        for(const i in data.body){
          id.push(data.body[i]._id);
          idMonitoreo.push(data.body[i].idMonitoreo);
          tipo.push(data.body[i].tipo);
          nombre.push(data.body[i].nombre);
        };
        this.setState({
          variable:{
                id:id,
                idMonitoreo:idMonitoreo,
                tipo:tipo,
                nombre:nombre,
        }})
      })
      url = baseurl+'/restaured/variablexindicador'
      await axios.get(url,{withCredentials:true})
      .then(response => response.data)
      .then(data => {
        // console.log(data)
        var id =[];
        var idIndicador =[];
        var idVariable =[];
        for(const i in data.body){
          id.push(data.body[i]._id);
          idIndicador.push(data.body[i].idIndicador);
          idVariable.push(data.body[i].idVariable);
        };
        this.setState({
          variablexindicador:{
                id:id,
                idIndicador:idIndicador,
                idVariable:idVariable,
        }})
      })
      url = baseurl+'/restaured/indicador'
      await axios.get(url, {withCredentials:true})
      .then(response => response.data)
      .then(data => {
        // console.log(data)
        var id =[];
        var idMonitoreo =[];
        var formula =[];
        var nombre =[];
        var pubObj =[];
        var tipo =[];
        var tipoGrafico =[];
        var unidades =[];
        for(const i in data.body){
          id.push(data.body[i]._id);
          idMonitoreo.push(data.body[i].idMonitoreo);
          formula.push(data.body[i].formula);
          nombre.push(data.body[i].nombre);
          pubObj.push(data.body[i].pubObj);
          tipo.push(data.body[i].tipo);
          tipoGrafico.push(data.body[i].tipoGrafico);
          unidades.push(data.body[i].unidades);
        };
        this.setState({
          indicador:{
              id:id,
              idMonitoreo:idMonitoreo,
              formula:formula,
              nombre:nombre,
              pubObj:pubObj,
              tipo:tipo,
              tipoGrafico:tipoGrafico,
              unidades:unidades,
        }})
      })
      // await axios.get('http://localhost:5000/restaured/localizacion', {withCredentials:true})
      // .then(response => response.data)
      // .then(data => {
        // console.log(data)
        // var id =[];
        // var idMonitoreo =[];
        // var nombre =[];
        // for(const i in data.body){
        //   id.push(data.body[i].id);
        //   idMonitoreo.push(data.body[i].idMonitoreo);
        //   nombre.push(data.body[i].nombre);
        // };
        // this.setState({
        //   variable:{
        //         id:id,
        //         idMonitoreo:idMonitoreo,
        //         nombre:nombre,
        // }})
      // })
      url = baseurl+'/restaured/monitoreo'
      await axios.get(url, {withCredentials:true})
      .then(response => response.data)
      .then(data => {
        // console.log(data)
        var id =[];
        var nombre =[];
        var descripcion =[];
        for(const i in data.body){
          id.push(data.body[i]._id);
          nombre.push(data.body[i].nombre);
          descripcion.push(data.body[i].descripcion);
        };
        this.setState({
          monitoreo:{
            id:id,
            nombre:nombre,
            descripcion:descripcion,
        }})
      })
      url = baseurl+'/restaured/registromonitoreo'
      await axios.get(url, {withCredentials:true})
      .then(response => response.data)
      .then(data => {
        //Extraer Fecha y agegarla al modelo Monitoreo
        // var date= new Date(data.body[0].date*1000);
        // console.log(date);
        // console.log(data.body[0]);
        var id =[];
        var nombre =[];
        var idMonitoreo =[];
        var idUser =[];
        var date=[]
        for(const i in data.body){
          id.push(data.body[i]._id);
          nombre.push(data.body[i].nombre);
          idUser.push(data.body[i].idUser);
          idMonitoreo.push(data.body[i].idMonitoreo);
          date.push(data.body[i].date);
        };
        this.setState({
          registroMonitoreo:{
            id:id,
            nombre:nombre,
            idUser:idUser,
            idMonitoreo:idMonitoreo,
            date:date,
        }})
      })
      url = baseurl+'/restaured/entradamonitoreo'
      await axios.get(url, {withCredentials:true})
      .then(response => response.data)
      .then(data => {
        //Extraer Localización y agegarla al modelo Monitoreo
        // console.log(data)
        var id =[];
        var idLocalizacion =[];
        var curado =[];
        var idRegistro =[];
        var idEspecies =[];
        for(const i in data.body){
          id.push(data.body[i]._id);
          idLocalizacion.push(data.body[i].idLocalizacion);
          curado.push(data.body[i].curado);
          idRegistro.push(data.body[i].idRegistro);
          idEspecies.push(data.body[i].idEspecie);
        };
        this.setState({
          entradaMonitoreo:{
                id:id,
                idLocalizacion:idLocalizacion,
                curado:curado,
                idRegistro:idRegistro,
                idEspecies:idEspecies,
        }})
      })
      url = baseurl+'/restaured/variableregistro'
      await axios.get(url, {withCredentials:true})
      .then(response => response.data)
      .then(data => {
        // console.log(data)
        var id =[];
        var idEntrada =[];
        var idVariable =[];
        var valor =[];
        for(const i in data.body){
          id.push(data.body[i]._id);
          idEntrada.push(data.body[i].idEntrada);
          idVariable.push(data.body[i].idVariable);
          valor.push(data.body[i].valor);
        };
        this.setState({
          variableRegistro:{
                id:id,
                idEntrada:idEntrada,
                idVariable:idVariable,
                valor:valor,
        }})
      })
    }
    viewdata=async()=>{
      await this.GetDataTable()
      var monitoreo={
        id:0,
        nombre:"",
        descripcion:"",
        variables:[],
        registros:[],
        indicadores:[],
      };
      var indicadores = {
        id:[],
        idMonitoreo:[],
        formula:[],
        nombre:[],
        pubObj:[],
        tipo:[],
        tipoGrafico:[],
        unidades:[],
      };
      var registros={
          id:[],
          iduser:[],
          nombre:[],
          entradas:[],
          date:[],
      };
      var entradas={
          id:[],  
          idLocalizacion:[],  
          idVariablesRegistro:[],  
          variables:[],
          especies:[],
          valores:[],
          curado:[],
      };
      var monitoreos=[]
      var valores=[];
      var variables=[];
      var idvariableRegistro=[]
      var idvariablesRegistro=[]
      var variableEntrada=[];
      var valorEntrada=[];
      for(const i in this.state.monitoreo.id){
        monitoreo.id=this.state.monitoreo.id[i]
        monitoreo.nombre=this.state.monitoreo.nombre[i]
        monitoreo.descripcion=this.state.monitoreo.descripcion[i]
        for(const j in this.state.variable.nombre){
          if(this.state.variable.idMonitoreo[j]===this.state.monitoreo.id[i]){
            var variable={
              id:this.state.variable.id[j],
              tipo:this.state.variable.tipo[j],
              nombre:this.state.variable.nombre[j]
            }
            monitoreo.variables.push(variable);
          }
        }
        for(const j in this.state.indicador.id){
          if(this.state.indicador.idMonitoreo[j]===this.state.monitoreo.id[i]){
            indicadores.id.push(this.state.indicador.id[j])
            indicadores.idMonitoreo.push(this.state.indicador.idMonitoreo[j])
            indicadores.formula.push(this.state.indicador.formula[j])
            indicadores.nombre.push(this.state.indicador.nombre[j])
            indicadores.pubObj.push(this.state.indicador.pubObj[j])
            indicadores.tipo.push(this.state.indicador.tipo[j])
            indicadores.tipoGrafico.push(this.state.indicador.tipoGrafico[j])
            indicadores.unidades.push(this.state.indicador.unidades[j])
          }
        }
        // console.log(this.state.entradaMonitoreo)
        for(const j in this.state.registroMonitoreo.id){
          if(this.state.monitoreo.id[i] === this.state.registroMonitoreo.idMonitoreo[j]){
            registros.id.push(this.state.registroMonitoreo.id[j])
            registros.iduser.push(this.state.registroMonitoreo.idUser[j])
            registros.nombre.push(this.state.registroMonitoreo.nombre[j])
            registros.date.push(this.state.registroMonitoreo.date[j])
            for(const k in this.state.entradaMonitoreo.id){
              if(this.state.registroMonitoreo.id[j] === this.state.entradaMonitoreo.idRegistro[k]){
                entradas.id.push(this.state.entradaMonitoreo.id[k]);
                entradas.idLocalizacion.push(this.state.entradaMonitoreo.idLocalizacion[k]);
                entradas.curado.push(this.state.entradaMonitoreo.curado[k]);
                entradas.especies.push(this.state.entradaMonitoreo.idEspecies[k]);
                  for(const l in this.state.variableRegistro.id){
                    if(this.state.entradaMonitoreo.id[k] === this.state.variableRegistro.idEntrada[l]){
                      idvariableRegistro.push(this.state.variableRegistro.id[l]);
                      valorEntrada.push(Number(this.state.variableRegistro.valor[l]));
                      variableEntrada.push(this.state.variableRegistro.idVariable[l]);
                    }
                  }
                idvariablesRegistro.push(idvariableRegistro)  
                variables.push(variableEntrada)  
                valores.push(valorEntrada)  
                idvariableRegistro=[]
                valorEntrada=[]
                variableEntrada=[]
                }
              }
              entradas.variables=variables;
              entradas.valores=valores;
              entradas.idVariablesRegistro=idvariablesRegistro;
              variables=[];
              idvariablesRegistro=[];
              valores=[];
              registros.entradas.push(entradas);
              entradas={id:[], idLocalizacion:[], idVariablesRegistro:[], variables:[], valores:[], curado:[],especies:[],}
          }
        }
        monitoreo.indicadores=indicadores;
        monitoreo.registros=registros;
        registros={ id:[], iduser:[], nombre:[], entradas:[], date:[] };
        indicadores = { id:[], idMonitoreo:[], formula:[], nombre:[], pubObj:[], tipo:[], tipoGrafico:[], unidades:[],};
        monitoreos.push(monitoreo);
        monitoreo={ id:0, nombre:"", descripcion:"", variables:[],indicadores:[],};
      }
      console.log(monitoreos)
      this.setState({
        monitoreos:monitoreos
      })
    }
    initial_state=async()=>{
      await this.viewdata()
    }
	cancelarAgregar=()=>{
		this.setState({
		open:false,
		form:{
		nombre:"",
		unidades:"",
		tipo:"",
		pubObj:"",
		formula:"",
		tipoGrafico:"",
	}})
	}
	crearIndicador=async(body)=>{
		var baseurl = "https://api.restaured.net"
		var url = baseurl+'/restaured/indicador'
		var data = JSON.stringify([body])
		var config = {
		method: 'post',
		url: url,
		headers: { 
			'Content-Type': 'application/json'
		},
		withCredentials:true,
		data: data
		};
		await axios(config)
		.then(response =>{
			console.log(response.data.body[0]._id)
			this.setState({form:{...this.state.form, id:response.data.body[0]._id }})
		})
	}
	crearRelaciones=async()=>{
		var idVar =this.state.form.variables;
		var body = []
		for(const i in idVar){
			var indicadorVariable={
				idIndicador: this.state.form.id,
				idVariable: idVar[i]
			} 
			body.push(indicadorVariable)
		}
		var baseurl = "https://api.restaured.net"
		var url = baseurl+'/restaured/variablexindicador'
		var data = JSON.stringify(body)
		var config = {
		method: 'post',
		url: url,
		headers: { 
			'Content-Type': 'application/json'
		},
		withCredentials:true,
		data: data
		};
		await axios(config)
		.then(response =>{
			console.log(response)
		})
	}
	confirmarAgregar= async()=>{
	var selectedMonitoreo =this.props.location.state
	if(this.state.form.pubObj ==="")await this.setState({form:{...this.state.form, pubObj:'Web'}})
	if(this.state.form.tipo ==="")await this.setState({form:{...this.state.form, tipo:'Directo'}})
	if(this.state.form.tipoGrafico ==="")await this.setState({form:{...this.state.form, tipoGrafico:'Barras'}})
	if(this.state.form.variables.length === 1 ){
		var indicador={
			idMonitoreo:this.state.monitoreos[selectedMonitoreo].id ,
			nombre:this.state.form.nombre,
			unidades:this.state.form.unidades,
			tipo:this.state.form.tipo,
			pubObj:this.state.form.pubObj,
			formula:this.state.form.formula,
			tipoGrafico:this.state.form.tipoGrafico,
		}
		console.log(this.state.form)
		await this.crearIndicador(indicador)
		await this.crearRelaciones()
		this.setOpen()
		this.setState({redirect:true})
	}
  if(this.state.form.variables.length === 0  )
		this.setState({alertEmpty:'Debe seleccionar Al menos una variable'})
  if(this.state.form.variables.length > 1  )
		this.setState({alertEmpty:'Puede seleccionar unicamente una variable'})

	}
	renderVariablesAsociar=()=>{
		var html = []
		var location = this.props.location;
		var selectedMonitoreo=(location.state)
		if(this.state.monitoreos.length > 0){
      console.log(this.state.monitoreos[selectedMonitoreo].variables)
			for(const i in this.state.monitoreos[selectedMonitoreo].variables){
        if(this.state.monitoreos[selectedMonitoreo].variables[i].tipo !== 'Nominal'){
          html.push(<>
          <div className="col-lg-4 mb-5"> 
          <div className="row"> 
          <div className="col-lg-5"/>
            <input type="checkbox" className="form-control col-lg-1" onClick={(e)=>this.checkboxVariables( e,this.state.monitoreos[selectedMonitoreo].variables[i].id)} />
            <h5 className="col-lg-6">
            {this.state.monitoreos[selectedMonitoreo].variables[i].nombre}
            </h5>
          </div>
          </div>
          </>)	
        }
			}
			return(
				<div className="row"> 
					{html}
				</div>
			)

		}else return null
		
	}
	renderPubObj=()=>{
		var html = []	
		html.push(<option value='Web' > Web</option>)
		html.push(<option value='Cientifico' > Cientifico</option>)
		html.push(<option value='Cientifico_Ciudadano' > Cientifico Ciudadano</option>)
		html.push(<option value='Cient_y_Cient_Ciudadano'> Cientifico Y Cientifico Ciudadano</option>)
		return(
		<select className="form-control" defaultValue="Web" name="pubObj"onChange={(e)=>this.onChange(e)}>
			{html}
		</select>)
	}
	renderTipoGrafico=()=>{
		var html = []	
		html.push(<option selected value='Barras' > Barras</option>)
		html.push(<option value='BarrasApiladas' > Barras Apiladas</option>)
		html.push(<option value='Sparkline' > Linea </option>)
		return(
		<select className="form-control" defaultValue="Barras" name="tipoGrafico"onChange={(e)=>this.onChange(e)}>
			{html}
		</select>)
	}
	renderAgregarIndicador=()=>{
		var tabla=
		<>
		<div className="row">
				<div className="col-lg-4">
					<p> Nombre: </p>
				</div>
				<div className="col-lg-4">
					<input type="text" className="form-control" name="nombre" value={this.state.form.nombre} onChange={this.onChange}/>
				</div>
			</div>
			<div className="row">
				<div className="col-lg-4">
					<p> Unidades: </p>
				</div>
				<div className="col-lg-4">
					<input type="text" className="form-control" name="unidades" value={this.state.form.unidades} onChange={this.onChange}/>
				</div>
			</div>
			<div className="row">
				<div className="col-lg-4">
				<p> Público Objetivo: </p>
				</div>
				<div className="col-lg-4">
					{this.renderPubObj()}
				</div>
			</div><div className="row">
				<div className="col-lg-4">
					<p> Tipo de Grafico: </p>
				</div>
				<div className="col-lg-4">
					{this.renderTipoGrafico()}
				</div>
			</div>
			<br/>
			<br/>
			{this.renderVariablesAsociar()}
			</>
		return (tabla)
	}    
    render(){
    return (
        <div>
            <div>
                <div id="wrapper">
                <NavbarAdmin/>
                {/* Content Wrapper */}
                <div id="content-wrapper" className="d-flex flex-column">
                    {/* Main Content */}
                    <div id="content">
                    <HeaderUsers Iduser={this.props.Iduser} Rol={this.props.Rol} Nombre={this.props.Nombre} External={this.props.External}/>
                    {this.props.Nombre ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
										{this.props.Iduser ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
										{this.props.Rol ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
                    {/* Begin Page Content */}
                    <div className="container-fluid">
                        {/* Page Heading */}
                        {this.state.alertEmpty? <Alert dismissible onClose={(e) => {this.setState({alertEmpty:null})}} variant={'primary'}> {this.state.alertEmpty}</Alert> : null}
                        <h1 className="h3 mb-2 text-gray-800">Gráficos del Monitoreo</h1>
                        {/* DataTales Example */}
                        <h5><span><p> 
                        <div className="row">
                            <div className="col-xl-12">
                            <p  onClick={this.setOpen} aria-controls="collapse-new-entry" 
                            aria-expanded={this.state.open} className="cta-btn" type="button" >
                            <BsPlusCircle/> <dr/>
                                Nuevo Gráfico</p>
                            <Collapse in={this.state.open}>
                                <div id="collapse-new-entry">
                                <br/>
                                <br/>
                                    {this.renderAgregarIndicador()}
                                <br/>
                                <br/>
                                <div className="row">
                                    <div className="col-lg-6">
                                    <button className="btn-primary" onClick={this.cancelarAgregar}> Cancelar</button>
                                    </div>
                                    <div className="col-lg-6">
                                        <button className="btn-primary" type="submit" onClick={this.confirmarAgregar}> Confirmar</button>
                                    </div>
                                </div>
                                { this.state.redirect ? <Redirect push to= {{pathname:"/restaured.admin/reloadermonitoreoindicador",state:this.props.location.state}}></Redirect> : null}
                                </div>
                            </Collapse>
                            </div>
                        </div>
                        </p></span></h5>
                        
                        <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">Visualiza todos los Graficos disponibles para el Monitoreo</h6>
                        </div>
                        <TablaIndicador/>
                        </div>
                    </div>
                    {/* /.container-fluid */}
                    </div>
                    {/* End of Main Content */}
                    {/* Footer */}
                     <footer id="footer" className="sticky-footer">
                    <div className="container my-auto">
                        <div className="copyright text-center my-auto">
                        © Copyright <strong><span>Restaured 2024</span></strong>. All Rights Reserved
                        </div>
                    </div>
                    </footer>
                    {/* End of Footer */}
                </div>
                {/* End of Content Wrapper */}
                </div>
                {/* End of Page Wrapper */}
                {/* Scroll to Top Button*/}
                <a className="scroll-to-top rounded" href="#page-top">
                <i className="fas fa-angle-up" />
                </a>
                {/* Logout Modal*/}
                <Logout/>
                {/* Bootstrap core JavaScript*/}
                {/* Core plugin JavaScript*/}
                {/* Custom scripts for all pages*/}
                {/* Page level plugins */}
                {/* Page level custom scripts */}
            </div>
        </div>
      )}
  
};

export default RegistrosMonitoreos