import React from 'react'
import '../../../assets/vendor/bootstrap/css/bootstrap.css'
import '../../../assets/css/sb-admin-2.css'
const axios = require('axios');


    

export default function Logout(){
    const QueryLogout =()=>{
        var baseurl = "https://api.restaured.net"
        var url = baseurl+'/auth/user/logout' 
        var config = {
            method: 'post',
            url: url,
            headers: { 
            'Content-Type': 'application/json'
            },
            withCredentials:true,
        }
        axios(config)
    }

    return (
    <>
        {/* Logout Modal*/}
        <div className="modal fade" id="logoutModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">¿Quieres salir?</h5>
                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
                </button>
            </div>
            <div className="modal-body">Selecciona Salir, si estás listo para cerrar sesión.</div>
            <div className="modal-footer">
                <button className="btn btn-secondary" type="button" data-dismiss="modal">Cancelar</button>
                <a className="btn btn-primary" onClick={QueryLogout} href="/">Salir</a>
            </div>
            </div>
        </div>
        </div>
    </>)

}