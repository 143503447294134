import React,{useState} from 'react'
import './App.css';
import {Switch, Route, Redirect} from 'react-router-dom'
import Landing from './Components/Main'
// USUARIO ADMINISTRADOR
import Home from './Components/Admin/Home'
import EditUsers from './Components/Admin/EditUsers'
import CreateUsers from './Components/Admin/CreateUsers'
import Editlocation from './Components/Admin/Editlocation'
import Monitoreos from './Components/Admin/Monitoreos'
import PublicMonitoreos from './Components/Monitoreos'
import Reportes from './Components/Admin/Reportes'
import HomeUsers from './Components/Admin/Home'
import MonitoreosVariables from './Components/Admin/MonitoreoVariables'
import MonitoreosVariablesEditar from './Components/Admin/MonitoreoVariablesEditar'
import MonitoreosIndicadores from './Components/Admin/MonitoreoIndicador'
import MonitoreosIndicadoresEditar from './Components/Admin/MonitoreoIndicadorEditar'
import ReloaderEditUsers from './Components/Admin/reloaderEditUsers'
import ReloaderMonitoreo from './Components/Admin/ReloaderMonitoreo'
import ReloaderMonitoreoVariable from './Components/Admin/ReloaderVariable'
import ReloaderMonitoreoInicador from './Components/Admin/ReloaderIndicador'
//USUARIO CIENTIFICO
import HomeUsersC from './Components/Usuarios/UsuarioCientifico/HomeUsers'
import ViewLUsersC from './Components/Usuarios/UsuarioCientifico/Viewlocation'
import MonitoreoUsersC from './Components/Usuarios/UsuarioCientifico/GenerarReporte'
import RegistrosUsersC from './Components/Usuarios/UsuarioCientifico/RegistrosUsersC'
import EntradaMUsersC from './Components/Usuarios/UsuarioCientifico/EntradaReportes'
import ReporteMUsersC from './Components/Usuarios/UsuarioCientifico/ReportesMonitoreos'
import CargarRegistro from './Components/Usuarios/UsuarioCientifico/CargarRegistro'
import CuracionEntrada from './Components/Usuarios/UsuarioCientifico/CuracionEntrada'
import CuracionRegistro from './Components/Usuarios/UsuarioCientifico/CuracionRegistro'
import CuracionValidacion from './Components/Usuarios/UsuarioCientifico/CuracionValidacion'
import ReloaderEntrada from './Components/Usuarios/UsuarioCientifico/ReloaderEntrada'
import ReloaderRegistro from './Components/Usuarios/UsuarioCientifico/ReloaderRegistro'
// USUARIO CIENTIFICO CIUDADANO
import HomeUsersCC from './Components/Usuarios/UsuarioCientificoCiudadano/HomeUsers'
import ViewLUsersCC from './Components/Usuarios/UsuarioCientificoCiudadano/Viewlocation'
import MonitoreosUsersCC from './Components/Usuarios/UsuarioCientificoCiudadano/GenerarReporte'
import RegistrosUsersCC from './Components/Usuarios/UsuarioCientificoCiudadano/RegistrosUsers'
import EntradaMUsersCC from './Components/Usuarios/UsuarioCientificoCiudadano/EntradaReportes'
import ReporteMUsersCC from './Components/Usuarios/UsuarioCientificoCiudadano/ReportesMonitoreos'
import CargarRegistroCC from './Components/Usuarios/UsuarioCientificoCiudadano/CargarRegistro'
import ReloaderEntradaCC from './Components/Usuarios/UsuarioCientificoCiudadano/ReloaderEntrada'
import ReloaderRegistroCC from './Components/Usuarios/UsuarioCientificoCiudadano/ReloaderRegistro'
//USUARIO CURADOR
import HomeUsersCU from './Components/Usuarios/UsuarioCurador/HomeUsers'
import CuracionEntradaCU from './Components/Usuarios/UsuarioCurador/CuracionEntrada'
import CuracionRegistroCU from './Components/Usuarios/UsuarioCurador/CuracionRegistro'
import CuracionValidacionCU from './Components/Usuarios/UsuarioCurador/CuracionValidacion'
import MonitoreosUsersCU from './Components/Usuarios/UsuarioCurador/MonitoreosUsersCU'
import ViewLUsersCU from './Components/Usuarios/UsuarioCurador/Viewlocation'

//VISTAS PÚBLICAS
import Login from './Components/Login'
import Register from './Components/Register'
import Recover from './Components/RecoverPassword'
import CambiarPassword from './Components/Usuarios/CambiarContraseña'
import VerifyAccount from './Components/Usuarios/VerificationAccount'
const axios = require('axios');

const App = props => {
  const [Rol,setRol] = useState("");
  const [Nombre,setNombre] = useState("");
  const [Iduser,setIduser] = useState("");
  const [External,setExternal] = useState("");
  const [registros,setRegistros] = useState([]);
  const syncManager =async()=>{
    var localRegistro = localStorage.getItem('registroQueries')
    var localRegistroCopy = localRegistro
    var localEntrada = localStorage.getItem('entradaQueries')
    console.log('back online')
    if(localRegistro){
      localStorage.removeItem('registroQueries')
      localRegistro = JSON.parse(localRegistro)
      for(const i in localRegistro){
        if(localRegistro[i].method === 'post'){
          console.log(localRegistro[i] )
          var data = JSON.parse(localRegistro[i].data)
          console.log(data)
          delete data[0].id
          console.log(data)
          localRegistro[i].data = JSON.stringify(data)
          console.log(localRegistro[i])
          await axios(localRegistro[i])
          .then(async response => {
            console.log(response)
            var localEntrada2 = JSON.parse(localEntrada)
            var localRegistroCopy2 = JSON.parse(localRegistroCopy)
            var dataReg = JSON.parse(localRegistroCopy2[i].data)
            for(const j in localEntrada2){
              var dataEntradaReg = JSON.parse(localEntrada2[j].entrada.data)
              if(dataReg[0].id === dataEntradaReg[0].idRegistro){
                console.log(response.data.body[0]._id , dataReg[0].id)
                dataEntradaReg[0].idRegistro = response.data.body[0]._id
                localEntrada2[j].entrada.data = JSON.stringify(dataEntradaReg)
                if(localEntrada2[j].hasOwnProperty('especie')){
                  console.log(localEntrada2[j].especie)
                  var dataEspecie = JSON.parse(localEntrada2[j].especie.data)
                  delete dataEspecie[0].id
                  localEntrada2[j].especie.data = JSON.stringify(dataEspecie)
                  console.log(localEntrada2[j].especie)
                  await axios(localEntrada2[j].especie)
                  .then(async responseEspecie => {
                    var dataEntrada = JSON.parse(localEntrada2[j].entrada.data)
                    dataEntrada[0].idEspecie = responseEspecie.data.body[0]._id
                    delete dataEntrada[0].id
                    localEntrada2[j].entrada.data = JSON.stringify(dataEntrada)
                    console.log(localEntrada2[j].entrada)
                    await axios(localEntrada2[j].entrada)
                    .then(async response => {
                      console.log(response)
                      if (response.data.status === 'OK'){
                        var variableRegistro = JSON.parse(localEntrada2[j].variableRegistro.data)
                        for(const k in variableRegistro){
                          variableRegistro[k].idEntrada = response.data.body[0]._id
                        }
                        localEntrada2[j].variableRegistro.data = JSON.stringify(variableRegistro)
                        console.log(localEntrada2[j].variableRegistro)
                        await axios(localEntrada2[j].variableRegistro)
                        .then(responseV =>console.log(responseV))
                      }
                    })
                  })
                }else{
                  var data = JSON.parse(localEntrada2[j].entrada.data)
                  delete data[0].id
                  localEntrada2[j].entrada.data = JSON.stringify(data)
                  console.log(localEntrada2[j].entrada)
                  await axios(localEntrada2[j].entrada)
                  .then(async response => {
                    console.log(response)
                    if (response.data.status === 'OK'){
                      var variableRegistro = JSON.parse(localEntrada2[j].variableRegistro.data)
                      for(const k in variableRegistro){
                        variableRegistro[k].idEntrada = response.data.body[0]._id
                      }
                      localEntrada2[j].variableRegistro.data = JSON.stringify(variableRegistro)
                      console.log(localEntrada2[j].variableRegistro)
                      await axios(localEntrada2[j].variableRegistro)
                      .then(response =>console.log(response))
                    }
                  })
                }
              }
            }
          })
        }
        if(localRegistro[i].method === 'delete'){
          await axios(localRegistro[i])
          .then(response => console.log(response))
        }
      }
    }
    if(localEntrada){
      localStorage.removeItem('entradaQueries')
      localEntrada = JSON.parse(localEntrada)
      console.log(localEntrada)
      for(const j in localEntrada){
        if(localEntrada[j].hasOwnProperty('especie')){
          console.log(localEntrada[j].especie)
          var dataEspecie = JSON.parse(localEntrada[j].especie.data)
          delete dataEspecie[0].id
          localEntrada[j].especie.data = JSON.stringify(dataEspecie)
          console.log(localEntrada[j].especie)
          await axios(localEntrada[j].especie)
          .then(async responseEspecie => {
            var dataEntrada = JSON.parse(localEntrada[j].entrada.data)
            dataEntrada[0].idEspecie = responseEspecie.data.body[0]._id
            delete dataEntrada[0].id
            localEntrada[j].entrada.data = JSON.stringify(dataEntrada)
            console.log(localEntrada[j].entrada)
            await axios(localEntrada[j].entrada)
            .then(async response => {
              console.log(response)
              if (response.data.status === 'OK'){
                var variableRegistro = JSON.parse(localEntrada[j].variableRegistro.data)
                for(const k in variableRegistro){
                  variableRegistro[k].idEntrada = response.data.body[0]._id
                }
                localEntrada[j].variableRegistro.data = JSON.stringify(variableRegistro)
                console.log(localEntrada[j].variableRegistro)
                await axios(localEntrada[j].variableRegistro)
                .then(responseV =>console.log(responseV))
                .catch(err => console.log(err))
              }
            })
            .catch(err => console.log(err))
          })
          .catch(err => console.log(err))
        }else{
          var data = JSON.parse(localEntrada[j].entrada.data)
          delete data[0].id
          localEntrada[j].entrada.data = JSON.stringify(data)
          console.log(localEntrada[j].entrada)
          await axios(localEntrada[j].entrada)
          .then(async response => {
            console.log(response)
            if (response.data.status === 'OK'){
              var variableRegistro = JSON.parse(localEntrada[j].variableRegistro.data)
              for(const k in variableRegistro){
                variableRegistro[k].idEntrada = response.data.body[0]._id
              }
              localEntrada[j].variableRegistro.data = JSON.stringify(variableRegistro)
              console.log(localEntrada[j].variableRegistro)
              await axios(localEntrada[j].variableRegistro)
              .then(response =>console.log(response))
              .catch(err => console.log(err))
            }
          })
          .catch(err => console.log(err))
        }
      }
    }
    
  }
  const offlineManager =()=>{
    // console.log('Holaa')
    // onlineValidator()
    var check = setInterval(async ()=> {await onlineValidator() }, 60000 )
      
  }
    const onlineValidator =async()=>{
      var online = navigator.onLine ? "ONLINE" : "OFFLINE";
      console.log(online)
      if(online === 'ONLINE'){
          await syncManager()
        }
      if (online === 'OFFLINE'){
        console.log('offline')
      }
  }
  
  return (
    <div className="App">
      { Iduser !== "" ?( Rol !== "" ? <Redirect push to={"/"+Rol}/> : null): null}
      {offlineManager()}
      <Switch>
        {/* USUARIO PÚBLICO */}
        <Route exact path ='/' component={Landing}/>
        <Route path='/login'exact render={propiedades => (<Login {...propiedades} setNombre = {setNombre} setRol = {setRol} setIduser={setIduser} setExternal={setExternal}/>)}/>
        <Route path='/register'exact render={propiedades => (<Register {...propiedades} setNombre = {setNombre} setRol = {setRol} setIduser={setIduser} setExternal={setExternal}/>)}/>
        <Route path='/recoverPassword'exact render={propiedades => (<Recover {...propiedades} setNombre = {setNombre} setRol = {setRol} setIduser={setIduser} setExternal={setExternal}/>)}/>
        <Route path='/changepassword' exact render={propiedades => (<CambiarPassword {...propiedades} Nombre = {Nombre} Rol = {Rol} Iduser={Iduser} External={External} />)}/>
        <Route path='/verificationaccount/:token' exact render={propiedades => (<VerifyAccount {...propiedades} Nombre = {Nombre} Rol = {Rol} Iduser={Iduser} External={External} />)}/>
        <Route path='/home' component = {Home}/>
        <Route path='/reportes' component = {PublicMonitoreos}/>
        {/* USUARIO ADMINISTRADOR */}
        <Route path='/restaured.admin/vusers' exact render={propiedades => (<EditUsers {...propiedades} Nombre = {Nombre} Rol = {Rol} Iduser={Iduser} External={External} />)}/>
        <Route path='/restaured.admin/reloadereditusers' exact render={propiedades => (<ReloaderEditUsers {...propiedades} Nombre = {Nombre} Rol = {Rol} Iduser={Iduser} External={External}/>)}/>
        <Route path='/restaured.admin/cusers' exact render={propiedades => (<CreateUsers {...propiedades} Nombre = {Nombre} Rol = {Rol} Iduser={Iduser} External={External}/>)}/>
        <Route path='/restaured.admin/location'exact render={propiedades => (<Editlocation {...propiedades} Nombre = {Nombre} Rol = {Rol} Iduser={Iduser} External={External}/>)}/>
        <Route path='/restaured.admin/charts' exact render={propiedades => (<Monitoreos {...propiedades} Nombre = {Nombre} Rol = {Rol} Iduser={Iduser} External={External}/>)}/>
        <Route path='/restaured.admin/monitoreo' exact render={propiedades => (<Reportes {...propiedades} Nombre = {Nombre} Rol = {Rol} Iduser={Iduser} External={External}/>)}/>
        <Route path='/restaured.admin/monitoreovariable' exact render={propiedades => (<MonitoreosVariables {...propiedades} Nombre = {Nombre} Rol = {Rol} Iduser={Iduser} External={External}/>)}/>
        <Route path='/restaured.admin/monitoreoindicador' exact render={propiedades => (<MonitoreosIndicadores {...propiedades} Nombre = {Nombre} Rol = {Rol} Iduser={Iduser} External={External}/>)}/>
        <Route path='/restaured.admin/reloadermonitoreo' exact render={propiedades => (<ReloaderMonitoreo {...propiedades} Nombre = {Nombre} Rol = {Rol} Iduser={Iduser} External={External}/>)}/>
        <Route path='/restaured.admin/reloadermonitoreovariable' exact render={propiedades => (<ReloaderMonitoreoVariable {...propiedades} Nombre = {Nombre} Rol = {Rol} Iduser={Iduser} External={External}/>)}/>
        <Route path='/restaured.admin/reloadermonitoreoindicador' exact render={propiedades => (<ReloaderMonitoreoInicador {...propiedades} Nombre = {Nombre} Rol = {Rol} Iduser={Iduser} External={External}/>)}/>
        <Route path='/restaured.admin/monitoreovariableeditar' exact render={propiedades => (<MonitoreosVariablesEditar {...propiedades} Nombre = {Nombre} Rol = {Rol} Iduser={Iduser} External={External}/>)}/>
        <Route path='/restaured.admin/monitoreoindicadoreditar' exact render={propiedades => (<MonitoreosIndicadoresEditar {...propiedades} Nombre = {Nombre} Rol = {Rol} Iduser={Iduser} External={External}/>)}/>
        <Route path='/restaured.admin' exact render={propiedades => (<HomeUsers {...propiedades} Nombre = {Nombre} Rol = {Rol} Iduser={Iduser} External={External}/>)}/>
        {/* USUARIO CIENTIFICO */}
        <Route path='/restaured.scie' exact render={propiedades => (<HomeUsersC {...propiedades} Nombre = {Nombre} Rol = {Rol} Iduser={Iduser} External={External}/>)}/>
        <Route path='/restaured.scie/viewlusers' exact render={propiedades => (<ViewLUsersC {...propiedades} Nombre = {Nombre} Rol = {Rol} Iduser={Iduser} External={External}/>)}/>
        <Route path='/restaured.scie/monitoreosusers' exact render={propiedades => (<MonitoreoUsersC {...propiedades} Nombre = {Nombre} Rol = {Rol} Iduser={Iduser} External={External}/>)}/>
        <Route path='/restaured.scie/registrosusers' exact render={propiedades => (<RegistrosUsersC {...propiedades} Nombre = {Nombre} Rol = {Rol} Iduser={Iduser} External={External}/>)}/>
        <Route path='/restaured.scie/entradasusers' exact render={propiedades => (<EntradaMUsersC {...propiedades} Nombre = {Nombre} Rol = {Rol} Iduser={Iduser} External={External}/>)}/>
        <Route path='/restaured.scie/cargarusers' exact render={propiedades => (<CargarRegistro {...propiedades} Nombre = {Nombre} Rol = {Rol} Iduser={Iduser} External={External}/>)}/>
        <Route path='/restaured.scie/reportesusers' exact render={propiedades => (<ReporteMUsersC {...propiedades} Nombre = {Nombre} Rol = {Rol} Iduser={Iduser} External={External}/>)}/>
        <Route path='/restaured.scie/curacion' exact render={propiedades => (<CuracionRegistro {...propiedades} Nombre = {Nombre} Rol = {Rol} Iduser={Iduser} External={External}/>)}/>
        <Route path='/restaured.scie/curacionentradasusers' exact render={propiedades => (<CuracionEntrada  {...propiedades} Nombre = {Nombre} Rol = {Rol} Iduser={Iduser} External={External}/>)}/>
        <Route path='/restaured.scie/curacionvalidacion' exact render={propiedades => (<CuracionValidacion  {...propiedades} Nombre = {Nombre} Rol = {Rol} Iduser={Iduser} External={External}/>)}/>
        <Route path='/restaured.scie/reloaderentrada' exact render={propiedades => (<ReloaderEntrada {...propiedades} Nombre = {Nombre} Rol = {Rol} Iduser={Iduser} External={External}/>)}/>
        <Route path='/restaured.scie/reloaderregistro' exact render={propiedades => (<ReloaderRegistro {...propiedades} Nombre = {Nombre} Rol = {Rol} Iduser={Iduser} External={External}/>)}/>
        {/* USUARIO CIENTIFICO CIUDADANO */}
        <Route path='/restaured.CzScie' exact render={propiedades => (<HomeUsersCC {...propiedades} Nombre = {Nombre} Rol = {Rol} Iduser={Iduser} External={External}/>)}/>
        <Route path='/restaured.CzScie/viewlusers' exact render={propiedades => (<ViewLUsersCC {...propiedades} Nombre = {Nombre} Rol = {Rol} Iduser={Iduser} External={External}/>)}/>
        <Route path='/restaured.CzScie/monitoreosusers' exact render={propiedades => (<MonitoreosUsersCC {...propiedades} Nombre = {Nombre} Rol = {Rol} Iduser={Iduser} External={External}/>)}/>
        <Route path='/restaured.CzScie/registrosusers' exact render={propiedades => (<RegistrosUsersCC {...propiedades} Nombre = {Nombre} Rol = {Rol} Iduser={Iduser} External={External}/>)}/>
        <Route path='/restaured.CzScie/entradasusers' exact render={propiedades => (<EntradaMUsersCC {...propiedades} Nombre = {Nombre} Rol = {Rol} Iduser={Iduser} External={External}/>)}/>
        <Route path='/restaured.CzScie/cargarusers' exact render={propiedades => (<CargarRegistroCC {...propiedades} Nombre = {Nombre} Rol = {Rol} Iduser={Iduser} External={External}/>)}/>
        <Route path='/restaured.CzScie/reportesusers' exact render={propiedades => (<ReporteMUsersCC {...propiedades} Nombre = {Nombre} Rol = {Rol} Iduser={Iduser} External={External}/>)}/>
        <Route path='/restaured.CzScie/reloaderentrada' exact render={propiedades => (<ReloaderEntradaCC {...propiedades} Nombre = {Nombre} Rol = {Rol} Iduser={Iduser} External={External}/>)}/>
        <Route path='/restaured.CzScie/reloaderregistro' exact render={propiedades => (<ReloaderRegistroCC {...propiedades} Nombre = {Nombre} Rol = {Rol} Iduser={Iduser} External={External}/>)}/>
        {/* USUARIO CURADOR */}
        <Route path='/restaured.curador' exact render={propiedades => (<CuracionRegistroCU {...propiedades} Nombre = {Nombre} Rol = {Rol} Iduser={Iduser} External={External}/>)}/>
        <Route path='/restaured.curador/curacionentradasusers' exact render={propiedades => (<CuracionEntradaCU  {...propiedades} Nombre = {Nombre} Rol = {Rol} Iduser={Iduser} External={External}/>)}/>
        <Route path='/restaured.curador/curacionvalidacion' exact render={propiedades => (<CuracionValidacionCU  {...propiedades} Nombre = {Nombre} Rol = {Rol} Iduser={Iduser} External={External}/>)}/>
        {/* USUARIO CURADOR */}
        <Route path='/homeusersCU' exact render={propiedades => (<HomeUsersCU {...propiedades} Nombre = {Nombre} Rol = {Rol} Iduser={Iduser} External={External}/>)}/>
        <Route path='/monitoreosusersCU' exact render={propiedades => (<MonitoreosUsersCU {...propiedades} Nombre = {Nombre} Rol = {Rol} Iduser={Iduser} External={External}/>)}/>
        <Route path='/viewlusersCU' exact render={propiedades => (<ViewLUsersCU {...propiedades} Nombre = {Nombre} Rol = {Rol} Iduser={Iduser} External={External}/>)}/>


      </Switch>
    </div>
  );
}

export default App;
