import React from 'react'
import { Bar } from '@reactchartjs/react-chart.js'

const data = {
  labels: ['Septiembre', 'Octubre', 'Noviembre', 'Diciembre', 'Enero', 'Febrero'],
  datasets: [
    {
      label: 'Machos',
      data: [12, 16, 23, 25, 28, 21],
      backgroundColor: 'rgb(255, 99, 132)',
    },
    {
      label: 'Hembras',
      data: [15, 18, 20, 20, 19, 29],
      backgroundColor: 'rgb(54, 162, 235)',
    },
  ],
}

const options = {
  scales: {
    yAxes: [
      {
        stacked: true,
        ticks: {
          beginAtZero: true,
        },
      },
    ],
    xAxes: [
      {
        stacked: true,
      },
    ],
  },
}

const StackedBar = () => (
  <>
    <div className='header'>
      <h1 className='title'>Proporción de Machos y hembras </h1>
      <div className='links'>
        {/* <a
          className='btn btn-gh'
          href='https://github.com/reactchartjs/react-chartjs-2/blob/react16/example/src/charts/StackedBar.js'
        >
          Github Source
        </a> */}
      </div>
    </div>
    <Bar data={data} options={options} />
  </>
)

export default StackedBar