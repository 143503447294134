import React , {Component} from 'react'
import '../../assets/css/sb-admin-2.css'
import '../../assets/vendor/bootstrap/css/bootstrap.css'
import { Link } from "react-router-dom";


class NavbarUC extends Component{
	constructor(props){
		super(props);
		this.state={
			Rol: this.props.Rol,
			Token: this.props.Token,
			Iduser: this.props.Iduser,
			open: false,
		}
	}
	render(){
		return(
		<div id="wrapper" >
		{/* Sidebar */}
			<ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
				{/* Sidebar - Brand */}
				<Link to="/restaured.curador"> 
					<p className="sidebar-brand d-flex align-items-center justify-content-center">
						<div className="sidebar-brand-icon rotate-n-15">
							<i className="fas fa-laugh-wink" />
						</div>
						<div className="sidebar-brand-text mx-3">RestauRed  <sup>Curador</sup></div>
					</p>
				</Link>
				{/* Divider */}
				<hr className="sidebar-divider my-0" />
				{/* Nav Item - Dashboard */}
				<li className="nav-item active">
					<Link to="/restaured.curador"> 
								<p className="nav-link" >
								<i className="fas fa-fw fa-tachometer-alt" />
									<span>Home</span>
									</p>
					</Link>
				</li>
				{/* Divider */}
				<hr className="sidebar-divider" />
				<li className="nav-item">
					<Link to="/restaured.curador">
					<p className="nav-link" >
						<i className="fas fa-fw fa-chart-area" />
						<span>Curacion de datos</span></p>
					</Link>
				</li>
			</ul>
		</div>)
	};
};   
	 
export default NavbarUC