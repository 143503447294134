import React, { Component } from 'react'
import '../../assets/vendor/bootstrap/css/bootstrap.css'
import '../../assets/css/sb-admin-2.css'
import NavbarAdmin from '../NavBar/NavbarAdmin'
import HeaderUsers from './Header'
import { Redirect} from 'react-router'
import Logout from './Logout'
const axios = require('axios');


class RegistrosMonitoreos extends Component{
    constructor(props){
        super(props);
        this.state={
            Rol: this.props.Rol,
            Token: this.props.Token,
            Iduser: this.props.Iduser,
            open:false,
            redirect:false,
			data:[],
            selectedMonitoreo:{},
            form:{
                nombre:"",
                tipo:"",
                pubObj:"",
                unidades:"",
            },            
			variable:{
				id:[],
				idMonitoreo:[],
				nombre:[]
			},
			variablexindicador:{
				id:[],
				idIndicador:[],
				idVariable:[]
			},
			indicador:{
				id:[],
				idMonitoreo:[],
				formula:[],
				nombre:[],
				pubObj:[],
				tipo:[],
				tipoGrafico:[],
				unidades:[],
			},
			monitoreo:{
				id:[],
				nombre:[],
				descripcion:[],
			},
			registroMonitoreo:{
				id:[],
				nombre:[],
				idUser:[],
				idMonitoreo:[],
			},
			entradaMonitoreo:{
				id:[],
				idLocalizacion:[],
				curado:[],
				idRegistro:[],
				idEspecies:[],
			},
			variableRegistro:{
				id:[],
				idEntrada:[],
				idVariable:[],
				valor:[],
			},
			ingresarEntradaMonitoreo:{
				body:[],
				status:"",
			},
			monitoreos:[],
	
		}
		this.Getespecies();
		this.initial_state();
		}
    //HANDLEE
  
	setOpen=()=>{
	this.setState({open:!this.state.open})
	}
	handleInputChange = (cellInfo, event) => {
	let data = [...this.state.data];
	data[cellInfo.index][cellInfo.column.id] = event.target.value;

	this.setState({ data });
	};   
	onChange = (event ) => {
	
	this.setState({
	form: { ...this.state.form, [event.target.name]:event.target.value, }
	});
	};
	//PETICIONES
	Getespecies=async()=>{
	var baseurl = "https://api.restaured.net"
	const url = baseurl+'/restaured/especie'
	await axios.get(url, {withCredentials:true})
	.then(response => response.data)
	.then(data => {
		var id =[];
		var nombreCientifico =[];
		var nombreComun =[];
		var idLocalizacion =[];
		var activo =[];
		for(const i in data.body){
		id.push(data.body[i]._id)
		idLocalizacion.push(data.body[i].idLocalizacion)
		nombreCientifico.push(data.body[i].nombreCientifico)
		nombreComun.push(data.body[i].nombreComun)
		activo.push(data.body[i].activo)
		};
		this.setState({
		especie:{
			id:id,
			idLocalizacion:idLocalizacion,
			nombreCientifico:nombreCientifico,
			nombreComun:nombreComun,
			activo:activo,
		}});
	})
	// console.log(this.state.especies);
	} 
	GetDataTable=async()=>{
	var baseurl = "https://api.restaured.net"
	var url = baseurl+'/restaured/variable'
	await axios.get(url,{withCredentials: true})
	.then(response => response.data)
	.then(data => {
		// console.log(data)
		var id =[]; var idMonitoreo =[]; var nombre =[]; var tipo =[];
		var unidades =[]; var pubObj =[];
		for(const i in data.body){
		  id.push(data.body[i]._id);
		  idMonitoreo.push(data.body[i].idMonitoreo);
		  nombre.push(data.body[i].nombre);
		  tipo.push(data.body[i].tipo);
		  unidades.push(data.body[i].unidades);
		  pubObj.push(data.body[i].pubObj);
		};
		this.setState({
		  variable:{
			  id:id,
			  idMonitoreo:idMonitoreo,
			  nombre:nombre,
			  tipo:tipo,
			  unidades:unidades,
			  pubObj:pubObj,
		}})
	  })
	url = baseurl+'/restaured/variablexindicador'
	await axios.get(url,{withCredentials:true})
	.then(response => response.data)
	.then(data => {
		// console.log(data)
		var id =[];
		var idIndicador =[];
		var idVariable =[];
		for(const i in data.body){
		id.push(data.body[i]._id);
		idIndicador.push(data.body[i].idIndicador);
		idVariable.push(data.body[i].idVariable);
		};
		this.setState({
		variablexindicador:{
				id:id,
				idIndicador:idIndicador,
				idVariable:idVariable,
		}})
	})
	url = baseurl+'/restaured/indicador'
	await axios.get(url, {withCredentials:true})
	.then(response => response.data)
	.then(data => {
		// console.log(data)
		var id =[];
		var idMonitoreo =[];
		var formula =[];
		var nombre =[];
		var pubObj =[];
		var tipo =[];
		var tipoGrafico =[];
		var unidades =[];
		for(const i in data.body){
		id.push(data.body[i]._id);
		idMonitoreo.push(data.body[i].idMonitoreo);
		formula.push(data.body[i].formula);
		nombre.push(data.body[i].nombre);
		pubObj.push(data.body[i].pubObj);
		tipo.push(data.body[i].tipo);
		tipoGrafico.push(data.body[i].tipoGrafico);
		unidades.push(data.body[i].unidades);
		};
		this.setState({
		indicador:{
			id:id,
			idMonitoreo:idMonitoreo,
			formula:formula,
			nombre:nombre,
			pubObj:pubObj,
			tipo:tipo,
			tipoGrafico:tipoGrafico,
			unidades:unidades,
		}})
	})
	url = baseurl+'/restaured/monitoreo'
	await axios.get(url, {withCredentials:true})
	.then(response => response.data)
	.then(data => {
		// console.log(data)
		var id =[];
		var nombre =[];
		var descripcion =[];
		for(const i in data.body){
		id.push(data.body[i]._id);
		nombre.push(data.body[i].nombre);
		descripcion.push(data.body[i].descripcion);
		};
		this.setState({
		monitoreo:{
			id:id,
			nombre:nombre,
			descripcion:descripcion,
		}})
	})
	url = baseurl+'/restaured/registromonitoreo'
	await axios.get(url, {withCredentials:true})
	.then(response => response.data)
	.then(data => {
		//Extraer Fecha y agegarla al modelo Monitoreo
		// var date= new Date(data.body[0].date*1000);
		// console.log(date);
		// console.log(data.body[0]);
		var id =[];
		var nombre =[];
		var idMonitoreo =[];
		var idUser =[];
		var date=[]
		for(const i in data.body){
		id.push(data.body[i]._id);
		nombre.push(data.body[i].nombre);
		idUser.push(data.body[i].idUser);
		idMonitoreo.push(data.body[i].idMonitoreo);
		date.push(data.body[i].date);
		};
		this.setState({
		registroMonitoreo:{
			id:id,
			nombre:nombre,
			idUser:idUser,
			idMonitoreo:idMonitoreo,
			date:date,
		}})
	})
	url = baseurl+'/restaured/entradamonitoreo'
	await axios.get(url, {withCredentials:true})
	.then(response => response.data)
	.then(data => {
		//Extraer Localización y agegarla al modelo Monitoreo
		// console.log(data)
		var id =[];
		var idLocalizacion =[];
		var curado =[];
		var idRegistro =[];
		var idEspecies =[];
		for(const i in data.body){
		id.push(data.body[i]._id);
		idLocalizacion.push(data.body[i].idLocalizacion);
		curado.push(data.body[i].curado);
		idRegistro.push(data.body[i].idRegistro);
		idEspecies.push(data.body[i].idEspecie);
		};
		this.setState({
		entradaMonitoreo:{
				id:id,
				idLocalizacion:idLocalizacion,
				curado:curado,
				idRegistro:idRegistro,
				idEspecies:idEspecies,
		}})
	})
	url = baseurl+'/restaured/variableregistro'
	await axios.get(url, {withCredentials:true})
	.then(response => response.data)
	.then(data => {
		// console.log(data)
		var id =[];
		var idEntrada =[];
		var idVariable =[];
		var valor =[];
		for(const i in data.body){
		id.push(data.body[i]._id);
		idEntrada.push(data.body[i].idEntrada);
		idVariable.push(data.body[i].idVariable);
		valor.push(data.body[i].valor);
		};
		this.setState({
		variableRegistro:{
				id:id,
				idEntrada:idEntrada,
				idVariable:idVariable,
				valor:valor,
		}})
	})
	}
	viewdata=async()=>{
	await this.GetDataTable()
	var monitoreo={
		id:0,
		nombre:"",
		descripcion:"",
		variables:[],
		registros:[],
		indicadores:[],
	};
	var indicadores = {
		id:[],
		idMonitoreo:[],
		formula:[],
		nombre:[],
		pubObj:[],
		tipo:[],
		tipoGrafico:[],
		unidades:[],
	};
	var registros={
		id:[],
		iduser:[],
		nombre:[],
		entradas:[],
		date:[],
	};
	var entradas={
		id:[],  
		idLocalizacion:[],  
		idVariablesRegistro:[],  
		variables:[],
		especies:[],
		valores:[],
		curado:[],
	};
	var monitoreos=[]
	var valores=[];
	var variables=[];
	var idvariableRegistro=[]
	var idvariablesRegistro=[]
	var variableEntrada=[];
	var valorEntrada=[];
	for(const i in this.state.monitoreo.id){
		monitoreo.id=this.state.monitoreo.id[i]
		monitoreo.nombre=this.state.monitoreo.nombre[i]
		monitoreo.descripcion=this.state.monitoreo.descripcion[i]
		for(const j in this.state.variable.nombre){
		if(this.state.variable.idMonitoreo[j]===this.state.monitoreo.id[i]){
			var variable={
			id:this.state.variable.id[j],
			nombre:this.state.variable.nombre[j],
            tipo:this.state.variable.tipo[j],
            pubObj:this.state.variable.pubObj[j],
            unidades:this.state.variable.unidades[j],
			}
			monitoreo.variables.push(variable);
		}
		}
		for(const j in this.state.indicador.id){
		if(this.state.indicador.idMonitoreo[j]===this.state.monitoreo.id[i]){
			indicadores.id.push(this.state.indicador.id[j])
			indicadores.idMonitoreo.push(this.state.indicador.idMonitoreo[j])
			indicadores.formula.push(this.state.indicador.formula[j])
			indicadores.nombre.push(this.state.indicador.nombre[j])
			indicadores.pubObj.push(this.state.indicador.pubObj[j])
			indicadores.tipo.push(this.state.indicador.tipo[j])
			indicadores.tipoGrafico.push(this.state.indicador.tipoGrafico[j])
			indicadores.unidades.push(this.state.indicador.unidades[j])
		}
		}
		// console.log(this.state.entradaMonitoreo)
		for(const j in this.state.registroMonitoreo.id){
		if(this.state.monitoreo.id[i] === this.state.registroMonitoreo.idMonitoreo[j]){
			registros.id.push(this.state.registroMonitoreo.id[j])
			registros.iduser.push(this.state.registroMonitoreo.idUser[j])
			registros.nombre.push(this.state.registroMonitoreo.nombre[j])
			registros.date.push(this.state.registroMonitoreo.date[j])
			for(const k in this.state.entradaMonitoreo.id){
			if(this.state.registroMonitoreo.id[j] === this.state.entradaMonitoreo.idRegistro[k]){
				entradas.id.push(this.state.entradaMonitoreo.id[k]);
				entradas.idLocalizacion.push(this.state.entradaMonitoreo.idLocalizacion[k]);
				entradas.curado.push(this.state.entradaMonitoreo.curado[k]);
				entradas.especies.push(this.state.entradaMonitoreo.idEspecies[k]);
				for(const l in this.state.variableRegistro.id){
					if(this.state.entradaMonitoreo.id[k] === this.state.variableRegistro.idEntrada[l]){
					idvariableRegistro.push(this.state.variableRegistro.id[l]);
					valorEntrada.push(Number(this.state.variableRegistro.valor[l]));
					variableEntrada.push(this.state.variableRegistro.idVariable[l]);
					}
				}
				idvariablesRegistro.push(idvariableRegistro)  
				variables.push(variableEntrada)  
				valores.push(valorEntrada)  
				idvariableRegistro=[]
				valorEntrada=[]
				variableEntrada=[]
				}
			}
			entradas.variables=variables;
			entradas.valores=valores;
			entradas.idVariablesRegistro=idvariablesRegistro;
			variables=[];
			idvariablesRegistro=[];
			valores=[];
			registros.entradas.push(entradas);
			entradas={id:[], idLocalizacion:[], idVariablesRegistro:[], variables:[], valores:[], curado:[],especies:[],}
		}
		}
		monitoreo.indicadores=indicadores;
		monitoreo.registros=registros;
		registros={ id:[], iduser:[], nombre:[], entradas:[], date:[] };
		indicadores = { id:[], idMonitoreo:[], formula:[], nombre:[], pubObj:[], tipo:[], tipoGrafico:[], unidades:[],};
		monitoreos.push(monitoreo);
		monitoreo={ id:0, nombre:"", descripcion:"", variables:[],indicadores:[],};
	}
	console.log(monitoreos)
	this.setState({
		monitoreos:monitoreos
	})
	}
	initial_state=async()=>{
		await this.viewdata()
		var location = this.props.location
		var selectedMonitoreo=(location.state)		
		var nombreVariable = this.state.monitoreos[selectedMonitoreo.monitoreo].variables[selectedMonitoreo.variable].nombre
		var tipo = this.state.monitoreos[selectedMonitoreo.monitoreo].variables[selectedMonitoreo.variable].tipo
		var pubObj = this.state.monitoreos[selectedMonitoreo.monitoreo].variables[selectedMonitoreo.variable].pubObj
		var unidades = this.state.monitoreos[selectedMonitoreo.monitoreo].variables[selectedMonitoreo.variable].unidades
		this.setState({form:{ nombre : nombreVariable, tipo: tipo, unidades: unidades,pubObj:pubObj}})
	}
	cancelarActualizar=()=>{
		this.setState({
		redirect:true,
		form:{
			nombre:"",
			tipo:"",
			pubObj:"",
			unidades:"",}
		})
	}
	actualizarVariable=async(body)=>{
		var baseurl = "https://api.restaured.net"
		var url = baseurl+'/restaured/variable'
		var data = JSON.stringify([body])
		var config = {
		method: 'put',
		url: url,
		headers: { 
			'Content-Type': 'application/json'
		},
		withCredentials:true,
		data: data
		};
		await axios(config)
	}
	confirmarActualizar= async()=>{
	var location = this.props.location
	var selectedMonitoreo=(location.state)		
	var variable={ 
		_id:this.state.monitoreos[selectedMonitoreo.monitoreo].variables[selectedMonitoreo.variable].id,
		nombre:this.state.form.nombre,
		tipo:this.state.form.tipo,
		pubObj:this.state.form.pubObj,
		unidades:this.state.form.unidades,
	}
	console.log(variable)
	await this.actualizarVariable(variable)
	this.setOpen()
	this.setState({redirect:true})
	}
	eliminarVariable= async()=>{
		var location = this.props.location
		var selectedMonitoreo=(location.state)		
		var variable={ 
			_id:this.state.monitoreos[selectedMonitoreo.monitoreo].variables[selectedMonitoreo.variable].id,
		}
		var baseurl = "https://api.restaured.net"
		var url = baseurl+'/restaured/variable'
		var data = JSON.stringify([variable])
		var config = {
		method: 'delete',
		url: url,
		headers: { 
			'Content-Type': 'application/json'
		},
		withCredentials:true,
		data: data
		};
		await axios(config)
		this.setOpen()
		this.setState({redirect:true})
		}
	renderPubObj=()=>{
		var html = []
		if(this.state.form.pubObj === 'Web'){
			html.push(<option value='Web' selected> Web</option>)
			html.push(<option value='Cientifico'> Cientifico</option>)
			html.push(<option value='Cientifico_Ciudadano'> Cientifico Ciudadano</option>)
			html.push(<option value='Cient_y_Cient_Ciudadano'> Cientifico Y Cientifico Ciudadano</option>)
		}
		if(this.state.form.pubObj === 'Cientifico'){
			html.push(<option value='Web' > Web</option>)
			html.push(<option value='Cientifico' selected> Cientifico</option>)
			html.push(<option value='Cientifico_Ciudadano'> Cientifico Ciudadano</option>)
			html.push(<option value='Cient_y_Cient_Ciudadano'> Cientifico Y Cientifico Ciudadano</option>)
		}
		if(this.state.form.pubObj === 'Cientifico_Ciudadano'){
			html.push(<option value='Web' > Web</option>)
			html.push(<option value='Cientifico' > Cientifico</option>)
			html.push(<option value='Cientifico_Ciudadano' selected> Cientifico Ciudadano</option>)
			html.push(<option value='Cient_y_Cient_Ciudadano'> Cientifico Y Cientifico Ciudadano</option>)
		}
		if(this.state.form.pubObj === 'Cient_y_Cient_Ciudadano'){
			html.push(<option value='Web' > Web</option>)
			html.push(<option value='Cientifico' > Cientifico</option>)
			html.push(<option value='Cientifico_Ciudadano'> Cientifico Ciudadano</option>)
			html.push(<option value='Cient_y_Cient_Ciudadano' selected> Cientifico Y Cientifico Ciudadano</option>)
		}
		return(
		<select className="form-control" name="pubObj"onChange={(e)=>this.onChange(e)}>
			{html}
		</select>)
	}
	renderTipo=()=>{
		var html = []
		if(this.state.form.tipo === 'Entero'){
			html.push(<option value='Entero' selected> Entero</option>)
			html.push(<option value='Decimal'> Decimal</option>)
			html.push(<option value='Nominal'> Nominal</option>)
		}
		if(this.state.form.tipo === 'Decimal'){
			html.push(<option value='Entero' > Entero</option>)
			html.push(<option value='Decimal' selected> Decimal</option>)
			html.push(<option value='Nominal'> Nominal</option>)
		}
		if(this.state.form.tipo === 'Nominal'){
			html.push(<option value='Entero' > Entero</option>)
			html.push(<option value='Decimal' > Decimal</option>)
			html.push(<option value='Nominal' selected> Nominal</option>)
		}
		return(
		<select className="form-control" name="tipo"onChange={(e)=>this.onChange(e)}>
			{html}
		</select>)
	}
	renderFormEditar=()=>{
		if(this.state.monitoreos.length > 0){
			return( 
				<>
				<div className="row">
					<div className="col-lg-4">
						<p> Nombre: </p>
					</div>
					<div className="col-lg-4">
						<input type="text" className="form-control" name="nombre" value={this.state.form.nombre} onChange={this.onChange}/>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-4">
						<p> Tipo: </p>
					</div>
					<div className="col-lg-4">
						{/* <input type="text" className="form-control" name="tipo" value={this.state.form.tipo} onChange={this.onChange}/> */}
						{this.renderTipo()}
					</div>
				</div>
				<div className="row">
					<div className="col-lg-4">
					<p> Público Objetivo: </p>
					</div>
					<div className="col-lg-4">
						{this.renderPubObj()}
					</div>
				</div>
				<div className="row">
					<div className="col-lg-4">
						<p> Unidades: </p>
					</div>
					<div className="col-lg-4">
						<input type="text" className="form-control" name="unidades" value={this.state.form.unidades} onChange={this.onChange}/>
					</div>
				</div>
				<br/>
				<br/>
				<div className="row">
					<div className="col-lg-4">
						<h5>
							<button className="btn-primary" onClick={this.eliminarVariable}> Eliminar</button>
						</h5>
					</div>
					<div className="col-lg-4">
						<h5>
							<button className="btn-primary" onClick={this.cancelarActualizar}> Cancelar</button>
						</h5>
					</div>
					<div className="col-lg-4">
						<h5>
							<button className="btn-primary" onClick={this.confirmarActualizar}> Guardar Cambios</button>
						</h5>
					</div>
					{ this.state.redirect ? <Redirect push to= {{pathname:"/restaured.admin/monitoreovariable",state:this.props.location.state.monitoreo}}></Redirect> : null}
				</div>
				</>)
			
		}else return null
	}
    
    render(){
    return (
        <div>
            <div>
                <div id="wrapper">
                <NavbarAdmin/>
                {/* Content Wrapper */}
                <div id="content-wrapper" className="d-flex flex-column">
                    {/* Main Content */}
                    <div id="content">
					<HeaderUsers Iduser={this.props.Iduser} Rol={this.props.Rol} Nombre={this.props.Nombre} External={this.props.External}/>
					{this.props.Nombre ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
					{this.props.Iduser ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
					{this.props.Rol ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
						<div className="container-fluid">
							<h1 className="h3 mb-2 text-gray-800">Variable Monitoreo</h1>
							<br/>
							<br/>
							{this.renderFormEditar()}
						</div>
					</div>
                     <footer id="footer" className="sticky-footer">
                    <div className="container my-auto">
                        <div className="copyright text-center my-auto">
                        © Copyright <strong><span>Restaured 2024</span></strong>. All Rights Reserved
                        </div>
                    </div>
                    </footer>
                </div>
                </div>
                <a className="scroll-to-top rounded" href="#page-top">
                <i className="fas fa-angle-up" />
                </a>
                <Logout/>
            </div>
        </div>
      )}
  
};

export default RegistrosMonitoreos