import React, { Component} from 'react'
import '../../../assets/vendor/bootstrap/css/bootstrap.css'
import { Redirect} from 'react-router'
import '../../../assets/css/sb-admin-2.css'
import MapView from '../../Charts/MapView'
import NavbarU from '../../NavBar/NavbarUsers'
import StackedBar from '../../Charts/StackedBar'
import PieChart from '../../Charts/PieChart'
import HeaderUsers from './HeaderUsers'


class HomeUsersCU extends Component {
    constructor(props){
      super(props);
      this.state={
        Rol: this.props.Rol,
        Token: this.props.Token,
        Iduser: this.props.Iduser,
      }
    }
    render(){
      return (
        <div>
          <div>
            <title>RestauRed Admin - Dashboard</title>
            {/* Page Wrapper */}
            <div id="wrapper">
              {/* Content Wrapper */}
              <NavbarU/>
              <div id="content-wrapper" className="d-flex flex-column">
                {/* Main Content */}
                <div id="content">
                <HeaderUsers Iduser={this.props.Iduser} Rol={this.props.Rol} Nombre={this.props.Nombre} External={this.props.External}/>
					{this.props.Nombre ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
					{this.props.Iduser ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
					{this.props.Rol ===''? <Redirect to={{pathname:"/login"}}> </Redirect> : null}
                  {/* Begin Page Content */}
                  <div className="container-fluid">
                    {/* Page Heading */}
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                      <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
                      <a href="/#" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i className="fas fa-download fa-sm text-white-50" /> Generar Reporte</a>
                    </div>
                    {/* Content Row */}
                    <div className="row">
                      {/* Registros de Aves del último mes */}
                      <div className="col-xl-3 col-md-6 mb-4">
                        <div className="card border-left-primary shadow h-100 py-2">
                          <div className="card-body">
                            <div className="row no-gutters align-items-center">
                              <div className="col mr-2">
                                <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                  Registros de Aves del último año</div>
                                <div className="h5 mb-0 font-weight-bold text-gray-800">500</div>
                              </div>
                              <div className="col-auto">
                                <i className="fas fa-calendar fa-2x text-gray-300" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Registros de vegetación del último mes */}
                      <div className="col-xl-3 col-md-6 mb-4">
                        <div className="card border-left-success shadow h-100 py-2">
                          <div className="card-body">
                            <div className="row no-gutters align-items-center">
                              <div className="col mr-2">
                                <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                Registros de vegetación del último año</div>
                                <div className="h5 mb-0 font-weight-bold text-gray-800">750</div>
                              </div>
                              <div className="col-auto">
                                <i className="fas fa-dollar-sign fa-2x text-gray-300" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Porcentaje de Aves Hembra del último mes */}
                      <div className="col-xl-3 col-md-6 mb-4">
                        <div className="card border-left-info shadow h-100 py-2">
                          <div className="card-body">
                            <div className="row no-gutters align-items-center">
                              <div className="col mr-2">
                                <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                                  Porcentaje de Aves Hembra del último año
                                </div>
                                <div className="row no-gutters align-items-center">
                                  <div className="col-auto">
                                    <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">58%</div>
                                  </div>
                                  <div className="col">
                                    <div className="progress progress-sm mr-2">
                                      <div className="progress-bar bg-info" role="progressbar" style={{width: '58%'}} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-auto">
                                <i className="fas fa-clipboard-list fa-2x text-gray-300" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Reportes Generados el último mes*/}
                      <div className="col-xl-3 col-md-6 mb-4">
                        <div className="card border-left-warning shadow h-100 py-2">
                          <div className="card-body">
                            <div className="row no-gutters align-items-center">
                              <div className="col mr-2">
                                <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                  Reportes Generados el último año</div>
                                <div className="h5 mb-0 font-weight-bold text-gray-800">60</div>
                              </div>
                              <div className="col-auto">
                                <i className="fas fa-comments fa-2x text-gray-300" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Content Row */}
                    <div className="row">
                      {/* Area Chart */}
                      <div className="col-lg-8">
                        <div className="card shadow ">
                          {/* Card Header - Dropdown */}
                          <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 className="m-0 font-weight-bold text-primary">Grafico de Reportes de Aves por zonas</h6>                            
                          </div>
                          {/* Card Body */}
                          <div className="card-body" >
                            <div id="mapid" >
                            <MapView />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Pie Chart - StackBar Chart*/}
                      <div className="col-lg-4">
                          <div className="card shadow mb-4">
                            {/* Card Header - Dropdown */}
                            <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                              <h6 className="m-0 font-weight-bold text-primary">Numero de Hembras y Machos</h6>
                            </div>
                            {/* Card Body */}
                            <div className="card-body">
                                <StackedBar/>
                            </div>
                          </div>
                          <div className="card shadow mb-4">
                            {/* Card Header - Dropdown */}
                            <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                              <h6 className="m-0 font-weight-bold text-primary">Numero de Hembras y Machos</h6>
                            </div>
                            {/* Card Body */}
                            <div className="card-body">
                                <PieChart/>
                            </div>
                          </div>
                      </div>
                    </div>
                    {/* Content Row */}
                    
                  </div>
                  {/* /.container-fluid */}
                </div>
                {/* End of Main Content */}
                {/* Footer */}
                 <footer id="footer" className="sticky-footer">
                  <div className="container my-auto">
                    <div className="copyright text-center my-auto">
                      © Copyright <strong><span>Restaured 2024</span></strong>. All Rights Reserved
                    </div>
                  </div>
                </footer>
                {/* End of Footer */}
              </div>
              {/* End of Content Wrapper */}
            </div>
            {/* End of Page Wrapper */}
            {/* Scroll to Top Button*/}
            <a className="scroll-to-top rounded" href="#page-top">
              <i className="fas fa-angle-up" />
            </a>
            {/* Logout Modal*/}
            <div className="modal fade" id="logoutModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
                    <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">Select "Logout" below if you are ready to end your current session.</div>
                  <div className="modal-footer">
                    <button className="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
                    <a className="btn btn-primary" href="/">Logout</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  )}
};
export default HomeUsersCU
