import React, { Component } from 'react'
import { BsTrash,BsArchive,BsGraphUp,BsTable} from "react-icons/bs"
import { useTable, useFilters, useGlobalFilter,useRowSelect, useAsyncDebounce, usePagination } from 'react-table'
import '../../assets/vendor/bootstrap/css/bootstrap.css'
import '../../assets/css/sb-admin-2.css'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
const axios = require('axios');
const EditableCell = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateMyData, // This is a custom function that we supplied to our table instance
  }) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue)
  
    const onChange = e => {
      setValue(e.target.value)
    }
  
    // We'll only update the external data when the input is blurred
    const onBlur = () => {
      updateMyData(index, id, value)
    }
  
    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
      setValue(initialValue)
    }, [initialValue])
  
    return <input className="form-control" value={value} onChange={onChange} onBlur={onBlur} />
}
const IndeterminateCheckboxBody = React.forwardRef(
	({ indeterminate, ...rest }, ref) => {
		const defaultRef = React.useRef()
		const resolvedRef = ref || defaultRef

		React.useEffect(() => {
			resolvedRef.current.indeterminate = indeterminate
		}, [resolvedRef, indeterminate])

		return (
			<>
				<input type="checkbox" ref={resolvedRef} {...rest} />
			</>
		)
	}
)
function DeleteEntrada(event,selectedRowIds,monitoreos,location){
	var id=[]
	for(const i in selectedRowIds){
		var idMonitoreo ={
			_id: monitoreos[i].id
		}
		id.push(idMonitoreo)
	}
	console.log(selectedRowIds, location, id)
	var baseurl = "https://api.restaured.net"
	var url = baseurl+'/restaured/monitoreo'
	var data = JSON.stringify(id)
	var config = {
		method: 'delete',
		url: url,
		headers: { 
		'Content-Type': 'application/json'
		},
		withCredentials:true,
		data: data
	};
	axios(config)
	.then(response => {console.log(response);	})

}

async function EditarMonitoreo(event,selectedRowIds,monitoreos,location,data){
	var body=[]
	console.log(data)
	for( const i in selectedRowIds){
		var registro={
			_id:data[i].id,
			nombre:data[i].nombre,
			descripcion:data[i].descripcion,
		}
		body.push(registro)
	}
	console.log(body)
	var baseurl = "https://api.restaured.net"
	var url = baseurl+'/restaured/monitoreo'
	// ACTUALIZAR DATOS
	var datos = JSON.stringify(body)
	var config = {
		method: 'put',
		url: url,
		headers: { 
		'Content-Type': 'application/json'
		},
		withCredentials:true,
		data: datos
	};
	await axios(config)
}

function RenderDesactivarMonitoreo({selectedRowIds,monitoreos}){
	var location = useLocation()
	return(
	<Link to={{pathname:"/restaured.admin/reloadermonitoreo"}}>
		<button type="button" className="btn border" onClick={(event) => DeleteEntrada(event,selectedRowIds,monitoreos,location)}> <BsTrash/><span>Desactivar Monitoreo </span></button>
	</Link>
	)
}
function RenderGuardarCambios({data,selectedRowIds,monitoreos}){
	var location = useLocation()
	return(
	<Link to={{pathname:"/restaured.admin/reloadermonitoreo"}}>
		<button type="button" className="btn border" onClick={(event) => EditarMonitoreo(event,selectedRowIds,monitoreos,location,data)}> <BsArchive/><span>Guardar Cambios </span></button>
	</Link>
	)
}
 

function GlobalFilter({
preGlobalFilteredRows,
globalFilter,
setGlobalFilter,
}) {
const count = preGlobalFilteredRows.length
const [value, setValue] = React.useState(globalFilter)
const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
}, 200)

return (
    <span>
    Search:{' '}
    <input
        value={value || ""}
        onChange={e => {
        setValue(e.target.value);
        onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        style={{
        fontSize: '1.1rem',
        border: '0',
        }}
    />
    </span>
)
}

function DefaultColumnFilter({
column: { filterValue, preFilteredRows, setFilter },
}) {
const count = preFilteredRows.length

return (
    <input
        className="form-control"
        value={filterValue || ''}
        onChange={e => {
            setFilter(e.target.value || undefined)
        }}
        placeholder={`Search ${count} records...`}
    />
)
}
const IndeterminateEdit =(row)=>{
    return (
        <>
        <Link to={{pathname:"/restaured.admin/monitoreovariable" ,state: row.selectedRowIds.id}} >
				<p  > <BsTable/> </p>
				<p> Variables </p>
        </Link>
        </>
    )
}

const IndeterminateView =(row)=>{
    return (
        <>
        <Link to={{pathname:"/restaured.admin/monitoreoindicador" ,state: row.selectedRowIds.id}}>
            <p > <BsGraphUp/> </p>
            <p> Graficos </p>
        </Link>
        </>
    )
}

function Table({ columns, data,updateMyData, setSelectedRows,monitoreos }) {

const defaultColumn = React.useMemo(
    () => ({
        // Default Filter UI
        Filter: DefaultColumnFilter,
    }),
    []
)

const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize, selectedRowIds },
} = useTable(
    {
        columns,
        data,
        defaultColumn,
        initialState:{pageSize:5},
        updateMyData,

    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect,
    hooks => {
    hooks.visibleColumns.push(columns => [
        // Let's make a column for selection
		{
			id: 'selection',
			// The header can use the table's getToggleAllRowsSelectedProps method
			// to render a checkbox
			Header: ({ getToggleAllRowsSelectedProps }) => (
				<div>
				</div>
			),
			// The cell can use the individual row's getToggleRowSelectedProps method
			// to the render a checkbox
			Cell: ({ row }) => (
				<div>
					<IndeterminateCheckboxBody {...row.getToggleRowSelectedProps()} />
				</div>
			),
			},
        {
        id: 'visualizar',
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
            
            </div>
        ),
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: ({ row }) => (
            <div>
            <IndeterminateView selectedRowIds={row}/>
            </div>
        ),
        },
        {
        id: 'editar',
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
            </div>
        ),
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: ({ row }) => (
            <div>
            <IndeterminateEdit selectedRowIds={row} />
            </div>
        ),
        },
        ...columns,
    ])
    }
)
React.useEffect(() => {
    setSelectedRows(selectedRowIds);
}, [setSelectedRows, selectedRowIds]);
return (
    <div>
        <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
        />
        <table className="table" {...getTableProps()}>
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}>
                                {column.render('Header')}
                                {/* Render the columns filter UI */}
                                <div>{column.canFilter ? column.render('Filter') : null}</div>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
                prepareRow(row)
                return (
                <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    })}
                </tr>
                )
            })}
            </tbody>
        </table>
        <br />
        <div className="pagination">
        <button className="page-link" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {'<<'}
        </button>{' '}
        <button className="page-link" onClick={() => previousPage()} disabled={!canPreviousPage}>
            {'<'}
        </button>{' '}
        <button className="page-link" onClick={() => nextPage()} disabled={!canNextPage}>
            {'>'}
        </button>{' '}
        <button className="page-link" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {'>>'}
        </button>{' '}
        <span className="page-link">
            Page{' '}
            <strong>
            {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
        </span>
        <span className="page-link">
            | Go to page:{' '}
            <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                gotoPage(page)
            }}
            style={{ width: '100px' }}
            />
        </span>{' '}
        <select className="page-link"
            value={pageSize}
            onChange={e => {
            setPageSize(Number(e.target.value))
            }}
        >
            {[5, 10, 15, 20, 25].map(pageSize => (
            <option key={pageSize} value={pageSize}>
                Show {pageSize}
            </option>
            ))}
        </select>
      </div>
	  <div className="row mt-5">
			<div className="col-lg-6">
				<RenderDesactivarMonitoreo selectedRowIds={selectedRowIds} monitoreos={monitoreos}/>
		  	</div>
		  	<div className="col-lg-6">
				<RenderGuardarCambios data={data} selectedRowIds={selectedRowIds} monitoreos={monitoreos}/>
		  	</div>
	  </div>
    </div>
)
}
function TablaMonitoreos(dataset,monitoreos){
const columns = React.useMemo(
    () => [
        {
            Header: 'Monitoreo',
            columns: [
                {
                    Header: 'Nombre',
                    accessor: 'nombre',
					Cell: EditableCell
                },
                {
                    Header: 'Descripcion',
                    accessor: 'descripcion',
					Cell: EditableCell
                },
                {
                    Header: 'Variables',
                    accessor: 'variables'
                },
            ],
        },
    ],
    []
)
	const [data, setData] = React.useState(() =>dataset)
	const [selectedRows, setSelectedRows] = React.useState({});
    const [skipPageReset, setSkipPageReset] = React.useState(false)
  
    const updateMyData = (rowIndex, columnId, value) => {
      // We also turn on the flag to not reset the page
      setSkipPageReset(true)
      setData(old =>
        old.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...old[rowIndex],
              [columnId]: value,
            }
          }
          return row
        })
      )
    }
  
    // After data chagnes, we turn the flag back off
    // so that if data actually changes when we're not
    // editing it, the page is reset
    React.useEffect(() => {
      setSkipPageReset(false)
    }, [data])
	return (
	<div className="table-responsive">
		<Table
		columns={columns}
		data={data}
		updateMyData={updateMyData}
		setSelectedRows={setSelectedRows}
		monitoreos={monitoreos}
		/>
		<br/>
		<br/>

	</div>
	)
}

class TablaMonitoreo extends Component {
    constructor(props){
        super(props);
        this.state={
            Rol: this.props.Rol,
            Token: this.props.Token,
            Iduser: this.props.Iduser,
            piechartdata: [],
            especie:{
                id:[],
                nombreCientifico:[],
                nombreComun:[],
                activo:[],
                idLocalizacion:[],
              },
            variableRegistro:{
              id:[],
              idEntrada:[],
              idVariable:[],
              valor:[],
            },
            entradaMonitoreo:{
              id:[],
              idLocalizacion:[],
              curado:[],
              idRegistro:[],
              idEspecies:[],
            },
            variable:{
              id:[],
              idMonitoreo:[],
              nombre:[]
            },
            variablexindicador:{
              id:[],
              idIndicador:[],
              idVariable:[]
            },
            indicador:{
                id:[],
                idIndicador:[],
                idVariable:[]
              },
            monitoreo:{
              id:[],
              nombre:[],
              descripcion:[],
            },
            registroMonitoreo:{
              id:[],
              nombre:[],
              idUser:[],
              idMonitoreo:[],
              date:[],
            },
            monitoreos:[],
            location:[],
    
        }
        this.Getespecies();
        this.initial_state();
      }
	//PETICIONES
	Getespecies=async()=>{
	var baseurl = "https://api.restaured.net"
	const url = baseurl+'/restaured/especie'
	await axios.get(url, {withCredentials:true})
	.then(response => response.data)
	.then(data => {
		var id =[];
		var nombreCientifico =[];
		var nombreComun =[];
		var idLocalizacion =[];
		var activo =[];
		for(const i in data.body){
		id.push(data.body[i]._id)
		idLocalizacion.push(data.body[i].idLocalizacion)
		nombreCientifico.push(data.body[i].nombreCientifico)
		nombreComun.push(data.body[i].nombreComun)
		activo.push(data.body[i].activo)
		};
		this.setState({
		especie:{
			id:id,
			idLocalizacion:idLocalizacion,
			nombreCientifico:nombreCientifico,
			nombreComun:nombreComun,
			activo:activo,
		}});
	})
	// console.log(this.state.especies);
	} 
	GetDataTable=async()=>{
	var baseurl = "https://api.restaured.net"
	var url = baseurl+'/restaured/variable'
	await axios.get(url,{withCredentials: true})
	.then(response => response.data)
	.then(data => {
		// console.log(data)
		var id =[];
		var idMonitoreo =[];
		var nombre =[];
		for(const i in data.body){
		id.push(data.body[i]._id);
		idMonitoreo.push(data.body[i].idMonitoreo);
		nombre.push(data.body[i].nombre);
		};
		this.setState({
		variable:{
				id:id,
				idMonitoreo:idMonitoreo,
				nombre:nombre,
		}})
	})
	url = baseurl+'/restaured/variablexindicador'
	await axios.get(url,{withCredentials:true})
	.then(response => response.data)
	.then(data => {
		// console.log(data)
		var id =[];
		var idIndicador =[];
		var idVariable =[];
		for(const i in data.body){
		id.push(data.body[i]._id);
		idIndicador.push(data.body[i].idIndicador);
		idVariable.push(data.body[i].idVariable);
		};
		this.setState({
		variablexindicador:{
				id:id,
				idIndicador:idIndicador,
				idVariable:idVariable,
		}})
	})
	url = baseurl+'/restaured/indicador'
	await axios.get(url, {withCredentials:true})
	.then(response => response.data)
	.then(data => {
		// console.log(data)
		var id =[];
		var idMonitoreo =[];
		var formula =[];
		var nombre =[];
		var pubObj =[];
		var tipo =[];
		var tipoGrafico =[];
		var unidades =[];
		for(const i in data.body){
		id.push(data.body[i]._id);
		idMonitoreo.push(data.body[i].idMonitoreo);
		formula.push(data.body[i].formula);
		nombre.push(data.body[i].nombre);
		pubObj.push(data.body[i].pubObj);
		tipo.push(data.body[i].tipo);
		tipoGrafico.push(data.body[i].tipoGrafico);
		unidades.push(data.body[i].unidades);
		};
		this.setState({
		indicador:{
			id:id,
			idMonitoreo:idMonitoreo,
			formula:formula,
			nombre:nombre,
			pubObj:pubObj,
			tipo:tipo,
			tipoGrafico:tipoGrafico,
			unidades:unidades,
		}})
	})
	// await axios.get('http://localhost:5000/restaured/localizacion', {withCredentials:true})
	// .then(response => response.data)
	// .then(data => {
		// console.log(data)
		// var id =[];
		// var idMonitoreo =[];
		// var nombre =[];
		// for(const i in data.body){
		//   id.push(data.body[i].id);
		//   idMonitoreo.push(data.body[i].idMonitoreo);
		//   nombre.push(data.body[i].nombre);
		// };
		// this.setState({
		//   variable:{
		//         id:id,
		//         idMonitoreo:idMonitoreo,
		//         nombre:nombre,
		// }})
	// })
	url = baseurl+'/restaured/monitoreo'
	await axios.get(url, {withCredentials:true})
	.then(response => response.data)
	.then(data => {
		// console.log(data)
		var id =[];
		var nombre =[];
		var descripcion =[];
		for(const i in data.body){
		id.push(data.body[i]._id);
		nombre.push(data.body[i].nombre);
		descripcion.push(data.body[i].descripcion);
		};
		this.setState({
		monitoreo:{
			id:id,
			nombre:nombre,
			descripcion:descripcion,
		}})
	})
	url = baseurl+'/restaured/registromonitoreo'
	await axios.get(url, {withCredentials:true})
	.then(response => response.data)
	.then(data => {
		//Extraer Fecha y agegarla al modelo Monitoreo
		// var date= new Date(data.body[0].date*1000);
		// console.log(date);
		// console.log(data.body[0]);
		var id =[];
		var nombre =[];
		var idMonitoreo =[];
		var idUser =[];
		var date=[]
		for(const i in data.body){
		id.push(data.body[i]._id);
		nombre.push(data.body[i].nombre);
		idUser.push(data.body[i].idUser);
		idMonitoreo.push(data.body[i].idMonitoreo);
		date.push(data.body[i].date);
		};
		this.setState({
		registroMonitoreo:{
			id:id,
			nombre:nombre,
			idUser:idUser,
			idMonitoreo:idMonitoreo,
			date:date,
		}})
	})
	url = baseurl+'/restaured/entradamonitoreo'
	await axios.get(url, {withCredentials:true})
	.then(response => response.data)
	.then(data => {
		//Extraer Localización y agegarla al modelo Monitoreo
		// console.log(data)
		var id =[];
		var idLocalizacion =[];
		var curado =[];
		var idRegistro =[];
		var idEspecies =[];
		for(const i in data.body){
		id.push(data.body[i]._id);
		idLocalizacion.push(data.body[i].idLocalizacion);
		curado.push(data.body[i].curado);
		idRegistro.push(data.body[i].idRegistro);
		idEspecies.push(data.body[i].idEspecie);
		};
		this.setState({
		entradaMonitoreo:{
				id:id,
				idLocalizacion:idLocalizacion,
				curado:curado,
				idRegistro:idRegistro,
				idEspecies:idEspecies,
		}})
	})
	url = baseurl+'/restaured/variableregistro'
	await axios.get(url, {withCredentials:true})
	.then(response => response.data)
	.then(data => {
		// console.log(data)
		var id =[];
		var idEntrada =[];
		var idVariable =[];
		var valor =[];
		for(const i in data.body){
		id.push(data.body[i]._id);
		idEntrada.push(data.body[i].idEntrada);
		idVariable.push(data.body[i].idVariable);
		valor.push(data.body[i].valor);
		};
		this.setState({
		variableRegistro:{
				id:id,
				idEntrada:idEntrada,
				idVariable:idVariable,
				valor:valor,
		}})
	})
	}
	viewdata=async()=>{
	await this.GetDataTable()
	var monitoreo={
		id:0,
		nombre:"",
		descripcion:"",
		variables:[],
		registros:[],
		indicadores:[],
	};
	var indicadores = {
		id:[],
		idMonitoreo:[],
		formula:[],
		nombre:[],
		pubObj:[],
		tipo:[],
		tipoGrafico:[],
		unidades:[],
	};
	var registros={
		id:[],
		nombre:[],
		entradas:[],
		date:[],
	};
	var entradas={
		id:[],  
		variables:[],
		especies:[],
		valores:[],
		curado:[],
	};
	var monitoreos=[]
	var valores=[];
	var variables=[];
	var variableEntrada=[];
	var valorEntrada=[];
	for(const i in this.state.monitoreo.id){
		monitoreo.id=this.state.monitoreo.id[i]
		monitoreo.nombre=this.state.monitoreo.nombre[i]
		monitoreo.descripcion=this.state.monitoreo.descripcion[i]
		for(const j in this.state.variable.nombre){
		if(this.state.variable.idMonitoreo[j]===this.state.monitoreo.id[i]){
			var variable={
			id:this.state.variable.id[j],
			nombre:this.state.variable.nombre[j]
			}
			monitoreo.variables.push(variable);
		}
		}
		for(const j in this.state.indicador.id){
		if(this.state.indicador.idMonitoreo[j]===this.state.monitoreo.id[i]){
			indicadores.id.push(this.state.indicador.id[j])
			indicadores.idMonitoreo.push(this.state.indicador.idMonitoreo[j])
			indicadores.formula.push(this.state.indicador.formula[j])
			indicadores.nombre.push(this.state.indicador.nombre[j])
			indicadores.pubObj.push(this.state.indicador.pubObj[j])
			indicadores.tipo.push(this.state.indicador.tipo[j])
			indicadores.tipoGrafico.push(this.state.indicador.tipoGrafico[j])
			indicadores.unidades.push(this.state.indicador.unidades[j])
		}
		}
		// console.log(this.state.entradaMonitoreo)
		for(const j in this.state.registroMonitoreo.id){
		if(this.state.monitoreo.id[i] === this.state.registroMonitoreo.idMonitoreo[j]){
			registros.id.push(this.state.registroMonitoreo.id[j])
			registros.nombre.push(this.state.registroMonitoreo.nombre[j])
			registros.date.push(this.state.registroMonitoreo.date[j])
			for(const k in this.state.entradaMonitoreo.id){
			if(this.state.registroMonitoreo.id[j] === this.state.entradaMonitoreo.idRegistro[k]){
				entradas.id.push(this.state.entradaMonitoreo.id[k]);
				entradas.curado.push(this.state.entradaMonitoreo.curado[k]);
				entradas.especies.push(this.state.entradaMonitoreo.idEspecies[k]);
				for(const l in this.state.variableRegistro.id){
					if(this.state.entradaMonitoreo.id[k] === this.state.variableRegistro.idEntrada[l]){
					valorEntrada.push(Number(this.state.variableRegistro.valor[l]));
					variableEntrada.push(this.state.variableRegistro.idVariable[l]);
					}
				}
				variables.push(variableEntrada)  
				valores.push(valorEntrada)  
				valorEntrada=[]
				variableEntrada=[]
				}
			}
			entradas.variables=variables;
			entradas.valores=valores;
			variables=[];
			valores=[];
			registros.entradas.push(entradas);
			// console.log(entradas)
			entradas={id:[],variables:[], valores:[], curado:[],especies:[],}
		}
		}
		monitoreo.indicadores=indicadores;
		monitoreo.registros=registros;
		registros={ id:[],nombre:[], entradas:[], date:[] };
		indicadores = { id:[], idMonitoreo:[], formula:[], nombre:[], pubObj:[], tipo:[], tipoGrafico:[], unidades:[],};
		monitoreos.push(monitoreo);
		// console.log(monitoreo)
		monitoreo={ id:0, nombre:"", descripcion:"", variables:[],indicadores:[],};
	}
	// console.log(monitoreos)
	this.setState({
		monitoreos:monitoreos
	})
	}
	initial_state=async()=>{
	await this.viewdata()
	}

    TablaMonitoreos=()=>{
        var dataset=[]
        if(this.state.monitoreos.length > 0){
        var datos={
			id:"",
            nombre:"",
            descripcion:"",
            variables:[]
        }
        for(const i in this.state.monitoreos){
            for(const j in this.state.monitoreos[i].variables){
            datos.variables.push(String(this.state.monitoreos[i].variables[j].nombre)+", ")
            }
            datos.id = String(this.state.monitoreos[i].id)
            datos.nombre = String(this.state.monitoreos[i].nombre)
            datos.descripcion = String(this.state.monitoreos[i].descripcion)
            dataset.push(datos)
            datos={
            id:"",
            nombre:"",
            descripcion:"",
            variables:[]
            }
        }
        var html = TablaMonitoreos(dataset,this.state.monitoreos)
        return(<>
                {html}
                </>
        )
        }else{
        return(<>
            
            </>
        )
        }
    }

    render(){
        return(<>
            <div className="col-xl-12"> 
                <this.TablaMonitoreos/>
            </div>
        </>)
    }
}  

export default TablaMonitoreo