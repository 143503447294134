import React from 'react'
import '../../assets/css/style.css'

export default function NavbarL(){
   if (1){
     return(
        <div>
          <header id="header" className="fixed-top header-transparent">
            <div className="container d-flex align-items-center">
                <div className="logo mr-auto">
                  <h1 className="text-light"><a href="/"><span>RestauRed</span></a></h1>
                </div>
                <div className="nav-menu d-lg-block">
                <ul>
                    <li ><a href="/">Inicio</a></li>
                    <li ><a href="/reportes">Reportes</a></li>
                    <li ><a href="/login">Login</a></li>
                </ul>
                </div>{/* .nav-menu */}
            </div>
            </header>{/* End Header */}
          </div>
     );

   }   
   else{
     return(
           <div> 
               {/* ======= Header ======= */}
             <header id="header" className="fixed-top header-transparent">
               <div className="container d-flex align-items-center">
                 <div className="logo mr-auto">
                   <h1 className="text-light"><a href="/"><span>RestauRed</span></a></h1>
                 </div>
                 <nav className="nav-menu d-none d-lg-block">
                   <ul>
                     <li className="active"><a href="/">Inicio</a></li>
                     <li><a href="../#about">Reportes</a></li>
                     <li><a href="../#services">Estadísticas</a></li>
                     <li><a href="../#portfolio">Galería</a></li>
                     <li className="drop-down"><a href="/#">Login</a>
                       <ul>
                         <li><a href="/login">Login</a></li>
                         <li className="drop-down"><a href="/register">Registro </a></li>
                       </ul>
                     </li> 
                     <li><a href="../#contact">Contáctanos</a></li>
                   </ul>
                 </nav>{/* .nav-menu */}
               </div>
             </header>{/* End Header */}
           </div>
       )
   }
};